import { Button, FormControlLabel, Grid, IconButton } from "@mui/material";
import React from "react";
import TestEditor from "../../../Editor/testEditor";
import CheckBoxPeriod from "./Components/CheckBoxPeriod";
import { formEdit } from "../../Tools/Tools";

export default function DialogPeriodCurrentYear({ props }) {
  const [buttonMap, setButtonMap] = React.useState();
  const [currentYear, setCurrentYear] = React.useState();

  const firstCheck = React.useRef();
  const secondCheck = React.useRef();
  const thirdCheck = React.useRef();
  const fourthCheck = React.useRef();

  const firstState = React.useRef(getMonths(props.ObjRef));

  const checkboxMap = () => {
    return {
      1: firstCheck,
      2: firstCheck,
      3: firstCheck,
      4: secondCheck,
      5: secondCheck,
      6: secondCheck,
      7: thirdCheck,
      8: thirdCheck,
      9: thirdCheck,
      10: fourthCheck,
      11: fourthCheck,
      12: fourthCheck,
    };
  };

  const symbolMap = () => {
    return {
      1: "\u2160",
      2: "\u2161",
      3: "\u2162",
      4: "\u2163",
      5: "\u2164",
      6: "\u2165",
      7: "\u2166",
      8: "\u2167",
      9: "\u2168",
      10: "\u2169",
      11: "\u216a",
      12: "\u216b",
    };
  };

  const checkstateMap = () => {
    return {
      1: [1, 2, 3],
      2: [1, 2, 3],
      3: [1, 2, 3],
      4: [4, 5, 6],
      5: [4, 5, 6],
      6: [4, 5, 6],
      7: [7, 8, 9],
      8: [7, 8, 9],
      9: [7, 8, 9],
      10: [10, 11, 12],
      11: [10, 11, 12],
      12: [10, 11, 12],
    };
  };
  const getCheckStateMap = () => {
    return {
      1: [1, 2, 3],
      2: [4, 5, 6],
      3: [7, 8, 9],
      4: [10, 11, 12],
    };
  };

  React.useEffect(() => {
    setButtonMap(firstState.current);
  }, []);

  React.useEffect(() => {
    if (buttonMap) {
      setCurrentYear(buttonMap.currentYear);
    }
  }, [buttonMap]);

  // парсинг числа на чекбоксы и год
  function getMonths(objref) {
    if (buttonMap !== undefined) return;

    const result = {};

    // получение числа чекбоксов
    let checkBoxes = objref & 0xfff;

    // получение значения каждого чекбокса
    for (let i = 0; i < 12; i++) {
      if ((checkBoxes >> i) & 0b1) {
        result[i + 1] = true;
      } else {
        result[i + 1] = false;
      }
    }

    // получение года
    result.year = objref >> 15;

    // если число меньше 4095 значит стоит галка текущий год
    result.currentYear = objref > 4095 ? false : true;

    return result;
  }

  function buttonClick(ev) {
    const checkbox = checkboxMap()[ev.target.dataset.number];
    if (ev.target.dataset.pressed === "true") {
      buttonMap[ev.target.dataset.number] = false;
    } else {
      buttonMap[ev.target.dataset.number] = true;
    }
    ev.target.dataset.pressed = ev.target.dataset.pressed === "false" ? "true" : "false";
    checkbox.current(checkboxCheck(ev.target.dataset.number));
    setButtonMap({ ...buttonMap });
  }

  function checkboxCheck(index) {
    const states = checkstateMap()[index];
    if (buttonMap[states[0]] && buttonMap[states[1]] && buttonMap[states[2]]) {
      return 1;
    } else if (buttonMap[states[0]] || buttonMap[states[1]] || buttonMap[states[2]]) {
      return 2;
    } else {
      return 0;
    }
  }

  function getFirstCheckboxState(index) {
    let buttonMap = firstState.current;
    const states = getCheckStateMap()[index];
    if (buttonMap[states[0]] && buttonMap[states[1]] && buttonMap[states[2]]) {
      return 1;
    } else if (buttonMap[states[0]] || buttonMap[states[1]] || buttonMap[states[2]]) {
      return 2;
    } else {
      return 0;
    }
  }

  function onYearEdit(data) {
    buttonMap.year = data.value > 3000 ? 3000 : data.value;
    setButtonMap({ ...buttonMap });
  }

  // сбор числа для сервера
  function getTag(data) {
    let result = 0;

    // сбор значений чекбоксов в число
    for (let i = 12; i > 0; i--) {
      result += (data[i] ? 1 : 0) << (i - 1);
    }

    if (data.currentYear) return result;

    // добавление года в число(сдвиг на 15 битов влево) + число чекбоксов
    return (data.year << 15) | result;
  }

  async function onSelect() {
    if (props.onEdit) {
      props.onEdit({
        tag: getTag(buttonMap),
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }
    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: getTag(buttonMap),
        },
        props.RequestID,
        props.from,
      );
    }
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: "calc(100% - 50px)" }}
      >
        <Grid
          item
          style={{
            height: "60px",
            width: "441px",
            border: "1px solid #cccccc",
            paddingTop: "11px",
            paddingLeft: "4.4px",
            borderRadius: "5px",
          }}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            {buttonMap ? (
              Object.values(buttonMap).map((item, index) => {
                if (index > 11) return null;
                return (
                  <IconButton
                    key={`month-bttn_${index}`}
                    data-pressed={item}
                    data-number={index + 1}
                    style={{
                      width: "34px",
                      height: "34px",
                      borderRadius: "10%",
                      border: "1px solid #cccccc",
                      marginRight: "2px",
                      backgroundColor: item ? "#efefef" : "",
                      fontSize: "130%",
                    }}
                    onClick={buttonClick}
                  >
                    {symbolMap()[index + 1]}
                  </IconButton>
                );
              })
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <div
            style={{
              position: "absolute",
              top: "124px",
              left: "40px",
              background: "white",
              color: "#aaaaaa",
            }}
          >
            <span>Год</span>
          </div>
          <div
            style={{
              position: "absolute",
              top: "124px",
              left: "185px",
              background: "white",
              color: "#aaaaaa",
            }}
          >
            <span>Квартал</span>
          </div>
          <div
            style={{
              position: "absolute",
              top: "48px",
              left: "40px",
              background: "white",
              color: "#aaaaaa",
            }}
          >
            <span>Периоды текущего года</span>
          </div>
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid
              item
              style={{
                width: "140px",
                height: "80px",
                border: "1px solid #cccccc",
                borderRadius: "5px",
                marginTop: "15px",
                marginRight: "5px",
              }}
            >
              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                <div
                  style={{
                    paddingLeft: "15px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    control={
                      <CheckBoxPeriod
                        CheckState={buttonMap ? (buttonMap.currentYear ? 1 : 0) : 0}
                        onChange={(data) => {
                          setCurrentYear(!currentYear);
                          buttonMap.currentYear = !buttonMap.currentYear;
                          setButtonMap({ ...buttonMap });
                        }}
                      />
                    }
                    label={<label style={{ fontSize: "90%" }}>Текущий</label>}
                  />
                </div>
                <div
                  style={{
                    border: "1px solid #eeeeee",
                    width: "80%",
                    marginLeft: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div>
                    {currentYear ? (
                      <div
                        style={{
                          paddingLeft: "3px",
                          paddingTop: "2px",
                          height: "21px",
                          fontSize: "13px",
                          letterSpacing: "0px",
                        }}
                      >
                        {new Date().getFullYear()}
                      </div>
                    ) : (
                      <TestEditor
                        value={`${buttonMap && buttonMap.year ? buttonMap.year : new Date().getFullYear()}`}
                        onEdit={onYearEdit}
                        editStyle={"8"}
                      />
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                width: "297px",
                height: "80px",
                border: "1px solid #cccccc",
                borderRadius: "5px",
                marginTop: "15px",
              }}
            >
              <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                <Grid item style={{ width: "50%" }}>
                  <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <div style={{ marginTop: "10px", paddingLeft: "15px" }}>
                      <FormControlLabel
                        sx={{
                          userSelect: "none",
                        }}
                        control={
                          <CheckBoxPeriod
                            checkId={1}
                            CheckState={getFirstCheckboxState(1)}
                            checkRef={firstCheck}
                            onChange={(data) => {
                              buttonMap[1] = data.checked;
                              buttonMap[2] = data.checked;
                              buttonMap[3] = data.checked;
                              setButtonMap({ ...buttonMap });
                            }}
                          />
                        }
                        label="1-й квартал"
                      />
                    </div>
                    <div style={{ marginTop: "10px", paddingLeft: "15px" }}>
                      <FormControlLabel
                        sx={{
                          userSelect: "none",
                        }}
                        control={
                          <CheckBoxPeriod
                            checkId={2}
                            CheckState={getFirstCheckboxState(2)}
                            checkRef={secondCheck}
                            onChange={(data) => {
                              buttonMap[4] = data.checked;
                              buttonMap[5] = data.checked;
                              buttonMap[6] = data.checked;
                              setButtonMap({ ...buttonMap });
                            }}
                          />
                        }
                        label="2-й квартал"
                      />
                    </div>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <div style={{ marginTop: "10px", marginLeft: "15px" }}>
                      <FormControlLabel
                        sx={{
                          userSelect: "none",
                        }}
                        control={
                          <CheckBoxPeriod
                            checkId={3}
                            CheckState={getFirstCheckboxState(3)}
                            checkRef={thirdCheck}
                            onChange={(data) => {
                              buttonMap[7] = data.checked;
                              buttonMap[8] = data.checked;
                              buttonMap[9] = data.checked;
                              setButtonMap({ ...buttonMap });
                            }}
                          />
                        }
                        label="3-й квартал"
                      />
                    </div>
                    <div style={{ marginTop: "10px", marginLeft: "15px" }}>
                      <FormControlLabel
                        sx={{
                          userSelect: "none",
                        }}
                        control={
                          <CheckBoxPeriod
                            checkId={4}
                            CheckState={getFirstCheckboxState(4)}
                            checkRef={fourthCheck}
                            onChange={(data) => {
                              buttonMap[10] = data.checked;
                              buttonMap[11] = data.checked;
                              buttonMap[12] = data.checked;
                              setButtonMap({ ...buttonMap });
                            }}
                          />
                        }
                        label="4-й квартал"
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          height: "40px",
          marginTop: "5px",
        }}
      >
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ paddingTop: "8px" }}>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
