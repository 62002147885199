// "use strict";
import { XMLrequest } from "../../Url";
// import Editor from "../Tools/Editor/Editor";
// import * as React from 'react';
// import ReactDOM from 'react-dom';
import { createGrid, createRecordSource } from "./rcsgrid";

const ManWhoSoldTheWorld = (data) => {
  var //   dataId = 0,
    source = null,
    grid = null;

  function Init(data) {
    //initGrid(document.getElementById("gridPanel"));

    function initGrid(gridPanel, data) {
      source = new createRecordSource();
      source.onHandleRequest = function (request) {
        let params = new Map();
        switch (data.who) {
          case "dbview":
            params.set("prefix", "dbview");
            params.set("comand", "handleTable");
            params.set("id", data.id);
            break;
          case "Forms":
            params.set("prefix", "programs");
            params.set("comand", "HandleTable");
            params.set("Path", data.path);
            params.set("SectionID", data.id);
            break;
          case "Documents":
            params.set("prefix", "documents");
            params.set("comand", "HandleDocument");
            params.set("DocCfgID", data.DocCfgID);
            params.set("SectionID", data.id);
            params.set("ParentID", data.ParentID === undefined ? 0 : data.ParentID);
            break;
          default:
            break;
        }
        return XMLrequest(params, request);
      };
      gridPanel.grid = new createGrid(gridPanel);
      // if(data.Columns){
      //     let columns = {title: []}, dest, sour;
      //     for (const [key, value] of Object.entries(data.Columns)) {
      //         sour = value;
      //         if (sour.FieldName) {
      //             dest = {};
      //             dest.fieldName = sour.FieldName;
      //             if (sour.Width) dest.width = parseInt(sour.Width)
      //             else dest.width = 100;
      //             if (sour.Level) dest.level = parseInt(sour.Level)
      //             else dest.level = 0;
      //             columns.title.push(dest);
      //         }
      //     }
      //     gridPanel.grid.setColumns(columns);
      // }else gridPanel.grid.defaultColumns = true;

      gridPanel.grid.defaultColumns = true;
      grid = gridPanel.grid;

      grid.setSource(null);
      source.close();
      source.open();
      if (data.state !== undefined) {
        source.onRecordIndexChanged = function (source) {
          data.state(source.getFieldText("ID"));
        };
      }

      grid.setSource(source);
      grid.refreshSource();

      // (source.getFieldText("ID"))
    }

    var grP = document.getElementById(`gridpanel${data.searchID === undefined ? data.id : data.searchID}`);
    if (grP) {
      grP.innerHTML = "";
      initGrid(grP, data);
    }
  }

  return <>{Init(data)}</>;
};
export default ManWhoSoldTheWorld;
