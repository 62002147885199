import React from "react";
import ContentOrganization from "./Components/ContentOrganization";
import TestTree from "../../Windows/ViewData/Tree/testTree";
import { GridLoader } from "../../rcsgrid/createGridTools";
import {
  GetDialogParamsForSearch,
  PostHandleTable,
  treeRequest,
  handleTable,
  getBookContextMenu,
} from "../../Tools/Requests";
import { parseColumns } from "../../rcsgrid/GridTools/parseColumnTools";
import { TabItem } from "smart-webcomponents-react/tabs";
import Search from "./Components/Search";
import TestParams from "../../Sections/ElementsSections/testParams";
import { AxiosRequest } from "../../../Url";
import IMemo from "../../Sections/ElementsSections/Forms/Components/IMemo";
import { CreateCollapsibleRecords } from "../../rcsgrid/GridTools/collapsibleRecordsTools";
import LoadingMask from "../../NotWorkArea(Side&Head)/LoadingMask";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import { tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import { formEdit, getRightsData } from "../../Tools/Tools";
import { getDetailContent, updateTabContentFunc } from "./Components/DialogDetailsTools";

async function saveCurrentGrid(data) {
  const json = await PostHandleTable(
    data.props.Module,
    "0",
    data.groupID,
    data.props.SectionID,
    data.OrgTypeCls ? data.OrgTypeCls : "0",
  );

  if (json) {
    data.gridObject.source.rights = getRightsData(json.Rights);
  }

  if (json && json.Details && data.detailValues) {
    data.detailValues.set(data.groupID, json.Details.items);
  }
  const objType = json ? json.ObjType : data.props.ObjType;

  const columns = parseColumns(json, undefined, "Name");

  data.gridObject.source.onHandleRequest = handleTable({
    Module: data.props.Module,
    ObjType: objType,
    GroupID: data.groupID,
    selectedRecordID: data.ObjRef ? data.ObjRef : "0",
    SectionID: data.props.SectionID,
    OrgTypeCls: data.OrgTypeCls ? data.OrgTypeCls : "0",
  });

  data.gridObject.source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  data.gridObject.grid.setColumns(columns);

  // await data.gridObject.source.first();

  data.gridObject.source.onRecordIndexChanged = function (source) {
    data.setSelectedRecordID(source.getFieldTextSync("ID"));
  };

  data.gridObject.source.onRecordCollapsed = async function (event) {
    let RecordIndex;
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = event;
    }
    const params = new Map();
    params
      .set("prefix", data.props.Module)
      .set("comand", "RecordCollapsed")
      .set("SectionID", data.SectionID)
      .set("ObjType", objType)
      .set("RecordIndex", RecordIndex);
    await AxiosRequest(true, params);
    await data.gridObject.grid.refreshSource("!");
  };

  await data.gridObject.grid.setSource(data.gridObject.source);
  await data.gridObject.grid.refreshSource(data.ObjRef ? data.ObjRef : "0");
  data.gridObject.grid.updateGridSize();

  const id = data.gridObject.source.getFieldTextSync("ID");

  data.setSelectedRecordID(id);
  data.mainSources.set(data.groupID, {
    columns: columns,
    ID: id,
    request: handleTable,
    objType: objType,
  });
}

export default function DialogNFA({ props }) {
  const [mainGrid, setMainGrid] = React.useState(undefined);
  const [selectedRecordID, setSelectedRecordID] = React.useState(props.ObjRef);
  const [selectRecord, setSelectRecord] = React.useState();

  //хук для отображения количества записей
  const [countRecords, setCountRecords] = React.useState("0");

  const gridPanel = React.useRef();
  const tabsPanel = React.useRef();

  const saveText = React.useRef();

  //хук выбора номера(ID) таблицы из дерева
  const [groupID, setGroupID] = React.useState(props.NodeID ? props.NodeID : "0");

  //хук для поиска
  const [searchResult, setSearchResult] = React.useState();

  //хуки для хранения Sources Для Таблиц
  const mainSources = React.useRef(new Map());

  //Сохранение значений деталей
  const detailValues = React.useRef(new Map());

  const [needUpdate, setNeedUpdate] = React.useState(false);
  const [groupIDChange, setGroupIDChange] = React.useState(true);

  const [orgTypeCls, setOrgTypeCls] = React.useState();

  //хук состаяния для деталей
  const [tabs, setTabs] = React.useState();

  const [timer, setTimer] = React.useState(null);

  //отрисовка Дерева
  const [tree, setTree] = React.useState(null);

  React.useEffect(() => {
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            CLSID: props.CLSID,
            multiCheck: false,
            ObjType: props.ObjType,
            SectionID: props.SectionID,
            Module: props.Module,
            Current: groupID,
            ObjRef: groupID,
            selectID: setGroupID,
            UsedDate: "0",
            Info: "0",
            Internal: "0",
          }}
        />,
      );
    }
    if (tree) {
      setMainGridLoader();
    }
  }, [tree]);

  React.useEffect(() => {
    if (orgTypeCls !== undefined) {
      mainSources.current = new Map();
      if (groupID && tree) {
        setMainSource();
      }
    }
  }, [orgTypeCls]);

  React.useLayoutEffect(() => {
    //отрисовка Деталей
    if (mainGrid && groupID != "0") {
      //Сохранение первого Source
      saveCurrentGrid({
        props: props,
        gridObject: mainGrid,
        groupID: groupID,
        setSelectedRecordID: setSelectedRecordID,
        selectedRecordID: selectedRecordID,
        mainSources: mainSources.current,
        ObjRef: selectedRecordID ? selectedRecordID : "0",
        detailValues: detailValues.current,
        OrgTypeCls: orgTypeCls,
        SectionID: props.SectionID,
      }).then(() => {
        //количество записей в таблице
        setCountRecords(mainGrid.source.recordCount);

        setTabs(getTabs(groupID));
        setTimeout(() => {
          setNeedUpdate(true);
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainGrid]);

  React.useLayoutEffect(() => {
    if (mainGrid) {
      //Выбор Source
      if (groupID && groupID !== "0") {
        setMainSource();
      }

      //Количество записей в таблице
      if (mainGrid) setCountRecords(mainGrid.source.recordCount);

      //выбор записи для дополнительных таблиц при переключении между таблицами в дереве
      if (mainSources.current.get(groupID)) {
        setSelectedRecordID(mainSources.current.get(groupID).ID);
      }

      setGroupIDChange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupID]);

  //Обновление Деталей, в зависимости от выбранной записи в основной таблице
  React.useLayoutEffect(() => {
    onRecChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID]);

  function onRecChange() {
    if (mainGrid !== undefined && mainSources.current.get(groupID)) {
      mainSources.current.get(groupID).ID = selectedRecordID;
    }
    if (mainGrid !== undefined) {
      const tabID = tabsPanel.current.selectedIndex;
      updateTab(tabID, tabs);
    }
  }

  //обновление в зависимости от результатов поиска
  React.useLayoutEffect(() => {
    if (mainGrid && searchResult) {
      // получение id таблицы для записи из поиска
      const id = GetDialogParamsForSearch(props.Module, props.ObjType, searchResult).NodeID;
      //если id таблицы совпадает с id выбранной таблицы то позиционирумся на запись из поиска
      if (id === groupID) {
        findSearchRecordFromTable();
        //если не совпадает, то очищаем дерево, что в последствии инициирует создание нового дерева с нужным GroupID
      } else {
        setTree(null);
        setGroupID(id);
        setTabs(getTabs(id));
        setTimeout(() => {
          setNeedUpdate(true);
        });

        setGroupIDChange(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  React.useLayoutEffect(() => {
    if (needUpdate) {
      updateTab(tabsPanel.current.selectedIndex, tabs);
      setNeedUpdate(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdate]);

  async function setMainGridLoader() {
    if (!mainGrid) {
      setMainGrid(
        await GridLoader(gridPanel.current, props, {
          main: true,
          GroupID: groupID,
          ReturnRecord: setSelectedRecordID,
          selectedRecordID: selectedRecordID,
        }),
      );
    }
  }

  async function setMainSource() {
    if (mainGrid && mainSources.current.get(groupID) === undefined) {
      //Сохранение новых Sources если они не были сохранены ранее
      await saveCurrentGrid({
        props: props,
        gridObject: mainGrid,
        groupID: groupID,
        setSelectedRecordID: setSelectedRecordID,
        selectedRecordID: selectedRecordID,
        mainSources: mainSources.current,
        detailValues: detailValues.current,
        OrgTypeCls: orgTypeCls,
        SectionID: props.SectionID,
      });
    } else if (mainGrid) {
      //Выбор Source в зависимости от выбранной таблицы в дереве
      const mainSource = mainSources.current.get(groupID);
      mainGrid.grid.setColumns(mainSource.columns);
      mainGrid.source.onHandleRequest = mainSource.request({
        Module: props.Module,
        ObjType: mainSource.objType,
        GroupID: groupID,
        selectedRecordID: mainSource.ID ? mainSource.ID : "0",
        SectionID: props.SectionID,
        OrgTypeCls: orgTypeCls,
      });
      mainGrid.source.onRecordCollapsed = async function (event) {
        let RecordIndex;
        if (event.currentTarget !== undefined) {
          RecordIndex = event.currentTarget.getAttribute("recordindex");
        } else {
          RecordIndex = event;
        }
        let params = new Map();
        params
          .set("prefix", props.Module)
          .set("comand", "RecordCollapsed")
          .set("SectionID", props.SectionID)
          .set("ObjType", mainSource.objType)
          .set("RecordIndex", RecordIndex);
        await AxiosRequest(true, params);
        await mainGrid.grid.refreshSource("!");
      };
      if (!(selectRecord || searchResult)) {
        await mainGrid.grid.refreshSource(mainSource.ID);
      }
      await mainGrid.grid.setSource(mainGrid.source);
      mainGrid.grid.updateGridSize();
    }

    // позиционирование таблицы при выборе записи из функций
    if (selectRecord) {
      await findSelectRecordFromTable();
      return;
    }

    // позиционирование таблицы при выборе значений из поиска
    if (searchResult) {
      await findSearchRecordFromTable();
      return;
    }

    if (mainGrid) {
      setCountRecords(mainGrid.source.recordCount);
    }

    setTabs(getTabs(groupID));
    setTimeout(() => {
      setNeedUpdate(true);
    });
  }

  async function findSearchRecordFromTable() {
    await mainGrid.grid.refreshSource(searchResult);
    setSelectedRecordID(searchResult);
    setSearchResult(undefined);
  }

  async function findSelectRecordFromTable() {
    await mainGrid.grid.refreshSource(selectRecord.ID);
    setSelectedRecordID(selectRecord.ID);
    setSelectRecord(undefined);
  }

  function getTabs(groupID) {
    const details = detailValues.current.get(groupID)
      ? detailValues.current.get(groupID).map((item, index) => {
          return getLoadTab(item, index);
        })
      : [];
    const defaultTabs = getDefaultTabs();
    return [...details, ...defaultTabs];
  }

  function getLoadTab(detailItem, index) {
    return (
      <TabItem
        id={`LoadTab_${detailItem.Caption}`}
        key={`TabItem_${detailItem.Caption}_${index}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={detailItem.Caption}
      >
        <div
          id={`item_${detailItem.Caption}`}
          load="true"
          style={{ width: "100%", height: "100%", position: "relative" }}
        >
          <LoadingMask />
        </div>
      </TabItem>
    );
  }

  function getFullTab(detailItem, content, index) {
    return (
      <TabItem
        id={`Tab_${detailItem.Caption}`}
        key={`TabItem_${detailItem.Caption}_${index}`}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={detailItem.Caption}
      >
        <div id={`item_${detailItem.Caption}`} style={{ width: "100%", height: "100%", position: "relative" }}>
          {content}
        </div>
      </TabItem>
    );
  }

  function updateTabsContent(data, firstLoad) {
    if (firstLoad) {
      data.firstLoadFunc();
    } else {
      updateTabContentFunc(data);
    }
  }

  function getContentForTab(detail) {
    if (detail.Params.prm !== undefined) {
      return "params";
    }
    if (detail.Params.ProgID !== undefined) {
      return "documents";
    }

    return "emptyTab";
  }

  function getReqForContent(selector) {
    switch (selector) {
      case "params":
        return async (data) => {
          return handleParametersNfa(data.Module, data.detailItem, data.selectedRecordID);
        };
      default:
        break;
    }
  }

  // функция Обновления контента Деталей
  function updateTab(index, tabs) {
    if (index !== undefined && tabs) {
      clearTimeout(timer);
      const newTimer = setTimeout(() => {
        if (tabs[index] && tabs[index].key === "tabItem_Text") {
          const [text, id] = Object.values(mainGrid.source.getActiveRecordText());
          tabs[index] = (
            <TabItem
              key={"tabItem_Text"}
              style={{
                textTransform: "none",
                width: "100%",
                height: "100%",
                padding: "0px",
                display: "inline-block",
              }}
              label={"Описание"}
            >
              <div id={"item_Text"} style={{ height: "100%", width: "100%" }}>
                <IMemo
                  Text={text}
                  style={{
                    height: "calc(100% - 3px)",
                    width: "100%",
                    outline: "0",
                    resize: "none",
                  }}
                  onBlur={TextSave}
                  onChange={onTextChange}
                />
              </div>
            </TabItem>
          );
          setTabs([...tabs]);
          return;
        }

        if (tabs[index] && tabs[index].key === "tabItem_Search" && groupIDChange) {
          if (tabs[index].props.id !== `LoadTab_Search`) {
            tabs[index] = getLoadSearch();
            setTabs([...tabs]);
          }
          tabs[index] = getSearch();
          setTabs([...tabs]);
          setGroupIDChange(false);
          return;
        }
        if (!detailValues.current || !detailValues.current.get(groupID)) return;

        const detailItem = detailValues.current.get(groupID)[index];

        if (!detailItem || !detailItem.Params) return;
        const selector = getContentForTab(detailItem, index);

        const blockTab = tabsPanel.current.nativeElement.getTabContent(index);

        const firstLoad = blockTab.querySelector("div[load='true']");

        updateTabsContent(
          {
            Module: props.Module,
            detailItem: detailItem,
            selectedRecordID: selectedRecordID,
            index: index,
            element: blockTab.ownerElement,
            selector: selector,
            SectionID: props.SectionID,
            onRequest: getReqForContent(selector),
            firstLoadFunc: () => {
              const tabContent = getDetailContent(selector, props, {
                selectedRecordID: selectedRecordID,
                onRequest: getReqForContent(selector),
                detailItem: detailItem,
              });
              const tab = getFullTab(detailItem, tabContent, index);

              tabs[index] = tab;
              setTabs([...tabs]);
            },
          },
          firstLoad,
        );
      }, 100);
      setTimer(newTimer);
    }
  }

  function getDefaultTabs() {
    return [getText(), getLoadSearch()];
  }

  function getText() {
    return (
      <TabItem
        key={"tabItem_Text"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={"Описание"}
      >
        <div id={"item_Text"} style={{ height: "100%", width: "100%" }}>
          <textarea
            style={{
              height: "calc(100% - 3px)",
              width: "100%",
              outline: "0",
              resize: "none",
            }}
          />
        </div>
      </TabItem>
    );
  }

  function getLoadSearch() {
    return (
      <TabItem
        key={"tabItem_Search"}
        id={"LoadTab_Search"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={"Поиск"}
      >
        <div id={"item_Search"} style={{ height: "100%", width: "100%" }}>
          <LoadingMask />
        </div>
      </TabItem>
    );
  }

  function getSearch() {
    return (
      <TabItem
        key={"tabItem_Search"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={"Поиск"}
      >
        <div id={"item_Search"} style={{ height: "100%", width: "100%" }}>
          <Search GroupId={groupID} Module={props.Module} ObjType={props.ObjType} return={setSearchResult} />
        </div>
      </TabItem>
    );
  }

  function handleParametersNfa(module, item, selectedRecordID) {
    const params = new Map();
    params
      .set("prefix", module)
      .set("comand", item.Token)
      .set("prm", item.Params.prm)
      .set("Path", item.Params.Path)
      .set("NeedRefresh", "1");
    if (selectedRecordID) params.set("GroupId", selectedRecordID);
    return AxiosRequest(true, params);
  }

  async function TextSave(ev) {
    if (saveText.current) {
      const params = new Map();
      params.set("prefix", props.Module).set("comand", "SetNotes").set("GroupID", selectedRecordID);
      const json = await AxiosRequest(true, params, { text: ev.target.value });
      if (json && json.Result === "1") {
        // mainGrid.source.setActiveRecordText(
        //   Number(ev.target.id),
        //   ev.target.value
        // );
      }
    }
  }

  function onTextChange(ev) {
    if (ev.target.value !== mainGrid.source.getActiveRecordText()) {
      saveText.current = true;
    } else {
      saveText.current = false;
    }
  }

  async function onDropDownList() {
    const params = new Map();
    params.set("prefix", "departments").set("comand", "GetObjectValues").set("ObjType", "2773").set("ObjRef", "0");
    const json = await AxiosRequest(true, params);
    const result = [{ label: "Все учреждения", id: "0" }];
    if (json && json.Items) {
      json.Items.forEach((item, index) => {
        if (typeof item === "object") {
          result.push({ label: item.text, id: item.id });
        } else {
          result.push({ label: item, id: index + 1 });
        }
      });
    }
    return result;
  }

  function onEditButtonClick(data) {
    if (data.json) tokenProcessingTest(data.json, data.subData);
  }

  function onEdit(data) {
    if (!data.value) {
      data.setValue({ label: "Все учреждения", id: "0" });
      data.setInputValue("Все учреждения");
      setOrgTypeCls("0");
      return;
    }
    mainSources.current = new Map();
    data.setValue({ label: data.value, id: data.tag });
    data.setInputValue(data.value);
    setSelectedRecordID("0");
    setOrgTypeCls(data.tag);
  }

  async function onSelect() {
    const short =
      gridPanel.current.grid.source.getFieldTextSync("ShortName") !== ""
        ? `(${gridPanel.current.grid.source.getFieldTextSync("ShortName")})`
        : "";
    const full = gridPanel.current.grid.source.getFieldTextSync("FullName");
    const name = gridPanel.current.grid.source.getFieldTextSync("Name");

    let value = `${short} ${full}`;
    if (value === " ") {
      value = name;
    }

    if (props.onEdit && props.setValue && props.setInputValue) {
      props.onEdit({
        value: value.trim(),
        tag: selectedRecordID,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
      return;
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: selectedRecordID,
        },
        props.RequestID,
        props.from,
      );
    }
  }

  function BookContextMenuHandler(data) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "BookContextMenuHandler")
      .set("ID", data.id)
      .set("ObjRef", data.ObjRef)
      .set("ObjType", data.ObjType)
      .set("WSM", "1");

    if (data.SectionID) params.set("SectionID", data.SectionID);
    return AxiosRequest(true, params);
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    let json;
    switch (comand) {
      case "":
        break;

      default:
        if (data.ID !== undefined) {
          json = await BookContextMenuHandler({
            id: data.ID,
            ObjRef: selectedRecordID,
            ObjType: mainSources.current.get(groupID).objType,
          });
          if (json.Token) {
            tokenProcessingTest(json, {
              func: () => mainGrid.grid.refreshSource(selectedRecordID),
            });
          } else mainGrid.grid.refreshSource(selectedRecordID);
        }
        break;
    }
  }

  return (
    <>
      <ContentOrganization
        props={props}
        data={{
          editor: { editStyle: "17" },
          tree: tree,
          gridPanel: gridPanel,
          mainGrid: mainGrid,
          countRecords: countRecords,
          tabs: tabs,
          tabsPanel: tabsPanel,
          updateTab: updateTab,
          nfa: true,
          editorProps: {
            editStyle: "17",
            placeholder: "Учреждение",
            onDropDownList: onDropDownList,
            onEdit: onEdit,
            onEditButtonClick: onEditButtonClick,
            value: "Все учреждения",
            objref: "0",
            datatype: "2773",
            type: "departments",
          },
          onSelect: onSelect,
          mainMenuSelector: "NFA",
          mainMenuRequest: getBookContextMenu,
          ContextMenuHandler: ContextMenuHandler,
          objType: mainSources.current.get(groupID) ? mainSources.current.get(groupID).objType : props.ObjType,
          selectedRecordID: selectedRecordID ? selectedRecordID : "0",
        }}
        buttons={{
          search: tabs ? tabs.length - 1 : 1,
          tree: true,
          detail: true,
        }}
      />
    </>
  );
}
