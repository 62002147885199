import React from "react";
import TestTree from "../../../../Windows/ViewData/Tree/testTree";

export default function TreeMultiCheckForTabs({ props }) {
  const [tree, setTree] = React.useState(null);
  const mainBlock = React.useRef();
  const treeRef = React.useRef();

  React.useEffect(() => {
    mainBlock.current.update = (id) => {
      if (treeRef.current) {
        treeRef.current.updateMultiCheckState(id);
      }
    };
  }, []);

  React.useEffect(() => {
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            MultiCheckSet: true,
            CheckSet: [1, 9, 11, 12],
            Module: "paytypes",
            ObjType: "2380",
            SectionID: props.SectionID,
            Info: props.selectedRecordID,
            UsedDate: "0",
            ObjRef: "0",
            ref: treeRef,
          }}
        />,
      );
    }
  }, [tree]);

  return (
    <div
      id="treeBox"
      ref={mainBlock}
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {tree}
    </div>
  );
}
