import React from "react";

import { AxiosRequest } from "../../../../Url";
import { formEdit, getMulticheckIconForTree } from "../../../Tools/Tools";

import { Button, Grid } from "@mui/material";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import "./treeClasses.css";
import Scrollbars from "react-custom-scrollbars-2";
import { createFullGroupItem, createFullItem, getInnerContent } from "./treeComponents/treeCreationTools";
import {
  createContextGroup,
  createContextItem,
  deleteContextItem,
  getTreeView,
  treeManipulationsById,
} from "./treeComponents/treeContextMenuTools";

function getNextState(arr, state) {
  const length = arr.length;
  const index = arr.indexOf(Number(state));

  return index < length - 1 ? arr[index + 1] : arr[0];
}

// картинка для распахивания дерева
const expandIcon = `
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ExpandMoreIcon"
            style="height: 15px; width: 15px;"
          >
            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        `;

// запрос дерева
// для запроса необходимо передать только необходимые параметры
async function fetchData(idItem, Current, props) {
  const params = new Map();
  params.set("prefix", props.Module).set("comand", "GetGroupTree");
  if (props.SectionID) params.set("SectionID", props.SectionID);
  if (props.Info) params.set("Info", props.Info);
  if (props.UsedDate) params.set("UsedDate", props.UsedDate);
  if (props.ObjType) params.set("ObjType", props.ObjType);
  if (Current || props.Current) params.set("Current", Current ? Current : props.Current);
  if (props.ObjRef) params.set("ObjRef", props.ObjRef);
  if (idItem) {
    params.set("GroupID", idItem).set("Current", idItem);
  }
  if (!props.CLSID && props.Path) params.set("Path", props.Path);
  // возвращаем промис
  return await AxiosRequest(true, params).then((res) => {
    if (!res.Tree) return;
    // возвращаем ответ для обработки промиса через .then
    return res;
  });
}

// ref= React.useRef() - переменная хука useRef() в которую будет записана ссылка на компонент дерева
// selectID= function(id){setID(id)} *React.useState()* - функция setState() для получения выбранного id элемента/массива выбранных id элементов
// Current= int - Изначально выбранный элемент при первой отрисовке
// Selection= "1,2,3" - Изначаьные id элементов выбранных множественным выбором через запятую
// checkModeOnly= false/true - Только множественный выбор
// defaultMultiSel= false/true - Множественный выбор по умолчанию
// MultiSel= false/true - Режим возможности переключаться между множественным и одиночным выбором
const TestTree = ({ props }) => {
  //хук для хранения id группы, можно получить передав по пропсам setState под ключом selectID
  const [GroupID, setGroupID] = React.useState();
  //хук для хранения данных последнего запроса параметров дерева
  const [load, setLoad] = React.useState(true);
  // переменная для первой загрузки
  const firstLoad = React.useRef(true);
  // главный контейнер
  const treeContainer = React.useRef();

  const reqData = React.useRef({
    Module: props.Module,
    SectionID: props.SectionID,
    Info: props.Info,
    UsedDate: props.UsedDate,
    ObjType: props.ObjType,
    ObjRef: props.ObjRef,
    Path: props.Path,
    Current: props.Current,
    CLSID: props.CLSID,
  });

  // переменная с данными по отрисованому дереву
  const values = React.useRef();
  // переменные для множественного выбора
  const selection = React.useRef([]);
  const selectionMode = React.useRef(false);
  //элемент дерево
  const tree = React.useRef();

  const treeJson = React.useRef({});
  const fulljson = React.useRef();

  const checkSet = React.useRef(props.CheckSet);

  // при первой отрисовке заполняем дерево элементами
  React.useEffect(() => {
    tree.current.addGroupID = addGroupID;
    tree.current.selectGroupID = selectGroupID;
    tree.current.updateMultiCheckState = updateMultiCheckState;
    tree.current.changeSelected = handleChangeSelected;
    tree.current.refreshTree = refreshTree;

    // установка значений множественного выбора по умолчанию
    if (props.saveState) {
      // console.log(GetOptionsForUser());
    }

    if (props.Selection) {
      selection.current = props.Selection.split(",");
    }

    // первая отрисовка
    if (tree.current) {
      tree.current.innerHTML = "";
      fetchData(undefined, undefined, reqData.current).then((res) => {
        if (!res) return;

        if (props.Current === undefined) {
          fetchData(undefined, res.Current, reqData.current).then(() => {
            if ((props.MultiSel && props.Selection) || (props.MultiSel && props.defaultMultiSel))
              selectionMode.current = true;
            addNewItems(res["Tree"]["items"], "tree", {
              Current: res.Current,
            });
            // разворачивание первого выбранного элемента
            expandItem();
            setLoad(false);
          });
        } else {
          if ((props.MultiSel && props.Selection) || (props.MultiSel && props.defaultMultiSel))
            selectionMode.current = true;
          addNewItems(res["Tree"]["items"], "tree", { Current: res.Current });
          // разворачивание первого выбранного элемента
          expandItem();
          setLoad(false);
        }

        if (props.saveState) {
          // console.log(res);
          fillRowObject({ id: "tree", items: res.Tree.items }, treeJson.current);
          // console.log(treeJson.current);
          fulljson.current = res;
        }
      });

      values.current = {};
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // при изменении id выбранной группы вызывается setState переданный по пропсам для передачи данных в родительский компонент
  React.useEffect(() => {
    if (props.selectID && GroupID !== undefined) {
      if (selectionMode.current && typeof GroupID === "object") {
        props.selectID(GroupID.join(","));
      } else {
        props.selectID(GroupID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GroupID]);

  React.useEffect(() => {
    if (props.setSelectionMode) {
      props.setSelectionMode(selectionMode.current);
    }
  }, [selectionMode.current]);

  // внешние функции
  React.useEffect(() => {
    if (tree.current) {
      // информация по текущему элементу
      tree.current.getCurrentInfo = getCurrentInfo;
      // информация по элементу с переданным id
      tree.current.getSelectData = getSelectData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree.current]);

  // добавление элементов в дерево
  function addNewItems(input, current, res) {
    if (!input || !tree.current) return;

    // перебор элементов массива с данными
    const items = input;

    if (!res.Current && items.length) {
      res.Current = items[0].id;
    }

    items.forEach((item, index) => {
      const id = item.id ? item.id : item.ID;

      // установка первого элемента как выбранный если он не указан в ответе
      if (firstLoad.current && (res.Current === id || !res.Current)) {
        if (selectionMode.current && selection.current.length) {
          setGroupID([...selection.current]);
        } else setGroupID(id);
        firstLoad.current = false;
      }

      // текст и картинка из запроса
      const text = item.text || item.Text;
      const image = item.image || item.Image;

      const data = {
        text,
        res,
        id,
        item,
        items,
        index,
        image,
        props,
        selection,
        selectionMode,
        values,
      };

      // если это законченная ветвь то создаем элемент tree-item внутри элемента с id current
      if (item.leaf) {
        const fullItem = createFullItem(data);

        // Добавление элемента в ДОМ
        if (current === "tree") {
          tree.current.appendChild(fullItem);
        } else {
          current.appendChild(fullItem);
        }

        // обновление выбранной группы если id совпадает с ObjRef или  Current
        if (id === props.ObjRef || id === res.Current) {
          if (selectionMode.current && selection.current.length) {
            setGroupID([...selection.current]);
          } else setGroupID(id);
        }
      } else {
        const fullGroupItem = createFullGroupItem(data);

        // Добавление элемента в ДОМ
        if (current === "tree") {
          tree.current.appendChild(fullGroupItem);
        } else {
          current.appendChild(fullGroupItem);
        }

        // обновление выбранной группы если id совпадает с ObjRef или  Current
        if (id === props.ObjRef || id === res.Current) {
          if (selectionMode.current && selection.current.length) {
            setGroupID([...selection.current]);
          } else setGroupID(id);
        }

        // вызов рекурсии если группа содержит внутри items
        // как current передается текущий элемент дерева к которому будут добавляться новые ветви (LI)
        if (item.items) addNewItems(item.items, fullGroupItem.querySelector("LI"), res);
      }
    });
  }

  function fillRowObject(inputObj, resultObj) {
    if (inputObj.items) {
      for (let elem of inputObj.items) {
        fillRowObject(elem, resultObj);
      }
    }

    resultObj[inputObj.id] = inputObj;
  }

  async function addGroupID(id) {
    if (selectionMode.current) {
      let item = tree.current.querySelector(`#text-tree-item_${id}`);

      if (!item) {
        if (selection.current.indexOf(id) === -1) {
          selection.current.push(id);
          setGroupID([...selection.current]);
        }
        await refreshTree(id);
      }

      if (item) item = item.closest(".tree-item");
      if (!item) return;

      let checkbox = item.querySelector(`input[type="checkbox"]`);

      if (checkbox && !checkbox.checked) {
        checkbox.click();
      }
    }
  }

  async function selectGroupID(id) {
    if (!selectionMode.current) {
      let item = tree.current.querySelector(`#text-tree-item_${id}`);
      if (!item) await refreshTree(id);
      if (item) item = item.closest(".tree-item");
      if (!item) return;

      item.click();
    }
  }

  function getSelectData() {
    let item = tree.current.querySelector(".tree-item-selected");
    let id = 0;
    let itemText = "";
    if (item) {
      id = item.id;
      itemText = tree.current.querySelector(`#text-tree-item_${id}`).innerText;
    }

    if (itemText && id) return { label: itemText, id: id };
    return {};
  }

  function getCurrentInfo() {
    let item = tree.current.querySelector(".tree-item-selected") || tree.current.querySelector(".tree-item");

    return {
      GroupID: item && item.id,
      item: item,
      title: item && item.querySelector(`#text-tree-item_${item.id}`).innerText,
      selectionMode: selectionMode.current,
      selection: selection.current,
    };
  }

  // функция для разворачивания дерева по id группы
  async function getNewLeaf(current, id) {
    await fetchData(id.split("_")[1], undefined, reqData.current).then((res) => {
      addNewItems(res["Tree"]["items"], current, { Current: res.Current });
      if (props.saveState) {
        treeJson.current[res.Current]["items"] = res.Tree.items;
        fillRowObject({ id: "lastLeaf", items: res.Tree.items }, treeJson.current);
      }
    });
  }

  async function ContextMenuHandler(data) {
    const command = data.value;
    const parent = (data.item && data.item.closest("LI")) || tree.current;
    const item = data.item;

    const createData = {
      res: { Current: GroupID },
      item: {
        noDel: item && item.dataset.noDel,
        NoEdit: item && item.dataset.noEdit,
      },
      items: [],
      index: 0,
      props,
      selection,
      selectionMode,
      values,
    };
    const properties = { ...data, parent, createData, props, tree };
    const commands = {
      AddSection: () => createContextItem(properties),
      AddSubSection: () => createContextGroup(properties),
      Delete: () => deleteContextItem(properties),
      default: () => {
        treeManipulationsById(properties);
      },
    };
    const doCommand = commands[command] || commands.default;
    await doCommand();
  }

  // ВРЕМЕННЫЙ КОСТЫЛЬ требуется доработка сервера
  function addItemsForChecking(input, current, res, arr) {
    if (!input || !tree.current) return;

    const items = input;

    items.forEach((item, index) => {
      const id = item.id ? item.id : item.ID;

      const text = item.text ? item.text : item.Text;
      const image = item.image ? item.image : item.Image;
      const data = {
        text,
        res,
        id,
        item,
        items,
        index,
        image,
        props,
        selection,
        selectionMode,
        values,
      };
      if (item.leaf) {
        const ul = createFullItem(data);
        arr.push(ul);
        if (current === "tree") {
          tree.current.appendChild(ul);
        } else {
          current.appendChild(ul);
        }
      } else {
        const ul = createFullGroupItem(data);
        arr.push(ul);
        if (current === "tree") {
          tree.current.appendChild(ul);
        } else {
          current.appendChild(ul);
        }
        if (item.items) addItemsForChecking(item.items, newItemsGroup, res);
      }
    });
  }

  // ВРЕМЕННЫЙ КОСТЫЛЬ=
  async function loadAllInnerGroups(parentGroup) {
    const li = parentGroup.querySelector("li");
    const children = li.children;
    if (children.length === 0) {
      const item = parentGroup.querySelector(".tree-item");
      const id = item.id;
      const res = await fetchData(id, GroupID, reqData.current);
      li.style.display = "none";
      const children = [];
      addItemsForChecking(res["Tree"]["items"], li, res, children);
      for (let child of children) {
        if (child.group) {
          await loadAllInnerGroups(child);
        }
      }
    } else {
      for (let child of children) {
        if (child.group) {
          await loadAllInnerGroups(child);
        }
      }
    }
    return "";
  }

  async function onCheckGroup(data) {
    // ВРЕМЕННЫЙ КОСТЫЛЬ
    await loadAllInnerGroups(data.parentElement);
    // выбор всей группы
    const items = data.parentElement.querySelectorAll(`ul`);
    checking(items, data);
    // выбор для внешних элементов
  }

  // ВРЕМЕННЫЙ КОСТЫЛЬ
  async function checking(items, data) {
    items.forEach((item) => {
      const div = item.querySelector(".tree-item");
      const id = div.id;
      const checbox = div.querySelector(`input[type="checkbox"]`);

      checbox.checked = data.check;
      updateSelection(checbox.checked, id);
    });
    setGroupID([...selection.current]);
    setLoad(false);
  }

  // функция для распахивания по элементу
  function expandItem() {
    if (!tree.current) return;
    const selected = tree.current.querySelector(".tree-item-selected");
    if (selected) {
      const bttn = selected.querySelector(".expandButton");
      if (bttn) bttn.click();
    }
  }

  // функция смены выбранного элемента визуально
  function changeSelected(item) {
    if (!item) return;
    const oldSelectedItem = tree.current.querySelector(".tree-item-selected");

    if (oldSelectedItem) {
      if (oldSelectedItem === item) return;
      oldSelectedItem.classList.remove("tree-item-selected");
    }

    item.classList.add("tree-item-selected");
  }

  async function refreshTree(id, subData = {}) {
    reqData.current = { ...reqData.current, ...subData };
    tree.current.innerHTML = "";
    const json = await fetchData(undefined, id, reqData.current);

    addNewItems(json["Tree"]["items"], "tree", { Current: json.Current });
    // разворачивание первого выбранного элемента
    expandItem();
    setLoad(false);

    if (props.saveState) {
      fillRowObject({ id: "tree", items: json.Tree.items }, treeJson.current);
      // console.log(treeJson.current);
      fulljson.current = json;
    }

    values.current = {};
  }

  function handleChangeSelected(item) {
    if (!item) return;
    changeSelected(item);
    setGroupID(item.id);
  }

  // функция обновление массива выбранных элементов
  function updateSelection(checked, id) {
    if (checked) {
      selection.current.push(id);
    } else {
      selection.current = selection.current.filter((item) => item !== id);
    }
  }

  // поворот кнопки в зависимости от состояния распахивания
  function updateStateEpxandButton(bttn, state) {
    bttn.expanded = state ? "true" : "false";
    bttn.style.transform = state ? "" : "rotate(-90deg)";
  }

  // функция клика на кнопку распахивания
  const onClickExpandButtn = (ev) => {
    const bttn = ev.target.closest(".expandButton");

    updateStateEpxandButton(bttn, bttn.expanded === "false");

    const el = ev.target.closest("ul");

    if (el.group === "true") {
      if (bttn.expanded === "true") {
        if (el.children[1].children.length === 0) {
          getNewLeaf(el.children[1], el.children[1].id);
        } else {
          el.children[1].style.display = "";
        }
        el.expanded = "true";
      } else {
        el.children[1].style.display = "none";
        el.expanded = "false";
      }
    }
  };

  // функция клика по чекбоксу
  function onClickCheckBox(ev) {
    const checbox = ev.target.tagName === "INPUT" && ev.target.type === "checkbox" ? ev.target : null;
    if (checbox) {
      const treeItem = checbox.closest(".tree-item");
      const id = treeItem.id;
      const ulGroup = checbox.closest("UL");
      updateSelection(checbox.checked, id);

      if (ulGroup && ulGroup.group) {
        onCheckGroup({ check: checbox.checked, parentElement: ulGroup });
        return;
      }
      setGroupID([...selection.current]);
    }
  }

  async function ChangeNodeState(Current, State) {
    const params = new Map();
    params.set("prefix", props.Module).set("comand", "ChangeNodeState");
    params.set("Info", props.Info);
    params.set("ObjType", props.ObjType);
    if (!props.CLSID && props.Path) params.set("Path", props.Path);
    params.set("Current", Current).set("State", State);
    return AxiosRequest(true, params);
  }

  async function ReviewTreeNodes(data) {
    const params = new Map();
    params.set("prefix", props.Module).set("comand", "ReviewTreeNodes");
    if (data.Info) {
      params.set("Info", data.Info);
    } else params.set("Info", props.Info);
    params.set("ObjType", props.ObjType);
    if (!props.CLSID && props.Path) params.set("Path", props.Path);
    params.set("Keys", data.Keys);
    return AxiosRequest(true, params);
  }

  async function updateMultiCheckState(id) {
    const keys = [];
    const collection = tree.current.querySelectorAll(".tree-item-checkbox-mc");
    for (let checkbox of collection) {
      keys.push(checkbox.id.split("_")[1]);
    }
    const json = await ReviewTreeNodes({ Info: id, Keys: keys.join(",") });
    if (json && json.items && json.items.length) {
      json.items.forEach((item) => {
        const innerCheckBox = tree.current.querySelector(`div[id="mc_${item.ID}"]`);
        if (innerCheckBox) {
          innerCheckBox.checkState = item.CheckState;
          innerCheckBox.innerHTML = getMulticheckIconForTree(item.CheckState);
        }
      });
    }
  }

  function getKeysforReq(parentElement, resArr) {
    const children = parentElement.children;
    resArr.push(children[0].id);
    if (children[1].children.length) {
      for (let child of children[1].children) {
        getKeysforReq(child, resArr);
      }
    }
  }

  async function onClickCheckBoxMC(ev) {
    const checkbox = ev.target.closest(".tree-item-checkbox-mc");
    const parentElement = checkbox.closest("UL");
    if (checkbox) {
      let json;
      const nextState = getNextState(checkSet.current, checkbox.checkState);
      checkbox.checkState = nextState;
      checkbox.innerHTML = getMulticheckIconForTree(nextState);

      json = await ChangeNodeState(checkbox.id.split("_")[1], nextState);
      const keys = [];
      getKeysforReq(parentElement, keys);
      json = await ReviewTreeNodes({ Keys: keys.join(",") });

      if (json && json.items && json.items.length) {
        json.items.forEach((item) => {
          const innerCheckBox = parentElement.querySelector(`div[id="mc_${item.ID}"]`);
          if (innerCheckBox) {
            innerCheckBox.checkState = item.CheckState;
            innerCheckBox.innerHTML = getMulticheckIconForTree(item.CheckState);
          }
        });
      }
    }
  }

  // функция на клик по дереву
  async function onClickItem(ev) {
    // клик по кнопке распахивания
    if (ev.target.closest(".expandButton")) {
      onClickExpandButtn(ev);
      return;
    }
    // клик по чекбоксу
    if (ev.target.closest(".tree-item-checkbox")) {
      onClickCheckBox(ev);
      return;
    }
    // клик по чекбоксу с множествои состояний
    if (ev.target.closest(".tree-item-checkbox-mc")) {
      await onClickCheckBoxMC(ev);
      return;
    }

    const item = ev.target.closest(".tree-item");
    if (!item) return;
    // визуальный выбор нового элемента
    changeSelected(item);

    // выбор для внешних элементов
    if (!selectionMode.current) {
      setGroupID(item.id);
    }
  }

  //функция на двойной клик по дереву
  function onDoubleClick(ev) {
    if (ev.target.closest(".expandButton")) return;
    if (ev.target.closest(".tree-item-checkbox")) return;
    if (ev.target.closest(".tree-item-checkbox-mc")) return;

    const el = ev.target.closest("ul");
    const expandButton = el.querySelector(".expandButton");
    if (expandButton) expandButton.click();
  }

  function calcHeight() {
    let correction = 0;
    if (props.showButtons) correction += 33.5;
    if (props.MultiSel && !props.checkModeOnly) correction += 20;
    return `calc(100% - ${correction}px)`;
  }
  // функция на клик по чек боксу множественного выбора
  function onChangeSelectionMode(ev) {
    const checkboxes = tree.current.querySelectorAll(".tree-item-checkbox");
    if (!checkboxes) return;

    if (ev.target.checked) {
      selectionMode.current = true;
      checkboxes.forEach((item) => {
        item.style.display = "";
      });
      setGroupID([...selection.current]);
    } else {
      selectionMode.current = false;
      checkboxes.forEach((item) => {
        item.style.display = "none";
      });
      const itemSelected = tree.current.querySelector(".tree-item-selected");
      setGroupID(itemSelected.id);
    }
  }

  // кнопка выбрать
  async function onSelect() {
    const selectedItem = tree.current.querySelector(".tree-item-selected");
    if (!selectedItem) return;

    if (props.onEdit && props.setValue && props.setInputValue) {
      let value = values.current[selectedItem.id];

      props.onEdit({
        value: value,
        tag: selectedItem.id,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
        multiselect: selectionMode.current ? "1" : "0",
        selection: selectionMode.current ? selection.current.join(",") : undefined,
      });
      return;
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: selectedItem.id,
        },
        props.RequestID,
        props.from,
      );
      return;
    }
  }

  return (
    <>
      <div ref={treeContainer} style={{ height: "100%", width: "100%" }}>
        {load && <LoadingMask />}
        <div
          style={{
            height: calcHeight(),
          }}
        >
          <ContextMenu
            for={"tree"}
            onRightClick={(id) => getTreeView(id, props)}
            defaultMenu={props.defaultMenu || ["add", "addSub", "del"]}
            // Menu={getDefaultMenu("tree")}
            onMenuItemClick={ContextMenuHandler}
          >
            <Grid
              style={{
                height: "100%",
                overflow: "auto",
                border: "1px solid #cccccc",
              }}
            >
              <Scrollbars style={{ height: "100%" }} autoHide>
                <ul
                  id={"tree"}
                  // tabIndex={0}
                  onClick={onClickItem}
                  onDoubleClick={onDoubleClick}
                  style={{
                    height: "fit-content",
                    width: "100%",
                    minWidth: "fit-content",
                    listStyleType: "none",
                    paddingLeft: "0px",
                    paddingTop: "5px",
                    margin: "0px",
                    outline: "0",
                    transition: "0.2s",
                    borderBottom: "1px solid rgb(238, 238, 238)",
                  }}
                  ref={(ref) => {
                    tree.current = ref;
                    if (props.ref) props.ref.current = ref;
                  }}
                ></ul>
              </Scrollbars>
            </Grid>
          </ContextMenu>
        </div>

        <Grid
          style={{
            height: "20px",
            display: props.MultiSel && !props.checkModeOnly ? "flex" : "none",
            alignItems: "center",
          }}
        >
          <input
            defaultChecked={
              (props.MultiSel && props.Selection) || (props.MultiSel && props.defaultMultiSel) || props.checkModeOnly
            }
            style={{ display: "flex" }}
            type="checkbox"
            onChange={onChangeSelectionMode}
          />
          <div
            style={{
              width: "fit-content",
              paddingTop: "2px",
              whiteSpace: "nowrap",
            }}
          >
            Множественный выбор
          </div>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            width: "100%",
            display: props.showButtons ? "" : "none",
            height: "30.5px",
            paddingTop: "3px",
          }}
        >
          {/* {Кнопка Выбрать} */}
          <Button
            onClick={(ev) => {
              if (props.onSelect) {
                props.onSelect(tree.current, props, selection.current.join(","));
                return;
              }
              onSelect();
            }}
            size="small"
            variant="outlined"
            style={{
              textTransform: "none",
              marginRight: "10px",
            }}
            value="1"
            className="button_Modal_Select"
          >
            Выбрать
          </Button>
          {/* {кнопка Отмена} */}
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </div>
    </>
  );
};

export default TestTree;
