import CircularProgress from "@mui/material/CircularProgress";
import cn from "classnames";
import useTheme from "../../Hooks/useTheme";

export default function LoadingMask(props) {
  const theme = useTheme();
  return (
    <div
      key={"LoadMaskСircle"}
      id={props.id}
      style={{
        position: "absolute",
        display: props.bool === undefined ? "flex" : props.bool ? "none" : "flex",
        MozBoxAlign: "flex",
        alignItems: "center",
        MozBoxPack: "center",
        justifyContent: "center",
        inset: "0 0 0 0",
        backgroundColor: "rgb(250, 249, 245)",
        color: "rgb(255, 255, 255)",
        zIndex: "10",
        opacity: 1,
        transition: "opacity 350ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      }}
      ref={props.ref}
    >
      <CircularProgress className={cn("circularProgress", { light: theme === "light" })} />
    </div>
  );
}
