import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";

const ConditionsValues = [
  "Равно",
  "Не равно",
  "Больше",
  "Меньше",
  "Больше или равно",
  "Меньше или равно",
  "Содержит",
  "Не содержит",
  "Начинается на",
  "Не начинается на",
  "Заполнено",
  "Не заполнено",
];

const ConditionsImage = [
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOElEQVR4nGNgGAUjBMTOOPekY/PdB5Tg2BnnnsANBAnc//DvPyW4Y/PdB7Rz4eAHsaORMgqGGQAAYHJYqJ0Fh6EAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAnElEQVR4nGNgoAVw6zh21b39uAPVDPToOG7j3n78pWv7CadRQ8kDsTPOPenYfPcBDFesuPHcu/vkn9LlN14gi+PDsTPOPYEbCBK4/+Hff2S858bH/yETz/zffvUDijgu3LH57gOcLiTHpbHILhz8SSoWh5dJ8X4soUjBhvFFVAcxkUKKS2OJjRSqhCkxYIQb6t5+3AFUSFPNQGQAANS5mPhdbDaGAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOklEQVR4nGNggALfnuOuDNQEmfOvvChbcf1cwsyLKfX1/5koNrBjy7379z/8+3/g1uefdetuXY6beb7Jvn4/B9kGZi+8cu/yi1//QYaC8OE7n/+ADE6adbHNvn4/D8kGerQeEk2bf2Vq66Y7N88+/v4PZvCZx9/+tWy4fStlzoXZzm0nhUk22L5+P0/anEsttWtuXT5698sfmMGXn//837fz4f3M+ZeX+XSckSPZ4ND6K2ypsy8WVqy8cXbvjY8/YAbfeP37/8wDT57kLLi6OXDCKR2SDQbFeNzs8zHFS68eX3fuzZd7UIPvvP37f/HxF6/yF1/dGznxnD3JBjP8/88YM+1scOHia0dWn3n14d57iMF33//9X7r8+mMGSkB4/2mH5FkXLmy5/O43yFBQkiPbsLAp57zyF1/dt+Tki7d33v39T5aBoHCMmX4uDhyOF95+gofju7//Fx17/iZt7uVdRBlkPPMMa/SMs7kFi6+eX3/h7XdYTN9+++f/vMNPX2XOv3w4eOo5K4IGeUzcxp4880Jl0dJrF/ff+vwTZtD1V7//z9z/+FH2witbwvvPahI2qP4EX/rcS621a25eOfHgGzxRX3z28/8EUKJecGW5d8sxaaK8lzrn4ryu7ffvgjTDDDrx4Ovfhg13rqXMudTn0nGGn4Gc0ub+h3//D935/Ktx/e2LSbPON/nUn+EiySAGJAN3XfvwrXLVzdNxMy/m29fvZyHLIBgIn3L2XOzUC+GgHEGRQdQGAM7VmHTK9LlYAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACN0lEQVR4nGNgoALw7TnuSrEh9fX/maKmn08oWnb9WMa8S8/JNsh45hnWhBkXikqWXb+w8/rH7/c//PvfseXefZINsq/fz5M062Jb3bpblw/f+fwHZBAIX37x63/2wiv3iDbIo/4EX9qciz2Vq25dO/Hg61+YQRef/fw/cfejexlzL61w6TwiRdAgr66TEpnzL09u2XD71vlnP8CGgPCph1//tm+5eyNp9qUp9v37BQgaFDThuGr2wiuL+3Y+eHz11S+4QQfvfPrVuP7OxaSZ55tCe49xEjQocOop04LF19bPPPDkxa23f+AG7br24Vv16lun4mdeyLOv389CdFiVLr/++O77v2BD7r3/93/1mVcfChdfOxIz7Wwww///jAykgo4t9+6DDNty+d3v5FkXLoT3n3Yg2RBsBt559/f/0hMvPxQvu3osdtoFHwZyQdrcy7sWHXv+BmQg2Nsf/v1fd+Htp+KlV4/HTD8XB8oZJBsaPPGUYfbCK1sm73704sYblIj5VbHi5qWYaefqPSZuYyfZYN/uE4rZC68u6d/58MGVl4ikc+Tu59+1a25eSZh5ocu1+wI3yQZ7tB4STZt/ZWrrpns3zz7+/g9m8OlH3/61b7l/I23upaluvceESDbYpeMMf8qcS30NG+5gZL+u7ffvps65OI+BHBBaf4UtZdalovKVN87uvfHxB8zgDnJKG2QAivH4mRdiC5dcObHu3JsvFBsIB///M8ZOvRAePuXsOeoYiAUAAHqKmASOZ56zAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABO0lEQVR4nGNgoBWIm3GhM2/R1S1RM86GG888w0qxgWlzLi24/+Hf/wO3Pv9s3XTvZvaCK1vDJ5zxqa//z0S2gTff/PkPMhSE77z7+3/jhbc/Gtbdvp698OoSv8knTUgy0Lf3rEranEuzq1ffOrX+/NuPIANhhoMsWn7q1eeaNbfOp8+7NM2j64wySYaHTj5rmTn/8rLqtbeubLn87vu99xCDQfjqq1//Fx17/qZy1Y0zKbMu9Xl1nZQg2mD7+v0s0RPPheQuurq5c+v92wdvf/4LMxiEzz/98X/qvidPkmdeqGMgFfjUn+EKm3wuJX/x1e19Ox/eP/nw2z+YwWlzLi0YGAPtqeXlUGpEii+1k00atRN2GrWzXhy1Cweqg/hpF2Z0bL77gBwcP+3CDAwDYWFIDsaasKnuQmoBAAOdBqNDTsZUAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABM0lEQVR4nGNgGCzAeOYZ1qgZZ8PzFl3dEjfjQifZBkVOPmuZOf/ysprVN65uufTux70P//6nzbm0gCRDfPqOGaXOuTSnZvWt8xsvvP16593f//c//APjm2/+EGegc/sppfjp53srVt44vvzkq/cgjTBDQAZuuPjuS926W5ezF1xdEjThnCpWQ9x6jwmlzb1UW7r82pHp+x6/uvz8J9wQED5w6/PP1k13bmbNv7wtfMIZH4b//xlxuih55oW6qfuePDn/9AeKIQdvf/7bufX+7dxFVzdHTzwXYl+/n4WocEqbc2kBzJBTj77/n7Tr0YuSZdf2x864kOFTf4aLKENoamAytb1M9UihSbKhacKmadajaeFANIifdmFGx+a7D8jB8dMuzMAwEDlhk4qxhiHVXUgtAACMswYJstw1TQAAAABJRU5ErkJggg==",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUEAYAAADdGcFOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOwwAADsMBx2+oZAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOTSURBVEiJ7ZVPTNNQHIDbrqulm8MO6OqmFhqRwGQ4hDDxwg7ExAgREhJC4kGjzBMhMSaY7LAICSf0sMMwmHAg7MABoka9+ecA8U9QGdkMJLAtYbNTOrZ1tG5d9zwQLiRmM0L0wHd938vve7/Lg6BDDvm3wMWKPT2PHy8s6PXnzhkMNP3wIUXpdEeOnD6dTIpiNsvza2sbG+m00+nx3LpVX+/zXb8+Pu7319UxjMGA4/39kcjW1vb23NzExI0bFsvbt8XORQqHzczMzKhUra0mk9H49OnFi7W15eUdHZubgpDNhsMEgWFqtUZz4YLZXFExO9vf/+iR33/qlMGg16vVXV2dnc3NJtPAgCxns4qCYcWGFR1YWakoDQ23b7e1WSw03dr6/PmHD5GI233v3pUrVVV9ffPzfv/GRlcXiqpUMCxJLGs0EsToaEVFaSmOs2wo9P17Os1xPM9xijI/v++BLGswEMTlyysr0Wgqtb7+8ePqKseNju6eT08PDNhsqZTPFwolEl4vy9K0RmO3YxiCAFBTk0iIoiyHQs+euVxNTaK474EaDY6jKE0Lwva2LK+vv3njctntP3/u9d69+/o1GnW7AYBhAGSZYShKq7VaBUGScrlg8E/Dig5UqVQqBCHJTEZRAOD533k74el0LLa1JUmfPp09yzAkSRA8LwiZzAEGQlA+D0GKgqIIAsM4XsiWJFkGIJlUFEXJ5yGI51OpbDYSObDATEaWc7kfP0pKMAxBTpz4vQkAADBcVqbVYpjVurN5CKqspCiCsFr32m1tLtfr14UfXDAwHI7HJcnvP3PGZCotNZsdjqmpQKC9fa83OOj1BgJ9fY2NLFtWVlf34sXiYjQaixmNJInjNttOEIru+jhOEDje21toPlpICAZjsURieDgYjMWOHm1vP3+eYXS64eGeHo/ny5dAAILUalGUJIuFYUhycDCXy+cBkOXPn4PBeHxkxG43mynK6bTZamuPH3e7a2omJ32+UEgUMxlFWV4uNL/on+TOHa83ELh6tbm5urq8fGwMQWAYhrVaCIJhAESRonS6khKSfPVqeZnjxsfv3+/urq4eGnI6Z2dXV+/era8/eZIkh4ZWVqLRZDIcfvKE4xKJlpbFRYejqUmW/zpwl2vXPJ6lJYqi6WPHMOzmTbUaRVEUw759i8dF8eXLyUmHo6Hh/fu99y5devBgYUGv39zUaDBMEAqFHXLI/8IvorKX56DZt8EAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUEAYAAADdGcFOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOwwAADsMBx2+oZAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAQjSURBVEiJ7ZRfSFN9GMfP2Tk7np3NM8/mzv7Y1A6j0Go2KdrmjXVTVAYSQQgFgjkvyrwIOkHELgojwqBJFhTmRYsWGIUp7MZBNChnobhogrPRttRt5s7+uZ2dc94L8Q18kdX7Iu+N39vfw/f7+f2e5/cAwLa2tTU6c8Zu9/sRpFQd/PuGjx97vQrF/v1qtUZz7x5J4nhZmcGQTGazhUIiMTcXDqfT168PDFy4sG/f9HR7+8OHfn99fU2NWo2inZ2RyM+fmcyrV9+/x2KZjFyeSgFAMnnixJq7zbZZrqg0mMvlckGQ1VpVpdO9edPUVFdXWdnSEo+nUoVCKIRhCCIWS6UWy549KtXwcGfno0d+f3W1Wq1QiMWtradOHTxYVdXdvbS0spLNHjsmCIIAgqdPx2IVFQhy8WKp/JKAtbUc19DQ1dXcbDRqNFbr27cfP0YiDse1aydP7tzZ1vb+vd8fDre2wjAEgWAuR1E6HYb19qpUcjmKUtTIyMREOMwwuVyhwPMEYbGsrh461N4+OWmzHTjAsqXyS7aYotRqDDt+PBCIRhkmGJyYmJ1dWOjtXT9/9qy722xmGL3+xYtAwOlsbKQogrh0yeOZmVlcLBQAQCQSidJpt5umrdauLre7VOIfAkqlKArDGk0qlcmwbDDo8djthw+vrm6se/fu27dYrL8/ny8WOY6mSVIuLyurrCSI8nKJ5OXLP8P6pZIthiAIEokIIp/nOEFIJDaer8+oTAYAEHTnDssWizwfDJ4/f+SIwSAWJxKpVD4/P79lgADA8wDAcTAsEoEgiv4CW1sTyWQwqNc/fSoIPA8AHo9er1JJpVNTHMdxPA8AiQTDFAqRyJYB5vMsWyzGYhIJgohEO3a0tNjtPh+GMQyKMszz52tVTqfbTdNms8ulVMpkCGIyrb08ANTWkiSGmUwbfZub7fbx8V8X/teAodDyci7n91dXq1Qy2d69HCeVcpzHA4IgKAgPHrjdNG2xjI319DidX760tTU2UpRSWV8/Ojo5GY0uLup0BIGiZvMaEPz3zKMohqHo2bP/GXBmJhqNxx2OoaHx8bk5QbBad+9WKjGsvBzHMezr1/UFbjTW1BBET0+xyPOCwLKfP8/PLy/fvCkIAAAAJGk219VptQ6HzTY4OD199apKheMourRUKn/TX3z06K1bPp9Wm04zDMvevYvjBMHzNG0waLU4fvnyrl06nVz+6RMAgKAgZLMkieMSCUGMjPh84fD9+0+edHQYjf39Wu3w8OysRGIyUZRSSdOBQDSaTIZCr18vLKys9PWVAgQ3B7x92+u9cQOCYFgQhoZGR69caWoKhc6dGxiYmiJJjaaiAkE6OsRiGIZhBPnxY3k5mx0bGxy02RoaPnz4p19fn9erUMTjUimCpFK/u6i3ta3/W38BgVXOiz68N3kAAAAASUVORK5CYII=",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACI0lEQVR4nGNgGNLAu/O4as7CK4+bN965D8JlK248iZxy2otsA9PmXpp65eWv//c//APju+///s9acGUt2QZWr7p5GmTQ0pMvX4EwiN284c41+/r9LCQb5t9/0mDZyRfvQIaULb+xr2z5jQMg9o6r739GTz4TSLKBGfMuz7vx+vf/y89//k+ZdakzadbFnkvPf/6/9/7f/5xFV9aRbGDdmlvnQS5aePz5W7/OU+runUfUFx17/gbs7Y23b4TWX2Ej2jDfvtPWq8+++gTSXL3m5jmYeO2aW2dBYjuvffgVNfl8KNEGZi64svzWmz//77z7+z9zweWlMPGMuZdWgMTuffj3P2fxlQ1EGVZf/5+pYf3tyyCXbL/64UfEpDPeMLngCWd8dlz98AMk17rp3k37+v0cBA0M6z/jvvnS228gTS2b7r5Kn3tpQeb8K7NBGMQGiYHk9tz4+CtiyrlIggZmL7iyFuQtWGLGhUHezl90dTNew4xnnmFt3njnOiHDYLh1893bob3HOHEaGD3xXMjOax9+ghTP2P/4ZfKsC6tS5lxcgYxBYiA5kJp9Nz/+jpt6Lg6ngbkLr24BJVyQ4spVN4/hUlex6sZxmCvzF13dglURKMY6tty7DVJ0482f/2lzL8/AZWDa3EuzQLkIpLZ9y707rt0XuDEUBfaeiihZfv1x97b7tytW3Xzo03/SEpeBIDmQGpDa0hU3Hgf0nyYc24MGAADCGcM5CAz6gwAAAABJRU5ErkJggg==",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUEAYAAADdGcFOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOwwAADsMBx2+oZAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAATkSURBVEiJ1ZVLTBNdFMdvh05nRtrODPTF07SlCoi6IAWMj7ChQFAQk7rA90JMDHtx140B1GhcsPCBAgtNTNhoVGwUMBGmpkgaqCSFFqSG8ihl6COF6Wu+xWTSpKYBPvlivv/mzj1zztzfveeeMwD8X2QwdHRQ1KNHBkNHh8VSXv63eXgJ+QcIgiAAPn9mWZZl2XfvONCGBrP59u2qqu/fUwNPn75712LR6dRqlUosHhpSKnEcw2Ix/n0wuLUVi8Hwr19ebyDQ2vryZVubXv/+/R8TGwydnRRVX19b29lJUcvLtbVdXWNjFRWpfjdu9PRMTXV32+0rK5EIy87Pb2wkEsnR5aLpeJxlb97s67PbBwb+LQ+UajCb29uPHfvwgWUBAODaNQBYViB48yYVVCaTSkWiiorMTAQRCgGgqJkZr9fr5UcIEggEAgBycnAcRUtKqqtNpuFhoTB1vV0Dbgfa0HDvnsVy8eKhQ4WFBKHV8v4229wcTdvtNpvLRdPT07xdr9fpZDKtNi9PJpNKz5zZM8B0oCzLsgA8eZKdLZEgCEmGQpub0SgANB0IRCJW69paKMQw4+PBIGc/cCA3VyoViUgSx1H00qU9B0wF1WgUiszMpaVnzz59cjoBMJsnJz2eQGBlhaYZ5vlzj2d9PRh8+tRm+/lzfd3n4xINgFIplSJIaanRaDL9+CES7TlgY+PDh1br8ePV1YcPq1Ry+eXL1dUaDQBDQ5OTKyv79jEMANEojg8O3rp14oTD4XR6PKHQwgIfX1mp08nlGg0MZ2dHIk1New6Yl0eSGNbWduTI/v0kKZGUlOTn4zgAJCkWI8jXr/wdravr6Bgd1eu9Xr9/a2t2Nh5PJFgWgKKi3FyJBIazs0lSJLpwYc8ATSaTiWUhSKnEcRQtK4NhoRCCAJidXV4OhRgmFotG4/H795N3VCCAoLdvPZ719XD42zenk/PjEs1VNYbxVY2ifww4PU0QExM1NeXlWi1JJqt2YsLlWlsLBAhCLBaJjEatVqWSSM6dk8mkUgSx2aLRRIJl79wZGZmaWloKh/m4ykqdTiZTq3Ny5HIcb27ebv1t+5JCQZII0tpaUpKfTxAYxttbWk6eVKvlcm525UpqnN3udm9sANDfPzIyN4dhCwurq6EQANyfB4YVCokERVtaOO9Xr9Ktn/YEy8sfPx4fh2GViiQxrLQ0IwOC+DTtRGVlhYUEAQBfTN3dg4MzMwC43RyoUkmSCFJcbDQ+eDA2ltx4qtKeYHExw0BQU5NeX1Qkk2k0vP3jR5vN41ldtVpnZ32+L1+4NpJIpPsO1zchqLS0oADHa2o4UIJobq6oKChQqx0Ot9vvNxo57/7+HQNmZREEil69qtPl5Egkyb61sLC6Gg67XD09168fPXr+fLr4VLW3v37tcFAUd6JVVVzqMzIIAsNguLU1HeBvKearKy8vKwtFDx7kGy3DxGKJBAA+XyjEMJOTOwXjRdPBIMNMTXEbTqZ+ednv39ysqjIYOjtHR0+d2haQJKVSsfjsWZ8vGIxEUHRggKLcbqezt3doaG7O7fZ4/P5wuK9vt4CLi4HA5uaLF729w8Pz8263w7G4GAg4nbm5WVliMU1DkEAAQck+ysft4tr/t6qr6+qiqMZG/l+PIAJBPK7T/W2u31Rf39VlseTn8/N/AMivUV4XOqXrAAAAAElFTkSuQmCC",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACjElEQVR4nJ1U309SYRg+Xpf1J1R/Qtl9N7laa8w/IH6sAgMvuEouXVkrB5LREhFBzRtAb6hpBW6tQDwmHpBzkMMkiFCRg5sOOW1A62sPNRYV5PHd3g3O+33Ped/nfZ5DUW2i20ifU45F+tSO9WGk3MborjxaPktJCkI6VLboTYOHp52BnSL9WST83rd6LmdE4gzmBYObp5XWiOq/WJcfh0/3TbOLb+L7XzMH30m8UCXuVaFk8WdzSM+qUIoLVYLa2419UTvFLuJOSzD9DMcAJJavkHveFK9xxJ5fN4cudBujJ5A9JroLzwa9KZ7NVwhXqBL9C27tb1BCOtAZwILpckU7xc7LhoKdrSZBTTfNLiylyxWAotOmA+AMY6IzgOEFR+EaoNxulSxw+6LcxigbNYMrsQJe7ns3k+06+zOuDtCnHr76xONuv5unG9LANjEu+KEkhtrBWjeEGpkIbAvXjEtnKJWV0UEa2KbM9PG8VMAeE901Gy4eQlLysegd6rYjNsIXa2TEl81dGnh3UiqgbCjY+dSX3QKGxhkzU2p77An+WHzZnBT+fufR4s9uJYQauWVfH6YUNkaLkT3hvRI0d5yR58LFw1BGJAprpJeCN52BvAAHHGcpvc5fS/mwU6gvBQFvYvVwgJSx4ZAHL1NJ3L3rSvyUDQJGhzdhJ4hVirDZ3SqZZyHsqLy5OMn5YSPYCQdBdrvO6tbLlCsA1E1yvn8egtEBCjsNejeTmonYqMy8chFyQuI3OMOYAELqZ+Lhli9HAUZ/HT8QwQvInl0TSs/8X3LIubBQwjPUMCY6azdJI26MM4p+V4K2v98WIAfoFBlKi/VtYgHNnB0xIAPFKKPF5x+ihbUa0mgRPwCqNiSFxXi1TgAAAABJRU5ErkJggg==",
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACmklEQVR4nL1UWW8SURil/0B99Af44J/wxRgTNPGlIVUTGivUhaWJNr61JFqrtWqUYIoWSqFII5WtqVISXCLWEJaBYVhKh5kaBRsMS0IfBNOOORhG6GDEB/2SyWTuPXPu+ZZzRaL/FQM64pRynnJqnHT4hmsjO+HJZccd61GlhXIO6CInRRzXB5z0MXnm/Gw8eOzu2oGuRP0PoodHFpLvPfHyNlPZ5dhq58NUdzk3UdpWmZOB09rINf2bfD5d/M7JDeQHAZlUT4hvLedYurzDkV/q3PRLhhl9mvEOG0mbbJa0qy1J/9QKw8YLdQ4YX6rawCEghVKBMpABsBgqluQGcu7I/df79h569Hbg4NlH0SCR/9ZUvJqq1qFUoA5p4tRn4WJpUE8ofldbKIEikIGULu9wSnMy0Kop3wDUDGnKDORcL2R4Q2m8UOdcsVKt/yEh5oHoJhqAmnVLsxsZvpH+nZVNFo1SmSgXDx5zZCMAXrGmV3sla+2pralXWB930mH+hwl3LovFi6aEdS/Z4ExMovN/Kvwk+7w19CQubd+XG8gl7GFO+cWbHqZJKDOS9r8hQ1wyUrYm4TKd4Rc1TrqZsnoh5e8lzfa4ak37gNE4NkL8osJMuVBYDC0K3SsZ7HbP+5FBQy+bqOf8BrzpjpVqGAGMQi9kiGFjwkJtNWDFmkQbO/Frh+P6VJZkAEPacsCfyM7p4+ql8Ncy/hmxpN4KALAPbNRyAJQi/W5pQpk9XCwDO+nJ5STa4KEOUHvNvMlKA6ci/akXDItGYTQuGMjFUVvGN+3dZJEmMCAbmokdF6jD1YMrCKRQCm/CTmiU4PqqNK+vGtIUKGuFePLdfpkxsdZeM3gTdsIFi6G97qbXxxzZkGKeWupswD+OH52Oks1SsyyPAAAAAElFTkSuQmCC",
];

export default function ConditionButton(props) {
  const [value, setValue] = React.useState(Number(props.condition) - 1);
  const [items, setItems] = React.useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setItems(getItems(props.conditions));
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickMenuItem = (ev) => {
    const item = ev.target.closest("li");
    if (item) {
      setValue(item.dataset.index);
      if (props.paramsBoxRef && props.paramsBoxRef.current) {
        const record = props.paramsBoxRef.current.querySelector(`#${props.recID}`);
        if (record) {
          record.dataset.condition = Number(item.dataset.index) + 1;
          if (props.onEdit) {
            props.onEdit({
              record: record,
              Condition: Number(item.dataset.index) + 1,
              textchanged: "0",
            });
          }
        }
      }
    }
    handleClose();
  };

  React.useEffect(() => {}, []);

  function getItems(conditions) {
    const result = [];
    for (let i = 0; Math.pow(2, i) < conditions; i++) {
      if (Math.pow(2, i) & conditions) {
        result.push(
          <MenuItem key={`item-condition_${i}`} onClick={clickMenuItem} data-index={i}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={ConditionsImage[i]} />
              </Grid>
              <Grid style={{ paddingLeft: "5px" }}>{ConditionsValues[i]}</Grid>
            </Grid>
          </MenuItem>,
        );
      }
    }
    return result;
  }

  return (
    <>
      <IconButton
        style={{
          height: "18px",
          width: "18px",
          display: props.visible ? "flex" : "none",
          fontSize: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src={ConditionsImage[value]} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items}
      </Menu>
    </>
  );
}
