import { getDateOfInt } from "../../../../Tools/Tools";

export function getColumns1() {
  return {
    title: [
      {
        fieldName: "Text",
        title: "Период",
        width: 200,
        fieldInfo: {
          DataType: "2040",
          EditStyle: "17",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "persons",
          Values: [],
        },
      },
    ],
  };
}

export function getDefaultFields1() {
  return [
    {
      Name: "ID",
      Title: "ID",
      Width: "10",
    },
    {
      Name: "UsedDate",
      Title: "Дата",
      Width: "10",
    },
    {
      Name: "Text",
      Title: "Значение",
      Width: "20",
    },
  ];
}

// StartRange
// EndRange
// FirstDate
// Value
// Info
// LastDate
// OrgTypeClsName

export function getColumns2() {
  return {
    title: [
      {
        fieldName: "StartRange",
        title: "От",
        width: 70,
        fieldInfo: {
          DataType: "6",
          EditStyle: "",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "EndRange",
        title: "До",
        width: 70,
        fieldInfo: {
          DataType: "6",
          EditStyle: "",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "FirstDate",
        title: "Начало",
        width: 70,
        fieldInfo: {
          DataType: "6",
          EditStyle: "2",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "Value",
        title: "Значение",
        width: 70,
        fieldInfo: {
          DataType: "6",
          EditStyle: "",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "Info",
        title: "Дополнительно",
        width: 70,
        fieldInfo: {
          DataType: "6",
          EditStyle: "16",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "LastDate",
        title: "Конец",
        width: 70,
        fieldInfo: {
          DataType: "6",
          EditStyle: "2",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "OrgTypeClsName",
        title: "Учреждение",
        width: 100,
        fieldInfo: {
          DataType: "2773",
          EditStyle: "17",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "departments",
          Values: [],
        },
      },
    ],
  };
}

export function getDefaultFields2() {
  return [
    {
      Name: "ID",
      Title: "ID",
      Width: "7",
    },
    {
      Name: "StartRange",
      title: "От",
      Width: "10",
    },
    {
      Name: "EndRange",
      title: "До",
      Width: "10",
    },
    {
      Name: "FirstDate",
      title: "Начало",
      Width: "10",
    },
    {
      Name: "Value",
      title: "Значение",
      Width: "10",
    },
    {
      Name: "Info",
      title: "Дополнительно",
      Width: "10",
    },
    {
      Name: "LastDate",
      title: "Конец",
      Width: "10",
    },
    {
      Name: "OrgTypeClsName",
      title: "Учреждение",
      Width: "10",
    },
    {
      Name: "OrgTypeCls",
      title: "OrgTypeCls",
      Width: "10",
    },
  ];
}

export function getRecords1(data) {
  if (!data.items || data.items.length === 0) return [];
  return data.items.map((item, index) => {
    return { ID: `${index}`, UsedDate: item.UsedDate, Text: item.Text };
  });
}

export function getGradesText(req, data) {
  const records = data.records;
  if (!records) return { Text: "" };
  const rec = records[req.RecordIndex];
  if (!rec) return { Text: "" };
  let text = rec[req.FieldName];
  return { Text: typeof text === "object" ? text.text : text };
}

export function getRecords2(data, selectedRec) {
  if (!data.innerRecords || data.innerRecords.length === 0 || !data.innerRecords[selectedRec]) {
    return [];
  }

  const records = data.innerRecords[selectedRec];

  records.forEach((item) => {
    if (item.FirstDate !== undefined && !isNaN(Number(item.FirstDate))) {
      item.FirstDate = {
        id: item.FirstDate,
        text: getDateOfInt(item.FirstDate),
      };
    }
    if (item.LastDate !== undefined && !isNaN(Number(item.LastDate))) {
      item.LastDate = { id: item.LastDate, text: getDateOfInt(item.LastDate) };
    }
  });

  return records;
}
