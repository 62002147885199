import { IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ImgURLSRC } from "../../Url";
import { getIconsAsync, getStyleWindow } from "../../MainPage/Tools/Tools";
import { CreateBackgroundForModal } from "../../TokenProcessing/TokenProcessing";
import ModalWindow from "../../MainPage/Module/ModalWindow";
import TextAreaWithButtons from "../../MainPage/Module/Dialogs/Components/TextAreaWithButtons";
import { EditColumnField } from "../../MainPage/Tools/Requests";
import React from "react";
import { openModal } from "../../MainPage/Tools/modalManager";
import DialogCalendar from "../../MainPage/Module/Dialogs/DialogCalendar";

export default function ModalButton({ props }) {
  const [icon, setIcon] = React.useState();

  React.useEffect(() => {
    if (props.buttonImage) {
      getIconsAsync(props.buttonImage).then((res) => {
        setIcon(ImgURLSRC(res));
      });
    }
  }, []);

  return (
    <IconButton
      disabled={props.disabled}
      ref={props.refButton}
      key={`modal-button_${props.id}`}
      style={{
        width: "20px",
        height: "100%",
        borderRadius: "0px",
        padding: "0",
        display: props.EditStyle_Ellipsis && props.EditStyle_Calendar === 0 ? "" : "none",
      }}
      type="button"
      onClick={async () => {
        if (props.disabled) return;
        props.closeList();
        const subData = {
          props: {
            FieldName: props.fieldname,
            Current: props.current,
            Value: props.value.label,
            ObjRef: props.value.id ? props.value.id : "0",
            ObjType: props.datatype,
            from: props.from,
            onEdit: props.onEdit,
            Module: props.type,
            data: props.data,
            Path: props.Path,
            GroupID: props.groupid,
            Name: props.name,
            input: props.input.current,
            record: props.record,
            editParam: props.editParam,
            setValue: props.setValue,
            setInputValue: props.setInputValue,
            setSelection: props.setSelection,
            addInfo: { ...props.addInfo, editButtonClick: true },
            MultiSel: props.multisel ? true : false,
            Selection: props.selection,
          },
          propsId: undefined,
          func: props.func,
        };
        let json;
        if (props.settings && (props.settings.link || props.settings.onEdit)) {
          // documents~EditColumnField?LicGUID=EC35FEF44C1DFB7AF21D0799BB955B34&ID=5007&DocCfgID=3218&SectionID=100&DocID=20391961&MasterID=20391946&Reference=0&WSM=1
          json = await EditColumnField({
            Module: "documents",
            DocCfgID: props.docProps.DocCfgID,
            SectionID: props.docProps.SectionID,
            ID: props.docProps.ID,
            DocID: props.docProps.DocID,
            MasterID: props.docProps.MasterID,
            Reference: props.docProps.Reference,
          });
          props.openModalWindow(props, json, {
            ...subData,
            from: "grid",
            func: props.addInfo.ModalButtonCallBack,
          });
          return;
        }
        if (props.addInfo?.onEditButtonClick) {
          props.addInfo.onEditButtonClick(subData);
          return;
        }
        if (props.datatype === "0") {
          subData.props.editParam = "1";
          props.openModalWindow(props, json, subData);
          return;
        }

        if (props.datatype > 0 && props.datatype <= 12) {
          if (props.datatype === "4") {
            openModal(
              <DialogCalendar
                props={{
                  onEdit: props.onEdit,
                  ...props,
                  value: props.inputValue,
                  newModal: true,
                }}
              />,
              {
                Title: "Календарь",
                style: getStyleWindow(),
                hiddenButton: true,
                blockMaximize: true,
              },
            );
            return;
          }
          CreateBackgroundForModal(
            <ModalWindow
              Title={props.record ? props.record.dataset.name : "Текст параметра"}
              style={getStyleWindow()}
              hiddenButton={true}
            >
              <TextAreaWithButtons
                props={{
                  ...props,
                  setValue: props.setValue,
                  setInputValue: props.setInputValue,
                  Title: "",
                  Value: props.inputValue,
                }}
              />
            </ModalWindow>,
          );
          return;
        }

        if (props.editParam === undefined && (props.datatype < 7 || props.datatype > 12)) {
          json = await props.GetDialogParam(
            props.type,
            props.datatype,
            props.value.id ? props.value.id : "0",
            props.multisel,
          );
          if (json.ObjType) subData.props.ObjType = json.ObjType;
          if (json.ObjRef) subData.props.ObjRef = json.ObjRef;

          if (!json.Break) json.Break = "";
          if (!json.Token) json.Token = "HandleTable";
        }
        props.openModalWindow(props, json, subData);
      }}
    >
      {props.buttonImage ? (
        <img style={{ maxHeight: "20px" }} src={icon} />
      ) : (
        <MoreHorizIcon style={{ maxHeight: "20px" }} />
      )}
    </IconButton>
  );
}
