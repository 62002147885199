import { PostHandleTableDoc, handleTableDoc } from "../../../Tools/Requests";
import { getRightsData } from "../../../Tools/Tools";
import { parseColumns } from "../../GridTools/parseColumnTools";

export async function documentsGrid(grid, source, props, data) {
  //если есть свойство в data с ключом sub и значением true то запросит поля для грида
  if (data.sub) {
    const json = await PostHandleTableDoc(props.Module, data.DocCfgID, props.SectionID);
    if (json) {
      source.rights = getRightsData(json.Rights);
      const columns = parseColumns(json);
      if (columns) {
        grid.setColumns(columns);
      } else grid.defaultColumns = true;
    }
  }

  source.onHandleRequest = handleTableDoc(props.Module, data.DocCfgID, data.GroupID, props.SectionID);

  await source.open();
  if (data.ReturnRecord) {
    source.onRecordIndexChanged = async function (source) {
      data.ReturnRecord(source.getFieldTextSync("ID"));
    };
  }

  if (data.sub) await source.refresh();
  await grid.setSource(source);
  grid.updateGridSize();
}
