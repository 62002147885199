import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

export default function TRadioButton({ json, TextFromServerToBrowser, RadioChange, checked, jsonData }) {
  const [radioValue, setRadioValue] = React.useState(checked);

  React.useEffect(() => {
    setRadioValue(checked);
  }, [json]);

  const getRadio = () => {
    let components = [],
      Left,
      Top,
      Width,
      Height,
      i = 0;
    for (const [key, value] of Object.entries(json)) {
      if (value.Type === "TRadioButton") {
        Left = value.Left;
        Top = value.Top;
        Height = value.Height;
        Width = value.Width;
        components.push(
          <FormControlLabel
            key={value.Name}
            value={jsonData.keyName + "," + i}
            name={value.Name}
            index={key}
            control={
              <Radio
                name={jsonData.keyName + "," + i}
                index={key}
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                    },
                  },
                }}
              />
            }
            label={TextFromServerToBrowser(value)}
            style={{
              position: "absolute",
              left: `${Left}px`,
              top: `${Top}px`,
              width: `${Width}px`,
              height: `${Height}px`,
            }}
          />,
        );
        i++;
      }
    }
    // sa.current = components
    // setRadioComponent(components);
    return components;
  };

  return (
    <FormControl>
      <RadioGroup
        onChange={(e) => {
          setRadioValue(e.target.value);
          RadioChange(e);
        }}
        value={radioValue}
      >
        {getRadio()}
      </RadioGroup>
    </FormControl>
  );
}
