import { AxiosRequest } from "../../../../Url";
import { CreateCollapsibleRecords } from "../../GridTools/collapsibleRecordsTools";

export async function groupingOrg(grid, source, props, data) {
  source.onHandleRequest = HandleJoinedFunc(data.ObjType);
  source.CreateCollapsibleRecords = CreateCollapsibleRecords;

  grid.setColumns(data.Columns);
  source.onRecordCollapsed = async function (event) {
    let RecordIndex,
      RecordState = await source.getRecordState();
    if (event.currentTarget !== undefined) {
      RecordIndex = event.currentTarget.getAttribute("recordindex");
    } else {
      RecordIndex = await event;
    }
    await HandleJoinedFunc(data.ObjType)({
      command: "CollapseGroup",
      collapsed: RecordState & 128 ? undefined : "1",
      recordindex: RecordIndex ? RecordIndex : undefined,
    });

    await grid.refreshSource("!");
  };
  await source.open();

  await source.refresh();
  await grid.setSource(source);
  grid.updateGridSize();
}

function HandleJoinedFunc(ObjType) {
  return function HandleJoin(request) {
    let param = new Map();
    param.set("prefix", "organizations").set("comand", "HandleJoined").set("ObjType", ObjType);
    return AxiosRequest(false, param, request);
  };
}
