import React from "react";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { Button, Grid, IconButton } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ClearIcon from "@mui/icons-material/Clear";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import Splitter, { SplitterItem } from "smart-webcomponents-react/splitter";
import { AxiosRequest } from "../../../Url";
import TestParams from "../../Sections/ElementsSections/testParams";
import { formEdit, getModalSectionID } from "../../Tools/Tools";
import { EmptyRequest } from "../../../TokenProcessing/TokenProcessing";

export default function DialogAcctools({ props }) {
  const gridpanelRef = React.useRef(null);
  const [params, setParams] = React.useState(null);
  const [filter, setFilter] = React.useState(false);
  const [mainGrid, setMainGrid] = React.useState();
  const [selectRecordID, setSelectRecordID] = React.useState(props.ObjRef);

  React.useLayoutEffect(() => {
    GridLoader(gridpanelRef.current, props, {
      setSelectedRecord: setSelectRecordID,
    }).then((res) => {
      setMainGrid(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (params === null) {
      getFilterParams(props.Module).then((res) => {
        setParams(
          <TestParams
            Module={props.Module}
            reqCommand={"GetFilterParams"}
            GroupID={"0"}
            SectionID={props.SectionID}
            data={res}
            NoTitle={true}
            NoHistory={true}
          />,
        );
      });
    }
  }, [params]);

  //хуки состояния отображения параметров
  const [colapsedParams, setColapsedParams] = React.useState(false);

  //хук для сохранения размеров Разделителей
  const [sizeSplit, setSizeSplit] = React.useState({
    left: "",
    right: "",
  });

  //первичная установка размеров для разделителей
  React.useEffect(() => {
    setSizeSplit({
      left: "25%",
      right: "75%",
    });
  }, []);

  //кнопка параметров
  function colapseParams() {
    updateGridSize();

    setColapsedParams(!colapsedParams);
  }

  function onCollapseParams() {
    setColapsedParams(true);
  }
  function onExpandParams() {
    setColapsedParams(false);
  }

  function updateGridSize() {
    setTimeout(() => {
      if (mainGrid) mainGrid.grid.updateGridSize();
    }, 251);
  }
  //сохраняет размеры элементов разделенных сплиттером(парметры)
  function onResizeParams(ev) {
    updateGridSize();
    setSizeSplit({
      left: ev.detail.firstItem.newSize,
      right: ev.detail.secondItem.newSize,
    });
    // refreshGrid();
  }

  function getFilterParams(module) {
    const params = new Map();
    params
      .set("prefix", module)
      .set("comand", "GetFilterParams")
      .set("GroupID", "0")
      .set("SectionID", props.SectionID)
      .set("NeedRefresh", "1");
    const json = AxiosRequest(true, params);
    return json;
  }

  async function ApplyCorrFilter(module) {
    const params = new Map();
    params.set("prefix", module).set("comand", "ApplyCorrFilter").set("SectionID", props.SectionID);
    await AxiosRequest(true, params);
    setFilter(true);
    if (mainGrid) await mainGrid.grid.refreshSource();
  }

  async function ResetFilter(module) {
    // GET /acctools~ResetFilter?LicGUID=1116AB9141967E1538796A886AABFAA7&SectionID=100
    const params = new Map();
    params.set("prefix", module).set("comand", "ResetFilter").set("SectionID", props.SectionID);
    await AxiosRequest(true, params);
    setParams(null);
    setFilter(false);
    if (mainGrid) await mainGrid.grid.refreshSource();
  }

  async function onSelect() {
    if (props.onEdit) {
      props.onEdit({
        value: null,
        tag: selectRecordID,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }

    if (props.RequestID) {
      await formEdit("1", props.func, { Name: "ObjRef", Value: selectRecordID }, props.RequestID, props.from);
    }
  }

  const styleButton = {
    margin: "2px 2px 2px 2px",
    width: "30px",
    height: "80%",
  };

  return (
    <>
      <Grid
        id={"mainElement"}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{ position: "relative", height: "100%" }}
      >
        {
          //Шапка
        }
        <Grid
          item
          style={{
            border: "1px solid #C8B58F",
            height: "35px",
          }}
        >
          {
            //кнопки шапки
          }
          {/* {кнопка Филтр операций} */}
          <IconButton title="Фильтр операций" style={styleButton} onClick={colapseParams}>
            <ManageSearchIcon />
          </IconButton>
        </Grid>
        {
          //Контейнер с разделителями
        }
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          style={{
            height: "calc(100% - 35px - 42px)",
            width: "100%",
          }}
        >
          <Splitter
            style={{
              height: "100%",
              width: "100%",
              borderRight: "1px solid #C8B58F",
              borderLeft: "1px solid #C8B58F",
            }}
            onResizeEnd={onResizeParams}
            onCollapse={onCollapseParams}
            onExpand={onExpandParams}
          >
            {
              //Контейнер с параметрами
            }
            <SplitterItem
              id={"org_splitter_item_1"}
              collapsed={colapsedParams}
              size={sizeSplit.left}
              min="175"
              collapsible
            >
              <Grid
                style={{
                  height: "100%",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  style={{
                    height: "25px",
                    width: "100%",
                    background: "linear-gradient(to top, #cccccc, 30%, #f9f9f9)",
                  }}
                >
                  <div style={{ whiteSpace: "nowrap", marginLeft: "5px" }}>Фильтр операций</div>
                  <IconButton
                    title="Очистить фильтр операций"
                    disabled={!filter}
                    style={{
                      position: "absolute",
                      left: "calc(100% - 50px)",
                      height: "25px",
                      width: "25px",
                      fontSize: "100%",
                      borderRadius: "0px",
                    }}
                    onClick={() => ResetFilter(props.Module)}
                  >
                    <CleaningServicesIcon />
                  </IconButton>
                  <IconButton
                    title="Скрыть фильтр операций"
                    style={{
                      position: "absolute",
                      left: "calc(100% - 25px)",
                      height: "25px",
                      width: "25px",
                      fontSize: "100%",
                      borderRadius: "0px",
                    }}
                    onClick={colapseParams}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
                <Grid style={{ height: "calc(100% - 50px)" }}>{params}</Grid>
                <div
                  style={{
                    borderRight: "1px solid #C8B58F",
                    borderTop: "1px solid #C8B58F",

                    height: "25px",
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  <IconButton
                    onClick={() => ApplyCorrFilter(props.Module)}
                    style={{
                      border: "0px",
                      borderRadius: "0",
                      backgroundColor: "#f9f9f9",
                      fontSize: "100%",
                      height: "100%",
                      width: "100%",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    Применить
                  </IconButton>
                </div>
              </Grid>
            </SplitterItem>
            {
              //Контейнер с классификатором
            }
            <SplitterItem size={sizeSplit.right}>
              {/* <ContextMenu
                for={"documents"}
                data={MenuMain}
                onMenuItemClick={ContextMainMenuHandler}
              > */}
              <Grid
                item
                id={`rcgrid_panel`}
                ref={gridpanelRef}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              ></Grid>
              {/* </ContextMenu> */}
            </SplitterItem>
          </Splitter>
          {/* {Футтер с кнопками Выбор и Отмена} */}
          <Grid
            container
            item
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              height: "42px",
              width: "100%",
              border: "1px solid #C8B58F",
              display: props.hiddenFutter ? "none" : "",
            }}
          >
            <Grid
              item
              style={{
                margin: "8px",
                width: props.hiddenButton
                  ? "calc(100% - 89px)"
                  : props.Buttons && (props.Button & 1) === 0
                  ? "calc(100% - 89px)"
                  : "calc(100% - 178px)",
              }}
            ></Grid>
            <Grid item style={{ width: "fit-content" }}>
              {/* {Кнопка Выбрать} */}
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                style={{ height: "100%", width: "100%" }}
              >
                {/* {Кнопка Выбрать} */}
                <Button
                  onClick={onSelect}
                  size="small"
                  variant="outlined"
                  style={{
                    textTransform: "none",
                    marginRight: "10px",
                    display: props.Buttons && (props.Button & 1) === 0 ? "none" : "",
                  }}
                  value="1"
                  className="button_Modal_Select"
                >
                  Выбрать
                </Button>
                {/* {кнопка Закрыть} */}
                <Button
                  size="small"
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  value="2"
                  className="button_Modal_Close"
                >
                  Закрыть
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
