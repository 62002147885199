import { Tooltip } from "@mui/material";
import React from "react";

export default function IMemo(props) {
  const [value, setValue] = React.useState(props.Text);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [countChars, setCountChars] = React.useState(props.Text ? props.Text.length : 0);
  const counter = React.useRef();

  function onOpenToolTip() {
    setOpenTooltip(true);
  }

  function onCloseToolTip() {
    setOpenTooltip(false);
  }

  function getHint() {
    if (props.showHint) return props.hint;
    return "";
  }
  React.useEffect(() => {
    if (props) {
      setValue(props.Text);
      setCountChars(props.Text ? props.Text.length : 0);
    }
  }, [props]);

  React.useEffect(() => {
    setValue(props.Text);
  }, [props.Text]);

  React.useEffect(() => {
    if (countChars > 1024 && props.from === "form") {
      counter.current.style.color = "red";
    } else {
      counter.current.style.color = "";
    }
  }, [countChars]);

  return (
    <Tooltip open={openTooltip} arrow placement="bottom-start" title={getHint()}>
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "calc(100% - 24px)", width: "100%" }}>
          <textarea
            id={props.id}
            onMouseEnter={onOpenToolTip}
            onMouseLeave={onCloseToolTip}
            value={value}
            keyname={props.keyName}
            key={props.keyName}
            readOnly={props.readOnly}
            style={props.style}
            onChange={(event) => {
              setValue(event.target.value);
              setCountChars(event.target.value.length);
              if (props.onChange) {
                props.onChange(event);
              }
            }}
            onBlur={(ev) => {
              if (props.onBlur) props.onBlur(ev);
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "23px",
            paddingLeft: "5px",
            display: "inline-flex",
            alignItems: "center",
            fontFamily: "monospace",
            fontSize: "13.33px",
            borderTop: "1px solid rgb(203, 203, 202)",
          }}
        >
          <div>Количество символов:</div>
          <div style={{ paddingLeft: "2px" }} ref={counter}>
            {countChars}
          </div>
          <div style={{ display: props.from === "form" ? "" : "none" }}>/1024</div>
        </div>
      </div>
    </Tooltip>
  );
}
