import { Button, Grid } from "@mui/material";
import React, { useRef } from "react";

export default function CheckMobile() {
  function checkMobileOs() {
    let link = "";
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href = "https://apps.rustore.ru/app/com.krista.smeta_personal_account";
    } else if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href = "https://apps.apple.com/id/app/id6447184444";
    }
    return link;
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%", m: 1 }}
    >
      <Grid item>Функционал недоступен для мобильных устройств, скачайте приложение:</Grid>
      <Grid item>
        <Button
          style={{ backgroundColor: "#3c5b77", textTransform: "none" }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={checkMobileOs}
        >
          Скачать
        </Button>
      </Grid>
    </Grid>
  );
}
