import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Popover } from "@mui/material";
import "./editorClasses.css";
import CheckBoxMultiCheck from "../../MainPage/Sections/ElementsSections/CheckBoxMultiCheck";

export default function CustomCheckBoxList(props) {
  const currentTarget = React.useRef();
  const listRef = React.useRef();
  const [items, setItems] = React.useState();
  const checkstatesCount = React.useRef({});
  React.useEffect(() => {
    if (props.items) {
      setItems(
        props.items.map((value) => {
          const labelId = `checkbox-list-label-${value.id}`;

          return (
            <ListItem
              title={value.label}
              key={value.id}
              style={{
                borderBottom: "1px solid #eeeeee",
                height: "27px",
                minWidth: "200px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
              disablePadding
            >
              <ListItemIcon style={{ height: "inherit", minWidth: "0" }}>
                <CheckBoxMultiCheck
                  parentProps={{ name: value.label, id: value.id }}
                  CheckState={value.checkState}
                  MultiCheckSet={value.MultiCheckSet}
                  onEdit={(data) => {
                    getCountCheckStates(data);
                    props.onEditCustomCheckList({
                      ...data,
                      states: checkstatesCount.current,
                    });
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.label} />
            </ListItem>
          );
        }),
      );
    }
  }, [props.items]);

  function getCountCheckStates(data) {
    if (data.CheckState === "0") {
      delete checkstatesCount.current[data.id];
    } else {
      checkstatesCount.current[data.id] = data.CheckState;
    }
  }

  function arrowDown() {
    let el = listRef.current.querySelector(".focusCheckListItem");
    const firstChild = listRef.current.firstChild;
    const lastChild = listRef.current.lastChild;

    if (!el) {
      el = firstChild;
      el.focus();
      el.classList.add("focusCheckListItem");
      currentTarget.current = el;
      return;
    }

    if (el === lastChild) {
      el.classList.remove("focusCheckListItem");
      el = firstChild;
      el.classList.add("focusCheckListItem");
      currentTarget.current = el;
    } else {
      el.classList.remove("focusCheckListItem");
      el = el.nextElementSibling;
      el.classList.add("focusCheckListItem");
      currentTarget.current = el;
    }

    const parentEl = listRef.current.parentElement.parentElement;
    const scrollingPos = el.getBoundingClientRect().y - parentEl.getBoundingClientRect().y;

    parentEl.scrollBy({
      left: 0,
      top: scrollingPos,
      behavior: "smooth",
    });
  }

  function arrowUp() {
    let el = listRef.current.querySelector(".focusCheckListItem");
    const firstChild = listRef.current.firstChild;
    const lastChild = listRef.current.lastChild;

    if (!el) {
      el = lastChild;
      el.classList.add("focusCheckListItem");
      currentTarget.current = el;
      return;
    }

    if (el === firstChild) {
      el.classList.remove("focusCheckListItem");
      el = lastChild;
      el.classList.add("focusCheckListItem");
      currentTarget.current = el;
    } else {
      el.classList.remove("focusCheckListItem");
      el = el.previousElementSibling;
      el.classList.add("focusCheckListItem");
      currentTarget.current = el;
    }

    const parentEl = listRef.current.parentElement.parentElement;
    const scrollingPos = el.getBoundingClientRect().y - parentEl.getBoundingClientRect().y;

    parentEl.scrollBy({
      left: 0,
      top: scrollingPos,
      behavior: "smooth",
    });
  }

  function onKeyDown(ev) {
    switch (ev.code) {
      case "ArrowDown":
        arrowDown();
        break;
      case "ArrowUp":
        arrowUp();
        break;
      case "Space":
        ev.preventDefault();
        if (currentTarget.current) {
          const bttn = currentTarget.current.querySelector(`[listbutton="true"]`);
          if (bttn) bttn.click();
        }
        break;
      case "Enter":
        ev.stopPropagation();
        break;
      case "Tab":
        ev.preventDefault();
        arrowDown();
        break;
    }
  }

  return (
    <Popover
      transitionDuration={50}
      onKeyDown={onKeyDown}
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={(ev) => {
        props.handleClose(ev);
        checkstatesCount.current = {};
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div
        ref={(el) => {
          if (!el) return;
          const listEl = el.querySelector("ul");
          listRef.current = listEl;
        }}
        checklist="true"
      >
        <List
          sx={{
            width: "fit-content",
            maxWidth: `${document.body.clientWidth / 2}px`,
            maxHeight: "400px",
            bgcolor: "background.paper",
          }}
        >
          {items}
        </List>
      </div>
    </Popover>
  );
}
