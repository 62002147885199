//картинки элементов
export const IconFolder =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAmElEQVRIiWNgGAUDDRhhjP/zGbwYGBlmMjAwyGCo+s/QyJjI0ECOBUxIVmE3HCJX/38+eRYgfLCW5z+DpAQDAwsLOeYgwJ8/fxm+fGpkdHvVjGrBMZX/FBsOA79//2W0vsvCwIAcRNQynIGBgYGVlRnGZMKnjhpg1IJRC0YtQLXgCfWM/f8Y0wLGf2nIEhQanka5OaOAWgAAdiEh3MqSfL4AAAAASUVORK5CYII=";
export const IconList =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAABmJLR0QA/wD/AP+gvaeTAAACfElEQVRIib2VTUhUURTHf/fNjOPofGTWfOVMhhFUEgRhUabkYtZCm8gEjWzRokULlwUZSLswIlxE4iKiWgUVmYsgCJqNMKEwZUKMjOP4AY36Xlq+12IEpxnfvKcz9d8c7nmH87v3nvPOhf8ske/IZDK1QLMQosJ0EiHiTqcztm3g0tKSV1XVz0IIr1nYhjRFUfp8Pt8to0Br3rpVCOG91nOf1bVf5kiqRlvkuLjU2XZzZmZGDgQCd00DVVW1CyFQNY07jww3C0B6WWWgfxjx5AMdF8/2JxIJJRQKDejFS6ayFpGQJILnO3kXTfDs+UfhdrvvxePxK/8MCCAsFuouXObV+y+8fjMm/H7/YCwW694qNr+GBRqfh6kfhX6PHVrqNteSzUaoo4enQw9xOCqk1pbDgyMjIwuRSOTltoDzP2F6udC/stFTlVZwWEHVgKpKDnZdZfjxA0LBXbZwONwHjAKyaaCexMYP5aqArqO5X6oZTTYx+S1F08kDLmAv8N00sDkIpwKFfkvByNDfW+7CEGiRwGI6t7EMgROLEKyG2BykFf24Ez6od5cBeGR31uZ2ZCkyBKblbGMklyGzph9X74EaexmAFpGdDlYJ7EWKaXaCGAJrHVm730R9ygKcWICgE8bSkFzRjzsTLFfT1GbtuZBxMjMyHm0KuGyQkjfH2Vba58zO15KBqgYq8FuD1fUiccYsc0BvVdY2eExmNFBZ3sPtaMsTKvIqb1+M7jjp5PgUe04fMgX8CnD9RjuyXGSsGKgheIzGxjDpuWSKvPL+BfR4PJ+i0ehtn9/ZDo6Srjs1O73Y29s7BMzm+vVetRrAVQoQWAfmgJ1fVTn0B+QbqU31/zFJAAAAAElFTkSuQmCC";

export const expandIcon = `
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ExpandMoreIcon"
            style="height: 15px; width: 15px;"
          >
            <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"></path>
          </svg>
        `;
