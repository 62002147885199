import { atom } from "jotai";
import { GetOptionsForUser } from "./components/Url";

const userOptions = GetOptionsForUser();
//salary
export const renderingIdAtom = atom(0);
export const groupIdAtom = atom(0);
export const leavesOpenAtom = atom(false);
export const isAdminAtom = atom<boolean>(userOptions?.isAdmin);
export const personsTableCurrentId = atom(null);
