import React from "react";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import { HandleRequestForPaytypes } from "../../../../rcsgrid/GridFunctions/paytypesGrid";

export default function PaytypesForTabs({ props }) {
  const [mainGrid, setMainGrid] = React.useState();
  const gridpanelRef = React.useRef();
  const box = React.useRef();

  React.useEffect(() => {
    GridLoader(
      gridpanelRef.current,
      {
        ...props,
        Module: "paytypes",
        ObjType: "2001",
        GroupID: props.selectedRecordID,
      },
      {
        Columns: "Programs",
      },
    ).then((res) => {
      setMainGrid(res);
    });
  }, []);

  React.useEffect(() => {
    if (mainGrid) {
      box.current.update = update;
    }
  }, [mainGrid]);

  async function update(id) {
    if (mainGrid) {
      mainGrid.source.onHandleRequest = HandleRequestForPaytypes({
        ...props,
        Module: "paytypes",
        ObjType: "2001",
        GroupID: id,
      });
      await mainGrid.grid.setSource(mainGrid.source);

      await mainGrid.grid.refreshSource();

      mainGrid.grid.updateGridSize();
    }
  }

  return (
    <div
      ref={box}
      id="paytypesBox"
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        id={`rcgrid_panel`}
        ref={gridpanelRef}
        style={{
          height: "100%",
          width: "100%",
          position: "relative",
        }}
      ></div>
    </div>
  );
}
