import { Button, Grid } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import React from "react";
import { AxiosRequest } from "../../../Url";

import { DateCalendar, LocalizationProvider, dateCalendarClasses, heIL, pickersDayClasses } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import dayjs from "dayjs";
import { store } from "../../../../store";
import { formEdit } from "../../Tools/Tools";

function GetDateRange(value) {
  const params = new Map();
  params.set("prefix", "ABLUtils").set("comand", "GetDateRange").set("Value", value);
  return AxiosRequest(true, params);
}
function SetDateRange(StartDate, EndDate) {
  const params = new Map();
  params.set("prefix", "ABLUtils").set("comand", "SetDateRange").set("StartDate", StartDate).set("EndDate", EndDate);

  return AxiosRequest(true, params);
}

export default function DialogStartAndEndDate({ props }) {
  // состояние выбора периода
  const [start, setStart] = React.useState();
  const [end, setEnd] = React.useState();

  const [calendarValue, setCalendarValue] = React.useState();

  // контейнер календаря
  const calendar = React.useRef();

  // переключатель выбора начальной и конечной даты
  const switcher = React.useRef();

  // даты начала и конца в милисекундах
  const startStamp = React.useRef();
  const endStamp = React.useRef();

  // контейнеры с полями редакторов начала и конца периода
  const startRef = React.useRef();
  const endRef = React.useRef();

  // значение для первичного позиционирования календаря
  // const [defaultValue, setDefaultValue] = React.useState();

  // тема
  const theme = `-${store.getState().theme.theme}`;

  React.useEffect(() => {
    // запрос дат календаря
    GetDateRange(props.ObjRef ? props.ObjRef : "0").then((res) => {
      // установка значений редакторов
      setStart(res.StartDate ? res.StartDate : "");
      setEnd(res.EndDate ? res.EndDate : "");

      // установка дат в милисекундах для сравнения
      if (res.StartDate) {
        startStamp.current = getStamp(res.StartDate);
        // позиционироавние календаря
        setCalendarValue(dayjs(getDateInFormat(res.StartDate)));
      } else {
        // позиционирование календаря на сегодня
        setCalendarValue(dayjs(new Date()));
      }

      if (res.EndDate) {
        endStamp.current = getStamp(res.EndDate);
      }

      // первичное обновление выбранного периода
      setTimeout(() => {
        updatePeriod();
      });

      // установка переключателя на начальную дату | false - конечная
      switcher.current = true;
      // визуальная фокусировка на редактор начальной даты
      selectStart();
    });
  }, []);

  // получение количества миллисекунд из даты формата DD.MM.YYYY
  function getStamp(date) {
    return (
      new Date(getDateInFormat(date)).getTime() +
      // корректировка по Часовому поясу
      new Date().getTimezoneOffset() * 60 * 1000
    );
  }

  // преобразование формата даты для екземпляра new Date
  function getDateInFormat(value) {
    let date = value;

    if (date) {
      if (date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) return date;
      if (date.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/)) {
        date = date.split(".");
        date = [date[2], date[1], date[0]];
        return date.join("-");
      }
    }
  }

  //  проверка наличия даты в периоде
  function checkPeriod(timeStamp) {
    if (!endStamp.current || !startStamp.current) return;
    return startStamp.current < timeStamp && timeStamp < endStamp.current;
  }

  // обновление периода
  function updatePeriod() {
    // получение группы записей календаря
    const rowgroup = calendar.current.querySelector("[role=rowgroup]").children;
    // перебор элементов
    for (let i = 0; i < rowgroup.length; i++) {
      // разделение записи на кнопки
      const group = rowgroup[i].children;
      // перебор кнопок в записи и обновление классов каждой видимой
      for (let j = 0; j < group.length; j++) {
        // удаление класса выбора по умолчанию(костыль)
        // group[j].classList.remove("Mui-selected");

        // установка или удаление класса начало периода
        if (Number(group[j].dataset.timestamp) === startStamp.current) {
          group[j].classList.remove(`date-range${`${theme}`}`);
          group[j].classList.add(`date-range-start${`${theme}`}`);
          continue;
        } else {
          group[j].classList.remove(`date-range-start${`${theme}`}`);
        }

        // установка или удаление класса конец периода
        if (Number(group[j].dataset.timestamp) === endStamp.current) {
          group[j].classList.remove(`date-range${`${theme}`}`);
          group[j].classList.add(`date-range-end${`${theme}`}`);
          continue;
        } else {
          group[j].classList.remove(`date-range-end${`${theme}`}`);
        }

        // установка или удаление класса внутри периода
        if (checkPeriod(Number(group[j].dataset.timestamp))) {
          group[j].classList.add(`date-range${`${theme}`}`);
        } else {
          group[j].classList.remove(`date-range${`${theme}`}`);
        }
      }
    }
  }

  // получение строкового значения даты
  function getDateStr(newValue) {
    return `${newValue.get("date") < 10 ? `0${newValue.get("date")}` : newValue.get("date")}.${
      newValue.month() + 1 < 10 ? `0${newValue.month() + 1}` : newValue.month() + 1
    }.${newValue.year()}`;
  }

  // проверка на попытку установить конечную дату раньше начальной
  function checkStartDate(newValue) {
    if (!end) return;
    const endDate = new Date(getDateInFormat(end));
    const currentDate = new Date(getDateInFormat(getDateStr(newValue)));

    return endDate.getTime() < currentDate.getTime();
  }

  // проверка на попытку установить начальную дату позже конечной
  function checkEndDate(newValue) {
    if (!start) return;
    const startDate = new Date(getDateInFormat(start));
    const currentDate = new Date(getDateInFormat(getDateStr(newValue)));

    return startDate.getTime() > currentDate.getTime();
  }

  // визуальная фокусировка
  function selectEnd() {
    startRef.current.classList.remove("date-range-current");
    endRef.current.classList.add("date-range-current");
  }

  function selectStart() {
    startRef.current.classList.add("date-range-current");
    endRef.current.classList.remove("date-range-current");
  }

  // ручная фокусировка при клике на поля редактирования
  function endClick() {
    selectEnd();
    switcher.current = false;
  }

  function startClick() {
    selectStart();
    switcher.current = true;
  }

  // функции редактирования для редакторов периода
  function onEditStart(data) {
    setCalendarValue(dayjs(getDateInFormat(data.value)));
    setStart(data.value);
    startStamp.current = getStamp(data.value);
    setTimeout(() => {
      if (startStamp.current && endStamp.current) {
        updatePeriod();
      }
    });
  }

  function onEditEnd(data) {
    setCalendarValue(dayjs(getDateInFormat(data.value)));
    setEnd(data.value);
    endStamp.current = getStamp(data.value);
    setTimeout(() => {
      if (startStamp.current && endStamp.current) {
        updatePeriod();
      }
    });
  }

  // выбор начальной и конечной даты из календаря периода
  function onChangePeriod(newValue) {
    setCalendarValue(newValue);
    const value = getDateStr(newValue);

    if (switcher.current) {
      if (checkStartDate(newValue)) {
        setEnd(value);
        endStamp.current = getStamp(value);
        return;
      }
      setStart(value);
      startStamp.current = getStamp(value);
      selectEnd();
    } else {
      if (checkEndDate(newValue)) {
        setStart(value);
        startStamp.current = getStamp(value);
        return;
      }
      setEnd(value);
      endStamp.current = getStamp(value);
      selectStart();
    }

    switcher.current = !switcher.current;
  }

  // кнопка Выбрать
  async function onSelect() {
    const json = await SetDateRange(start, end);

    if (props.onEdit) {
      props.onEdit({
        value: json.Text,
        tag: json.Value,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: json.Value,
        },
        props.RequestID,
        props.from,
      );
    }
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        style={{ height: "calc(100% - 45px)" }}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          style={{ height: "45px)" }}
        >
          <Grid
            item
            style={{
              height: "27px",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "158px", paddingLeft: "13px" }}
            >
              <Grid
                style={{
                  position: "relative",
                  top: "-11px",
                  left: "20px",
                  backgroundColor: "white",
                  color: "#aaaaaa",
                  display: "flex",
                  width: "12px",
                  height: "12px",
                  justifyContent: "center",
                }}
              >
                С
              </Grid>
              <Grid
                ref={startRef}
                onClick={startClick}
                style={{
                  border: "1px solid #cccccc",
                  height: "27px",
                  width: "100px",
                }}
              >
                <TestEditor value={start} editStyle={2} onEdit={onEditStart} />
              </Grid>
            </Grid>
          </Grid>
          -
          <Grid item style={{ height: "27px" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ width: "158px", paddingLeft: "15px" }}
            >
              <Grid
                style={{
                  position: "relative",
                  top: "-11px",
                  left: "29px",
                  backgroundColor: "white",
                  color: "#aaaaaa",
                  display: "flex",
                  width: "20px",
                  height: "12px",
                  justifyContent: "center",
                }}
              >
                По
              </Grid>
              <Grid
                ref={endRef}
                onClick={endClick}
                style={{
                  border: "1px solid #cccccc",
                  height: "27px",
                  width: "100px",
                }}
              >
                <TestEditor value={end} editStyle={2} onEdit={onEditEnd} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="center" alignItems="flex-start">
          {calendarValue !== undefined ? (
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
                <DateCalendar
                  showDaysOutsideCurrentMonth
                  views={["day"]}
                  ref={calendar}
                  // defaultValue={defaultValue}
                  value={calendarValue}
                  onClick={() => {
                    setTimeout(() => {
                      updatePeriod();
                    });
                  }}
                  onChange={onChangePeriod}
                />
              </LocalizationProvider>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <Grid
        item
        style={{
          width: "100%",
          height: "40px",
          marginTop: "5px",
        }}
      >
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ paddingTop: "8px" }}>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
