import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import TestEditor from "../../../Editor/testEditor";
import { AxiosRequest } from "../../../Url";
import EditorAdress from "../../Tools/Editor/EditorAdress";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Scrollbars from "react-custom-scrollbars-2";
import ReactDOM from "react-dom";
import { RenderMessageBox, tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import { ParamSetOption } from "../../Tools/ProjectX";
import {
  AddressEditKladrObj,
  createConfirmation,
  getDefaultMenu,
  getParamsObject,
  isEmptyObject,
} from "../../Tools/Tools";
import CheckBoxMultiCheck from "./CheckBoxMultiCheck";

// функции для сплиттера с возможностью изменения размеров полей
var resize = {};

// функция включения live изменения размеров
function onMouseDown(ev) {
  resize.resizeMode = true;
  resize.target = ev.target;
  resize.col = ev.target.closest("TABLE").firstChild.firstChild;
}

// функция установки размеров после изменения
function onMouseUp(ev, setWidth) {
  setWidth(resize.size);
  resize = {};
}

// функция live изменения размеров
function onMouseMove(ev) {
  if (resize.resizeMode) {
    let size = ev.clientX - resize.target.clientWidth - resize.target.getBoundingClientRect().x;
    size =
      (resize.col.clientWidth / resize.col.parentElement.clientWidth) * 100 +
      (size / resize.col.parentElement.clientWidth) * 100;
    if (size < 30) size = 30;
    if (size > 70) size = 70;
    resize.size = size;
    resize.col.style.width = `${size}%`;
  }
}

// функция расчета отступов для параметров в завизимости от уровня вложенности
function getPadding(items, item, index) {
  let padding = 15;
  const level = item.Level ? item.Level : 0;

  if (items.length - 1 > index && level < items[index + 1].Level) {
    padding -= 15;
  }
  if (item.Level) {
    padding += item.Level * 15;
  }
  return padding;
}

// функция полечения высоты в зависимости от модуля
function getHeight(module) {
  switch (module) {
    case "organizations":
      return 207;
    case "address":
      return 472.5;
    case "persons":
      return 207;
    default:
      return 34;
  }
}

// запрос данных для выпадающего списка для записей с типом Дом
function getAddressConditionValue(Name, ID) {
  const params = new Map();
  params.set("prefix", "address").set("comand", "GetParamValues").set("Name", Name).set("Type", "1").set("ID", ID);
  return AxiosRequest(true, params);
}

export default function AddressParams(props) {
  // полные данные параметров
  const [paramsData, setParamData] = React.useState();

  // состояние справочника Kladr
  const [useKladr, setUseKladr] = React.useState();

  // адресная строка
  const [addressCaption, setAddressCaption] = React.useState();

  // данные записей
  const [paramsItems, setParamsItems] = React.useState();
  // записи для отрисовки
  const [paramsJsx, setParamsJsx] = React.useState();
  // размер 1-ой колонки для контроля
  const [width, setWidth] = React.useState(35);
  // размер контейнера таблицы с параметрами
  const [height, setHeight] = React.useState();

  // переменная для хранения элемента с редактором
  const editor = React.useRef();

  // карта с функциями редактирования отрисованных редакторов
  const [editionMap, setEditionMap] = React.useState();

  // хук для получения функций редактирования и записи их в карту выше
  const [getEdition, setGetEdition] = React.useState();

  // переменная для динамической отрисовки редактора, контролирует отрисовку по нажатой клавише мыши
  const mouseDown = React.useRef(null);

  // ссылка на контейнер параметров
  const paramsBox = React.useRef();

  //деление адреса (муницип/адмнистр)
  const [division, setDivision] = React.useState("0");

  const [isRussia, setIsRussia] = React.useState();

  // Инструменты управления выпадающим списком для записей с типом Дом
  // jsx элементы списка
  const [list, setList] = React.useState();
  // ссылка на кнопку для выпадающего списка
  const [anchorElList, setAnchorElList] = React.useState(null);
  // состояние отображения списка
  const openList = Boolean(anchorElList);
  // функции открытия и закрытия
  const handleClickList = (event) => {
    setAnchorElList(event.currentTarget);
  };
  const handleCloseList = () => {
    setAnchorElList(null);
  };

  // Первичная установка объекта параметров
  React.useEffect(() => {
    if (props.data) {
      updateParams(props.data);
    }

    // для обновления данных снаружи
    paramsBox.current.update = (data) => {
      updateParams(data);
    };
    setEditionMap(new Map());

    // установка высоты блока параметров
    setHeight("calc(100% - 76px)");
    // установка дефолтного элемента для пустоко списка
    setList(<MenuItem disabled={true}>Нет опций</MenuItem>);
  }, []);

  // обновление состояния при переключении использования справочника Kladr
  React.useEffect(() => {
    setUseKladr(props.useKladr);
  }, [props.useKladr]);

  // при изменении данных снаружи они обновляются
  React.useEffect(() => {
    if (props.data) {
      updateParams(props.data);
    }
  }, [props.data]);

  React.useEffect(() => {
    // обновление параметров при изменении общих данных
    if (paramsData) {
      // скрытие текущего редактора если он был открыт
      if (editor.current) {
        deleteEditor(editor.current);
      }
      // обновление записей
      setParamsItems([...paramsData.Items]);
      setDivision(paramsData.division || "0");
      setIsRussia(
        paramsData.Items[0]?.Value === undefined ||
          paramsData.Items[0].Value === "" ||
          paramsData.Items[0].Value.toLowerCase().substring(0, 5) === "росси",
      );
      if (props.setObjRef && paramsData.ID) props.setObjRef(paramsData.ID);
    }
  }, [paramsData]);

  React.useEffect(() => {
    // обновление отрисованных записей
    if (paramsItems) {
      setParamsJsx(getJsxParams(paramsItems, setWidth));
    }
  }, [paramsItems, division, isRussia]);

  // заполнение карты с функциями редактирования при отрисовке нового редактора
  React.useEffect(() => {
    if (getEdition) {
      editionMap.set(getEdition.key, {
        setValue: getEdition.setValue,
        setInputValue: getEdition.setInputValue,
        value: getEdition.value,
        inputValue: getEdition.inputValue,
      });
    }
  }, [getEdition]);

  // разворачивание записей при первой отрисовке, если они должны быть развернуты
  React.useLayoutEffect(() => {
    if (paramsJsx) {
      paramsBox.current.querySelectorAll("#expandButtonParams").forEach((el) => {
        if ((Number(el.dataset.collapsed) & ParamSetOption.Collapsed) !== ParamSetOption.Collapsed) {
          el.closest("TR").dataset.collapsed = "1";
          expandRec({ target: el });
        }
      });
    }
  }, [paramsJsx]);

  function updateParams(data) {
    setEditionMap(new Map());
    // создание нового контента
    setAddressCaption(data.Caption);
    setParamData(data);
  }

  // функция для получения кнопки разворачивания записей
  function getExpandButton(items, item, index) {
    const level = item.Level ? item.Level : 0;
    if (items.length - 1 > index && level < items[index + 1].Level) {
      let collapsed = item.Options;
      if ((division === "0") & (item.FieldName === "Settlement")) {
        item.Options ? (collapsed += ParamSetOption.Collapsed) : (collapsed = ParamSetOption.Collapsed);
      }
      return (
        <IconButton
          id="expandButtonParams"
          data-collapsed={collapsed}
          name="expandButton"
          onClick={(ev) => {
            expandRec(ev);
          }}
          style={{
            height: "15px",
            width: "15px",
            transform: Number(collapsed) & ParamSetOption.Collapsed ? "rotate(-90deg)" : "",
            transition: "all 0.2s",
          }}
        >
          <ExpandMoreIcon style={{ height: "15px", width: "15px" }} />
        </IconButton>
      );
    }
  }

  // Функция скрытия и отображения записей при клике на кнопку expandButton
  function expandRec(ev) {
    // получение кнопки на текущей записи
    let button = ev.target.closest("BUTTON");
    // получение текущей записи
    let record = ev.target.closest("TR");
    // получение уровня вложенности
    let level = record.dataset.level;
    // получение следующей записи
    let nextRec = record.nextElementSibling;

    // получение id родительской записи
    // const parentID = record.id.split("_")[1];

    //выражение для проверки состояния развернутости записи
    const condition = record.dataset.collapsed === "1";
    // сокрытие или раскрытие всех дочерних записей у родительской
    // цикл работает до тех пор, пока уровень вложенности следующей записи больше чем уровень первоначальной записи
    while (nextRec && nextRec.dataset.level > level) {
      // проверка привязки к родительской записи
      // if (nextRec.dataset.parentid === parentID) {
      // проверка на наличие вложенных записей
      const nextButton = nextRec.querySelector("button[name=expandButton]");
      // если вложенные записи были развернуты то они свернутся при сворачивании записей родителя
      if (nextButton && nextRec.dataset.collapsed === "0") {
        nextRec.dataset.collapsed = "1";
        nextButton.style.transform = "rotate(-90deg)";
      }
      // сокрытие или раскрытие записей в зависимости от условия
      nextRec.style.display = condition ? "" : "none";
      // проверка привязки к родительской записи
      if (
        nextRec.dataset.level !== `${Number(level) + 1}` ||
        Number(nextRec.dataset.options) & ParamSetOption.Disabled
        // Number(nextRec.dataset.options) & ParamSetOption_Hidden
      ) {
        nextRec.style.display = "none";
      }
      // } else {
      //   nextRec.style.display = "none";
      // }
      nextRec = nextRec.nextElementSibling;
    }

    // поворот кнопки при сворачивании
    button.style.transform = condition ? "rotate(0deg)" : "rotate(-90deg)";
    record.dataset.collapsed = condition ? "0" : "1";
  }

  // функция на редактирование параметров адреса с помощью системы kladr
  async function kladrEdit(json) {
    const keys = Object.keys(json);
    let res;
    //итерация по ключам параметров которые необходимо установить после редактирования
    for (const objectKey of keys) {
      if (json[objectKey] !== null && (typeof json[objectKey] !== "object" || isEmptyObject(json[objectKey]))) {
        continue;
      }
      // происходит серверный запрос и после ответа устанавливаются значения
      switch (objectKey) {
        case "Classificator":
          res = await SetParamRequest({
            IsShort: "0",
            IsCode: "0",
            fieldname: objectKey,
            value: json[objectKey].Классификатор,
            current: paramsData.ID,
            clear: "1",
          });
          settingValues(res, { fieldname: objectKey });
          break;
        case "GUID":
          if (!json.Identifier) {
            res = await SetParamRequest({
              IsShort: "0",
              IsCode: "0",
              fieldname: "Identifier",
              value: json[objectKey].Идентификатор,
              current: paramsData.ID,
              clear: "1",
            });
          }
          settingValues(res, { fieldname: objectKey });

          break;
        case "Identifier":
          res = await SetParamRequest({
            IsShort: "0",
            IsCode: "0",
            fieldname: objectKey,
            value: json[objectKey]["ФИАС (ГАР)"],
            current: paramsData.ID,
            clear: "1",
          });
          settingValues(res, { fieldname: objectKey });
          changeDivision(division, false);

          break;
        case "PostIndex":
          res = await SetParamRequest({
            IsShort: "0",
            IsCode: "0",
            fieldname: objectKey,
            value: json[objectKey]["Почтовый индекс"],
            current: paramsData.ID,
            clear: "1",
          });
          settingValues(res, { fieldname: objectKey });

          break;
        case "OKTD":
          if (json[objectKey]["ОКАТО"]) {
            res = await SetParamRequest({
              IsShort: "0",
              IsCode: "0",
              fieldname: objectKey,
              value: json[objectKey]["ОКАТО"],
              current: paramsData.ID,
              clear: "1",
            });
          }
          settingValues(res, { fieldname: objectKey });

          break;
        default:
          for (const [key, value] of Object.entries(json[objectKey])) {
            switch (key) {
              case "Сокращение":
                // объект Дом имеет особую систему
                if (objectKey === "House") {
                  res = await setFieldsNames({
                    fieldname: objectKey,
                    value: value,
                    current: paramsData.ID,
                  });

                  break;
                }
                res = await SetParamRequest({
                  IsShort: "1",
                  IsCode: "0",
                  fieldname: objectKey,
                  value: value,
                  current: paramsData.ID,
                  clear: "1",
                });
                settingValues(res, { fieldname: objectKey, IsShort: true });

                break;
              case "Код":
                res = await SetParamRequest({
                  IsShort: "0",
                  IsCode: "1",
                  fieldname: objectKey,
                  value: value,
                  current: paramsData.ID,
                  clear: "1",
                });
                settingValues(res, { fieldname: objectKey, IsCode: true });

                break;
              default:
                res = await SetParamRequest({
                  IsShort: "0",
                  IsCode: "0",
                  fieldname: objectKey,
                  value: value,
                  current: paramsData.ID,
                  clear: "1",
                });
                // объект Дом имееот особую систему
                if (objectKey === "House") {
                  setTextValues(res);
                  break;
                }
                settingValues(res, { fieldname: objectKey });
                break;
            }
          }
          break;
      }
    }
  }

  // установка значений для параметров с типом Дом
  function setTextValues(res) {
    let rec, textValueDiv, edition;

    // Получение основной записи и ее контейнеров
    rec = paramsBox.current.querySelector("#param_House");
    textValueDiv = rec.querySelector('[textvalue="true"]');
    // получение данных редактора
    edition = editionMap.get(rec.dataset.key);
    // установка значений для записи
    rec.dataset.value = res.Value !== undefined ? res.Value : "";
    textValueDiv.innerText = res.Value !== undefined ? res.Value : "";

    // установка значений для редакторов
    if (edition) {
      edition.setValue({
        label: res.Value !== undefined ? res.Value : "",
      });
      edition.setInputValue(res.Value !== undefined ? res.Value : "");
    }

    // прерывание если не было изменено других объектов с типом Дом
    if (!res.content) return;
    // изменение следующих записей анологично первой в зависимости от данных из запроса
    res.content.split(",").forEach((item) => {
      if (item.match(/[A-z]+=[0-9]*/)) {
        const value = item.split("=")[1];
        rec = rec.nextElementSibling;
        textValueDiv = rec.querySelector('[textvalue="true"]');
        edition = editionMap.get(rec.dataset.key);
        rec.dataset.value = value !== undefined ? value : "";
        textValueDiv.innerText = value !== undefined ? value : "";

        if (edition) {
          edition.setValue({
            label: value !== undefined ? value : "",
          });
          edition.setInputValue(value !== undefined ? value : "");
        }
      } else {
        // если для записи нет данных она очищается
        clearValue(item);
      }
    });
  }

  // устнаовка заголовков параметров с типом Дом
  async function setFieldsNames(data) {
    let res = await SetParamRequest({
      IsShort: "0",
      IsCode: "0",
      ...data,
      tag: "0",
      type: "1",
      clear: "1",
    });

    let rec, fieldValueDiv;
    // получение записи
    rec = paramsBox.current.querySelector("#param_House");
    // установка названия для записи
    rec.dataset.name = res.Value ? res.Value : "";
    // получение контейнера с заголовком
    fieldValueDiv = rec.querySelector('[fieldvalue="true"]');
    // установка знчения в контейнер с заголовком
    fieldValueDiv.innerText = res.Value !== undefined ? res.Value : "";

    // аналогичная установка следующих заголовков если те должны быть изменены
    if (res.content) {
      res.content.split(",").forEach((item) => {
        const value = item.split("=")[1];

        rec = rec.nextElementSibling;
        rec.dataset.name = value !== undefined ? value : "";

        fieldValueDiv = rec.querySelector('[fieldvalue="true"]');
        fieldValueDiv.innerText = value !== undefined ? value : "";
      });
    }
    return res;
  }

  //универсальная функция установки параметров адреса
  async function SetParamRequest(data) {
    const params = new Map();
    params
      .set("prefix", "address")
      .set("comand", "SetParamProperty")
      .set("Name", data.fieldname)
      .set("ID", data.tag ? data.tag : "-1")
      .set("GroupID", paramsData.GroupID)
      .set("Current", paramsData.ID ? paramsData.ID : "0")
      .set("clear", data.clear ? data.clear : "1");
    if (data.fieldname !== "dv") params.set("dv", division);
    if (data.IsShort) params.set("IsShort", data.IsShort);
    if (data.IsCode) params.set("IsCode", data.IsCode);
    if (data.type) params.set("Type", data.type);
    if (data.value !== undefined) params.set("Value", data.value);
    if (data.CheckState !== undefined) {
      params.set("Value", data.CheckState ? "1" : "0");
    }
    const json = await AxiosRequest(true, params);
    // если изначально адрес пуст или по какой то причине нет ID у полных данных адреса, то он устанавливается из запроса
    if (!paramsData.ID && json.Current) {
      params.clear();
      params
        .set("prefix", props.Module)
        .set("comand", "SetAddress")
        .set("Type", "0")
        .set("ID", json.Current)
        .set("GroupID", paramsData.GroupID);
      await AxiosRequest(true, params);
      paramsData.ID = json.Current;
      setParamData({ ...paramsData });
    }

    return json;
  }

  // функция на клик по элементу выпадающего списка
  async function onEditList(data) {
    const params = new Map();
    params
      .set("prefix", "kladr")
      .set("comand", "AssignAddress")
      .set("ObjRef", data.current)
      .set("Key", data.tag)
      .set("dv", division)
      .set("Field", data.fieldname);
    const json = await AxiosRequest(true, params);
    setParamData(getParamsObject(json, paramsData.GroupID, paramsData.address, paramsData.parentModule));
    // установка заголовка
    if (json) {
      setAddressCaption(json.Caption);
    }
  }

  // функция на редактирование параметров
  async function onEdit(data) {
    // если включена система редактирования kladr то редактирование будет выполнятся по соответствующему сценарию
    if (data.kladr) {
      await kladrEdit(data.json, data);
      return;
    }

    // стандартное редактирование
    const json = await SetParamRequest(data);
    settingValues(json, data);
  }

  // универсальная установка значений
  function settingValues(res, data) {
    if (res) {
      if (res.Token) {
        tokenProcessingTest(res, {
          func: (val) => {
            settingValues(val, data);
          },
        });
        return;
      }
      // установка заголовка Адреса
      if (res.Caption) {
        setAddressCaption(res.Caption);
      }

      // очистка данных если необходима
      if (res.content) {
        res.content.split(",").forEach((item) => {
          if (item.match(/[A-z]+=[0-9]*/)) {
            return;
          }
          clearValue(item);
        });
      }

      // получение редактируемой записи
      let rec = paramsBox.current.querySelector(`#param_${data.fieldname}`);

      // для редактирования данных сокращения параметра
      if (data.IsShort) {
        rec = rec.nextElementSibling;
        if (rec.dataset.fieldname !== "ShortName") return;
      }
      // для редактирования данных Кода параметра
      if (data.IsCode) {
        rec = rec.nextElementSibling;
        if (rec.dataset.fieldname !== "Code") rec = rec.nextElementSibling;
        if (rec.dataset.fieldname !== "Code") return;
      }

      if (!rec) return;
      // получение контейнера со значением
      const textValueDiv = rec.querySelector('[textvalue="true"]');

      const index = rec.dataset.arrayindex;
      const paramitem = paramsData.Items[index];
      if (data.CheckState !== undefined) {
        paramitem.CheckState = res.Value;
      } else {
        // стандартное редактирование значения
        // установка данных для записи
        paramitem.Value = res.Value ? res.Value : "";
        paramitem.ObjRef = res.ID ? res.ID : "0";
      }
      setParamData({ ...paramsData });

      // проверка на редактирование с помощью чекбокса
      if (data.CheckState !== undefined) {
        if (data.setStateCheckBox) data.setStateCheckBox(Number(res.Value) & 1);
        rec.dataset.checkstate = res.Value;
        textValueDiv.innerText = "";
      } else {
        // стандартное редактирование значения
        // установка данных для записи
        rec.dataset.value = res.Value ? res.Value : "";
        rec.dataset.objref = res.ID ? res.ID : "0";
        // установка значения для контейнера со значением
        textValueDiv.innerText = res.Value ? res.Value : "";
        // обновление значения в редакторе
        if (editor.current) {
          const edition = editionMap.get(rec.dataset.key);
          if (edition) {
            edition.setValue({
              label: res.Value ? res.Value : "",
              id: res.ID ? res.ID : "0",
            });
            edition.setInputValue(res.Value ? res.Value : "");
          }
        }
      }
      if (rec.dataset.fieldname === "Country") {
        setIsRussia(rec.dataset.value === "" || rec.dataset.value.toLowerCase().substring(0, 5) === "росси");
      }
    }
  }

  // удаление значения по fieldname
  function clearValue(name, rec = paramsBox.current.querySelector(`#param_${name}`)) {
    if (!rec) return;

    let short, code;
    short = rec.nextElementSibling;
    // проверка на наличие сокращения и кода, и их очищение
    if (short && (short.dataset.fieldname === "ShortName" || short.dataset.fieldname === "Code")) {
      clearValue("", short);
    }
    if (short) {
      code = short.nextElementSibling;
      if (code && code.dataset.fieldname === "Code") {
        clearValue("", code);
      }
    }

    // очищение контейнера со значением, данных в записи, и редактора
    const textValueDiv = rec.querySelector('[textvalue="true"]');
    const edition = editionMap.get(rec.dataset.key);
    rec.dataset.value = "";
    rec.dataset.objref = "0";
    textValueDiv.innerText = "";

    if (edition) {
      edition.setValue({
        label: "",
        id: "0",
      });
      edition.setInputValue("");
    }
  }

  // функция открытия модального окна в редакторе
  function onEditButtonClick(data) {
    // для системы kladr
    if (!(data.datatype === "5005") && useKladr && isRussia) {
      const json = AddressEditKladrObj(
        data.subData.props.Current ? data.subData.props.Current : "0",
        data.subData.props.ObjType,
        data.subData.props.FieldName,
      );
      tokenProcessingTest(json, data.subData);

      return;
    }

    if (data.json) tokenProcessingTest(data.json, data.subData);
  }

  // функция значений для выпадающего списка в редакторе
  async function onDropDownList(data, checklist) {
    const params = new Map();
    params
      .set("prefix", "address")
      .set("comand", "GetParamValues")
      .set("Name", data.record.id.split("_")[1])
      .set("IsShort", "0")
      .set("ID", data.current);
    const json = await AxiosRequest(true, params);

    if (json && json.Items) {
      return json.Items.map((item, index) => {
        if (typeof item !== "object") {
          if (checklist) return { label: item, id: `${Math.pow(2, index)}` };
          return { label: item, id: `${index}` };
        }
        return { label: item.text, id: item.id };
      });
    }
  }

  // функция для рендера или сокрытия/раскрытия редактора
  function renderEditor(ev) {
    // выбранная запись
    const record = ev.target.closest("TR");
    if (record.querySelector('div[editor="true"]').style.display !== "none") return;
    record.style.backgroundColor = "whitesmoke";

    // контейнер для редактора
    const editorDiv = record.querySelector('[editor="true"]');
    // контейнер для текста параметров
    const textValueDiv = record.querySelector('[textvalue="true"]');
    // удаление(скрытие) другого отрисованного редактора
    if (editor.current && editor.current !== editorDiv) {
      deleteEditor(editor.current);
    }
    // если редактор уже отрисован то он отображается, а текст параметров скрывается
    // в противном случае редактор отрисуется, а текст параметров также скроется

    editorDiv.style.display = "flex";
    textValueDiv.style.display = "none";
    ReactDOM.render(
      <>
        <TestEditor
          placeholder=" "
          groupid={record.dataset.groupid}
          current={record.dataset.current}
          fieldname={record.dataset.fieldname}
          rusname={record.dataset.rusname}
          type={record.dataset.module}
          record={record}
          objref={record.dataset.objref}
          value={record.dataset.value}
          datatype={record.dataset.datatype}
          editStyle={record.dataset.editstyle}
          checkstate={record.dataset.checkstate}
          onEdit={onEdit}
          onEditList={onEditList}
          onEditButtonClick={onEditButtonClick}
          onDropDownList={onDropDownList}
          getEdition={setGetEdition}
          editParam={record.dataset.onedit}
          disabled={false}
        />
      </>,
      editorDiv,
    );

    // отрисованный редактор
    editor.current = editorDiv;

    // фокус на поле ввода
    const input = editorDiv.querySelector("input[type=text]");
    if (input) {
      input.focus();
      if (record.dataset.editstyle & 64) return;
      input.select();
    }
  }

  // функция скрытия редактора и отображения текста параметров
  function deleteEditor(editor) {
    if (editor && editor.style && editor.style.display !== "none") {
      const record = editor.closest("TR");
      record.style.backgroundColor = "";
      const textValueDiv = record.querySelector('[textvalue="true"]');
      editor.style.display = "none";
      textValueDiv.style.display = "flex";
    }
  }

  // функция для переключение редакторов клавишами вверх вниз
  function onKeyArrow(ev) {
    let record;
    switch (ev.key) {
      case "ArrowDown":
        record = ev.target.closest("TR").nextElementSibling;
        if (record) {
          if (record.style.display === "none") {
            while (record !== null) {
              record = record.nextElementSibling;
              if (record && record.style.display !== "none") {
                renderEditor({ target: record });
                break;
              }
            }
          } else {
            renderEditor({ target: record });
          }
        }

        break;
      case "ArrowUp":
        record = ev.target.closest("TR").previousElementSibling;
        if (record) {
          if (record.style.display === "none") {
            while (record !== null) {
              record = record.previousElementSibling;
              if (record && record.style.display !== "none") {
                renderEditor({ target: record });
                break;
              }
            }
          } else {
            renderEditor({ target: record });
          }
        }
        break;
      default:
        break;
    }
  }

  function getButtonColWidth(houseType) {
    let res = 0;
    if (houseType) {
      res += 20;
    }
    return res;
  }

  // Функция отрисовки записей для таблицы параметров
  function getJsxParams(items) {
    const returnArr = items.map((item, index) => {
      // условие для отрисовки чекбокса отдельно
      let editStyle = item.EditStyle;
      if (item.EditStyle & 4) {
        editStyle -= 4;
      }
      if (item.HouseType && (!useKladr || !isRussia)) {
        editStyle = 0;
      }
      return (
        <tr
          onMouseEnter={(ev) => {
            const record = ev.target.closest("TR");
            if (editor.current && editor.current.closest("TR") !== record) record.style.backgroundColor = "#f5f5f5";
            // динамический рендер редактора
            if (mouseDown) {
              if (mouseDown.current) renderEditor(ev);
            }
          }}
          onMouseLeave={(ev) => {
            const record = ev.target.closest("TR");
            if (editor.current && editor.current.closest("TR") !== record) record.style.backgroundColor = "";
          }}
          onClick={(ev) => {
            renderEditor(ev);
          }}
          onKeyDown={(ev) => {
            onKeyArrow(ev);
          }}
          style={{
            display:
              item.Level ||
              Number(item.Options) & ParamSetOption.Disabled ||
              Number(item.Options) & ParamSetOption.Hidden ||
              (division === "0") & (item.FieldName === "Settlement") ||
              (props.module === "persons") & (item.FieldName === "Options")
                ? "none"
                : "",
          }}
          // данные параметра хранятся внутри записи
          id={`param_${item.FieldName}`}
          key={`param-key_${item.FieldName}-${item.ID}`}
          data-key={`param-key_${item.FieldName}-${item.ID}`}
          data-fieldname={item.FieldName}
          data-level={item.Level ? item.Level : 0}
          data-collapsed={
            (Number(item.Options) & ParamSetOption.Collapsed) === ParamSetOption.Collapsed ||
            (division === "0") & (item.FieldName === "Settlement")
              ? "1"
              : "0"
          }
          data-module={item.Module}
          data-name={item.Name}
          data-value={item.Value}
          data-objref={item.ObjRef}
          data-datatype={item.DataType}
          data-groupid={paramsData.GroupID}
          data-current={item.Current}
          data-checkstate={item.CheckState}
          data-editstyle={editStyle}
          data-conditions={item.Conditions}
          data-nokldr={item.NoKldr}
          data-arrayindex={item.ID}
        >
          {/* Имя параметра */}
          <td
            id={`name_${item.FieldName}`}
            style={{
              height: "27px",
              borderCollapse: "collapse",
              backgroundColor: "#c9c8c82e",
              border: "0.1px solid",
              borderRight: "0",
              borderColor: "#e0e0e0",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              userSelect: "none",
              paddingLeft: getPadding(items, item, index),
            }}
          >
            {getExpandButton(items, item, index)}
            <span fieldvalue={"true"}>{item.Name}</span>
          </td>
          {/* Кнопка настройки типа дома */}
          <td
            style={{
              borderCollapse: "collapse",
              backgroundColor: "#c9c8c82e",
              overflow: "hidden",
              border: "0.1px solid",
              borderLeft: "0",
              borderRight: "0",
              borderColor: "#e0e0e0",
              height: "27px",
              width: getButtonColWidth(item.HouseType),
            }}
          >
            <IconButton
              id="adress-type"
              aria-controls={openList ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openList ? "true" : undefined}
              onClick={(ev) => {
                getList(item);
                handleClickList(ev);
              }}
              style={{
                height: "15px",
                width: "15px",
                display: item.HouseType ? "" : "none",
              }}
            >
              <ArrowDropDownCircleOutlinedIcon fontSize="small" />
            </IconButton>
          </td>
          {/* Разделитель */}
          <td
            onMouseDown={onMouseDown}
            style={{
              cursor: "col-resize",
              userSelect: "none",
              backgroundColor: "#e0e0e0",
            }}
            id="splitter-ID"
          >
            <br></br>
          </td>
          {/* Значение параметра */}
          <td
            id={`value_${item.FieldName}`}
            style={{
              borderCollapse: "collapse",
              overflow: "hidden",
              border: "0.1px solid",
              bordetLeft: "0",
              borderColor: "#e0e0e0",
              height: "27px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              display: "flex",
            }}
          >
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid
                item
                style={{
                  width: "15px",
                  marginRight: "5px",
                  display: item.EditStyle & 4 ? "" : "none",
                }}
              >
                {/* чекбокс с множеством состояний */}
                <CheckBoxMultiCheck onEdit={onEdit} CheckState={item.CheckState & 1} />
              </Grid>
              {/* текстовое значение параметра */}
              <Grid
                item
                style={{
                  fontSize: "13px",
                  paddingLeft: "3px",
                  width: item.EditStyle & 4 ? "calc(100% - 20px)" : "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  letterSpacing: "0px",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                textvalue={"true"}
              >
                {item.Value}
              </Grid>

              {/* контейнер для редактора */}
              <Grid
                item
                style={{
                  display: "none",
                  height: "100%",
                  width: item.EditStyle & 4 ? "calc(100% - 20px)" : "100%",
                }}
                editor={"true"}
              ></Grid>
            </Grid>
          </td>
        </tr>
      );
    });
    return returnArr;
  }

  // функция отрисовки элементов списка для параметров с типом Дом
  async function getList(item) {
    const json = await getAddressConditionValue(item.FieldName, paramsData.ID);
    const result = [];
    if (json && json.Items) {
      json.Items.forEach((value, index) => {
        result.push(
          <MenuItem
            onClick={() => {
              onClickMenuItem(index, item.FieldName, value);
            }}
            disabled={props.useKladr && !item.NoKldr && isRussia}
            sx={{ height: "27px", borderBottom: "1px solid #cccccc" }}
            value={index}
            key={`menu_item_${index}`}
          >
            {value}
          </MenuItem>,
        );
      });

      if (result.length) {
        setList(result);
      }
    }
  }

  // функция на клик по элементу списка
  async function onClickMenuItem(tag, name, value) {
    const rec = paramsBox.current.querySelector(`#param_${name}`);
    const fieldname = rec.querySelector('[fieldvalue="true"]');

    const json = await SetParamRequest({
      type: "1",
      tag: tag,
      fieldname: name,
    });
    if (json.Caption) setAddressCaption(json.Caption);
    rec.dataset.name = value !== undefined ? value : "";

    fieldname.innerText = value !== undefined ? value : "";
    handleCloseList();
  }

  function kladrLevelByFieldname(fieldname) {
    switch (fieldname) {
      case "Country":
        return 5005;
      case "Region":
        return 5006;
      case "District":
        return 5007;
      case "Community":
        return 5010;
      case "City":
        return 5001;
      case "Street":
        return 5000;
      case "House":
      case "BulkNo":
      case "BuildNo":
        return 1;
      case "PlanStruct":
        return 5009;
      default:
        return 0;
    }
  }

  // функция на клик по контекстному меню
  async function ContextMenuHandler(data) {
    const comand = data.value;
    switch (comand) {
      case "ClearValues":
        let record = data.rec;
        if (isRussia && useKladr) {
          while (record.dataset.fieldname === "ShortName" || record.dataset.fieldname === "Code") {
            record = record.previousSibling;
          }
        }
        // очищение значений
        clearValue("", record);
        const json = await SetParamRequest({
          fieldname: record.dataset.fieldname,
          current: record.dataset.current,
          tag: "-1",
        });
        const identifier = paramsBox.current.querySelector("#param_Identifier").dataset.value;
        if (identifier && isRussia && useKladr) {
          const params = new Map();
          params
            .set("prefix", "kladr")
            .set("comand", "ClearLevel")
            .set("ObjRef", paramsData.ID)
            .set("Code", identifier)
            .set("dv", division)
            .set("Level", kladrLevelByFieldname(record.dataset.fieldname));
          const response = await AxiosRequest(true, params);
          if (!isEmptyObject(response)) {
            setParamData(getParamsObject(response, paramsData.GroupID, response.Caption, paramsData.parentModule));
            return;
          }
        }
        // очищение других значений если это необходимо
        if (json.content) {
          json.content.split(",").forEach((item) => {
            if (item.match(/[A-z]+=[0-9]*/)) {
              return;
            }
            clearValue(item);
          });
        }
        // установка заголовка
        setAddressCaption(json.Caption);
        break;
      default:
        break;
    }
  }

  // функция на изменение деления адреса
  async function changeDivision(dv, setDv = true) {
    const identifier = paramsBox.current.querySelector("#param_Identifier").dataset.value;
    if (identifier && useKladr) {
      if (
        paramsBox.current.querySelector("#param_District").dataset.value &&
        !paramsBox.current.querySelector("#param_City").dataset.value &&
        !paramsBox.current.querySelector("#param_Community").dataset.value
      ) {
        RenderMessageBox(
          1,
          1,
          'Для изменения типа деления необходимо установить значение в поле "Город" или "Населенный пункт"!',
        );
        return;
      }
      const params = new Map();
      params
        .set("prefix", "kladr")
        .set("comand", "ChangeDivision")
        .set("ObjRef", paramsData.ID)
        .set("Code", identifier)
        .set("dv", dv)
        .set("ClearOnlyKladr", 1);
      const response = await AxiosRequest(true, params);
      if (!isEmptyObject(response)) {
        setParamData(getParamsObject(response, paramsData.GroupID, response.Caption, paramsData.parentModule));
      }
    }
    if (setDv) {
      const record = paramsBox.current.querySelector("#param_Settlement");
      const nextRec = record.nextElementSibling;
      const condition = record.dataset.collapsed === "1";
      await onEdit({
        fieldname: "dv",
        value: dv,
        clear: "0",
      });
      setDivision(dv);
      if (dv === "0") {
        record.style.display = "none";
        if (!condition) nextRec.style.display = "none";
      } else {
        record.style.removeProperty("display");
        if (!condition) nextRec.style.removeProperty("display");
      }
    }
  }

  return (
    // при клике вне контейнера параметров редактор скроется
    <ContextMenu for={"addressParams"} Menu={getDefaultMenu("addressParams")} onMenuItemClick={ContextMenuHandler}>
      <ClickAwayListener
        onClickAway={(ev) => {
          mouseDown.current = false;
          // список условий при которых редактор не скроется
          if (
            ev.target.closest("[checklist]") ||
            ev.target.closest("[calendar]") ||
            (ev.target.closest("SMART-WINDOW") &&
              ev.target.closest("SMART-WINDOW") !== paramsBox.current.closest("SMART-WINDOW")) ||
            ev.target.closest("LI") ||
            ev.target.classList.contains("MuiBackdrop-root")
          ) {
            return;
          }
          if (editor.current) {
            editor.current.closest("TR").style.backgroundColor = "";
            deleteEditor(editor.current);
          }
        }}
      >
        {/* Главный контейнер параметров */}
        <Box
          id="paramsBox"
          ref={paramsBox}
          onMouseDown={(ev) => {
            mouseDown.current = true;
          }}
          onMouseUp={() => {
            mouseDown.current = false;
          }}
          style={{ height: "100%" }}
        >
          <Scrollbars autoHide>
            <Box
              style={{
                // overflowY: "auto",
                height: "76px",
                width: "100%",
                paddingTop: "1px",
                position: "sticky",
                top: "0px",
                backgroundColor: "#ffffff",
                zIndex: "1",
              }}
            >
              <Box
                style={{
                  paddingLeft: "1px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ fontSize: "1rem" }}>Деление:</span>
                <FormControl
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  <RadioGroup
                    row
                    sx={{ height: "38px", overflow: "auto" }}
                    value={division}
                    onChange={(ev) => changeDivision(ev.target.value)}
                  >
                    <FormControlLabel
                      value={"0"}
                      control={<Radio size="small" />}
                      label={"Административно-территориальное"}
                    />
                    <FormControlLabel value={"1"} control={<Radio size="small" />} label={"Муниципальное"} />
                  </RadioGroup>
                </FormControl>
              </Box>
              {/* {Подсказки для адреса} */}
              <Box>
                <EditorAdress
                  height={getHeight(props.Module)}
                  setData={updateParams}
                  paramsData={paramsData}
                  defaultPopper={true}
                  text={addressCaption}
                  ObjRef={paramsData ? paramsData.ID : undefined}
                  GroupID={paramsData ? paramsData.GroupID : undefined}
                  Module={paramsData ? paramsData.parentModule : undefined}
                  onEdit={onEdit}
                  confirmation={createConfirmation}
                  confirmationText={"Установить выбранный Адрес?"}
                  disabled={!props.useKladr || !isRussia}
                  style={{
                    backgroundColor: "#ffffff",
                    border: "2px solid #999999",
                    height: "34px",
                    width: "calc(100% - 1px)",
                    textTransform: "none",
                  }}
                  division={division}
                ></EditorAdress>
              </Box>
            </Box>
            {/* контейнер с таблицей параметров */}
            <Box
              style={{
                height: height,
                // overflowY: "auto",
                display: "flex",
              }}
            >
              <table
                onMouseMove={onMouseMove}
                onMouseUp={(ev) => onMouseUp(ev, setWidth)}
                style={{
                  height: "fit-content",
                  borderSpacing: "0px",
                  tableLayout: "fixed",
                  width: "100%",
                  border: "1px solid #e0e0e0",
                  fontFamily: "Roboto",
                  fontSize: "10pt",
                }}
              >
                {/* размеры колонок */}
                <colgroup>
                  <col style={{ width: `${width}%` }} />
                  <col style={{ width: "20px" }} />
                  <col
                    style={{
                      width: "2px",
                    }}
                  />
                  <col
                    style={{
                      minWidth: "120px",
                    }}
                  />
                </colgroup>
                <tbody>{paramsJsx}</tbody>
              </table>
              {/* Выпадающий список для выбора заголовков параметров с типом Дом */}
              <Menu
                id="adress-type"
                anchorEl={anchorElList}
                open={openList}
                onClose={handleCloseList}
                MenuListProps={{
                  "aria-labelledby": "adress-type",
                }}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  style={{
                    backgroundColor: "#eeeeee",
                    borderTop: "1px solid #eeeeee",
                  }}
                >
                  <Grid style={{ width: "40px" }}></Grid>
                  <Grid
                    style={{
                      minWidth: "200px",
                      width: "fit-content",
                      backgroundColor: "#ffffff",
                    }}
                  >
                    {list}
                  </Grid>
                </Grid>
              </Menu>
            </Box>
            {/* контейнер для кнопок истории */}
          </Scrollbars>
        </Box>
      </ClickAwayListener>
    </ContextMenu>
  );
}
