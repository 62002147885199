import { Button, Grid, IconButton } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import CheckBoxMultiCheck from "../../Sections/ElementsSections/CheckBoxMultiCheck";
import React from "react";
import { AxiosRequest } from "../../../Url";
import RefreshIcon from "@mui/icons-material/Refresh";
import { GetNextNumber } from "../../Tools/Requests";
import { closeModal } from "../../Tools/modalManager";

function getStrNumMode() {
  return {
    0: "Дублировать основной номер",
    1: "Следующее значение от генератора номеров",
    2: "Сквозная нумерация в разрезе учреждений",
    3: "Сквозная нумерация без учета учреждений",
    4: "Генератор номеров для каждого учреждения",
  };
}

function getStrNumModeList() {
  return [
    { label: "Дублировать основной номер", id: 0 },
    { label: "Следующее значение от генератора номеров", id: 1 },
    { label: "Сквозная нумерация в разрезе учреждений", id: 2 },
    { label: "Сквозная нумерация без учета учреждений", id: 3 },
    { label: "Генератор номеров для каждого учреждения", id: 4 },
  ];
}

function getNewNumMode() {
  return {
    0: "Следующее значение от генератора номеров",
    1: "Сквозная нумерация в разрезе учреждений",
    2: "Сквозная нумерация без учета учреждений",
    3: "Генератор номеров для каждого учреждения",
  };
}

function getNewNumModeList() {
  return [
    { label: "Следующее значение от генератора номеров", id: 0 },
    { label: "Сквозная нумерация в разрезе учреждений", id: 1 },
    { label: "Сквозная нумерация без учета учреждений", id: 2 },
    { label: "Генератор номеров для каждого учреждения", id: 3 },
  ];
}

function getRangeList(generationkey) {
  if (
    generationkey === "Сквозная нумерация в разрезе учреждений" ||
    generationkey === "Сквозная нумерация без учета учреждений"
  ) {
    return [
      { label: "Никогда", id: -17 },
      { label: "Каждый месяц", id: -1 },
      { label: "Каждый квартал", id: -2 },
      { label: "Каждый год", id: -3 },
      { label: "От установленного номера", id: -4 },
    ];
  }
  return [
    { label: "Никогда", id: 0 },
    { label: "Каждый месяц", id: 1 },
    { label: "Каждый квартал", id: 2 },
    { label: "Каждый год", id: 3 },
    { label: "До установленного номера", id: 4 },
  ];
}

function getRange() {
  return {
    [-17]: "Никогда",
    [-1]: "Каждый месяц",
    [-2]: "Каждый квартал",
    [-3]: "Каждый год",
    [-4]: "От установленного номера",
    0: "Никогда",
    1: "Каждый месяц",
    2: "Каждый квартал",
    3: "Каждый год",
    4: "До установленного номера",
  };
}

function getAssignMode() {
  return {
    0: "При создании документа",
    1: "При сохранении документа",
    2: "При создании с коррекцией при сохранении",
  };
}

function getAssignModeList() {
  return [
    { label: "При создании документа", id: 0 },
    { label: "При сохранении документа", id: 1 },
    { label: "При создании с коррекцией при сохранении", id: 2 },
  ];
}

// GET /sequence~SequenceGetParams?LicGUID=DD265F62408FB46CD8B275A69BC0C8B0&Name=Акт%20списания&GetNumMode=1

export default function DialogDocNumbering({ props }) {
  const [velocity, setVelocity] = React.useState();
  const [NewNumMode, setNewNumMode] = React.useState();
  const [Current, setCurrent] = React.useState();

  const [Range, setRange] = React.useState();
  const [MaxNumber, setMaxNumber] = React.useState();

  const [AssignMode, setAssignMode] = React.useState();
  const [AssignModeDisabled, setAssignModeDisabled] = React.useState();

  const [showSub, setShowSub] = React.useState();

  const [SNumber, setSNumber] = React.useState();
  const [Mask, setMask] = React.useState();
  const [StrNumMode, setStrNumMode] = React.useState();
  const [CheckUnique, setCheckUnique] = React.useState();

  const [needRefreshCurrent, setNeedRefreshCurrent] = React.useState(false);
  const [currentReadonly, setCurrentReadonly] = React.useState();

  const currentChange = React.useRef();

  const responseData = React.useRef();
  const sequence = React.useRef();

  React.useEffect(() => {
    responseData.current = {
      GetNumMode: "1",
      Name: props.Sequence.split("\r\n")[0],
      NeedCurrent: false,
    };
    setVelocity(props.Sequence.split("\r\n")[0]);
    if (props.res) {
      setShowSub(props.res.HasSNum === "1" ? true : false);

      setNewNumMode(getNewNumMode()[props.res.NewNumMode ? Number(props.res.NewNumMode) : 0]);

      setCurrent(props.res.Current !== undefined ? props.res.Current : "0");

      if (props.res.NewNumMode === "1" || props.res.NewNumMode === "2") {
        setCurrentReadonly(true);
      } else {
        setCurrentReadonly(false);
      }

      let defaultPeriod = props.res.NewNumMode === "1" || props.res.NewNumMode === "2" ? -17 : 0;
      setRange(getRange()[props.res.Range !== undefined ? Number(props.res.Range) : defaultPeriod]);

      setMaxNumber(props.res.MaxNumber !== undefined ? props.res.MaxNumber : "0");

      if (!props.res.NewNumMode || props.res.NewNumMode === "3") {
        setAssignModeDisabled(true);
        if (!props.res.NewNumMode) setAssignMode(getAssignMode()[0]);
        if (props.res.NewNumMode === "3") setAssignMode(getAssignMode()[2]);
      } else {
        setAssignMode(getAssignMode()[Number(props.res.AssignMode !== undefined ? props.res.AssignMode : 0)]);
      }

      setSNumber(props.res.SNumber != undefined ? props.res.SNumber : "0");

      setMask(props.res.Mask !== undefined ? props.res.Mask : "");

      setStrNumMode(getStrNumMode()[props.res.StrNumMode !== undefined ? Number(props.res.StrNumMode) : 0]);

      setCheckUnique(props.res.CheckUnique !== undefined ? props.res.CheckUnique : "0");

      responseData.current = {
        AssignMode: props.res.AssignMode !== undefined ? props.res.AssignMode : "0",
        Current: props.res.Current,
        GetNumMode: "1",
        MaxNumber: props.res.MaxNumber !== undefined ? props.res.MaxNumber : "0",
        NewNumMode: props.res.NewNumMode !== undefined ? props.res.NewNumMode : "0",
        Range: props.res.Range !== undefined ? props.res.Range : `${defaultPeriod}`,
        Rights: props.res.Rights,
        Name: props.Sequence.split("\r\n")[0],
        SNumber: props.res.SNumber != undefined ? props.res.SNumber : "0",
        Mask: props.res.Mask,
        StrNumMode: props.res.StrNumMode !== undefined ? props.res.StrNumMode : "0",
        CheckUnique: props.res.CheckUnique !== undefined ? props.res.CheckUnique : "0",
        NeedCurrent: false,
      };
    }
  }, []);

  function onEdit(type, data) {
    switch (type) {
      case "velocity":
        setVelocity(data.value);
        responseData.current.Name = data.value;

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "NewNumMode":
        setNewNumMode(data.value);
        responseData.current.NewNumMode = data.tag;

        if (!needRefreshCurrent) setNeedRefreshCurrent(true);

        if (data.tag === 1 || data.tag === 2) {
          setAssignModeDisabled(false);
          setAssignMode(getAssignMode()[Number(responseData.current.AssignMode)]);
          switch (Range) {
            case "Никогда":
              responseData.current.Range = -17;
              break;
            case "Каждый месяц":
              responseData.current.Range = -1;
              break;
            case "Каждый квартал":
              responseData.current.Range = -2;
              break;
            case "Каждый год":
              responseData.current.Range = -3;
              break;
            case "До установленного номера":
              setRange(getRange()[-4]);
              responseData.current.Range = -4;
              break;
          }

          setCurrentReadonly(true);
        } else {
          setAssignModeDisabled(true);
          if (data.tag === 0) {
            setAssignMode(getAssignMode()[0]);
          }
          if (data.tag === 3) {
            setAssignMode(getAssignMode()[1]);
          }

          switch (Range) {
            case "Никогда":
              responseData.current.Range = 0;
              break;
            case "Каждый месяц":
              responseData.current.Range = 1;
              break;
            case "Каждый квартал":
              responseData.current.Range = 2;
              break;
            case "Каждый год":
              responseData.current.Range = 3;
              break;
            case "От установленного номера":
              setRange(getRange()[4]);
              responseData.current.Range = 4;
              break;
          }

          setCurrentReadonly(false);
        }

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "Current":
        setCurrent(data.value);
        responseData.current.Current = data.value;

        currentChange.current = true;

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "Range":
        setRange(data.value);
        responseData.current.Range = data.tag;

        setNeedRefreshCurrent(true);

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "MaxNumber":
        setMaxNumber(data.value);
        responseData.current.MaxNumber = data.value;

        setNeedRefreshCurrent(true);

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "AssignMode":
        setAssignMode(data.value);
        responseData.current.AssignMode = data.tag;

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "SNumber":
        setSNumber(data.CheckState);
        responseData.current.SNumber = data.CheckState;
        break;

      case "Mask":
        setMask(data.value);
        responseData.current.Mask = data.value;

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "StrNumMode":
        setStrNumMode(data.value);
        responseData.current.StrNumMode = data.tag;

        data.setValue({ label: data.value, id: data.tag });
        break;

      case "CheckUnique":
        setCheckUnique(data.CheckState);
        responseData.current.CheckUnique = data.CheckState;
        break;

      default:
        break;
    }
    console.log(responseData.current);
  }

  function onSelect() {
    const params = new Map();
    params
      .set("prefix", "sequence")
      .set("comand", "SequenceSetParams")
      .set("Name", velocity)
      .set("GetNumMode", "1")
      .set("AssignMode", responseData.current.AssignMode)
      .set("MaxNumber", responseData.current.MaxNumber)
      .set("NewNumMode", responseData.current.NewNumMode)
      .set("Range", responseData.current.Range)
      .set("SNumber", responseData.current.SNumber)
      .set("Mask", responseData.current.Mask)
      .set("StrNumMode", responseData.current.StrNumMode)
      .set("CheckUnique", responseData.current.CheckUnique);
    if (currentChange.current) params.set("Current", responseData.current.Current);
    AxiosRequest(true, params);

    closeModal();
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ height: "100%", width: "100%" }}
    >
      <Grid
        container
        item
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        style={{
          height: "calc(100% - 40px)",
          width: "100%",
          flexWrap: "nowrap",
          overflow: "auto",
        }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{
            height: "60%",
            width: "100%",
            minHeight: "200px",
            border: "1px solid #cccccc",
            borderRadius: "5px",
            marginBottom: "20px",
            paddingLeft: "5px",
            flexWrap: "nowrap",
            overflow: "auto",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "48px",
              left: "43px",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
          >
            Основная нумерация
          </div>
          <div style={{ display: "inline-flex", marginTop: "20px" }}>
            <div
              style={{
                height: "27px",
                width: "150px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Последовательность:
            </div>
            <div
              style={{
                height: "27px",
                width: "244px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                editStyle={1}
                readonly={true}
                value={velocity}
                list={props.Sequence}
                onEdit={(data) => {
                  onEdit("velocity", data);
                }}
              />
            </div>
            <div
              style={{
                height: "27px",
                width: "90px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button style={{ width: "90%", height: "27px", textTransform: "none" }} variant="outlined" size="small">
                Удалить
              </Button>
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <div
              style={{
                height: "27px",
                width: "150px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Генерация номера:
            </div>
            <div
              style={{
                height: "27px",
                width: "330px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                editStyle={1}
                readonly={true}
                value={NewNumMode}
                // list={Object.values(getGeneraton()).join("\r\n")}
                onDropDownList={getNewNumModeList}
                onEdit={(data) => {
                  onEdit("NewNumMode", data);
                }}
              />
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <div
              style={{
                height: "27px",
                width: "150px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Следующий номер:
            </div>
            <div
              style={{
                height: "27px",
                width: "100px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                disabled={currentReadonly}
                editStyle={8}
                value={Current}
                onEdit={(data) => {
                  onEdit("Current", data);
                }}
              />
            </div>
            <div
              style={{
                height: "27px",
                width: "30px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                title="Обновить текущую нумерацию"
                onClick={(e) => {
                  setNeedRefreshCurrent(false);
                  GetNextNumber({
                    Name: velocity,
                    Range: responseData.current.Range,
                    MaxNumber: responseData.current.MaxNumber,
                  }).then((res) => {
                    if (res.Number) {
                      setCurrent(res.Number);
                    }
                  });
                }}
              >
                <RefreshIcon fontSize={"small"} className={needRefreshCurrent ? "SaveIcon" : ""} />
              </IconButton>
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <div
              style={{
                height: "27px",
                width: "150px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Частота обновления:
            </div>
            <div
              style={{
                height: "27px",
                width: "250px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                editStyle={1}
                readonly={true}
                onDropDownList={() => getRangeList(NewNumMode)}
                value={Range}
                onEdit={(data) => {
                  onEdit("Range", data);
                }}
              />
            </div>
          </div>
          <div
            style={{
              display:
                Range && (Range === "От установленного номера" || Range === "До установленного номера")
                  ? "inline-flex"
                  : "none",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                height: "27px",
                width: "150px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Установленный номер:
            </div>
            <div
              style={{
                height: "27px",
                width: "100px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                editStyle={8}
                value={MaxNumber}
                onEdit={(data) => {
                  onEdit("MaxNumber", data);
                }}
              />
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <div
              style={{
                height: "27px",
                width: "150px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Присвоение номера:
            </div>
            <div
              style={{
                height: "27px",
                width: "330px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                disabled={AssignModeDisabled}
                editStyle={1}
                readonly={true}
                onDropDownList={getAssignModeList}
                value={AssignMode}
                onEdit={(data) => {
                  onEdit("AssignMode", data);
                }}
              />
            </div>
          </div>
        </Grid>
        <Grid
          style={{
            height: "40%",
            width: "100%",
            minHeight: "100px",
            border: "1px solid #cccccc",
            borderRadius: "5px",
            paddingLeft: "5px",
            flexWrap: "nowrap",
            overflow: "auto",
            display: showSub ? "" : "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "319px",
              left: "16px",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
          >
            <div style={{ display: "inline-flex" }}>
              <div style={{ width: "30px" }}>
                <CheckBoxMultiCheck
                  CheckState={SNumber}
                  onEdit={(data) => {
                    onEdit("SNumber", data);
                  }}
                />
              </div>
              <div>Дополнительная нумерация</div>
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "20px" }}>
            <div
              style={{
                height: "27px",
                width: "180px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Текстовая часть номера:
            </div>
            <div
              style={{
                height: "27px",
                width: "150px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                value={Mask}
                onEdit={(data) => {
                  onEdit("Mask", data);
                }}
              />
            </div>
            <div
              style={{
                height: "27px",
                width: "160px",
                display: "inline-flex",
                alignItems: "center",
                paddingLeft: "10px",
              }}
            >
              {'(Символ "%" - номер)'}
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <div
              style={{
                height: "27px",
                width: "180px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Генерация номера:
            </div>
            <div
              style={{
                height: "27px",
                width: "250px",
                border: "1px solid #cccccc",
              }}
            >
              <TestEditor
                editStyle={1}
                readonly={true}
                value={StrNumMode}
                onDropDownList={getStrNumModeList}
                onEdit={(data) => {
                  onEdit("StrNumMode", data);
                }}
              />
            </div>
          </div>
          <div style={{ display: "inline-flex", marginTop: "10px" }}>
            <div
              style={{
                height: "30px",
                width: "27px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <CheckBoxMultiCheck
                CheckState={CheckUnique}
                onEdit={(data) => {
                  onEdit("CheckUnique", data);
                }}
              />
            </div>
            <div
              style={{
                height: "30px",
                width: "305px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Проверять уникальность введенного номера
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item style={{ height: "40px", width: "100%" }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ height: "100%", width: "100%" }}
        >
          <Button
            onClick={onSelect}
            size="small"
            variant="outlined"
            style={{
              textTransform: "none",
              marginRight: "10px",
            }}
            value="1"
            className="button_Modal_Select"
          >
            Установить
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
            onClick={() => closeModal()}
          >
            Закрыть
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
