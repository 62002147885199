import { Button, Grid } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import React from "react";
import { HandleSamples } from "../../Tools/Requests";

export default function DialogSaveSample({ props }) {
  const [value, setValue] = React.useState(props.value !== undefined ? props.value : "");
  function onEdit(data) {
    setValue(data.value);
  }
  function onSelect() {
    HandleSamples({ Path: props.Path, Command: "save", Name: value });
  }
  return (
    <>
      <Grid
        id={"mainElement"}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ position: "relative", height: "100%" }}
      >
        <Grid
          item
          style={{
            height: "calc(100% - 42px)",
            width: "100%",
          }}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid style={{ width: "135px" }} item>
              Название шаблона:{" "}
            </Grid>
            <Grid
              style={{
                width: "calc(100% - 135px)",
                backgroundColor: "#FFFFFF",
                border: "1px solid #eeeeee",
                height: "27px",
              }}
              item
            >
              <TestEditor value={value} onEdit={onEdit} />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{
            height: "42px",
            width: "100%",
          }}
        >
          <Grid item style={{ width: "fit-content" }}>
            {/* {Кнопка Выбрать} */}
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ height: "100%", width: "100%" }}
            >
              {/* {Кнопка Выбрать} */}
              <Button
                onClick={onSelect}
                size="small"
                variant="outlined"
                style={{
                  textTransform: "none",
                  marginRight: "10px",
                }}
                value="1"
                className="button_Modal_Select"
              >
                Сохранить
              </Button>
              {/* {кнопка Закрыть} */}
              <Button
                size="small"
                variant="outlined"
                style={{ textTransform: "none" }}
                value="2"
                className="button_Modal_Close"
              >
                Закрыть
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
