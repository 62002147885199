// GET /grades~GetGroupTree?LicGUID=49EBB9FC4EB368FC77C66BB4D6B13DC6&ObjType=0&SectionID=145&Info=0&UsedDate=0&ObjRef=0

import { Grid } from "@mui/material";
import SplitterLocal from "../../../../Tools/Splitter";
import React from "react";
import TestTree from "../../../../Windows/ViewData/Tree/testTree";
import { AxiosRequest } from "../../../../../Url";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import {
  getColumns1,
  getColumns2,
  getDefaultFields1,
  getDefaultFields2,
  getGradesText,
  getRecords1,
  getRecords2,
} from "./localGroupGridData";
import { getDefaultMenu, updateGridData } from "../../../../Tools/Tools";
import ContextMenu from "../../../../NotWorkArea(Side&Head)/ContextMenu";

function GetGradeScope(GroupID, Department) {
  // grades~GetGradeScope?LicGUID=49EBB9FC4EB368FC77C66BB4D6B13DC6&GroupID=102&Department=101
  const params = new Map();
  params.set("prefix", "grades").set("comand", "GetGradeScope").set("GroupID", GroupID).set("Department", Department);
  return AxiosRequest(true, params);
}
export default function GradesGrid({ props }) {
  const [tree, setTree] = React.useState();
  const [groupID, setGroupID] = React.useState(0);
  const [data, setData] = React.useState(props.data);

  const [title, setTitle] = React.useState(null);

  const [gridPeriod, setGridPeriod] = React.useState();
  const [gridData, setGridData] = React.useState();

  const [selectedRecordID1, setSelectedRecordID1] = React.useState();
  const [selectedRecordID2, setSelectedRecordID2] = React.useState();

  const gridPanel1 = React.useRef();
  const gridPanel2 = React.useRef();
  const treeRef = React.useRef();
  const localGridBox = React.useRef();

  React.useEffect(() => {
    if (props && props.data) setData(props.data);
    if (tree === undefined) setTree(null);

    localGridBox.current.update = (data) => {
      setGroupID();
      setData(data);
      setTree(null);
    };
  }, [props]);

  React.useEffect(() => {
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            Module: "grades",
            SectionID: "145",
            multiCheck: false,
            ObjType: "0",
            Current: groupID,
            ObjRef: groupID,
            selectID: setGroupID,
            UsedDate: "0",
            Info: "0",
            ref: treeRef,
          }}
        />,
      );
    }
  }, [tree]);

  React.useEffect(() => {
    if (groupID !== undefined) {
      GetGradeScope(groupID, props.data.Department).then((res) => {
        const innerRec = {};
        if (res.items && res.items.length) {
          res.items.forEach((item, index) => {
            innerRec[index] = item.items;
          });
        }
        setData({ ...res, innerRecords: innerRec });
      });

      if (treeRef.current) {
        let info = treeRef.current.getCurrentInfo(groupID);
        if (info.title) setTitle(info.title);
      }
    }
  }, [groupID]);

  React.useEffect(() => {
    if (data) {
      const refresh = gridPeriod !== undefined;
      GridLoader(gridPanel1.current, props, {
        localGrid: true,
        refresh: refresh, // обновление данных таблицы без изменения grid
        source: refresh ? gridPeriod.source : undefined,
        Columns: getColumns1(),
        defaultFields: getDefaultFields1(),
        keyField: "ID",
        getRecords: () => getRecords1(data),
        recordKeys: ["ID", "UsedDate", "Text"],
        titleKey: "Text",
        setSelectedRecord: setSelectedRecordID1,
        showIndicator: false,
        Rights: "0",
      }).then((res) => {
        setGridPeriod(res);
      });
    }
  }, [data]);

  React.useEffect(() => {
    if (gridPeriod && selectedRecordID1 !== undefined) {
      const refresh = gridData !== undefined;
      GridLoader(gridPanel2.current, props, {
        localGrid: true,
        refresh: refresh, // обновление данных таблицы без изменения grid
        source: refresh ? gridData.source : undefined,
        Columns: getColumns2(),
        defaultFields: getDefaultFields2(),
        keyField: "ID",
        getRecords: () => getRecords2(data, selectedRecordID1),
        getFieldText: getGradesText,
        onPostRecord: onPostRecord,
        recordKeys: [
          "ID",
          "StartRange",
          "EndRange",
          "FirstDate",
          "Value",
          "Info",
          "LastDate",
          "OrgTypeClsName",
          "OrgTypeCls",
        ],
        titleKey: "StartRange",
        setSelectedRecord: setSelectedRecordID2,
        showIndicator: false,
        Rights: "-1",
      }).then((res) => {
        setGridData(res);
      });
    }
  }, [selectedRecordID1, gridPeriod]);

  async function onPostRecord(req, recData, postData) {
    const editFieldName = req.RecordSet.Attributes[0];
    let editFieldData;
    if (typeof recData[0] === "object") {
      if (recData[0].id) {
        editFieldData = recData[0].id;
      } else {
        editFieldData = recData[0].text;
      }
    } else {
      editFieldData = recData[0];
    }

    const result = {
      ...postData.records[req.RecordIndex],
      [editFieldName]: editFieldData,
      GroupID: groupID,
    };

    const res = await ChangeGradeValue(result);
    const innerRec = {};
    if (res.items && res.items.length) {
      res.items.forEach((item, index) => {
        innerRec[index] = item.items;
      });
    }
    setData({ ...res, innerRecords: innerRec });
  }

  function ChangeGradeValue(reqData) {
    const params = new Map();
    params
      .set("prefix", "grades")
      .set("comand", "ChangeGradeValue")
      .set("ID", reqData.ID ? reqData.ID : "0")
      .set("GroupID", reqData.GroupID)
      .set("Department", reqData.Info ? reqData.Info : "0")
      .set("StartRange", reqData.StartRange ? reqData.StartRange : "0")
      .set("EndRange", reqData.EndRange ? reqData.EndRange : "0")
      .set("FirstDate", reqData.FirstDate ? reqData.FirstDate : "0")
      .set("LastDate", reqData.LastDate ? reqData.LastDate : "0")
      .set("Value", reqData.Value ? reqData.Value : "0")
      .set("Options", reqData.Options ? reqData.Options : "0")
      .set("OrgTypeCls", reqData.OrgTypeClsName ? reqData.OrgTypeClsName : "0")
      .set("Status", reqData.Status ? reqData.Status : "4");
    return AxiosRequest(true, params);
  }

  function RestoreGrade() {
    const params = new Map();
    params.set("prefix", "grades").set("comand", "RestoreGrade").set("ID", groupID);
    return AxiosRequest(true, params);
  }

  async function ContextMenuHandler(contextData) {
    const comand = contextData.value;
    switch (comand) {
      case "ConfigRestore":
        await RestoreGrade();
        const res = await GetGradeScope(groupID, props.data.Department);
        const innerRec = {};
        if (res.items && res.items.length) {
          res.items.forEach((item, index) => {
            innerRec[index] = item.items;
          });
        }
        setData({ ...res, innerRecords: innerRec });
        break;
      default:
        break;
    }
  }

  return (
    <div style={{ height: "100%", width: "100%" }} ref={localGridBox} id="localGridBox">
      <SplitterLocal
        onResizeEnd={() => updateGridData(0)}
        onCollapse={() => updateGridData(0)}
        onExpand={() => updateGridData(0)}
        orientation="vertical"
        style={{ width: "100%", height: "100%" }}
        primaryIndex={1}
        defaultCollapsed={false}
        transitionDuration={250}
      >
        <div style={{ height: "100%" }}>{tree}</div>
        <div style={{ height: "calc(100% - 23px)" }}>
          <Grid
            item
            style={{
              height: "23px",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              backgroundColor: "rgb(235,235,235)",
              fontWeight: "bold",
            }}
          >
            {title}
          </Grid>
          <SplitterLocal
            onResizeEnd={() => updateGridData(0)}
            onCollapse={() => updateGridData(0)}
            onExpand={() => updateGridData(0)}
            orientation="vertical"
            style={{ width: "100%", height: "100%" }}
            primaryIndex={1}
            defaultCollapsed={false}
            transitionDuration={250}
          >
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              style={{ height: "100%", width: "100%" }}
            >
              <Grid
                item
                id={`rcgrid_panel`}
                ref={gridPanel1}
                style={{
                  height: "100%",
                  width: "100%",
                  position: "relative",
                }}
              ></Grid>
            </Grid>
            <ContextMenu
              for={"grid"}
              Menu={getDefaultMenu("grades")}
              // onRightClick={onMenuClick}
              onMenuItemClick={ContextMenuHandler}
            >
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                style={{ height: "100%", width: "100%" }}
              >
                <Grid
                  item
                  id={`rcgrid_panel`}
                  ref={gridPanel2}
                  style={{
                    height: "100%",
                    width: "100%",
                    position: "relative",
                  }}
                ></Grid>
              </Grid>
            </ContextMenu>
          </SplitterLocal>
        </div>
      </SplitterLocal>
    </div>
  );
}
