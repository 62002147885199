import { Box, Button, Grid, TextField } from "@mui/material";
import React from "react";
import { AxiosRequest } from "../../../Url";
import { closeModal } from "../../Tools/modalManager";
import TestTree from "../../Windows/ViewData/Tree/testTree";

/**
 * @param {Object} [props]
 * @param {string} [props.inputNameObj]
 * @param {any} [props.groups]
 * @param {string} [props.CLSID]
 * @param {string} props.Module
 * @param {string | number} props.ObjType
 * @param {Function} [props.onEdit]
 * @param {any} [props.data]
 * @param {any} [props.record]
 * @param {any} [props.name]
 * @param {any} [props.RequestID]
 * @param {any} [props.CheckState]
 * @param {string} [props.spanText]
 */

export default function DialogCreateObj(props) {
  //отрисовка дерева
  const [tree, setTree] = React.useState();
  //хук выбора элемента дерева
  const groupId = React.useRef();
  //введенное имя объекта
  const [nameObj, setNameObj] = React.useState(props.inputNameObj);
  const textFieldRef = React.useRef();

  function handleKeyDown(ev) {
    if (document.body.contains(ev.target)) {
      switch (ev.key) {
        case "Enter":
          if (textFieldRef.current.value) {
            onEdit();
          }
          break;
        case "Escape":
          closeModal();
          break;
        default:
          break;
      }
    }
  }

  React.useEffect(() => {
    if (props.groups)
      setTree(
        <TestTree
          props={{
            CLSID: props.CLSID,
            multiCheck: false,
            ObjType: props.groups.ObjType,
            Module: props.groups.Module,
            ObjRef: props.groups.ObjRef ? props.groups.ObjRef : "0",
            UsedDate: "0",
            selectID: (GroupID) => {
              groupId.current = GroupID;
            },
          }}
        />,
      );
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  async function onEdit() {
    if (textFieldRef.current.value) {
      const params = new Map();
      params
        .set("prefix", props.Module)
        .set("comand", "CreateObject")
        .set("ObjType", props.ObjType)
        .set("GroupID", groupId.current ? groupId.current : "0")
        .set("Name", textFieldRef.current.value);
      const res = await AxiosRequest(true, params);
      if (props.onEdit) {
        let data = {};
        if (props.data) {
          data = { ...props.data };
        }
        props.onEdit({
          record: props.record,
          textchanged: "0",
          name: props.name,
          setValue: props.data ? props.data.setValue : undefined,
          setInputValue: props.data ? props.data.setInputValue : undefined,
          ...data,
          proof: false,
          value: res.Text ? res.Text : textFieldRef.current.value,
          tag: res.ObjRef,
          requestId: props.RequestID,
          CheckState: props.CheckState || "0",
        });
      }
      closeModal();
      return;
    }
  }

  return (
    <>
      <Box style={{ width: "100%", height: "calc(100% - 40px)" }}>
        <Grid item style={{ margin: "8px", width: "calc(100% - 10px)" }}>
          <span style={{ width: "46px" }}>
            {props.spanText === undefined ? "Наименование объекта:" : props.spanText}
          </span>
          <TextField
            size="small"
            id="name-object"
            autoComplete="off"
            variant="outlined"
            value={nameObj}
            fullWidth={true}
            error={nameObj === "" ? true : false}
            helperText={nameObj === "" ? "Не указано наименование объекта" : ""}
            onChange={(ev) => {
              setNameObj(ev.target.value);
            }}
            inputRef={textFieldRef}
          />
        </Grid>
        {props.groups ? (
          <Grid item style={{ width: "100%", height: nameObj === "" ? "68%" : "73%" }}>
            {tree}
          </Grid>
        ) : null}
      </Box>
      <Box style={{ height: "10px", width: "100%" }}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            disabled={nameObj === "" ? true : false}
            onClick={() => {
              onEdit();
            }}
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="2"
            className="button_Modal_Close"
            onClick={() => closeModal()}
          >
            Отмена
          </Button>
        </Grid>
      </Box>
    </>
  );
}
