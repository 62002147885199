import { Grid, IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export default function UpDownButtons(props) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{
        width: "20px",
        height: "100%",
        borderRadius: "0px",
        padding: "0",
        display: props.EditStyle_UpDown ? "" : "none",
      }}
    >
      <IconButton
        disabled={props.disabled}
        style={{
          width: "20px",
          height: "50%",
          borderRadius: "0px",
          padding: "0",
        }}
        onClick={() => {
          if (props.disabled) return;
          props.incValue(props.inputValue, props.setInputValue, props.valueChanged);
        }}
      >
        <ExpandLessIcon style={{ maxHeight: "10px" }} />
      </IconButton>
      <IconButton
        disabled={props.disabled}
        style={{
          width: "20px",
          height: "50%",
          borderRadius: "0px",
          padding: "0",
        }}
        onClick={() => {
          if (props.disabled) return;
          props.decValue(props.inputValue, props.setInputValue, props.valueChanged);
        }}
      >
        <ExpandMoreIcon style={{ maxHeight: "10px" }} />
      </IconButton>
    </Grid>
  );
}
