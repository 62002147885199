// import React from "react";
import { Window } from "smart-webcomponents-react/window";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
// import { CreateBackgroundForModal, tokenProcessingTest } from "../../TokenProcessing/TokenProcessing"
import { MultilineTextBox } from "smart-webcomponents-react/multilinetextbox";
import Tooltip from "@mui/material/Tooltip";
export default function WinForText(props) {
  function ClickWin(e) {
    let bg,
      el = e.target;
    bg = el;
    if (el.classList.contains("smart-close-button")) {
      if (props.onSave) props.onSave("", props.RequestID, false, props.func);
      while (bg.className !== "background_ModalDialog") {
        bg = bg.parentElement;
      }
      if (bg) bg.remove();
    }
  }
  function onSave(ev) {
    let memo = document.getElementById("BoxForText");
    if (memo) {
      if (props.onSave) props.onSave(memo.getAttribute("value"), props.RequestID, true, props.func);
    }
    let bg = ev.target;
    while (bg.className !== "background_ModalDialog") {
      bg = bg.parentElement;
    }
    if (bg) bg.remove();
  }
  function OpenText(ev) {
    let input = document.createElement("input");
    let memo = document.getElementById("BoxForText");
    let output;
    input.type = "file";
    input.accept = ".txt, .json";
    if (window.FileList && window.File) {
      input.addEventListener("change", function (e) {
        output = "";
        let file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsText(file); // readAsDataURL(file);
        reader.onload = (event) => {
          output = reader.result; //event.target.result.split(",");
          //  output = decodeURI(escape(atob(rcdata[1])))
          if (output) memo.value = output;
          input.remove();
        };
      });
    }
    input.click();
  }
  function SaveText(ev) {
    let memo = document.getElementById("BoxForText");
    var text = memo.getAttribute("value");
    const b = new Blob([text], { type: "text/plain" });
    const url = window.URL.createObjectURL(b);

    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", props.Title + ".txt");
    a.type = "text/plain";
    a.addEventListener("click", () => {
      setTimeout(() => window.URL.revokeObjectURL(url), 10000);
    });

    a.click();
    a.remove();
  }
  let Win = (
    <Window
      dropPosition={"center"}
      headerButtons={["close", "maximize"]}
      unfocusable
      modal
      style={{ minWidth: "400px", ...props.style }}
      opened
      active
      label={props.Title ? props.Title : ""}
      onClick={ClickWin}
    >
      <Box id="txt" style={{ width: "100%", height: "100%" }}>
        <Box id="btns">
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            onClick={onSave}
            className="SaveBtn"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 512 512"
              style={{ top: "-2px", position: "relative" }}
            >
              <path
                fill="#3AB37C"
                d="M424.3,180c-1-1.2-1.5-2.8-1.5-4.3c-14.8-26.1-15.7-58-30.5-84.1c-41.7,23.4-70.2,65.1-97.4,103.1c-16.4,22.9-31.1,46.4-44.6,71.1c-13.6,24.8-26.8,49.9-42,73.8c-2.2,3.4-7.9,5-10.3,0.7c-7.2-13.3-15.3-26.2-24.6-38.2c-8-10.3-17.1-19.5-25.3-29.6c-12.7-15.7-26.3-34.5-43.9-45.4c-6.4,21-13.9,41.8-17.2,63.6c24.6,15.9,43.4,38.9,61.5,61.6c21.2,26.6,43.1,52,66.9,76.3c15.4-20.1,26-43.5,38.8-65.3c15.1-25.7,32.7-49.4,51.4-72.6c18.7-23.2,40.3-43.7,62-63.9c10.2-9.5,22.2-17.3,33.1-26c8.2-6.6,16.2-13.4,23.7-20.7C424.4,180.2,424.4,180.1,424.3,180z"
              />
              <path d="M436.2,170.3h-2.8c-16.3-27.7-16.2-62.6-34-89.9c-1.9-3-5.4-3.1-8.3-1.6c-45.6,23.1-76.2,67.7-105.2,108.1c-16.6,23.2-31.6,47-45.4,72c-12.3,22.3-24.1,44.8-37.4,66.6c-6-10.4-12.6-20.4-19.9-29.9c-8.2-10.8-17.8-20.3-26.3-30.8c-15.2-18.7-31.4-40.9-53.7-51.5c-3.7-1.8-7.4,0.5-8.5,4.2c-6.8,23.7-15.8,47-19.5,71.4c0,0.1,0,0.2,0,0.4c-2,2.7-2.2,7.1,1.6,9.4c26.5,15.6,46,40.8,64.9,64.6c22,27.7,45.2,54.1,70.2,79.1c2.3,2.3,6.4,1.8,8.4-0.5c17.2-20.6,28.7-45,41.8-68.2c14.7-25.9,32-50.3,51.1-73.2c19.2-22.9,40-43.7,61.9-64c10.4-9.7,22.6-17.7,33.8-26.6c9-7.2,17.7-14.7,25.9-22.8c2.3-0.5,4.2-2.1,4.6-4.7c0.6-0.6,1.2-1.3,1.8-1.9C445,176.1,441.2,170.3,436.2,170.3z M400.7,201c-10.9,8.7-22.8,16.5-33.1,26c-21.8,20.2-43.4,40.7-62,63.9c-18.7,23.2-36.3,46.8-51.4,72.6c-12.8,21.8-23.4,45.2-38.8,65.3c-23.8-24.2-45.8-49.7-66.9-76.3c-18.1-22.7-37-45.8-61.5-61.6c3.3-21.8,10.8-42.6,17.2-63.6c17.7,10.9,31.2,29.7,43.9,45.4c8.2,10.1,17.3,19.3,25.3,29.6c9.3,12,17.4,24.9,24.6,38.2c2.4,4.4,8.1,2.7,10.3-0.7c15.3-23.9,28.4-49,42-73.8c13.5-24.7,28.2-48.2,44.6-71.1c27.2-38,55.8-79.8,97.4-103.1c14.8,26.1,15.7,58,30.5,84.1c0,1.5,0.5,3.1,1.5,4.3c0,0.1,0.1,0.2,0.1,0.2C416.9,187.6,408.9,194.4,400.7,201z" />
            </svg>
            Сохранить
          </Button>
          <Tooltip title="Открыть текст из файла">
            <Button style={{ minWidth: "20px" }} onClick={OpenText}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                <path
                  fill="#FFA000"
                  d="M38,12H22l-4-4H8c-2.2,0-4,1.8-4,4v24c0,2.2,1.8,4,4,4h31c1.7,0,3-1.3,3-3V16C42,13.8,40.2,12,38,12z"
                />
                <path
                  fill="#FFCA28"
                  d="M42.2,18H15.3c-1.9,0-3.6,1.4-3.9,3.3L8,40h31.7c1.9,0,3.6-1.4,3.9-3.3l2.5-14C46.6,20.3,44.7,18,42.2,18z"
                />
              </svg>
            </Button>
          </Tooltip>
          <Tooltip title="Сохранить текст в файл">
            <Button style={{ minWidth: "20px" }} onClick={SaveText}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="20" height="20">
                <path fill="#3498db" d="M42 42L6 42 6 6 37 6 42 11z" />
                <path
                  fill="#fff"
                  d="M39,39c0,0.553-0.447,1-1,1H10c-0.553,0-1-0.447-1-1V25c0-0.553,0.447-1,1-1h28c0.553,0,1,0.447,1,1 V39z"
                />
                <path fill="#cfd8dc" d="M13 31H35V33H13zM13 27H35V29H13zM13 35H35V37H13z" />
                <path fill="#2980b9" d="M9,6v10c0,1.104,0.896,2,2,2h15c1.104,0,2-0.896,2-2V6H9z" />
                <path fill="#b0bec5" d="M15,6v10c0,1.104,0.896,2,2,2h15c1.104,0,2-0.896,2-2V6H15z" />
                <path fill="#263238" d="M26 8H30V16H26z" />
              </svg>
            </Button>
          </Tooltip>
        </Box>
        <Box style={{ width: "100%", height: "calc(100% - 33px)" }}>
          <MultilineTextBox
            id="BoxForText"
            value={props.Text ? props.Text : ""}
            style={{ width: "100%", height: "100%" }}
          ></MultilineTextBox>
        </Box>
      </Box>
    </Window>
  );
  return Win;
}
