import React from "react";
import ReactDOM from "react-dom";
import Wrapper from "./components/Wrapper";
import reportWebVitals from "./reportWebVitals";
// import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { StyleSmartWebcomponents } from "./components/MainPage/GlobalStyled";
import ModalPortal from "./components/MainPage/Module/ModalPortal";
import { ThemeProvider } from "./provider/ThemeProvider";
import "./static/scss/CheckBox.scss";
import "./static/scss/DBview.scss";
import "./static/scss/DocTabs.scss";
import "./static/scss/DropList.scss";
import "./static/scss/Grid.scss";
import "./static/scss/ModalPortal.scss";
import "./static/scss/ReactToastify.scss";
import "./static/scss/ResizeGrid.scss";
import "./static/scss/ResizePanel.scss";
import "./static/scss/SplitterLocal.scss";
import "./static/scss/TextField.scss";
import "./static/scss/Tree.scss";
import "./static/scss/Lk.scss";
import { store } from "./store/index";

//let pref = require('./components/stimweb/tools/trsview.html')
ReactDOM.render(
  <React.StrictMode>
    <StyleSmartWebcomponents />
    <Provider store={store}>
      <Wrapper />
      <ThemeProvider>
        <ModalPortal />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
