
      import API from "!../../../node_modules/@rsbuild/core/compiled/style-loader/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/@rsbuild/core/compiled/style-loader/runtime/styleDomAPI.js";
      import insertFn from "!../../../node_modules/@rsbuild/core/compiled/style-loader/runtime/insertBySelector.js";
      import setAttributes from "!../../../node_modules/@rsbuild/core/compiled/style-loader/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../node_modules/@rsbuild/core/compiled/style-loader/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../node_modules/@rsbuild/core/compiled/style-loader/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../node_modules/@rsbuild/core/compiled/css-loader/index.js??ruleSet[1].rules[9].use[1]!builtin:lightningcss-loader??ruleSet[1].rules[9].use[2]!../../../node_modules/@rsbuild/plugin-sass/compiled/resolve-url-loader/index.js??ruleSet[1].rules[9].use[3]!../../../node_modules/@rsbuild/plugin-sass/compiled/sass-loader/index.js??ruleSet[1].rules[9].use[4]!./DocTabs.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;

      options.insert = insertFn.bind(null, "head");
    
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../node_modules/@rsbuild/core/compiled/css-loader/index.js??ruleSet[1].rules[9].use[1]!builtin:lightningcss-loader??ruleSet[1].rules[9].use[2]!../../../node_modules/@rsbuild/plugin-sass/compiled/resolve-url-loader/index.js??ruleSet[1].rules[9].use[3]!../../../node_modules/@rsbuild/plugin-sass/compiled/sass-loader/index.js??ruleSet[1].rules[9].use[4]!./DocTabs.scss";
       export default content && content.locals ? content.locals : undefined;
