import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ReactDOM from "react-dom";

// функция создания стилей для сворачивающихся записей
export async function CreateCollapsibleRecords(div, me, i, td) {
  const RecordLevel = await me.source.getRecordLevel();
  const RecordState = await me.source.getRecordState();

  const ClassfiresIcon = document.createElement("div");
  if (isCollapsibleRecord(RecordState)) {
    ClassfiresIcon.textContent = RecordState === 160 || RecordState === 161 ? "+" : "-";
    ClassfiresIcon.type = RecordState === 160 || RecordState === 161 ? "+" : "-";
    ClassfiresIcon.style.position = "absolute";
    ClassfiresIcon.style.cursor = "pointer";
    ClassfiresIcon.style.marginLeft = 6 + MarginRecordsClassifires(RecordLevel) - 15 + "px";
    ClassfiresIcon.style.marginTop = "1px";
    ClassfiresIcon.id = "ExpandIcon";
    ClassfiresIcon.setAttribute("recordindex", me.source.activeRecord);
    ClassfiresIcon.onclick = function (e) {
      me.source.onRecordCollapsed(e);
    };
  } else if (RecordLevel !== 0) {
    ClassfiresIcon.textContent = ".";
    ClassfiresIcon.type = ".";
    ClassfiresIcon.style.position = "absolute";
    ClassfiresIcon.style.marginLeft = 6 + MarginRecordsClassifires(RecordLevel) - 15 + "px";
    ClassfiresIcon.style.marginTop = "-3px";
    ClassfiresIcon.style.color = "red";
    ClassfiresIcon.style.fontWeight = "800";
  }
  div.style.marginLeft = MarginRecordsClassifires(RecordLevel) + "px";

  // функция монтирования иконок
  if (ClassfiresIcon.textContent !== "") {
    td.appendChild(ClassfiresIcon);
    addIcons(ClassfiresIcon, me, RecordState);
  }

  return ClassfiresIcon.textContent === "" ? null : ClassfiresIcon;
}

export function isCollapsibleRecord(RecordState) {
  switch (RecordState) {
    case 32:
      return true;
    case 160:
      return true;
    case 33:
      return true;
    case 161:
      return true;
    default:
      return false;
  }
}

export function MarginRecordsClassifires(level) {
  let margin = 17;
  for (var i = 0; i < level; i++) {
    margin += 17;
  }
  return margin;
}

function addIcons(container, me, state) {
  // +
  if (state === 160 || state === 161) {
    ReactDOM.render(
      <IconButton
        style={{
          height: "20px",
          width: "20px",
          position: "absolute",
          marginLeft: "-6px",
          marginTop: "-1px",
        }}
      >
        <AddIcon style={{ height: "15px", width: "15px" }} />
      </IconButton>,
      container,
    );
  }
  // -
  else if (state === 32 || state === 33) {
    ReactDOM.render(
      <IconButton
        style={{
          height: "20px",
          width: "20px",
          position: "absolute",
          marginLeft: "-6px",
          marginTop: "-1px",
        }}
      >
        <RemoveIcon style={{ height: "15px", width: "15px" }} />
      </IconButton>,
      container,
    );
  }
  // .
  else {
    ReactDOM.render(
      <IconButton
        disabled
        style={{
          height: "20px",
          width: "20px",
          position: "absolute",
          marginLeft: "-6px",
          marginTop: "3px",
        }}
      >
        <FiberManualRecordIcon color="error" style={{ height: "5px", width: "5px" }} />
      </IconButton>,
      container,
    );
  }
}
