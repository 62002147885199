import { AxiosRequest } from "../../../Url";
import { getModalSectionID } from "../../Tools/Tools";

export async function defaultGrid(grid, source, props, data) {
  source.onHandleRequest = HandleRequest(props, data);

  await source.open();

  grid.defaultColumns = true;

  await source.refresh();
  await grid.setSource(source);
  grid.updateGridSize();
}

function HandleRequest(props, data) {
  return function onHandleRequest(request) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("ObjType", props.ObjType)
      .set("SectionID", props.SectionID ? props.SectionID : getModalSectionID());
    return AxiosRequest(true, params, request);
  };
}
