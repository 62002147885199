import { DocColumnOption } from "../../Tools/Consts";

//Парсинг полей объекта запросов GetTableLayout и GetDocumentLayout
export function parseColumns(json, inputColumns = { title: [] }, collapsibleField, addInfo) {
  let columns = { ...inputColumns }; // если есть входящие поля, они учитываются
  let dest, sour;
  for (const value of Object.values(json.Columns)) {
    sour = value;
    if (sour.FieldName) {
      dest = {};
      dest.fieldName = sour.FieldName;
      dest.title = sour.Title ? sour.Title : sour.Name; // у объекта GetDocumentLayout название поля приходит как Title, а у объекта GetTableLayout как Name
      if (sour.Width) dest.width = parseInt(sour.Width);
      else dest.width = 100;
      if (sour.Level) dest.level = parseInt(sour.Level);
      else dest.level = 0;
      columns.title.push(dest);
      dest.fieldInfo = {
        DataType: value.DataType ? value.DataType : "",
        EditStyle: value.EditStyle ? value.EditStyle : "",
        Options: value.Options ? value.Options : "",
        TitleAjust: value.TitleAjust ? value.TitleAjust : "",
        TextAjust: value.TextAjust ? value.TextAjust : "",
        HardCodeReadOnlyForEditor: json.readonly ? json.readonly : "",
        Module: value.Module ? value.Module : addInfo && addInfo.Module ? addInfo.Module : "",
        Values: value.Values ? getValues(Object.values(value.Values), value.EditStyle & 32) : [],
        Details: value.Details ? value.Details : "",
        AccDetails:
          value.DataType === "-1" && (value.FieldName === "D_Analitika" || value.FieldName === "C_Analitika")
            ? true
            : false,
        OnEdit: value.OnEdit ? value.OnEdit : "",
        OnList: value.OnList ? value.OnList : "",
        OnHint: value.OnHint ? value.OnHint : "",
        EditMask: value.EditMask ? value.EditMask : "",
        ColumnID: value.ID ? value.ID : "",
        OnCloseUpList: value.OnCloseUpList ? value.OnCloseUpList : "",
        OnGetEditStyle: value.OnGetEditStyle ? value.OnGetEditStyle : "",
        FieldName: value.FieldName ? value.FieldName : "",
        Path: addInfo && addInfo.Path ? addInfo.Path : "",
        FilterReq: addInfo && addInfo.FilterReq ? addInfo.FilterReq : "",
        ButtonImage: value.ButtonImage ? value.ButtonImage : "",
        MultiCheckSet: value.MultiCheckSet ? value.MultiCheckSet : "",
        ListCommand: value.ListCommand ? value.ListCommand : "",
        DetailsByReq: value.DetailsByReq ? value.DetailsByReq : "",
        OnListCustom: value.OnListCustom ? value.OnListCustom : "",
      };
      if (sour.FieldName === collapsibleField) {
        dest.collapseRecord = true;
      }
    }
  }
  return { ...columns };
  // возвращает объект вида {title:[{nameField:valueField},...]}
}

export function getValues(arr, checklist) {
  return arr.map((item, index) => {
    if (typeof item !== "object") {
      if (checklist) {
        return { label: item, id: `${Math.pow(2, index)}` };
      }
      return { label: item, id: `${index}` };
    }
    if (checklist) {
      return { label: item.text, id: `${Math.pow(2, index)}` };
    }
    return { label: item.text, id: item.id };
  });
}
/*
  Markable: 1,
  Sortable: 2,
  ReadOnly: 4,
  NotEditing: 8,
  VerticalText: 16,
  ShortObjName: 32,
  Fixed: 64,
  RedNegative: 128,
  HiddenInGrid: 256,
  HiddenInInsp: 512,
  Filterable: 1024,
  Reference: 2048,
  InParams: 4096,
  CanGroup: 8192,
  Invisible: 16384,
  Hidden: 32768,
*/

export function getDocOptions(options) {
  return {
    markable: options & DocColumnOption.Markable ? true : false,
    sort: options & DocColumnOption.Sortable ? true : false,
    readOnly: options & DocColumnOption.ReadOnly ? true : false,
    disabled: options & DocColumnOption.NotEditing ? true : false,
    verticalText: options & DocColumnOption.VerticalText ? true : false,
    shortObjName: options & DocColumnOption.ShortObjName ? true : false,
    fixed: options & DocColumnOption.Fixed ? true : false,
    redNegative: options & DocColumnOption.RedNegative ? true : false,
    hideInGrid: options & DocColumnOption.HiddenInGrid ? true : false,
    hideInCard: options & DocColumnOption.HiddenInInsp ? true : false,
    filterByField: options & DocColumnOption.Filterable ? true : false,
    reference: options & DocColumnOption.Reference ? true : false,
    inParams: options & DocColumnOption.InParams ? true : false,
    group: options & DocColumnOption.CanGroup ? true : false,
    invisible: options & DocColumnOption.Invisible ? true : false,
    hidden: options & DocColumnOption.Hidden ? true : false,
  };
}

export function getParamColumnsOptions(options) {
  return {
    disabled: options & 1 ? true : false,
    config: options & 2 ? true : false,
    maths: options & 4 ? true : false,
    details: options & 8 ? true : false,
    markable: options & 16 ? true : false,
    collapsed: options & 32 ? true : false,
    reference: options & 64 ? true : false,
    redNegative: options & 128 ? true : false,
    forParams: options & 256 ? true : false,
    forTable: options & 512 ? true : false,
    sort: options & 1024 ? true : false,
    filterByField: options & 2048 ? true : false,
    proto: options & 4096 ? true : false,
    hidden: options & 8192 ? true : false,
    period: options & 16384 ? true : false,
    group: options & 32768 ? true : false,
  };
}
