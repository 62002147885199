import React from "react";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";

export default function TSectionCheckBox({
  Checked,
  jsonData,
  Left,
  Top,
  Width,
  Height,
  Visability,
  disabled,
  label,
  onClick,
}) {
  const [checked, setChecked] = React.useState(Checked);

  React.useEffect(() => {
    setChecked(Checked);
  }, [Checked]);

  return (
    <Grid keyName={jsonData.keyName} style={{ whiteSpace: "nowrap" }}>
      <FormControlLabel
        keyName={jsonData.keyName}
        style={{
          width: "max-content",
          position: "absolute",
          left: `${Left}px`,
          top: `${Top}px`,
          width: `${Width}px`,
          height: `${Height}px`,
          visibility: Visability,
        }}
        control={
          <Checkbox
            disabled={disabled}
            keyName={jsonData.keyName}
            style={{ padding: 0 }}
            // defaultChecked={json.Checked === "0" ? false : true}
            checked={checked}
            onChange={(e) => {
              setChecked(!checked);
              onClick(e);
            }}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
          />
        }
        label={label}
      />
    </Grid>
  );
}
