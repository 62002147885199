// import FormsMainFile from "../Sections/ElementsSections/Forms/FormsMainFile";
// import Params from "../Sections/ElementsSections/Params";
// import SectionDocuments from "../Sections/ElementsSections/SectionDocuments/SectionDocuments";
// import SectionReports from "../Sections/ElementsSections/SectionReport/SectionReports";
// import StorObj from "../Sections/StorObj";
// import SectionsDBview from "../Sections/ElementsSections/DbView/dbview";
// import BackgroundProcess from "../Tools/BackgroundProcess";
// import StillDevelopmentPage from "./StillDevelopmentPage";
// import SectionReportsAndDocuments from "../Sections/SectionReportsAndDocuments";
// import OtherObjects from "../Sections/ElementsSections/OhersSections/OtherObjects";
// import SectionOrganizations from "../Sections/ElementsSections/Organizations/SectionOrganizations";
// import SectionDocumentsChose from "../Sections/ElementsSections/SectionDocuments/SectionDocumentsChose";
// import TestParams from "../Sections/ElementsSections/testParams";
// import SectionDocuments from "../Sections/ElementsSections/SectionDocuments/SectionDocuments";
// import SectionToolsJS from "../Tools/SectionToolsJS";
// import SectionDepartaments from "../Sections/ElementsSections/Departament/SectionDepartament";
// import TabsForReports from "../Sections/ElementsSections/SectionReport/TabsForReports";
import { lazy, Suspense } from "react";
import LoadingMask from "../NotWorkArea(Side&Head)/LoadingMask";
import { AxiosRequest } from "../../Url";
import { GetSectionDocs } from "../Tools/Requests";
import { isDeveloper } from "../Tools/Tools";
import { ViewClass } from "../Tools/Consts";

const SectionPersonalData = lazy(() => import("../Sections/ElementsSections/SectionPersonalData/SectionPersonalData"));
const Forms = lazy(() => import("../Sections/ElementsSections/Forms/FormsMainFile.jsx"));
const DocAndRepo = lazy(() => import("../Sections/SectionReportsAndDocuments.jsx"));
const Docs = lazy(() => import("../Sections/ElementsSections/SectionDocuments/SectionDocumentsChose.jsx"));
const Reports = lazy(() => import("../Sections/ElementsSections/SectionReport/SectionReports.jsx"));
const DView = lazy(() => import("../Sections/ElementsSections/DbView/dbview.jsx"));
const SectionNFA = lazy(() => import("../Sections/ElementsSections/NFA/SectionNFA.jsx"));
const OtherObjects = lazy(() => import("../Sections/ElementsSections/OhersSections/OtherObjects.jsx"));
const SectionOrganizations = lazy(() => import("../Sections/ElementsSections/Organizations/SectionOrganizations.jsx"));
const SectionDocuments = lazy(() => import("../Sections/ElementsSections/SectionDocuments/SectionDocuments"));
const SectionToolsJS = lazy(() => import("../Tools/SectionToolsJS"));
const SectionDepartaments = lazy(() => import("../Sections/ElementsSections/Departament/SectionDepartament"));
const TabsForReports = lazy(() => import("../Sections/ElementsSections/SectionReport/TabsForReports"));
const TestParams = lazy(() => import("../Sections/ElementsSections/testParams.jsx"));
const BackgroundProcess = lazy(() => import("../Tools/BackgroundProcess.jsx"));
const StillDevelopmentPage = lazy(() => import("./StillDevelopmentPage"));
const Salary = lazy(() => import("./../Sections/ElementsSections/Salary/SalaryComponent.jsx"));
const SectionStaff = lazy(() => import("../Sections/ElementsSections/Staff/SectionStaff"));
const Audit = lazy(() => import("../Sections/Audit/UsersAudit.jsx"));
const SectionTimeTable = lazy(() => import("../Sections/ElementsSections/TimeTable/SectionTimeTable"));
const AccountsTable = lazy(() => import("../Module/Dialogs/Components/AccountsTable"));
const SectionAccounting = lazy(() => import("../Sections/ElementsSections/Accounting/SectionAccounting"));
const SectionClsBook = lazy(() => import("../Sections/ElementsSections/ClsBook/SectionClsBook"));
const SectionPayTypes = lazy(() => import("../Sections/ElementsSections/PayTypes/SectionPayTypes"));

interface Section {
  CLSID: string;
  ID?: string;
  ObjType?: string;
  Params?: { Path?: string };
  Documents?: Array<object>;
}

interface SectionData {
  element: Section;
  ElemJson?: object;
  CommitFormOnSection?: any;
  SectionID?: string | number;
}

const getSuspenseForContent = (content: JSX.Element | null) => {
  return <Suspense fallback={<LoadingMask />}>{content}</Suspense>;
};

const dbviewContent = (data: SectionData) => {
  return getSuspenseForContent(<DView CLSID={data.element.CLSID} id={data.element.ID} />);
};

const docsAndReports = (data: SectionData) => {
  return getSuspenseForContent(<DocAndRepo CLSID={data.element.CLSID} id={data.element.ID} ElemJson={data.ElemJson} />);
};

const forms = (data: SectionData) => {
  return getSuspenseForContent(<Forms id={data.element.ID} CommitFormOnSection={data.CommitFormOnSection} />);
};

const reports = (data: SectionData) => {
  return getSuspenseForContent(
    <Reports CLSID={data.element.CLSID} id={data.element.ID || data.SectionID} SectionToolsJS />,
  );
};

const sectionNFA = (data: SectionData) => {
  return getSuspenseForContent(<SectionNFA sectionData={data.element} SectionID={data.SectionID} />);
};

const backgroundProcess = () => {
  return getSuspenseForContent(<BackgroundProcess />);
};

const params = async (data: SectionData) => {
  const params = new Map();
  params.set("prefix", "programs");
  params.set("comand", "GetParamDialog");
  params.set("GroupID", "0");
  params.set("Path", data.element.Params?.Path);
  params.set("NeedRefresh", "1");
  const json = await AxiosRequest(true, params);

  return getSuspenseForContent(
    <TestParams
      Module={"programs"}
      reqCommand={"GetParamDialog"}
      GroupID={"0"}
      id={data.element.ID || data.SectionID}
      SectionID={data.element.ID || data.SectionID}
      Path={data.element.Params?.Path}
      data={json}
      NoTitle={true}
      NoHistory={true}
    />,
  );
};

const docs = (data: SectionData) => {
  return getSuspenseForContent(<Docs CLSID={data.element.CLSID} id={data.element.ID} ElemJson={data.ElemJson} />);
};

const sectionDepartments = (data: SectionData) => {
  return getSuspenseForContent(<SectionDepartaments sectionData={data.element} SectionID={data.SectionID} />);
};

const tabsForReports = (data: SectionData) => {
  return getSuspenseForContent(<TabsForReports reportData={data.element} />);
};

const audit = () => {
  return getSuspenseForContent(<Audit />);
};

const sectionPersonalData = (data: SectionData) => {
  return getSuspenseForContent(<SectionPersonalData sectionData={data.element} SectionID={data.SectionID} />);
};

const docsWithTree = async (data: SectionData) => {
  const sectionId: string | number | undefined = data.SectionID || data.element.ID;
  const objType: string = data.element.ObjType || (await GetSectionDocs(sectionId));

  return getSuspenseForContent(
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <SectionDocuments
        id={sectionId}
        DocCfgID={objType}
        HideParams={sectionId && Number(sectionId) > 100}
        SectionToolsJS={sectionId && Number(sectionId) > 100}
        setRecorcCount={() => {}}
        tree={{
          multiCheck: false,
          Module: "documents",
          ObjType: objType,
          SectionID: sectionId,
          ObjRef: "0",
          Info: "0",
          UsedDate: "0",
        }}
        // updateTree={setTree}
      />
    </div>,
  );
};

const accountsTable = (data: SectionData) => {
  return getSuspenseForContent(<AccountsTable props={data.element} />);
};

const sectionOrganizations = (data: SectionData) => {
  return getSuspenseForContent(<SectionOrganizations sectionData={data.element} SectionID={data.SectionID} />);
};

const sectionAccounting = (data: SectionData) => {
  return getSuspenseForContent(
    <SectionAccounting
      CLSID={data.element.CLSID}
      id={data.SectionID || data.element.ID}
      SectionID={data.SectionID || data.element.ID}
      sectionData={data.element}
      element={data.element}
      ElemJson={data.ElemJson}
    />,
  );
};

const salary = (data: SectionData) => {
  return getSuspenseForContent(
    <Salary
      CLSID={data.element.CLSID}
      id={data.SectionID || data.element.ID}
      SectionID={data.SectionID || data.element.ID}
      sectionData={data.element}
      element={data.element}
      ElemJson={data.ElemJson}
    />,
  );
};

const sectionStaff = (data: SectionData) => {
  return getSuspenseForContent(
    <SectionStaff
      sectionId={data.SectionID || data.element.ID || -1}
      CLSID={data.element.CLSID}
      ElemJson={data.ElemJson}
    />,
  );
};

const sectionTimeTable = (data: SectionData) => {
  return getSuspenseForContent(
    <SectionTimeTable
      sectionId={data.SectionID || data.element.ID || -1}
      CLSID={data.element.CLSID}
      ElemJson={data.ElemJson}
    />,
  );
};

const otherObjects = (data: SectionData) => {
  return getSuspenseForContent(<OtherObjects sectionData={data.element} />);
};

const sectionPayTypes = (data: SectionData) => {
  return getSuspenseForContent(
    <SectionPayTypes SectionID={data.SectionID || data.element.ID} sectionData={data.element} />,
  );
};

const sectionClsBook = (data: SectionData) => {
  return getSuspenseForContent(
    <SectionClsBook SectionID={data.SectionID || data.element.ID} sectionData={data.element} />,
  );
};

const stillDevelopmentPage = (data: SectionData) => {
  return getSuspenseForContent(<StillDevelopmentPage SectionToolsJS={true} id={data.SectionID || data.element.ID} />);
};

const defaultContent = (data: SectionData) => {
  if (data.element.Documents) {
    return docsAndReports(data);
  }

  return stillDevelopmentPage(data);
};

export async function returnPageBasedOnCLSID(
  element: Section,
  ElemJson: object,
  CommitFormOnSection?: any,
  SectionID?: string,
) {
  const data: SectionData = {
    element,
    ElemJson,
    CommitFormOnSection,
    SectionID,
  };

  const content = {
    [ViewClass.DBView]: dbviewContent,
    [ViewClass.DocReps]: docsAndReports,
    "{1D3BB51B-8729-4F3F-91F3-574898BFA297}": docsAndReports,
    "{DB69A304-4DED-46F6-8BD3-BADF1BB24393}": docsAndReports,
    [ViewClass.Forms]: forms,
    [ViewClass.RepSite]: reports,
    [ViewClass.StgBook]: sectionNFA,
    [ViewClass.LazyStat]: backgroundProcess,
    [ViewClass.ParamDlg]: params,
    [ViewClass.DocSite]: docs,
    [ViewClass.Departs]: sectionDepartments,
    [ViewClass.CellObj]: tabsForReports,
    [ViewClass.Audit]: audit,
    [ViewClass.Persons]: sectionPersonalData,
    [ViewClass.DocBook]: docsWithTree,
    [ViewClass.Transact]: accountsTable,
    [ViewClass.OrgBook]: sectionOrganizations,
    [ViewClass.Account]: sectionAccounting,
    [ViewClass.Salary]: salary,
    [ViewClass.Staff]: sectionStaff,
    [ViewClass.TimeTable]: sectionTimeTable,
    [ViewClass.MultiTab]: otherObjects,
    [ViewClass.PayTypes]: sectionPayTypes,
    [ViewClass.ClsBook]: sectionClsBook,
  };

  return content[element.CLSID]?.(data) || defaultContent(data);
}

export async function contentMaker(sideBarSelected: any, ElemJson: any, CommitFormOnSection?: any) {
  //
  let content;
  if (sideBarSelected.ID && sideBarSelected.CLSID === "{353FD9D7-651E-4840-9319-A8578806C496}") {
    content = (
      <Suspense fallback={<LoadingMask />}>
        <OtherObjects sectionData={sideBarSelected} />
      </Suspense>
    );
  } else if (sideBarSelected.ID !== undefined) {
    content = await returnPageBasedOnCLSID(sideBarSelected, ElemJson, CommitFormOnSection);
  }
  return content;
}
