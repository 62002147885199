import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid/Grid";

export function FooterDataShow(id: string, data: any, idDataBlock?: string) {
  const dataBlock = document.getElementById(idDataBlock ? idDataBlock : "footerDataShow");
  const BlockIsExisting = document.getElementById(id + "footerData");
  if (dataBlock) {
    if (!BlockIsExisting) {
      const idDataBlock = document.createElement("div");
      idDataBlock.style.display = "flex";
      idDataBlock.style.flexFlow = "wrap";
      idDataBlock.style.boxSizing = "border-box";
      idDataBlock.style.alignItems = "center";
      idDataBlock.style.justifyContent = "flex-start";

      idDataBlock.className = "MuiGrid-root MuiGrid-container css-16a96b4";
      // "MuiGrid-root MuiGrid-container css-1e2bu2o-MuiGrid-root";
      idDataBlock.id = id + "footerData";
      dataBlock.appendChild(idDataBlock);
      AddData(idDataBlock, data);
    } else {
      // BlockIsExisting.style.display = ""
      AddData(BlockIsExisting, data);
    }
  }
}

function AddData(dataBlock: any, data: any) {
  dataBlock.innerHTML = "";
  dataBlock.style.display = "flex";
  let keys: any;
  for (const [key, value] of Object.entries(data)) {
    keys = key;
    const divBlock = document.createElement("div");
    // divBlock.className = "MuiGrid-root MuiGrid-item css-13i4rnv-MuiGrid-root";
    divBlock.style.paddingLeft = "3px";
    divBlock.style.paddingRight = "3px";
    divBlock.style.fontSize = "75%";
    if (keys !== "0") {
      divBlock.style.borderLeftStyle = "solid";
      divBlock.style.borderLeftWidth = "1px";
    }
    divBlock.textContent = `${value ? value : ""}`;
    dataBlock.appendChild(divBlock);
  }
}

export function HideAllFooterData(id: string) {
  const dataBlock = document.getElementById("footerDataShow");
  if (dataBlock) {
    let valueAny: any;
    for (const value of Object.values(dataBlock.children)) {
      valueAny = value;
      if (valueAny.id === id + "footerData") valueAny.style.display = "flex";
      else valueAny.style.display = "none";
    }
  }
}

export default function StickyFooter() {
  const [currentHeight, setCurrentHeight] = useState(window.innerHeight - 190);

  const handleResize = () => {
    setCurrentHeight(window.innerHeight - 190);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        zIndex: "4",
      }}
      id={"footer"}
      style={{
        minHeight: "30px",
        height: "30px",
        overflow: "hidden",
        borderWidth: "0px 0px 1px 0px",
        borderStyle: "solid",
      }}
    >
      <CssBaseline />

      <Box
        component="footer"
        style={{ height: "100%" }}
        sx={{
          // py: 3,
          // px: 2,
          // mt: `${currentHeight}px`,
          overflow: "hidden",
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800],
        }}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: "100%" }}>
          {" "}
          <Grid item>
            <Grid id="footerProgress" maxWidth="xl"></Grid>
          </Grid>
          <Grid item sx={{ height: "100%" }}>
            <Grid
              id={"footerDataShow"}
              // container
              // direction="row"
              // justifyContent="flex-start"
              // alignItems="center"
              sx={{ mt: 1 }}
              style={{ height: "100%", paddingLeft: "17px" }}
            ></Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
