import { Grid } from "@mui/material";

// Данные для справочника Объеденить организации
// dest.fieldInfo = {
//   DataType: value.DataType ? value.DataType : "",
//   EditStyle: value.EditStyle ? value.EditStyle : "",
//   Options: value.Options ? value.Options : "",
//   TitleAjust: value.TitleAjust ? value.TitleAjust : "",
//   TextAjust: value.TextAjust ? value.TextAjust : "",
//   Module: value.Module ? value.Module : "",
//   Values: value.Values ? Object.values(value.Values) : [],
// };
//Поля
export function getColumnOrg() {
  return {
    title: [
      {
        fieldName: "OrgName",
        title: "Название организации",
        level: 0,
        width: 200,
        collapseRecord: true,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "None",
        title: "-//-",
        level: 0,
        width: 40,
        fieldInfo: {
          DataType: "",
          EditStyle: "4",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "INN",
        title: "ИНН",
        level: 0,
        width: 120,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "KPP",
        title: "КПП",
        level: 0,
        width: 120,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "GroupName",
        title: "Группа",
        level: 0,
        width: 200,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "ContragentIdent",
        title: "Идентификатор контрагента",
        level: 0,
        width: 200,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
    ],
  };
}

export function getColumnBank() {
  return {
    title: [
      {
        fieldName: "OrgName",
        title: "Название Банка",
        level: 0,
        width: 200,
        collapseRecord: true,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "None",
        title: "-//-",
        level: 0,
        width: 40,
        fieldInfo: {
          DataType: "",
          EditStyle: "4",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "BIK",
        title: "ИНН",
        level: 0,
        width: 120,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "COR",
        title: "КПП",
        level: 0,
        width: 120,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
    ],
  };
}

export function getColumnAccount() {
  return {
    title: [
      {
        fieldName: "AccName",
        title: "Название Счета",
        collapseRecord: true,
        level: 0,
        width: 200,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "None",
        title: "-//-",
        level: 0,
        width: 40,
        fieldInfo: {
          DataType: "",
          EditStyle: "4",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "BankName",
        title: "Банк",
        level: 0,
        width: 120,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
      {
        fieldName: "GroupName",
        title: "Группа",
        level: 0,
        width: 120,
        fieldInfo: {
          DataType: "",
          EditStyle: "",
          Options: "8",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        },
      },
    ],
  };
}

// Чекбоксы
export function getCheckBoxes(props, hookCheckBox) {
  switch (props.module) {
    case "Объединить организации":
      return (
        <>
          <Grid item style={{ marginLeft: "5px" }}>
            <label>
              <input
                id={"checkBox_orgGroup"}
                type={"checkbox"}
                onChange={() => {
                  hookCheckBox.Org.setGroup(!hookCheckBox.Org.Group);
                }}
              />
              В текущей группе
            </label>
          </Grid>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Org.setName(!hookCheckBox.Org.Name);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_orgName"} type={"checkbox"} />
              ИМЯ
            </label>
          </Grid>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Org.setINN(!hookCheckBox.Org.INN);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_orgINN"} type={"checkbox"} />
              ИНН
            </label>
          </Grid>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Org.setKPP(!hookCheckBox.Org.KPP);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_orgKPP"} type={"checkbox"} />
              КПП
            </label>
          </Grid>
        </>
      );
    case "Объединить банки":
      return (
        <>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Bank.setName(!hookCheckBox.Bank.Name);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_bankName"} type={"checkbox"} />
              ИМЯ
            </label>
          </Grid>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Bank.setBIK(!hookCheckBox.Bank.BIK);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_bankBIK"} type={"checkbox"} defaultChecked={hookCheckBox.Bank.BIK} disabled />
              БИК
            </label>
          </Grid>
        </>
      );
    case "Объединить Счета":
      return (
        <>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Account.setGroup(!hookCheckBox.Account.Group);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_accountGroup"} type={"checkbox"} />В текущей группе
            </label>
          </Grid>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Account.setOneGroup(!hookCheckBox.Account.oneGroup);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_oneOrgGroup"} type={"checkbox"} />В пределах одной организации
            </label>
          </Grid>
          <Grid
            item
            onChange={() => {
              hookCheckBox.Account.setNumber(!hookCheckBox.Account.Number);
            }}
            style={{ marginLeft: "5px" }}
          >
            <label>
              <input id={"checkBox_number20"} type={"checkbox"} />
              Только 20-ти значные числа
            </label>
          </Grid>
        </>
      );
  }
}
