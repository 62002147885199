import { Button, Grid } from "@mui/material";
import TestEditor from "../../../Editor/testEditor";
import React from "react";
import { AxiosRequest } from "../../../Url";
import { formEdit } from "../../Tools/Tools";

// 1001001 0111 00000 00000000 0000000
//  73       // 7
function getMonthList() {
  return [
    { label: "б/у месяца", id: "0" },
    { label: "Январь", id: "1" },
    { label: "Февраль", id: "2" },
    { label: "Март", id: "3" },
    { label: "Апрель", id: "4" },
    { label: "Май", id: "5" },
    { label: "Июнь", id: "6" },
    { label: "Июль", id: "7" },
    { label: "Август", id: "8" },
    { label: "Сентябрь", id: "9" },
    { label: "Октябрь", id: "10" },
    { label: "Ноябрь", id: "11" },
    { label: "Декабрь", id: "12" },
  ];
}
function getMaxDay(id, year) {
  {
    return {
      ["0"]: "0",
      ["1"]: "31",
      ["2"]: Number(year) % 4 === 0 ? "29" : "28",
      ["3"]: "31",
      ["4"]: "30",
      ["5"]: "31",
      ["6"]: "30",
      ["7"]: "31",
      ["8"]: "31",
      ["9"]: "30",
      ["10"]: "31",
      ["11"]: "30",
      ["12"]: "31",
    }[id];
  }
}

function getMonthValue(value) {
  const monthList = getMonthList();
  let result = { value: "б/у месяца", tag: "0" };
  for (let month of monthList) {
    if (month.label.toLowerCase().match(new RegExp(value.toLowerCase()))) {
      result = { value: month.label, tag: month.id };
      break;
    }
  }
  return result;
}

function getDataObj(
  objref,
  params = {
    year: 8,
    month: 4,
    day: 5,
    number: 8,
    source: 7,
    startDate: 1950,
  },
) {
  const result = {};
  let shift = 0;

  shift += params.year;
  result.year = getYear(params.startDate, shift, objref);

  shift += params.month;
  result.month = getNextBittes(params.month, shift, objref);

  shift += params.day;
  result.day = getNextBittes(params.day, shift, objref);

  shift += params.number;
  result.number = getNextBittes(params.number, shift, objref);

  shift += params.source;
  result.source = getNextBittes(params.source, shift, objref);

  result.startDate = params.startDate;
  result.yearSize = params.year;
  result.monthSize = params.month;
  result.daySize = params.day;
  result.numberSize = params.number;
  result.sourceSize = params.source;

  return result;
}

function getYear(startYear, shift, code) {
  return startYear + (code >> (32 - shift));
}

function getNextBittes(countBittes, shift, code) {
  return (code >> (32 - shift)) & ((1 << countBittes) - 1);
}

export default function DialogPeriod({ props }) {
  const [mainData, setMainData] = React.useState();
  const [year, setYear] = React.useState();
  const [month, setMonth] = React.useState();
  const [day, setDay] = React.useState();
  const [dayEdition, setDayEdition] = React.useState();
  const [NumAcc, setNumAcc] = React.useState();
  const [period, setPeriod] = React.useState();

  React.useEffect(() => {
    setMainData(getDataObj(props.ObjRef, getParams(props.Info)));
  }, []);

  function getParams(info) {
    const result = {};
    const params = info ? info.split(",") : [];
    if (params.length !== 5) return;
    result.year = 8;
    result.startDate = Number(params[0]);
    result.month = Number(params[1]);
    result.day = Number(params[2]);
    result.number = Number(params[3]);
    result.source = Number(params[4]);
    return result;
  }

  React.useEffect(() => {
    if (mainData) {
      const mth = getMonthList()[mainData.month];
      setYear(`${mainData.year}`);
      setMonth({ ...mth, maxDay: getMaxDay(mth.id, mainData.year) });
      setDay(`${mainData.day}`);
      setNumAcc(`${mainData.number}`);
      if (mainData.source) {
        getPeriodData(mainData.year, mth.id).then((res) => {
          let index;
          for (let i = 0; i < res.length; i++) {
            if (res[i].id === "0") {
              index = res[i].index;
              break;
            }
          }
          if (mainData.source <= index) {
            setPeriod(res[mainData.source - 1]);
          } else {
            setPeriod(res[mainData.source]);
          }
        });
      }
    }
  }, [mainData]);

  React.useEffect(() => {
    if (year && month && month.id === "2") {
      setMonth({ ...month, maxDay: getMaxDay(month.id, year) });
    }
    if (!year || year === "") {
      setMonth(getMonthList[0]);
    }
  }, [year]);

  React.useEffect(() => {
    if (month && day && dayEdition) {
      if (day > month.maxDay && day > 28) {
        setDay(month.maxDay);
        dayEdition.setValue(month.maxDay);
        dayEdition.setInputValue(month.maxDay);
      }
    }
    if (!month || month.id === "0") {
      setDay();
      setNumAcc();
      setPeriod();
    }
    if (month && day === undefined) {
      setDay("0");
    }
  }, [month]);

  React.useEffect(() => {
    if (day && !NumAcc) {
      setNumAcc("0");
    }
  }, [day]);

  function yearEdit(data) {
    if (data.value > mainData.startDate + 127) {
      setYear(`${mainData.startDate + 127}`);
      data.setInputValue(`${mainData.startDate + 127}`);
      return;
    }
    if (data.value < mainData.startDate) {
      setYear(`${mainData.startDate}`);
      data.setInputValue(`${mainData.startDate}`);
      return;
    }
    setYear(data.value);
  }

  function monthEdit(data) {
    if (data.setValue && data.setInputValue) {
      if (data.proof) {
        const newValue = getMonthValue(data.value);

        data.value = newValue.value;
        data.tag = newValue.tag;
      }

      setMonth({
        label: data.value,
        id: data.tag,
        maxDay: getMaxDay(data.tag, year),
      });

      data.setValue({ label: data.value, id: data.tag });
      data.setInputValue(data.value);
    }
  }

  function dayEdit(data) {
    if (data.value === "") {
      setDay("0");
      setNumAcc();
      setPeriod();
      data.setInputValue(data.value);
      return;
    }
    if (Number(data.value) <= 0) {
      setDay("1");
      data.setInputValue("1");
      return;
    }
    if (Number(data.value) <= month.maxDay) {
      setDay(data.value);
      data.setInputValue(data.value);
    } else {
      setDay(month.maxDay);
      data.setInputValue(month.maxDay);
    }
  }

  function numAccEdit(data) {
    if (data.value === "") {
      setNumAcc("0");
      setPeriod();
      return;
    }
    if (data.value > 255) {
      setNumAcc("255");
      data.setInputValue("255");
      return;
    }
    if (data.value < 0) {
      setNumAcc("0");
      data.setInputValue("0");
      return;
    }
    if (data.value) setNumAcc(data.value);
  }

  async function getPeriodData(Year, Month) {
    const params = new Map();
    params.set("prefix", "ABLUtils").set("comand", "GetRangeSourceList").set("Year", Year).set("Month", Month);
    const json = await AxiosRequest(true, params);
    if (json && json.Items) {
      const data = Object.values(json.Items);
      return data.map((item, index) => {
        if (typeof item !== "object") {
          if (item === "(Без учета периода)") return { label: item, id: "0", index: index };
          return { label: item, id: -index };
        }
        return { label: item.text, id: item.id };
      });
    }
  }

  function periodEdit(data) {
    if (data.value === "") {
      setPeriod({ label: "(Без учета периода)", id: "0" });
      data.setValue({ label: "(Без учета периода)", id: "0" });
      data.setInputValue("(Без учета периода)");
      return;
    }
    setPeriod({ label: data.value, id: data.tag });
    data.setValue({ label: data.value, id: data.tag });
    data.setInputValue(data.value);
  }

  function getTag() {
    const y = year ? Number(year) - mainData.startDate : 0;
    const m = month ? month.id : 0;
    const d = day ? day : 0;
    const n = NumAcc ? NumAcc : 0;
    const s = period ? period.id : 0;

    let result;

    let shift = 32 - mainData.yearSize;
    result = y << shift;

    shift -= mainData.monthSize;
    result += m << shift;

    shift -= mainData.daySize;
    result += d << shift;

    shift -= mainData.numberSize;
    result += n << shift;

    shift -= mainData.sourceSize;
    result += s << shift;

    return result;
  }

  async function onSelect() {
    if (props.onEdit) {
      props.onEdit({
        tag: getTag(),
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
    }
    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        {
          Name: "ObjRef",
          Value: getTag(),
        },
        props.RequestID,
        props.from,
      );
    }
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Grid
          item
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            height: "40px",
            width: "100%",
          }}
        >
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item style={{ width: "30px" }}>
              Год:
            </Grid>
            <Grid
              item
              style={{
                width: "70px",
                height: "27px",
                marginRight: "5px",
                border: "1px solid #eeeeee",
              }}
            >
              <TestEditor value={year} editStyle={"8"} datatype={"int"} onEdit={yearEdit} />
            </Grid>
            <Grid item style={{ width: "50px" }}>
              Месяц:
            </Grid>
            <Grid
              item
              style={{
                width: "125px",
                height: "27px",
                border: "1px solid #eeeeee",
              }}
            >
              {year ? (
                <TestEditor
                  value={month.label}
                  objref={month.id}
                  editStyle={"1"}
                  onDropDownList={getMonthList}
                  onEdit={monthEdit}
                />
              ) : (
                <div
                  style={{
                    paddingTop: "3px",
                    paddingLeft: "3px",
                    fontSize: "13px",
                  }}
                >
                  б/у месяца
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            height: "115px",
          }}
        >
          <Grid
            style={{
              height: "100%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                marginBottom: "15px",
              }}
            >
              <Grid item style={{ width: "110px" }}>
                День расчета:
              </Grid>
              <Grid
                item
                style={{
                  width: "170px",
                  height: "27px",
                  border: "1px solid #eeeeee",
                }}
              >
                {month && month.id !== "0" ? (
                  <TestEditor
                    value={day}
                    getEdition={setDayEdition}
                    editStyle={"8"}
                    datatype={"int"}
                    onEdit={dayEdit}
                  />
                ) : (
                  <div
                    style={{
                      paddingTop: "2px",
                      paddingLeft: "5px",
                      color: "#cccccc",
                    }}
                  >
                    Укажите месяц
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{
                marginBottom: "15px",
              }}
            >
              <Grid item style={{ width: "110px" }}>
                Номер расчета:
              </Grid>
              <Grid
                item
                style={{
                  width: "170px",
                  height: "27px",
                  border: "1px solid #eeeeee",
                }}
              >
                {day && day !== "0" ? (
                  <TestEditor value={NumAcc} editStyle={"8"} onEdit={numAccEdit} datatype={"byte"} />
                ) : (
                  <div
                    style={{
                      paddingTop: "2px",
                      paddingLeft: "5px",
                      color: "#cccccc",
                    }}
                  >
                    Укажите день
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" style={{}}>
              <Grid item style={{ width: "110px" }}>
                За период:
              </Grid>
              <Grid
                item
                style={{
                  width: "170px",
                  height: "27px",
                  border: "1px solid #eeeeee",
                }}
              >
                {NumAcc && NumAcc !== "0" ? (
                  <TestEditor
                    value={period ? period.label : "(Без учета периода)"}
                    objref={period ? period.id : "0"}
                    editStyle={"1"}
                    onEdit={periodEdit}
                    onDropDownList={() => {
                      return getPeriodData(year, month.id);
                    }}
                  />
                ) : (
                  <div
                    style={{
                      paddingTop: "2px",
                      paddingLeft: "5px",
                      color: "#cccccc",
                    }}
                  >
                    Укажите номер
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          style={{
            width: "100%",
            height: "40px",
          }}
        >
          <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ paddingTop: "8px" }}>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              value="1"
              className="button_Modal_Select"
              onClick={onSelect}
            >
              Выбрать
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              value="2"
              className="button_Modal_Close"
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
