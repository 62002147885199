import SectionDocuments from "../../../../Sections/ElementsSections/SectionDocuments/SectionDocuments";

export default function DocumentsForTabs({ props }) {
  return (
    <div style={{ height: "100%" }}>
      <SectionDocuments
        id={props.SectionID}
        DocCfgID={props.detailItem.DocCfgID ? props.detailItem.DocCfgID : props.detailItem.ObjType}
        ParentID={props.selectedRecordID}
        HideParams={true}
        SectionToolsJS={false}
        PlaneView={"0"}
        setRecorcCount={() => {}}
        HideControlButtons={true}
        footerID={`footerData_${props.SectionID}`}
        noAutoFocus={true}
      />
    </div>
  );
}
