import { createSlice } from "@reduxjs/toolkit";
// import { GetOptionsForUser } from "../components/Url";

export const BackGroundProcessingSlice = createSlice({
  // любое название файла
  name: "BackGroundProcessing",
  initialState: {
    selectedProcess: "",
    ListOfProcesses: [],
  },
  reducers: {
    AddProcess: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      state.ListOfProcesses = action.payload;
    },
    setSelectedBGProcess: (state, action) => {
      //1 значение - переменная что бы изменять; 2 значение - то что приходит при вызове функции
      state.selectedProcess = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { AddProcess, setSelectedBGProcess } = BackGroundProcessingSlice.actions;

export default BackGroundProcessingSlice.reducer;
