import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { AxiosRequest, ImgURL, UpdateOptionsForUser, XMLrequest } from "../../../../Url";
import Grid from "@mui/material/Grid/Grid";
import Button from "@mui/material/Button/Button";
import { Tabs, TabItem } from "smart-webcomponents-react/tabs";
import { createGrid, createRecordSource } from "../../../rcsgrid/rcsgrid";
// import cn from "classnames";
import ReactDOM from "react-dom";
import {
  getIcons,
  getKeyCodeOfInt,
  getRightsData,
  getStyleWindow,
  isEmptyObject,
  MenuItems,
} from "../../../Tools/Tools";
import { Divider, IconButton, Tooltip } from "@mui/material";
import ContextMenu, { GetDisable } from "../../../NotWorkArea(Side&Head)/ContextMenu";
import Menu from "@mui/material/Menu";
import LoadingMask from "../../../NotWorkArea(Side&Head)/LoadingMask";
import {
  CheckModalBackGrounds,
  getBase64ByFileReader,
  tokenProcessingTest,
} from "../../../../TokenProcessing/TokenProcessing";
import { FooterDataShow } from "../../../NotWorkArea(Side&Head)/Footer";
import SectionToolsJS from "../../../Tools/SectionToolsJS";
// import { Scrollbars } from "react-custom-scrollbars-2";
import TabsForReports from "../SectionReport/TabsForReports";
import { DialogHtml } from "../../../../Forms/DialogHtml";
import { CheckMultiSelect } from "../../../rcsgrid/createGridTools";
import TestParams from "../testParams";
import classNames from "classnames";
import IMemo from "../Forms/Components/IMemo";
import GridSas from "../../../rcsgrid/GridSas";
import { store } from "../../../../../store";
import { AddCallBack } from "../../../../../store/themeSlice";
import TestTree from "../../../Windows/ViewData/Tree/testTree";
import { SequenceGetParams, setTreeGroup } from "../../../Tools/Requests";
import cn from "classnames";
import SplitterLocal from "../../../Tools/Splitter";
import ButtonsForGridComponent from "../../../rcsgrid/GridTools/ButtonsForGridComponent";
import DocInfoAndSettings from "./DocInfoAndSettings";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import DialogDocNumbering from "../../../Module/Dialogs/DialogDocNumbering";
import { openModal } from "../../../Tools/modalManager";
import { getLabelKeyCode } from "../../../Tools/KeyCods";
import Samples from "./Samples";
import { getValues } from "../../../rcsgrid/GridTools/parseColumnTools";

const MenuJson = {
  Table: [
    { Caption: "Нумерация...", Disabled: false, value: "Numbering" },
    { Caption: "Создать копию документа", value: "RecordCopy" },
    { Caption: "Сохранить как шаблон...", value: "Samples" },
    {
      Caption: "Детализация",
      Image: <BackupTableIcon fontSize="small" />,
      value: "Details",
    },
    { Caption: "Очистить состояние документа", value: "ClearDocumentState" },
    { Caption: "Печать отмеченных записей", value: "PrintSelectedRecords" },
    // { Caption: "Открыть в другом окне", Disabled: true },
    // { Caption: "Открыть карточку", Disabled: true },
    // {
    //   Caption: "Копировать ссылку на документ",
    //   value: "CopyDocLink",
    //   Image: <LinkIcon fontSize="small" />,
    // },
    { Caption: "Divider" },
  ],
};

export function getImageHint(doccfgId, index, docId) {
  let json;
  const params = new Map();
  params
    .set("prefix", "documents")
    .set("comand", "GetImageHint")
    .set("DocCfgID", doccfgId)
    .set("ImageIndex", index)
    .set("DocID", docId)
    .set("smart", "1");
  json = XMLrequest(params);
  return json.Text;
}

export function SaveDocumentState(doccfgId, id, state, activeDetails) {
  if (state) {
    const params = new Map();
    params
      .set("prefix", "documents")
      .set("comand", "SaveDocumentState")
      .set("DocCfgID", doccfgId)
      .set("SectionID", id)
      .set("ActiveDetail", activeDetails ? activeDetails + 1 : 0);
    AxiosRequest(true, params, { GridState: state });
  }
}

export function createGridStateString(gridState) {
  if (gridState) {
    let FinalStr = "";
    let valueForString;
    for (const [key, value] of Object.entries(gridState)) {
      if (value) {
        if (typeof value === "object") {
          valueForString = `${key}=`;
          for (const [keyPoint, data] of Object.entries(value)) {
            if (keyPoint === "9") {
              valueForString += `${data}`;
            } else {
              valueForString += `${data}•`;
            }
          }
        } else {
          valueForString = `${key}=${value}`;
        }
        FinalStr += valueForString + "\r\n";
      }
    }
    //
    return FinalStr;
  }
}

export function parseGridState(gridState) {
  if (gridState) {
    let arr = gridState.split("\r\n");
    let obj = {};
    if (arr.length > 0) {
      let valueForObj;
      for (const value of Object.values(arr)) {
        if (value !== "") {
          const ValueArr = value.split("=");
          if (ValueArr[1] && ValueArr[1].includes("•")) {
            valueForObj = Object.assign({}, ValueArr[1].split("•"));
          } else {
            valueForObj = ValueArr[1];
          }
          Object.assign(obj, { [ValueArr[0]]: valueForObj });
        }
      }
      return obj;
    }
  }
}

export default function SectionDocuments(props) {
  const [DocumentLayout, setDocumentLayout] = useState(undefined); //Данные о основном гриде
  const [DocumentLayoutDetails, setDocumentLayoutDetails] = useState(undefined); //данные о гриде детализации документа
  const [detailsBool, setDetailsBool] = useState(true); //вкл/выкл деталей
  const [selectedTabIndex, setSelectedTabIndex] = useState(0); //На какой вкадке находимся
  const [selectedRecordID, setSelectedRecordID] = useState("0"); //ТЕкущая выбранная запись в гриде
  // const [firstLoad, setFirstLoad] = useState(true); //Костыль для первой загрузки
  const [Load, setLoad] = useState(true); //
  const [canModifyObj, setCanModifyObj] = useState({
    delete: true,
    insert: true,
  });
  // const [saveDocs, setSaveDocs] = useState({}); //храним doccfgid гридов, в которых меняли данные
  const [redactor, setRedactor] = useState(false);
  const [samples, setSamples] = useState([]);
  const [save, setSave] = useState({ [props.DocCfgID]: false });
  const [test, setTest] = useState(false);
  const [parentID, setParentID] = useState(props.ParentID ? props.ParentID : "0");
  const [tree, setTree] = useState(null);
  const [loadDetails, setLoadDetails] = useState(false);
  const [loadMain, setLoadMain] = useState(false);
  // const [tabs, setTabs] = React.useState(<></>);
  // const [sources, setSources] = React.useState({}); //тут сохраняем сурсы
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElButton, setAnchorElButton] = React.useState(null);
  const [selectedView, setSelectedView] = React.useState("doc"); // doc, paper, card какой вид сейчас у документа
  const [PaperFormData, setPaperFormData] = React.useState({}); //основной грид
  // обновление истории параметров
  const [fixHistory, setFixHistory] = React.useState();
  const [paramPath, setParamPath] = React.useState();
  const [portal, setPortal] = React.useState([]);
  const [shortsCutsData, setShortsCutsData] = React.useState({});

  const [controlButtonsState, setControlButtonsState] = React.useState({
    detail: detailsBool,
    card: false,
    filter: false,
  });
  const [controlButtons, setControlButtons] = React.useState({
    navigate: true,
    delete: true,
    save: true,
    card: true,
    create: true,
    createList: true,
    editButtons: true,
    detail: true,
    sign: true,
    refreshFull: true,
    filter: true,
  });
  const [maingridtest, setmaingridtest] = React.useState();
  // const theme = useSelector((state) => state.theme.theme);
  // const [options, setOptions] = useState({ filter: false, groupby: false }); //какие опции вкл/выкл
  const MainGridRef = useRef(null); //грид основной
  const focusedGridRef = useRef(null);
  const SubGridRef = useRef(null); //грид детализации
  const TabsRef = useRef(null);
  const timeout = useRef(null);
  const timeoutUpdateGrid = useRef(null);
  const tabs = useRef(null);
  const sources = useRef({});
  const saveDocs = useRef({});
  const me = useRef(null);
  const applyButton = useRef(null);
  const redactorRef = useRef(false);
  const formidRef = useRef(null);
  const leftSplitterRef = useRef(null);
  const parentIDStorage = useRef({ needUpdt: true });

  var grid = null;

  useEffect(() => {
    if (me.current) {
      me.current.refreshMainGrid = (data) => refreshMainGrid(data);
      me.current.CommitSaveOrCancelChanges = (comand) => CommitSaveOrCancelChanges(comand);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me.current]);

  // Получаем выбоанный документ, запрашиваем для него данные и отрисовываем грид на основе данных
  useEffect(() => {
    setDocumentLayout(undefined);
    setTimeout(() => {
      if (redactor) {
        redactor.cancelRecord();
      }
      // console.log(webkcrypt);
      ClearDocumentStatus();
      UpdateOptionsForUser({
        Selected: {
          docid: props.DocCfgID === undefined ? undefined : selectedRecordID,
          DocCfgID: props.DocCfgID,
        },
      });
      // setDetailsBool(true);
    }, 750);
    return () => {
      setDocumentLayout(undefined);
      setDocumentLayoutDetails(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.DocCfgID]);

  useEffect(() => {
    if (MainGridRef.current && MainGridRef.current.grid && props.tree) {
      if (parentID !== undefined) {
        setTreeGroup(props.tree.Module, props.tree.ObjType, props.tree.SectionID, parentID);
        MainGridRef.current.grid.source.onHandleRequest = onHandleRequestDocuments(
          props.DocCfgID,
          DocumentLayout.TreeInConfig === "1" ? "0" : parentID,
        );
      }
      ApplyButtonClick();
    }
    if (parentIDStorage.current.needUpdt) {
      parentIDStorage.current.id = parentID;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentID]);

  // Обновлении деталей при смене выбранной записи в гриде
  useLayoutEffect(() => {
    async function asyncFunc() {
      if (!detailsBool) {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          UpdDetails(selectedTabIndex, DocumentLayoutDetails);
        }, 250);
      }
      if (selectedView === "paper") {
        setTimeout(() => {
          PaperForm();
        }, 0);
      }
      updStateOfButtons();
    }
    asyncFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID]);

  useEffect(() => {
    //меняем данные в футере, в зависимости от выбранной записи(пока только на основной грид)
    FooterMultiOrData(MainGridRef.current.grid);
  }, [selectedRecordID]);

  useEffect(() => {
    if (selectedView === "doc") {
      TestUpdateGrid();
    }
  }, [selectedView]);

  useEffect(() => {
    if (MainGridRef.current.grid && MainGridRef.current.grid.source) {
      if (controlButtonsState.detail !== detailsBool)
        setControlButtonsState({
          ...controlButtonsState,
          detail: detailsBool,
          card: MainGridRef.current.grid.isCard(),
          filter: MainGridRef.current.grid.filter.style.display === "grid",
        });
      MainGridRef.current.grid.getActiveSource().details = {
        ...MainGridRef.current.grid.getActiveSource().details,
        activeDetails: selectedTabIndex,
        detailsBool: detailsBool,
      };
      MainGridRef.current.grid.updateStateValues();
      MainGridRef.current.grid.refreshFullDocumnet = refreshFullDocument;
    }
  }, [selectedTabIndex, detailsBool]);

  // При изменеии хука перерисовываем грид
  useLayoutEffect(() => {
    //
    if (DocumentLayout) {
      CreateGrid(MainGridRef.current, {
        id: props.id,
        who: "Documents",
        DocCfgID: props.DocCfgID,
        state: setSelectedRecordID,
        Columns: DocumentLayout.Columns,
        Which: true,
        detailsBoolLocal: DocumentLayout.detailsBoolLocal,
      }).then(async (source) => {
        const idselected = await source.getFieldText("ID");
        setSelectedRecordID(idselected === "" ? "0" : idselected);
      });
      ButtonsForDocuments(DocumentLayout.Tools.Buttons, {
        //берем кнопки и рисуем их
        DocCfgID: props.DocCfgID,
        from: "main",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DocumentLayout]);

  useEffect(() => {
    if (DocumentLayout) {
      MainGridRef.current.focus();
    }
  }, [MainGridRef]);

  useEffect(() => {
    redactorRef.current = redactor;
    //
    document.removeEventListener("click", clickAwayForRedactor, true);
  }, [redactor]);

  useEffect(() => {
    // тестовый useEffect
    if (DocumentLayoutDetails !== undefined && !isEmptyObject(DocumentLayoutDetails) && selectedRecordID !== "0") {
      UpdDetails(selectedTabIndex, DocumentLayoutDetails).then(() => {
        // ButtonsForDocuments(DocumentLayout.Tools.Buttons, {
        //   //берем кнопки и рисуем их
        //   DocCfgID: props.DocCfgID,
        //   from: "main",
        // });
      });
    }
  }, [DocumentLayoutDetails]);

  function refreshMainGrid(data) {
    MainGridRef.current.grid.source.onHandleRequest = onHandleRequestDocuments(data.DocCfgID, data.ParentID);
    MainGridRef.current.grid.setSource(MainGridRef.current.grid.source);
    MainGridRef.current.grid.source.first();
    MainGridRef.current.grid.refreshSource();
    MainGridRef.current.grid.updateGridSize();
    setParentID(data.ParentID);
  }

  async function updStateOfButtons() {
    if (MainGridRef.current.grid) {
      UpdateOptionsForUser({
        Selected: { docid: selectedRecordID, DocCfgID: props.DocCfgID },
      });
      setCanModifyObj({
        delete: !(await MainGridRef.current.grid.canModify(2, undefined, MainGridRef.current.grid)),
        insert: !(await MainGridRef.current.grid.canModify(0, undefined, MainGridRef.current.grid)),
      });
      MainGridRef.current.grid.refreshFullDocumnet = refreshFullDocument;
    }
  }

  function parseShortsCuts(Tools) {
    let buttonsData = { Buttons: Tools.Buttons, Menu: Tools.Menu };
    if (Tools.Buttons) buttonsData.Buttons = parseShortCut(Tools.Buttons);
    if (Tools.Menu) buttonsData.Menu = parseShortCut(Tools.Menu);
    return buttonsData;
  }

  function parseShortCutsForKeyDown(Tools, doccfgid, updateAll) {
    let onlyShortCuts = updateAll !== undefined ? updateAll : shortsCutsData;
    if (Tools.Buttons)
      onlyShortCuts = {
        ...parseShortCut(Tools.Buttons, doccfgid),
        ...onlyShortCuts,
      };
    if (Tools.Menu)
      onlyShortCuts = {
        ...parseShortCut(Tools.Menu, doccfgid),
        ...onlyShortCuts,
      };
    if (updateAll !== undefined) {
      return onlyShortCuts;
    } else setShortsCutsData(onlyShortCuts);
  }

  function updAllShortsCut(DocumentLayoutDetails, doccfgidDetails) {
    let onlyShortCuts = {};
    onlyShortCuts = parseShortCutsForKeyDown(DocumentLayout.Tools, props.DocCfgID, true);
    onlyShortCuts = {
      ...onlyShortCuts,
      ...parseShortCutsForKeyDown(DocumentLayoutDetails.Tools, doccfgidDetails, onlyShortCuts),
    };
    setShortsCutsData(onlyShortCuts);
  }

  const parseShortCut = (Tool, localdoccfgid) => {
    if (localdoccfgid !== undefined) {
      let returnArr = {};
      for (const value of Object.values(Tool)) {
        if (value.ShortCut) {
          let parsed;
          if (typeof value.ShortCut === "object") {
            parsed = value.ShortCut;
          } else {
            parsed = getKeyCodeOfInt(Number(value.ShortCut));
          }
          returnArr = {
            ...returnArr,
            [[parsed]]: {
              ...value,
              ShortCut: parsed,
              ShortCutString: getLabelKeyCode(parsed),
              localdoccfgid,
            },
          };
        }
      }
      return returnArr;
    } else {
      return Tool.map((val) => {
        if (val.ShortCut) {
          const parsed = getKeyCodeOfInt(Number(val.ShortCut));
          return {
            ...val,
            ShortCut: parsed,
            ShortCutString: getLabelKeyCode(parsed),
          };
        } else return val;
      });
    }
  };

  function getTheme() {
    return store.getState().theme.theme;
  }

  async function clickAwayForRedactor(e) {
    const closestGrid = e.target.closest(".grid-panel");
    if (redactorRef.current && closestGrid && closestGrid.grid.doccfgid === redactorRef.current.doccfgid) return;
    if (
      e.target.tagName === "BODY" ||
      e.target.closest("[loadmask]") ||
      e.target.classList.contains("MuiBackdrop-root") ||
      e.target.closest("[componenteditor]") ||
      e.target.closest("[checklist]") ||
      e.target.closest("[calendar]") ||
      e.target.closest("[details]") ||
      e.target.closest("UL") ||
      e.target.closest(".background_ModalDialog")
    ) {
      return;
    }
    if (redactorRef.current && !e.target.closest(".CloseButtonForGrid") && !e.target.closest(".ApplyButtonForGrid")) {
      await redactorRef.current.postExicstedEditRec();
    }
    document.removeEventListener("click", clickAwayForRedactor, true);
  }

  async function FooterMultiOrData() {
    if (props.DocCfgID && focusedGridRef.current && focusedGridRef.current.grid) {
      const grid = focusedGridRef.current.grid;
      const multiSelectCount = grid.getMultiSelect().length,
        arrtoShow = [`Всего: ${grid.getActiveSource().recordCount}`];
      if (multiSelectCount > 0) {
        arrtoShow.push(`Выделено: ${multiSelectCount}`);
      } else {
        const dataAboutRecord = {
          updTime: await grid.getActiveSource().getFieldText("UpdateTime"),
          UpdateUser: await grid.getActiveSource().getFieldText("UpdateUser"),
        };
        arrtoShow.push(
          `Изменен ${dataAboutRecord.updTime ? dataAboutRecord.updTime : ""} (${
            dataAboutRecord.UpdateUser ? dataAboutRecord.UpdateUser : ""
          })  `,
        );
      }
      FooterDataShow(props.id, arrtoShow, props.footerID);
    }
  }

  function addButtonsForSectionTools(resButtons, newButtons) {
    if (resButtons) {
      return [...newButtons, ...resButtons];
    }
  }

  function getDefaultButtonsforSectionTools() {
    return [
      {
        ID: "-10",
        Hint: "Видимость левой боковой панели",
        Image: getIcons("35"),
      },
    ];
  }

  const ClearDocumentStatus = async (refresh) => {
    // Кнопка отчиски статуса документа или первая отрисовка грида
    if (props.DocCfgID !== undefined) {
      setLoadMain(true);
      const res = await GetDocumentLayout(props.DocCfgID, undefined, refresh);
      if (refresh && sources.current[props.DocCfgID]) {
        MainGridRef.current.grid.getActiveSource().setGridState({});
        await refreshFullDocument(true);
      }
      let gridState,
        activeDetails = 0,
        detailsBoolLocal = detailsBool,
        DetailsSplittersSizes = undefined,
        ParsedTools;
      ParsedTools = parseShortsCuts(res.Tools, props.DocCfgID);
      res.Tools = ParsedTools;
      if (res.Samples && res.Samples.items && res.Samples.items.length > 0) {
        setSamples(res.Samples.items);
      }
      // доп кнопки для секции инструментов
      res.Tools.Buttons = addButtonsForSectionTools(res.Tools.Buttons, getDefaultButtonsforSectionTools());

      parseShortCutsForKeyDown(res.Tools, props.DocCfgID);
      if (res.GridState) {
        gridState = parseGridState(res.GridState);
        if (gridState.details) {
          const details = JSON.parse(gridState.details);
          detailsBoolLocal = details.detailsBool;
        }
        // activeDetails = gridState.ShowDetail;
      }
      if (sources.current[props.DocCfgID] && sources.current[props.DocCfgID].source.details) {
        activeDetails = Number(sources.current[props.DocCfgID].source.details.activeDetails);
        detailsBoolLocal = sources.current[props.DocCfgID].source.details.detailsBool;
        DetailsSplittersSizes = sources.current[props.DocCfgID].source.details.DetailsSplittersSizes;
      } else {
        activeDetails = Number(res.ActiveDetail) - 1;
      }
      activeDetails = isNaN(activeDetails) ? 0 : activeDetails;

      // path для запроса истории параметров
      if (res && res.Params && res.Params.Path) setParamPath(res.Params.Path);

      // setDocumentLayout({ ...res, GridState: gridState });

      setLoad(false);
      setSelectedView("doc"); //для работы печатной формы
      formidRef.current = null;
      setDocumentLayout({
        ...res,
        GridState: gridState,
        detailsBoolLocal: detailsBoolLocal,
      });
      let indexOfDetails = undefined;
      if (!isEmptyObject(res.Details)) {
        for (const [index, detail] of Object.entries(res.Details.items)) {
          if (detail.Hidden !== "1") {
            indexOfDetails = Number(index);
            break;
          }
        }
      }
      if (indexOfDetails !== undefined) {
        if (activeDetails !== undefined) indexOfDetails = activeDetails;
        const detailsdataFromActiveDetails = processDetailsTabs(res.Details.items, indexOfDetails);
        // DisplaySplitterDetaild("show");
        const resDetails = await GetDocumentLayout(
          detailsdataFromActiveDetails.DocCfgID,
          res.Columns[indexOfDetails].ID,
        );
        if (resDetails && resDetails.GridState) {
          resDetails.GridState = parseGridState(resDetails.GridState);
        }
        // setLoadDetails(true);
        tabs.current = TabItemsDetails(res.Details, undefined, DetailsSplittersSizes);
        if (!isEmptyObject(resDetails.Tools)) {
          resDetails.Tools = parseShortsCuts(resDetails.Tools, detailsdataFromActiveDetails.DocCfgID);
        }
        // updAllShortsCut(resDetails, )
        setDocumentLayoutDetails(resDetails);
        // setTabs(TabItemsDetails(res.Details));

        setTimeout(() => {
          if (activeDetails !== 0) TabsRef.current.nativeElement.select(activeDetails);
        }, 50);
        if (!detailsBoolLocal && !props.HideControlButtons) ChangeCollapsedDetails(detailsBoolLocal);
        // UpdDetails(activeDetails, resDetails)
      } else {
        setDetailsBool(true);
      }
      setTree(getTree(res));
    }
  };

  function processDetailsTabs(data, indexToCheck, indexFrom) {
    let index = indexFrom !== undefined ? indexFrom : -1;
    for (const value of Object.values(data)) {
      if (typeof index === "object") {
        return index;
      }
      if (value.Hidden !== "1") {
        // if (index !== 0) {
        index++;
        // }
        if (index === indexToCheck) return value;
        // if (index === 0) index++;
        if (value.items) index = processDetailsTabs(value.items, indexToCheck, index);
      }
    }
    return index;
  }

  // Делаем правильныую высоту вертикальных сплиттеров при первом рендере
  // FixSplitterHeight();

  // Рисуем кнопки для документа
  function ButtonsForDocuments(json, subData) {
    let items = [],
      iconbutton,
      buttonsBlock =
        props.buttonsContainerRef && props.buttonsContainerRef.current
          ? props.buttonsContainerRef.current
          : document.getElementById("buttons_for_section" + props.id);
    if (json !== undefined && !isEmptyObject(json) && buttonsBlock) {
      for (const [key, value] of Object.entries(json)) {
        if (value.Caption !== "-") {
          // проверка на разделитель |
          let hintForButton = value.Hint;
          if (value.ShortCutString) {
            hintForButton += ` (${value.ShortCutString})`;
          }
          iconbutton =
            value.Width && value.Caption !== undefined ? ( //если есть ширина, то рисуем обычную кнопку а не айкон баттон
              <Button
                id={value.ID}
                from={subData.from}
                style={{
                  width: value.Width === undefined ? "" : `${value.Width * 1.286}px`,
                  fontSize: "13px",
                  textTransform: "none",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                component="span"
                disabled={value.Disabled === "1"}
                index={key}
                doccfgid={props.DocCfgID}
                key={key}
                onClick={
                  (event) => {
                    value.items ? setAnchorElButton(event.currentTarget) : HandleToolButton(event);
                  }
                  //условия для выпадающего списка из кнопки
                }
                startIcon={ImgURL(value.Image)}
              >
                {value.Caption === undefined ? "" : ` ${value.Caption}`}
              </Button>
            ) : (
              <IconButton
                id={value.ID}
                from={subData.from}
                key={key}
                variant="text"
                style={{
                  width: value.Caption === undefined ? "40px" : `${value.Width}px`, //value.Width === undefined ||
                  fontSize: "13px",
                }}
                component="span"
                doccfgid={props.DocCfgID}
                index={key}
                disabled={value.Disabled === "1"}
                onClick={(event) => {
                  value.items ? setAnchorElButton(event.currentTarget) : HandleToolButton(event);
                }}
              >
                {ImgURL(value.Image, { marginLeft: "0px" })}
                {value.Caption === undefined ? "" : ` ${value.Caption}`}
              </IconButton>
            );
          items.push(
            <Grid key={key} item documentsectionbutton="">
              {value.Hint ? (
                <Tooltip title={hintForButton} arrow>
                  {iconbutton}
                </Tooltip>
              ) : (
                iconbutton
              )}
            </Grid>,
          );
        } else {
          items.push(<Divider key={key} orientation="vertical" flexItem sx={{ mr: 0.5 }} />);
        }
      }
      // ReactDOM.createPortal(items, buttonsBlock);
      setPortal(ReactDOM.createPortal(items, buttonsBlock));
    }
  }

  function customClick(id) {
    switch (id) {
      case "-10":
        const state = leftSplitterRef.current.querySelector(`div[role="separator"]`);
        if (state.hasAttribute("item-collapsed")) {
          leftSplitterRef.current.expand(true);
          onExpand();
        } else {
          leftSplitterRef.current.collapse(true);
          onCollapse();
        }

        break;
      default:
        break;
    }
  }

  // Запрос-клик на кнопку для документа
  function HandleToolButton(event, gridRef) {
    if (event.currentTarget.id <= -10) {
      customClick(event.currentTarget.id);
      return;
    }
    setAnchorElButton(null);
    let params = new Map(),
      gridToHandle = gridRef ? gridRef : MainGridRef.current,
      mainGrid = MainGridRef.current.grid,
      DocID,
      MainDocID,
      doccfgId,
      isDetails = false;
    DocID = gridToHandle.grid.getActiveSource().getFieldTextSync("ID");
    MainDocID = mainGrid.getActiveSource().getFieldTextSync("ID");
    doccfgId = gridToHandle.grid.doccfgid === "0" ? props.DocCfgID : gridToHandle.grid.doccfgid;
    isDetails =
      gridToHandle &&
      gridToHandle.grid &&
      gridToHandle.grid.getActiveSource().Master &&
      gridToHandle.grid.getActiveSource().Master.MasterID;
    params.set("prefix", "documents");
    params.set("comand", "HandleToolButton");
    params.set("ID", event.currentTarget.id);
    params.set("DocID", DocID ? DocID : 0);
    params.set("DocCfgID", props.DocCfgID);
    params.set("SectionID", props.id);
    console.log(gridToHandle);
    params.set("MasFld", mainGrid.getSelectedField());
    params.set("PlaneView", mainGrid.isCard() ? "1" : "0");
    params.set("WSM", "1");
    if (isDetails) {
      params.set("MasterID", gridToHandle.grid.getActiveSource().Master.MasterID);
      params.set("ActiveDetail", gridToHandle.grid.doccfgid);
      params.set("DetailID", DocID ? DocID : 0);
      // params.set("Details", "");//SELECTION
      params.set("DetailsFld", gridToHandle.grid.getSelectedField());
    }

    if (mainGrid.getMultiSelect().length > 0) {
      CheckMultiSelect(mainGrid).then((selection) => {
        params.set("Selection", selection);
        AfterSelection();
      });
    } else {
      AfterSelection();
    }
    function AfterSelection(passed) {
      if (isDetails && gridToHandle.grid.getMultiSelect().length > 0 && passed === undefined) {
        CheckMultiSelect(gridToHandle.grid).then((selection) => {
          params.set("Details", selection); //SELECTION
          AfterSelection(true);
        });
      } else {
        AxiosRequest(true, params).then((res) => {
          tokenProcessingTest(res, {
            from: "grid",
            func: (data) => {
              AfterWSM(gridToHandle, data);
            },
          });
        });
      }
    }
  }

  async function AfterWSM(gridRef, data) {
    if (data.DocID) {
      await gridRef.grid.refreshSource(data.DocID);
    } else {
      await gridRef.grid.refreshSource("!");
    }
    if (
      (gridRef && gridRef.grid && gridRef.grid.source.details && gridRef.grid.source.details.detailsBool === false) ||
      !detailsBool
    ) {
      const selectedIndex = Number(TabsRef.current.nativeElement.getAttribute("selected-index"));
      const CurrentTabContent = TabsRef.current.nativeElement.getTabContent(selectedIndex); //берем текущий контент вкладки деталзации
      if (!CurrentTabContent) return;
      await UpdDetails(selectedIndex, CurrentTabContent.DocumentLayoutDetails);
    }
  }

  // Функция установки полей для грида
  function setCollumsForGrid(Columns, grid) {
    // не показывать в списке 256(отображение в таблице и списке колонок) Options
    if (Columns) {
      // не показывать в шапке 512 Options
      let columns = { title: [] },
        dest;
      for (const value of Object.values(Columns)) {
        if (value.FieldName) {
          dest = {};
          dest.fieldName = value.FieldName;
          dest.title = value.Title;
          if (value.Width) dest.width = parseInt(value.Width * 1.33);
          else dest.width = 100;
          if (value.Deep) dest.level = parseInt(value.Deep);
          else dest.level = 0;
          if (value.Level) dest.level = parseInt(value.Level);
          if (value.Options) {
            dest.hide = value.Options & 32768 ? true : false;
            dest.OptionsDisable = {};
            dest.OptionsDisable.filterByField = value.Options & 1024 ? true : false;
            dest.OptionsDisable.group = value.Options & 8192 ? true : true;
            dest.OptionsDisable.sort = value.Options & 2 ? true : false;
            dest.OptionsDisable.verticalText = value.Options & 16 ? true : false;
            dest.OptionsDisable.link = value.Options & 2048 ? true : false;
            dest.OptionsDisable.OnEdit = value.OnEdit ? true : false;
          }
          if (value.Type) {
            switch (value.Type) {
              case "Level": {
                dest.collapseRecord = true;
                break;
              }
            }
          }

          dest.fieldInfo = {
            CheckField: value.CheckField ? value.CheckField : "",
            Type: value.Type ? value.Type : "",
            Image: value.Image ? value.Image : "",
            TitleTextStyle: value.TitleTextStyle ? value.TitleTextStyle : "",
            TextColor: value.TextColor ? value.TextColor : "",
            DataType: value.DataType ? value.DataType : "",
            EditStyle: value.EditStyle ? value.EditStyle : "",
            Options: value.Options ? value.Options : "",
            TitleAjust: value.TitleAjust ? value.TitleAjust : "",
            TextAjust: value.TextAjust ? value.TextAjust : "",
            Module: value.Module ? value.Module : "documents",
            Values: value.Values ? getValues(Object.values(value.Values), value.EditStyle & 32) : [],
            Details: value.Details ? value.Details : "",
            OnEdit: value.OnEdit ? value.OnEdit : "",
            OnList: value.OnList ? value.OnList : "",
            OnHint: value.OnHint ? value.OnHint : "",
            TitleAjust: value.TitleAjust ? value.TitleAjust : "0",
            TextAjust: value.TextAjust ? value.TextAjust : "0",
            EditMask: value.EditMask ? value.EditMask : "",
            ColumnID: value.ID ? value.ID : "",
            ButtonImage: value.ButtonImage ? value.ButtonImage : "",
            OnCloseUpList: value.OnCloseUpList ? value.OnCloseUpList : "",
            OnGetEditStyle: value.OnGetEditStyle ? value.OnGetEditStyle : "",
            MultiCheckSet: value.MultiCheckSet ? value.MultiCheckSet : "",
          };
          // else dest.level = 0;
          if (value.Options & 256) {
            // dest.hide = true;
            dest.hideInGrid = true;
            // columns.title.push(dest);
          } else if (value.Options & 512) {
            dest.hideInCard = true;
          }
          columns.title.push(dest);
        }
      }

      grid && grid.setColumns(columns, true);
      return columns;
    } else grid.defaultColumns = true;
  }

  function canModify(flag, column, grid, dd) {
    // dd - детали в деталях, что бы понять когда не нужно возвращаться false
    // 0 - добавление записей, 2 - удаление записей, 1 - изменять значения, 3 - вставка через буфер
    //mfInsert = 0, mfDelete = 2, mfEdit = 1, mfPaste = 3,
    // return new Promise(async (resolve) => {

    let LockStatus,
      canModify = true,
      recordInfo = grid.getActiveSource().getRecordInfo(),
      mfInsert = 0,
      mfEdit = 1,
      mfDelete = 2,
      mfPaste = 3,
      InsertFlag_Disable = 1,
      OnlyViewMode = grid.OnlyViewMode;
    if (!localCanModify(flag, grid)) {
      // первоначальная проверка прав
      return false;
    }
    if (dd === undefined && OnlyViewMode) {
      return false;
    }
    if (grid.getActiveSource().Master.parentCanModify) {
      // если есть родитель, то смотрим на его canModify
      canModify = grid
        .getActiveSource()
        .Master.parentCanModify(flag, column, grid.detailsInDetail !== true ? true : undefined);
    }
    if (props.canModify) {
      canModify = props.canModify(flag, column, grid, true);
    }
    if (grid.detailsInDetail === true && dd !== true) {
      return false;
    }
    if (!isEmptyObject(grid.source)) {
      LockStatus = recordInfo.LockStatus;
      switch (flag) {
        case mfPaste:
        case mfInsert: //добавление записей или вставка
          if (canModify) canModify = !grid.getActiveSource().Master.isEmpty; //проверка на сурс мастер
          if (canModify) canModify = grid.getActiveSource().rights.insert; //Проверка прав
          if (canModify && !dd) canModify = grid.getActiveSource().InsertFlag !== InsertFlag_Disable; //Проверка запрета из getFields
          if (canModify && flag === mfPaste) canModify = canModify;
          if (canModify && dd === true && LockStatus === 6) canModify = false;
          break;
        case mfEdit: // редактирование записей
          if (column && column.fieldName && dd === undefined) {
            const indexOfField = grid.getActiveSource().getFieldIndex(column.fieldName);
            const Locking = recordInfo.Data$content;
            // if (canModify) {
            canModify = Locking[indexOfField] === "0" || Locking[indexOfField] === "4";
            // }
          } else {
            if (LockStatus === 6) canModify = false;
            if (canModify && grid.getActiveSource().rights) canModify = grid.getActiveSource().rights.modify; ///
            // if (column && column.fieldInfo !== undefined && canModify) {
            //   canModify = (Number(column.fieldInfo.Options) & 8) === 0;
            //   if (canModify)
            //     canModify = (Number(column.fieldInfo.Options) & 4) === 0;
            // }
          }
          break;
        case mfDelete: //удаление
          canModify = grid.getActiveSource().rights.delete;
          if (canModify && LockStatus === 6) canModify = false;
          if (canModify && grid.getActiveSource().recordCount === 0) canModify = false;
          break;
      }
    }
    // resolve(canModify);
    return canModify;

    function localCanModify(flag, me) {
      // значение возможности редактирования по умолчанию
      let result = false;

      // разрешения на основе флага и прав
      switch (flag) {
        case mfInsert:
          result = me.getActiveSource().rights.insert;
          break;
        case mfEdit:
          result = me.getActiveSource().rights.modify;
          break;
        case mfDelete:
          result = me.getActiveSource().rights.delete;
          break;
      }

      return result;
    }
    // });
  }

  // Создание грида с сохранением сурса
  async function CreateGrid(gridPanel, data) {
    let source,
      dcfg = data.DocCfgID;
    if (sources.current[dcfg] && sources.current[dcfg].source) {
      //проверяем есть ли у нас уже сохраненный сурс
      grid = gridPanel.grid;
      if (grid.isCard()) {
        await grid.Rotate("", false);
      }
      source = sources.current[dcfg].source;
      setCollumsForGrid(data.Columns, gridPanel.grid);
      grid.BkClrField = DocumentLayout.BkClrField;
      grid.ColorField = DocumentLayout.ColorField;
      grid.StyleField = DocumentLayout.StyleField;
      grid.doccfgid = data.DocCfgID;
      grid.setImageFields(DocumentLayout.ImageFields);
      await grid.setSource(source);
      grid.doccfgid = data.DocCfgID;
      // setDetailsBool(sources.current[dcfg].details);
      source.onRecordIndexChanged = async function (source) {
        source.getFieldText("ID").then((id) => {
          setSelectedRecordID(id);
        });
      };
      const gridStateFromSource = source.getGridState();
      if (gridStateFromSource && gridStateFromSource.ViewMode === "1") {
        await grid.Rotate();
      }
      setControlButtons({
        ...controlButtons,
        sign: source.findField("Doc$DSign"),
      });
      FooterDataShow(
        props.id,
        [
          `Число записей: ${source.recordCount}`,
          `Изменен ${await source.getFieldText("UpdateTime")} (${await source.getFieldText("UpdateUser")})  `,
        ],
        props.footerID,
      );
      props.setRecorcCount && props.setRecorcCount(source.recordCount);
    } else {
      source = new createRecordSource();
      source.onHandleRequest = onHandleRequestDocuments(data.DocCfgID, data.ParentID);
      source.details = {
        activeDetails: selectedTabIndex,
        detailsBool: data.detailsBoolLocal,
      };
      if (!gridPanel.grid || !data.Which) {
        gridPanel.grid = new createGrid(gridPanel);
        gridPanel.grid.onButtonsStateChange = onButtonsStateChange;
        gridPanel.grid.canModify = canModify;
      }
      gridPanel.grid.doccfgid = props.DocCfgID;
      grid = gridPanel.grid;
      if (grid.isCard()) {
        await grid.Rotate("", false);
      }
      setCollumsForGrid(data.Columns, gridPanel.grid);

      grid.BkClrField = DocumentLayout.BkClrField;
      grid.ColorField = DocumentLayout.ColorField;
      grid.StyleField = DocumentLayout.StyleField;
      grid.doccfgid = data.DocCfgID;
      grid.setImageFields(DocumentLayout.ImageFields);
      if (DocumentLayout.GridState && props.disiableState !== true) {
        source.setGridState(DocumentLayout.GridState);
      }
      // source.details = {
      //   activeDetails: selectedTabIndex,
      //   detailsBool: detailsBool,
      // };
      // await source.open();
      if (DocumentLayout.Rights) {
        source.rights = getRightsData(DocumentLayout.Rights);
      }
      source.onRecordIndexChanged = function (source) {
        source.getFieldText("ID").then((id) => {
          setSelectedRecordID(id);
        });
      };
      grid.onMultiSelectChange = function (grid) {
        FooterMultiOrData(grid);
      };
      grid.onRecordCountChange = function (grid) {
        FooterMultiOrData(grid);
      };
      source.onNeedToSaveChanged = function (source) {
        //изменяем массив с гридами, которые нужно сохранить
        onNeedToSaveChanged(grid);
      };
      grid.CommitToSaveChanges = function (data) {
        if (props.setRedactor) {
          props.setRedactor(data);
        } else {
          if (data) document.addEventListener("click", clickAwayForRedactor, true);
          setRedactor(data);
        }
      };
      const detailsBoolLocal =
        sources.current[dcfg] && sources.current[dcfg].details ? sources.current[dcfg].details : true;
      sources.current = {
        ...sources.current,
        [data.DocCfgID]: {
          source: source,
          details: detailsBoolLocal,
          sourcesDetail: {},
        },
      };
      grid.statusData = {
        handleStatuses: () => {
          return AxiosRequest(true, DocumentRequest("GetStatusList"));
        },
        changeStatus: ChangeStatus,
      };
      await grid.setSource(source);
      if (DocumentLayout.GridState) {
        if (DocumentLayout.GridState.ViewMode === "1") {
          grid.OnlyViewMode = true;
        }
      }
      if (props.ObjRef) {
        await grid.refreshSource("^" + props.ObjRef);
        await updStateOfButtons();
      }
      if ((DocumentLayout.GridState && DocumentLayout.GridState.CardMode === "1") || props.PlaneView === "1") {
        await grid.Rotate();
      }
      // grid.updateGridSize();
      grid.refreshFullDocumnet = refreshFullDocument;
      grid.sectionid = props.id;
      // const idselected = await source.getFieldText("ID");
      // setSelectedRecordID(idselected === "" ? "0" : idselected);
      setControlButtons({
        ...controlButtons,
        sign: source.findField("Doc$DSign"),
      });

      FooterDataShow(
        props.id,
        [
          `Число записей: ${source.recordCount}`,
          `Изменен ${await source.getFieldText("UpdateTime")} (${await source.getFieldText("UpdateUser")})  `,
        ],
        props.footerID,
      );
      props.setRecorcCount && props.setRecorcCount(source.recordCount);
      if (!props.noAutoFocus && MainGridRef.current) MainGridRef.current.focus();

      // setDetailsBool(detailsBoolLocal);
      store.dispatch(
        AddCallBack({
          source: grid.getActiveSource(),
          DocCfgID: dcfg,
          secId: props.id,
        }),
      );

      if (DocumentLayout.GridState && DocumentLayout.GridState.ShowDetail) {
        // ChangeCollapsedDetailsByButton();
        // setTimeout(() => {
        //   TabsRef.current.nativeElement.select(
        //     Number(DocumentLayout.GridState.ShowDetail)
        //   );
        // }, 500);
      }
    }
    grid.doccfgid = props.DocCfgID;
    me.current.grid = MainGridRef.current;
    setActiveSite();
    setLoadMain(false);
    return source;
  }

  function onButtonsStateChange(grid) {
    if (grid) {
      setControlButtonsState({
        ...controlButtonsState,
        card: grid.isCard(),
        filter: grid.filter.style.display === "grid",
        detail: grid.getActiveSource().details === undefined ? false : grid.getActiveSource().details.detailsBool,
      });
      setControlButtons({
        ...controlButtons,
        filter: !grid.isCard(),
        sign: grid.getActiveSource().findField("Doc$DSign"),
      });
      updStateOfButtons();
    }
  }

  async function setActiveSite() {
    let params = new Map(),
      MasterID = await MainGridRef.current.grid.source.getFieldText("ID");
    params
      .set("prefix", "documents")
      .set("comand", "SetActiveSite")
      .set("SectionID", props.id)
      .set("DocCfgID", props.DocCfgID)
      .set("PlaneView", "0");
    if (MasterID !== "") params.set("MasterID", MasterID);
    await AxiosRequest(true, params);
  }

  function testSas() {
    // if (
    //   sources.current &&
    //   sources.current[props.DocCfgID] &&
    //   sources.current[props.DocCfgID].source &&
    //   DocumentLayout &&
    //   DocumentLayout.Columns &&
    //   test
    // ) {
    //   return (
    //     <GridSas
    //       source={sources.current[props.DocCfgID].source}
    //       columns={setCollumsForGrid(DocumentLayout.Columns)}
    //     />
    //   );
    // }
  }

  function CheckDetailsSources(dcgid, DocumentLayoutDetails) {
    let detailsSource;
    if (sources.current[props.DocCfgID] && !isEmptyObject(sources.current[props.DocCfgID].sourcesDetail)) {
      for (const [key, value] of Object.entries(sources.current[props.DocCfgID].sourcesDetail)) {
        if (Number(key) === Number(dcgid)) detailsSource = value;
      }
    }
    if (detailsSource === undefined) {
      detailsSource = new createRecordSource();
      if (DocumentLayoutDetails && DocumentLayoutDetails.GridState) {
        detailsSource.setGridState(DocumentLayoutDetails.GridState);
      }
      store.dispatch(AddCallBack({ source: detailsSource, DocCfgID: dcgid, secId: props.id }));
      sources.current = {
        ...sources.current,
        [props.DocCfgID]: {
          ...sources.current[props.DocCfgID],
          sourcesDetail: {
            ...sources.current[props.DocCfgID].sourcesDetail,
            [dcgid]: detailsSource,
          },
        },
      };
    }
    return detailsSource;
  }

  async function refreshRecord(needToUpdDetails) {
    if (MainGridRef.current && MainGridRef.current.grid)
      await MainGridRef.current.grid.getActiveSource().refreshRecord();
    if (needToUpdDetails) UpdDetails(selectedTabIndex, DocumentLayoutDetails);
  }

  async function CreateDetails(gridPanel, data) {
    //создание деталей
    let source;
    if (data.ParentDocCfgID !== undefined && data.ParentDocCfgID !== "") {
      //проверка на детализацию в детализации
      let parentDocCfgIDArr = data.ParentDocCfgID.split(","), //разделение данныех, для отрисовки грида 3001,3005; По очереди отрисовываем гриды
        parentNamesArr = data.ParentName.split(",");
      const subDetailsGrid = document.getElementById("subDetailsGrid" + data.DocCfgID);
      if (data.length) {
        //размеры что бы не было скролла
        // subDetailsGrid.style.width = data.length * 122 + "px";
        subDetailsGrid.style.width = "100%";
        gridPanel.style.width = `calc(100% - ${data.length * 0 + "px"})`;
        subDetailsGrid.style.marginRight = "1px";
        subDetailsGrid.style.display = "";
      }
      let parentSource = CheckDetailsSources(parentDocCfgIDArr[0], data.DocumentLayoutDetails);
      parentSource.rights = getRightsData("-1");
      parentSource.Master = {
        parentCanModify: (flag, column) => {
          return data.ParentGrid.canModify(flag, column, data.ParentGrid, true);
        },
        isEmpty: !data.ParentGrid.source.recordCount > 0,
        MasterID: data.ParentID,
        refreshRecord: refreshRecord,
      };
      //
      parentSource.onHandleRequest = onHandleRequestDocuments(parentDocCfgIDArr[0], data.ParentID);

      let columns = { title: [] },
        dest = {};
      dest.fieldName = "Doc$Title";
      dest.title = parentNamesArr[0];
      if (data.length === 1) {
        dest.width = subDetailsGrid.offsetWidth - 3;
      } else {
        dest.width = 119;
      }
      dest.level = 0;
      columns.title.push(dest);
      const gridParent = DetailsInDetailsSize(subDetailsGrid, data); //создание грида createGrid
      gridParent.ShowIndicatorF(false); //отключаем индикатор
      await parentSource.refresh();
      gridParent.setColumns(columns);
      await parentSource.open();
      gridParent.DisableResize = false;

      await gridParent.setSource(parentSource);
      parentSource.onNeedToSaveChanged = function (source) {
        onNeedToSaveChanged(gridParent, data.ParentID);
      };
      parentSource.onRecordIndexChanged = function (source) {
        ProcessTheDetailsInDetail(source); //при смене выбранной записи перерисовываем все послд данные
      };

      async function ProcessTheDetailsInDetail(source) {
        const ParentDocCfgID = data.ParentDocCfgID.replace(`${parentDocCfgIDArr[0]},`, "");
        const parentName = data.ParentName.replace(`${parentNamesArr[0]},`, "");

        await CreateDetails(gridPanel, {
          //вызываем сами себя с данными нужного уровня(если изменили 2 уровень, 3 и 4 поменяются)
          DocCfgID: data.DocCfgID,
          Columns: data.Columns,
          ParentID: await source.getFieldText("ID"),
          ParentGrid: gridParent,
          ParentDocCfgID: ParentDocCfgID,
          CurrentSourceDocCfgID: await source.getFieldText("DocCfgID"),
          gridParent: gridParent,
          ParentName: parentName,
          textField: data.textField,
          DocumentLayoutDetails: data.DocumentLayoutDetails, //115308949
        });
      }
      await gridParent.refreshSource("!");
      await gridParent.updateGridSize();
      await ProcessTheDetailsInDetail(parentSource); //вызываем для рекурсии
    } else {
      if (data.textField) {
        // TEXT FIELD D.I.D.
        //если у нас не грид и текстовое поле
        const grid = data.gridParent;
        gridPanel.innerHTML = "";
        let TextFeildComponent;
        if (grid.source.findField(data.textField + "_HTML")) {
          let textfieldcontent,
            page = true;
          textfieldcontent = await handlePage({
            DocID: data.ParentID,
            fieldName: data.textField + "_HTML",
            DocCfgID: data.CurrentSourceDocCfgID,
          });
          if (textfieldcontent === "" || isEmptyObject(textfieldcontent)) {
            textfieldcontent = grid.source.getFieldTextSync(data.textField + "_HTML");
            page = false;
            if (textfieldcontent === "" || textfieldcontent === undefined) {
              textfieldcontent = await handlePage({
                DocID: data.ParentID,
                fieldName: data.textField,
                DocCfgID: data.CurrentSourceDocCfgID,
              });
            }
          }
          TextFeildComponent = CreateHTMLEditor(textfieldcontent, data.gridParent, data.textField + "_HTML", page);
        } else {
          TextFeildComponent = await CreateTextField(grid, data.textField, true);
        }
        gridPanel.appendChild(TextFeildComponent);
        setLoadDetails(false);
      } else {
        grid = gridPanel.grid;
        if (!grid) {
          source = CheckDetailsSources(data.DocCfgID, data.DocumentLayoutDetails);
          source.onHandleRequest = onHandleRequestDocuments(data.DocCfgID, data.ParentID);
          source.onNeedToSaveChanged = function (source) {
            //изменяем массив с гридами, которые нужно сохранить
            onNeedToSaveChanged(grid);
          };
          gridPanel.grid = new createGrid(gridPanel);
          gridPanel.grid.canModify = canModify;
          grid = gridPanel.grid;
          grid.onMultiSelectChange = function (grid) {
            FooterMultiOrData(grid);
          };
          grid.BkClrField = data.DocumentLayoutDetails.BkClrField;
          grid.ColorField = data.DocumentLayoutDetails.ColorField;
          grid.StyleField = data.DocumentLayoutDetails.StyleField;
          grid.setImageFields(data.DocumentLayoutDetails.ImageFields);
          grid.CommitToSaveChanges = function (data) {
            if (props.setRedactor) {
              props.setRedactor(data);
            } else {
              if (data) document.addEventListener("click", clickAwayForRedactor, true);
              setRedactor(data);
            }
          };
          grid.doccfgid = data.DocCfgID;
          setCollumsForGrid(data.Columns, gridPanel.grid);
          await grid.setSource(source);
          await grid.refreshSource();
          grid.sectionid = props.id;
        } else {
          const title = Object.assign({}, gridPanel.grid.columns);
          if (isEmptyObject(title.title)) {
            setCollumsForGrid(data.Columns, gridPanel.grid);
          } else {
            if (!grid.isCard()) grid.setColumns(title, gridPanel.grid);
          }
          grid.getActiveSource().onHandleRequest = onHandleRequestDocuments(data.DocCfgID, data.ParentID);
          await grid.refreshSource("!");
          // if(grid.isCard()){
          //   await grid.getActiveSource().refresh("!");
          // }
        }
        if (data.DocumentLayoutDetails.Rights) {
          grid.source.rights = getRightsData(data.DocumentLayoutDetails.Rights);
        }

        grid.source.Master = {
          parentCanModify: (flag, column, dd) => {
            return data.ParentGrid.canModify(flag, column, data.ParentGrid, true);
          },
          isEmpty: !data.ParentGrid.source.recordCount > 0,
          MasterID: data.ParentID,
          refreshRecord: refreshRecord,
        };
        // await grid.updateGridSize();
        gridPanel.ondragover = handleDrag;
        gridPanel.ondrop = handleDrop;
        SubGridRef.current = gridPanel;
        setLoadDetails(false);
      }
    }
  }

  function onNeedToSaveChanged(grid, parentID, secId) {
    //перебираем все doccfgID и сохраняем их данные
    const dcfgid = grid.doccfgid === "0" ? props.DocCfgID : grid.doccfgid;
    if (grid.source.updatesPending === true) {
      if (props.onNeedToSaveChanged) {
        props.onNeedToSaveChanged(grid, parentID);
        return;
      }

      if (saveDocs.current[props.DocCfgID] && saveDocs.current[props.DocCfgID].dcfgid) return;
      saveDocs.current = {
        ...saveDocs.current,
        [props.DocCfgID]: {
          ...saveDocs.current[props.DocCfgID],
          [dcfgid]: {
            bool: true,
            grid: grid,
            parentID: parentID ? parentID : 0,
            id: secId,
          },
        },
      };
      setSave({ ...save, [props.DocCfgID]: true });
    } else {
      if (props.onNeedToSaveChanged) {
        props.onNeedToSaveChanged(grid, parentID);
        return;
      }
      if (saveDocs.current[props.DocCfgID] && saveDocs.current[props.DocCfgID][dcfgid]) {
        saveDocs.current = { ...saveDocs.current, [props.DocCfgID]: {} };
        setSave({ ...save, [props.DocCfgID]: false });
      }
    }
  }

  async function CreateTextField(grid, textField, dd) {
    const div = document.createElement("div");
    div.style.width = "100%";
    div.style.height = "100%";
    const style = {
      width: "100%",
      height: "100%",
      overflowY: "auto",
      overflowX: "hidden",
      outline: "none",
      resize: "none",
      border: "none",
    };
    let text = await grid.getActiveSource().getFieldText(textField);
    if (text === undefined) {
      text = await handlePage({
        fieldName: textField,
        DocID: grid.getActiveSource().getFieldTextSync("ID"),
      });
    }

    ReactDOM.render(
      <IMemo
        readOnly={!grid.canModify(1, { fieldName: textField }, grid, dd)}
        style={style}
        Text={text}
        onChange={async (event) => {
          // await grid.postSomeData(textField, { text: event.target.value });
          setRedactor({
            postExicstedEditRec: async (e) => {
              await grid.postSomeData(textField, { text: event.target.value });
              await grid.postExicstedEditRec(false);
            },
            cancelRecord: () => {
              setRedactor(false);
            },
          });
        }}
        onBlur={(e) => {
          if (!!redactorRef.current) {
            if (
              redactorRef.current &&
              !e.target.closest(".CloseButtonForGrid") &&
              !e.target.closest(".ApplyButtonForGrid")
            ) {
              redactorRef.current.postExicstedEditRec(false);
              if (props.setRedactor) {
                props.setRedactor(false);
              } else {
                setRedactor(false);
              }
            }
          }
        }}
      />,
      div,
    );
    return div;
  }

  function CreateHTMLEditor(mimeContent, grid, textField, page) {
    const div = document.createElement("div");
    div.style.width = "100%";
    div.style.height = "100%";
    const oldContainers = document.querySelectorAll(".jodit-ui-tooltip-container");
    if (oldContainers.length > 0) {
      for (let index = 0; index < oldContainers.length; index++) {
        const element = oldContainers[index];
        element.remove();
      }
    }
    // const emptyContent = "MIME-Version: 1.0\r\nContent-Type: multipart/related; boundary=\"----=_BOUNDARY_LINE_\"\r\n\r\n------=_BOUNDARY_LINE_\r\nContent-Type: text/html; charset=\"utf-8\"\r\nContent-Transfer-Encoding: base64\r\n\r\n\r\n\r\n------=_BOUNDARY_LINE_--\r\n"

    ReactDOM.render(
      <DialogHtml
        htmlInput={mimeContent}
        HiddenButton={true}
        readonly={!grid.canModify(1, { fieldName: textField }, grid)}
        onBlur={(content) => {
          if (grid.source.getFieldTextSync(textField) !== content) {
            // grid.postSomeData(textField, { text: content });
            if (!!redactorRef.current) {
              redactorRef.current.postExicstedEditRec(false);
              if (props.setRedactor) {
                props.setRedactor(false);
              } else {
                setRedactor(false);
              }
            }
          }
        }}
        onChange={(content) => {
          if (grid.source.getFieldTextSync(textField) !== content) {
            // grid.postSomeData(textField, { text: content });
            setRedactor({
              postExicstedEditRec: async () => {
                await grid.postSomeData(textField, { text: content });
                await grid.postExicstedEditRec(false);
              },
              cancelRecord: () => {
                setRedactor(false);
              },
            });
            // console.log("HTML РАЗЛИЧИЯ", content);
          }
        }}
      />,
      div,
    );
    return div;
  }

  function CreateDocuments(data) {
    const div = document.createElement("div");
    div.style.width = "100%";
    div.style.height = "100%";
    div.id = "DocumetsDetails" + data.id;
    ReactDOM.render(
      <SectionDocuments
        DocCfgID={data.ObjType ? data.ObjType : "-1"}
        canModify={(flag, column, grid, dd) => data.grid.canModify(flag, column, grid, dd)}
        HideParams={data.HideParams === "1"}
        SectionToolsJS={false}
        id={data.id}
        HideControlButtons
        // HideDetails
        setRedactor={(value) => {
          if (value) document.addEventListener("click", clickAwayForRedactor, true);
          setRedactor(value);
        }}
        onNeedToSaveChanged={(g, p) => {
          onNeedToSaveChanged(g, p, data.id);
        }}
      />,
      div,
    );
    return div;
  }
  // function WorkWithSource(source, grid) {}

  function DetailsInDetailsSize(subDetailsGrid, data) {
    let gridPanelParent = document.getElementById("gridpanelDetails" + data.ParentDocCfgID);
    if (gridPanelParent) gridPanelParent.parentElement.remove();
    const gridPanelParentBlockWidth = document.createElement("div");
    gridPanelParentBlockWidth.style.width = "100%";
    gridPanelParentBlockWidth.style.position = "relative";
    gridPanelParentBlockWidth.style.height = "inherit";
    gridPanelParentBlockWidth.style.marginRight = "1px";
    gridPanelParent = document.createElement("div");
    gridPanelParent.tabIndex = "0";
    gridPanelParent.onfocus = onFocusChange;
    gridPanelParent.style.height = "100%";
    gridPanelParent.id = "gridpanelDetails" + data.ParentDocCfgID;
    gridPanelParentBlockWidth.appendChild(gridPanelParent);
    subDetailsGrid.appendChild(gridPanelParentBlockWidth);
    subDetailsGrid.style.marginRight = "1px";
    subDetailsGrid.style.display = "";
    gridPanelParentBlockWidth.appendChild(gridPanelParent);
    subDetailsGrid.appendChild(gridPanelParentBlockWidth);
    gridPanelParent.grid = new createGrid(gridPanelParent);
    gridPanelParent.grid.doccfgId = data.ParentDocCfgID;
    gridPanelParent.grid.detailsInDetail = true;
    // gridPanelParent.grid.canModify = (flag) => {
    //   return false;
    // };
    gridPanelParent.grid.sectionid = props.id;
    gridPanelParent.grid.canModify = canModify;
    return gridPanelParent.grid;
  }

  function onHandleRequestDocuments(DocCfgID, ParentID, id, wsm) {
    //общой handleRequest
    return function (request, load, wsmFromGrid) {
      let params = new Map();
      params.set("prefix", "documents");
      params.set("comand", "HandleDocument");
      params.set("DocCfgID", DocCfgID);
      params.set("SectionID", id ? id : props.id);
      params.set("ParentID", ParentID === undefined ? parentID : ParentID);
      if (wsm || wsmFromGrid) params.set("WSM", "1");

      return AxiosRequest(load === undefined ? true : load, params, request).then((result) => {
        if (result.Tabs) {
        }
        return result;
      });
      // return XMLrequest(params, request);
    };
  }

  function DisplaySplitterDetaild(WhatToDo) {
    //костыль для работы деталей
    const Splitter = document.getElementById("MainSplitterOfDocumentsGrid" + props.id);
    if (Splitter) {
      if (WhatToDo === "show") {
        Splitter.showBar(0);
      } else {
        Splitter.hideBar(0);
        setDocumentLayoutDetails({});
      }
    }
  }

  // Получение данных о документе, основываясь на его DocCfgID
  function GetDocumentLayout(docConfigID, MasterID, refresh) {
    let params = new Map();
    if (!docConfigID) return;
    params.set("prefix", "documents");
    params.set("comand", "GetDocumentLayout");
    params.set("DocCfgID", docConfigID);
    params.set("SectionID", props.id);
    if (MasterID !== undefined) params.set("MasterID", MasterID);
    params.set("GridState", 1);
    if (refresh) {
      params.set("ClearState", 1);
      params.set("Refresh", 1);
    }
    return AxiosRequest(true, params);
    // return XMLrequest(params);
  }

  // Параметры
  function ReturnParams(data) {
    if (data) {
      return (
        <TestParams
          Module={"documents"}
          id={props.DocCfgID}
          SectionID={props.id}
          data={data.Params}
          Path={data.Params.Path}
          NoTitle={true}
          defaultFilter={data.Filter === "1" ? true : false}
          fixHistory={fixHistory}
          setFixHistory={setFixHistory}
        />
      );
    } else {
      return <LoadingMask />;
    }
  }

  function getTree(docLay) {
    if (docLay.HasTree === "1") {
      return <TestTree props={{ ...props.tree, selectID: setParentID }} />;
    }
  }

  // Обработка изменения параметров и перерисовка грида на основе измененных параметров
  async function ApplyButtonClick() {
    if (redactor) {
      await redactor.postExicstedEditRec();
    }
    await refreshFullDocument();
    if (paramPath && !props.HideParams) {
      const json = await FixParamHistory(paramPath);
      if (json.History) setFixHistory(json.History);
    }
  }

  async function refreshFullDocument(refresh) {
    let selectRecord = await MainGridRef.current.grid.getActiveSource().getFieldText("ID");
    if (selectRecord === "" || refresh) {
      await MainGridRef.current.grid.refreshSource();
    } else {
      await MainGridRef.current.grid.refreshSource("!" + selectRecord);
    }
    if (!detailsBool) {
      await UpdDetails(selectedTabIndex, DocumentLayoutDetails);
    }
    await updStateOfButtons();
  }

  function FixParamHistory(path) {
    // programs~FixParamHistory?LicGUID=3BF73F2A46DEF36C917A8C964E996C79&Path=Documents\Params\3008\108
    const params = new Map();
    params.set("prefix", "programs").set("comand", "FixParamHistory").set("Path", path);
    return AxiosRequest(true, params);
  }
  // Костыльная функция для отрисовки деталий основного грида
  function ChangeCollapsedDetails(bool) {
    setDetailsBool(bool);
    sources.current = {
      ...sources.current,
      [props.DocCfgID]: { ...sources.current[props.DocCfgID], details: bool },
    };
    if (store.getState().theme.animations) {
      clearTimeout(timeoutUpdateGrid.current);
      timeoutUpdateGrid.current = setTimeout(() => {
        TestUpdateGrid().then(() => {
          UpdDetails(selectedTabIndex, DocumentLayoutDetails);
        });
      }, 501);
    } else {
      clearTimeout(timeoutUpdateGrid.current);
      timeoutUpdateGrid.current = setTimeout(() => {
        TestUpdateGrid().then(() => {
          UpdDetails(selectedTabIndex, DocumentLayoutDetails);
        });
      }, 1);
    }
  }

  // Перерасчет размеров грида
  async function TestUpdateGrid() {
    if (MainGridRef.current.grid) {
      await MainGridRef.current.grid.updateGridSize();
      await MainGridRef.current.grid.scrollTo(MainGridRef.current.grid.getActiveSource().activeRecord);
    }
    if (!detailsBool) {
      // await SubGridRef.current.grid.updateGridSize();
      let tab = TabsRef.current.nativeElement.getTabContent(selectedTabIndex);
      if (tab) {
        tab = tab.querySelectorAll(".grid-panel");
        if (tab.length > 0) {
          for (let DetailGrid of tab) {
            if (DetailGrid.grid) {
              await DetailGrid.grid.updateGridSize();
            }
          }
        }
      }
    }
  }

  function CreateRecordCopy(DocCfgID, DocId) {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", "CreateRecordCopy");
    params.set("SectionID", props.id);
    params.set("DocCfgID", DocCfgID);
    params.set("ID", DocId);
    return AxiosRequest(true, params);
  }

  // Изменить вид грида(фильтр и группировка)
  // function UpdateGridFunctional(index) {
  //   MainGridRef.current.grid.setOptions(index);
  // }

  // При изменение вкладки перерисовать грид
  function onTabChange(event) {
    // todo на предыдущей вкладке вкл загрузку
    //
    if (
      // !firstLoad &&
      DocumentLayout &&
      !isEmptyObject(DocumentLayout.Details) &&
      event.detail &&
      event.detail["oldIndex"] !== null // в теории можно сделать на timeStamp
    ) {
      setLoadDetails(true);
      const newTabIndex = event.detail.index;
      const CurrentTabContent = TabsRef.current.nativeElement.getTabContent(newTabIndex);
      const DetailsDocCfgID = CurrentTabContent.parentElement.docCfgID; // получаем DOCcfg вкладки
      GetDocumentLayout(DetailsDocCfgID, selectedRecordID).then((res) => {
        setSelectedTabIndex(newTabIndex);
        setDocumentLayoutDetails(res);
      });
    }
  }

  // Отрисовка вкладки деталей с гридом
  function TabItemsDetails(data, parentData, DetailsSplittersSizes) {
    if (data !== undefined && !isEmptyObject(data) && data.items !== undefined && !isEmptyObject(data.items)) {
      let returnJsx = [];
      let DataFromParent = parentData === undefined ? "" : parentData;
      for (const value of Object.values(data.items)) {
        if (value.Hidden !== "1") {
          returnJsx.push(
            <TabItem
              style={{
                textTransform: "none",
                width: "100%",
                height: "100%",
                padding: "0px",
                display: "inline-block",
                overflowX: "hidden",
              }}
              label={value.Name}
              key={"DetailsDocuments" + value.DocCfgID}
              // parentDocCfgID={data.DocCfgID}
              // parentName={data.Name}
              parentDocCfgID={parentData === undefined ? undefined : parentData.DocCfgID}
              parentName={parentData === undefined ? undefined : parentData.Name}
              docCfgID={value.DocCfgID}
              textField={value.TextField !== undefined ? value.TextField : null}
              id={"details" + value.DocCfgID}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{ height: "inherit" }}
              >
                <SplitterLocal
                  style={{
                    width: "100%",
                    borderTop: "1px solid rgb(208, 208, 208)",
                    flex: "1 1",
                  }}
                  orientation="vertical"
                  onExpand={(e) => {
                    detailsInDetailsSizeChange(e, value.DocCfgID);
                  }}
                  onCollapse={(e) => {
                    detailsInDetailsSizeChange(e, value.DocCfgID);
                  }}
                  hideSplitter={parentData === undefined}
                  onResizeEnd={(e) => {
                    detailsInDetailsSizeChange(e, value.DocCfgID);
                  }}
                  sizeSecondary={
                    parentData === undefined
                      ? "0"
                      : DetailsSplittersSizes && DetailsSplittersSizes[value.DocCfgID]
                      ? DetailsSplittersSizes[value.DocCfgID] + "px"
                      : "112px"
                  }
                  defaultCollapsed={false}
                  primaryIndex={1}
                  blockResize={false}
                >
                  <Grid
                    id={`subDetailsGrid${value.DocCfgID}`}
                    item
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{
                      width: "0px",
                      height: "100%",
                      overflow: "hidden",
                      display: "none",
                      position: "relative",
                    }}
                  ></Grid>
                  <div
                    id={`gridpanel${value.DocCfgID}`}
                    onFocus={onFocusChange}
                    tabIndex={"0"}
                    style={{
                      width: "100%",
                      height: "100%",
                      overflow: "hidden",
                      position: "relative",
                    }}
                  ></div>
                </SplitterLocal>
              </Grid>
            </TabItem>,
          );
          returnJsx.push(
            TabItemsDetails(
              value,
              {
                DocCfgID:
                  `${DataFromParent.DocCfgID === undefined ? "" : DataFromParent.DocCfgID + ","}` + value.DocCfgID,
                Name: `${DataFromParent.Name === undefined ? "" : DataFromParent.Name + ","}` + value.Name,
              },
              DetailsSplittersSizes,
            ),
          );
        }
      }
      return returnJsx;
    }
  }
  // Изменение размеров
  function onResizeEnd() {
    TestUpdateGrid();
  }

  function onCollapse() {
    if (DocumentLayout !== undefined && DocumentLayout.HasTree === "1" && DocumentLayout.ForceUpdates === "1") {
      parentIDStorage.current.needUpdt = false;
      refreshMainGrid({ DocCfgID: props.DocCfgID, ParentID: "0" });
    }
    onResizeEnd();
  }

  function onExpand() {
    if (DocumentLayout !== undefined && DocumentLayout.HasTree === "1" && DocumentLayout.ForceUpdates === "1") {
      parentIDStorage.current.needUpdt = true;
      refreshMainGrid({
        DocCfgID: props.DocCfgID,
        ParentID: parentIDStorage.current.id,
      });
    }
    onResizeEnd();
  }

  function onFocusChange(e) {
    if (e.currentTarget.grid && focusedGridRef.current !== e.currentTarget) {
      //
      // setFocusedGrid()
      focusedGridRef.current = e.currentTarget;
      FooterMultiOrData(e.currentTarget.grid);
    }
  }

  function detailsInDetailsSizeChange(block, doccfgid) {
    // console.log(block);
    const grids = block.querySelectorAll(".grid-panel");
    if (block.firstChild.offsetWidth !== 112) {
      const maingridDetails = MainGridRef.current.grid.getActiveSource().details;
      MainGridRef.current.grid.getActiveSource().details = {
        ...maingridDetails,
        DetailsSplittersSizes: {
          ...maingridDetails.DetailsSplittersSizes,
          [doccfgid]: block.firstChild.offsetWidth,
        },
      };
    }
    for (const detailsGrid of Object.values(grids)) {
      if (detailsGrid && detailsGrid.grid) {
        if (detailsGrid.grid.detailsInDetail) {
          detailsGrid.grid.header.firstChild.field.width = detailsGrid.offsetWidth - 2;
        }
        detailsGrid.grid.updateGridSize();
      }
    }
  }

  // обновление грида деталей
  async function UpdDetails(tabIndex, DocumentLayoutDetails) {
    if (
      DocumentLayout !== undefined &&
      !isEmptyObject(DocumentLayout.Details) &&
      !isEmptyObject(DocumentLayoutDetails)
    ) {
      const selectedIndex = Number(TabsRef.current.nativeElement.getAttribute("selected-index"));
      const CurrentTabContent = TabsRef.current.nativeElement.getTabContent(selectedIndex); //берем текущий контент вкладки деталзации
      if (!CurrentTabContent) return;
      CurrentTabContent.DocumentLayoutDetails = DocumentLayoutDetails;
      const ParentDocCfgID = CurrentTabContent.parentElement.parentDocCfgID; //получаем doccfgid
      const DetailsDocCfgID = CurrentTabContent.parentElement.docCfgID; //Получем doccfgid вкладки
      const ParentName = CurrentTabContent.parentElement.parentName; //название вкладки
      const GridDetailsBlock = me.current.querySelector(
        //получаем для отчиски и отрисовки загрузки
        "#gridpanel" + DetailsDocCfgID,
      );
      // CurrentTabContent.children[1].style.display = "none"; // `LoadMaskСircle${DetailsDocCfgID}`
      const textField = CurrentTabContent.parentElement.textField;
      updAllShortsCut(DocumentLayoutDetails, DetailsDocCfgID);
      if (GridDetailsBlock) {
        if (textField !== null && ParentDocCfgID === undefined) {
          // TEXT FIELD SOLO
          let TextFeildComponent;

          if (
            textField === "{03338F11-FC1E-11D4-A605-0000E8D9CBDB}" ||
            textField === "{48C155C0-1336-11D6-9CF2-DB1D5BC3046D}"
          ) {
            const idForDocument = Number(props.id) + Number(DetailsDocCfgID) * 10000;
            const infoAboutDoc = await handlePage({
              DocID: await MainGridRef.current.grid.getActiveSource().getFieldText("ID"),
              DocCfgID: DetailsDocCfgID,
              id: idForDocument,
            });
            const TextFieldBlockDocuments = document.getElementById("Documents" + idForDocument);
            if (!TextFieldBlockDocuments) {
              TextFeildComponent = CreateDocuments({
                ...infoAboutDoc,
                id: idForDocument,
                grid: MainGridRef.current.grid,
              });
            } else {
              await TextFieldBlockDocuments.grid.grid.refreshSource();
            }
          } else {
            GridDetailsBlock.innerHTML = "";
            let textfieldcontent = await MainGridRef.current.grid.getActiveSource().getFieldText(textField + "_HTML");
            if (MainGridRef.current.grid.getActiveSource().findField(textField + "_HTML")) {
              //html
              let page = true;
              textfieldcontent = await handlePage({
                DocID: MainGridRef.current.grid.getActiveSource().getFieldTextSync("ID"),
                fieldName: textField + "_HTML",
              });
              if (textfieldcontent === "" || isEmptyObject(textfieldcontent)) {
                textfieldcontent = MainGridRef.current.grid.getActiveSource().getFieldTextSync(textField);
                page = false;
                if (textfieldcontent === "" || textfieldcontent === undefined) {
                  textfieldcontent = await handlePage({
                    DocID: MainGridRef.current.grid.getActiveSource().getFieldTextSync("ID"),
                    fieldName: textField,
                  });
                }
                if (textfieldcontent === undefined) {
                  textfieldcontent = "";
                }
              }
              TextFeildComponent = CreateHTMLEditor(
                textfieldcontent,
                MainGridRef.current.grid,
                textField + "_HTML",
                page,
              );
            } else {
              //обычный текстовый блок
              TextFeildComponent = await CreateTextField(MainGridRef.current.grid, textField);
            }
          }
          if (TextFeildComponent !== undefined) {
            GridDetailsBlock.appendChild(TextFeildComponent);
          }
          setLoadDetails(false);
        } else {
          //GRID
          // delete GridDetailsBlock.grid;
          CreateDetails(GridDetailsBlock, {
            DocCfgID: DetailsDocCfgID,
            Columns: DocumentLayoutDetails.Columns,
            ParentDocCfgID: ParentDocCfgID === undefined ? "" : ParentDocCfgID + ",",
            ParentID: await MainGridRef.current.grid.getActiveSource().getFieldText("ID"),
            ParentGrid: MainGridRef.current.grid,
            ParentName: ParentName === undefined ? "" : ParentName + ",",
            DocumentLayoutDetails: DocumentLayoutDetails,
            textField: textField,
            length: ParentDocCfgID === undefined ? 0 : ParentDocCfgID.split(",").length,
          });
        }
      }
    }
  }

  async function ControlButtonClick(e) {
    const value = e.currentTarget.getAttribute("value");
    switch (value) {
      case "RefreshFull":
        await ApplyButtonClick();
        break;
      case "append":
        if (samples.length === 0) {
          MainGridRef.current.grid.appendRecord();
        } else {
          handleClickAnchorEl(e);
        }
        break;
      case "details":
        //
        ChangeCollapsedDetails(!detailsBool);
        // ChangeCollapsedDetailsByButton();
        break;
      case "sign":
        const params = DocumentRequest("GetDocSignImage", true, true);
        AxiosRequest(true, params).then(async (response) => {
          let data = response;
          if (response.Token !== "SignDialog" && response.Result === "1" && response.RCDATA) {
            // alert("неверный формат открытия");
            const Notes = await MainGridRef.current.grid.getActiveSource().getRecordInfo();
            const utf8decoder = new TextDecoder("windows-1251");
            const arcdata = atob(response.RCDATA);
            const uint8 = Uint8Array.from(arcdata.split("").map((x) => x.charCodeAt()));
            const content = utf8decoder.decode(uint8);
            const ObjRef = MainGridRef.current.grid.getActiveSource().getFieldTextSync("ID");
            data = {
              Token: "SignDialog",
              Break: "1",
              content: content,
              Params: {
                RCDATA: response.RCDATA,
                ObjType: props.DocCfgID,
                ObjRef: ObjRef,
                Token: "0",
                ID: ObjRef,
                Notes: Notes.title,
                Options: "1026",
              },
            };
          }
          tokenProcessingTest(data, {
            func: () => {
              refreshRecord();
            },
            from: "grid",
          });
        });
        break;
      case "Commit":
        CommitSaveOrCancelChanges(e.currentTarget.getAttribute("command"));
        break;
      case "SaveRedactor":
        await redactor.postExicstedEditRec();
        if (props.setRedactor) {
          props.setRedactor(false);
        } else {
          setRedactor(false);
        }

        break;
      case "CancelRedactor":
        await redactor.cancelRecord();
        if (props.setRedactor) {
          props.setRedactor(false);
        } else {
          setRedactor(false);
        }
        await UpdDetails(selectedTabIndex, DocumentLayoutDetails);
        break;
      case "cardmode": {
        await MainGridRef.current.grid.Rotate();
        break;
      }
      default:
        break;
    }
  }

  function sampleMenuClick(e) {
    const textContent = e.currentTarget.textContent;
    setAnchorEl(null);
    if (textContent === "Новый документ") {
      MainGridRef.current.grid.appendRecord();
    } else {
      handleSamples("document", { Name: textContent }).then((res) => {
        if (res.Result) {
          AfterWSM(MainGridRef.current, { DocID: res.Result });
        }
      });
    }
  }

  async function handleSamples(command, sampleInfo) {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", "HandleSamples");
    params.set("Command", command);
    params.set("SectionID", props.id);
    params.set("DocCfgID", props.DocCfgID);
    switch (command) {
      case "create": {
        params.set("Name", sampleInfo.Name);
        params.set("DocID", selectedRecordID);
        params.set("AllUsers", sampleInfo.AllUsers);
        params.set("All", sampleInfo.All);
        break;
      }
      case "delete": {
        params.set("Name", sampleInfo.Name);
        params.set("User", sampleInfo.User);
        params.set("Section", sampleInfo.Section);
        break;
      }
      case "document": {
        params.set("Name", sampleInfo.Name);
        break;
      }
    }
    const json = await AxiosRequest(true, params);
    if (json.items) {
      setSamples(json.items);
    }
    return json;
  }

  async function CommitSaveOrCancelChanges(command) {
    let commandToDo = command === undefined ? "CommitUpdates" : command;
    //
    const id = await MainGridRef.current.grid.source.getFieldText("ID");
    await processCommitOrCancelUpdates({
      commandToDo: commandToDo,
      dcfgid: props.DocCfgID,
      parentID: 0,
      id: props.id,
    });
    await MainGridRef.current.grid.refreshSource("!");
    for (const value of Object.values(TabsRef.current.nativeElement["_tabs"])) {
      await processCommitOrCancelUpdates({
        dcfgid: value.docCfgID,
        commandToDo: commandToDo,
        parentID: id,
        id: props.id,
      });
      if (value.textField) {
        const idForDocument = Number(props.id) + Number(value.docCfgID) * 10000;
        const TextFieldBlockDocuments = document.getElementById("Documents" + idForDocument);
        if (TextFieldBlockDocuments) {
          await TextFieldBlockDocuments.CommitSaveOrCancelChanges(commandToDo);
        }
      }
    }

    async function processCommitOrCancelUpdates(
      data = { dcfgid: props.DocCfgID, parentID: 0, id: props.id, commandToDo },
    ) {
      const request = onHandleRequestDocuments(data.dcfgid, data.parentID ? data.parentID : "0", data.id, true);
      const otvet = await request({
        Command: data.commandToDo,
      });
      if (otvet.Token) {
        tokenProcessingTest(otvet, { subData: afterWSMCommit, from: "grid" });
      }
    }

    function afterSave() {
      // saveDocs.current = { ...saveDocs.current, [props.DocCfgID]: {} };
      // setSave({ ...save, [props.DocCfgID]: {} });
      if (MainGridRef.current.grid && MainGridRef.current.grid.isCard()) {
        MainGridRef.current.grid.updateCard().then(() => {
          if (!detailsBool) {
            UpdDetails(selectedTabIndex, DocumentLayoutDetails);
          }
        });
      } else {
        if (!detailsBool) {
          UpdDetails(selectedTabIndex, DocumentLayoutDetails);
        }
      }
    }
    function afterWSMCommit(json) {
      if (json.Command === "CommitUpdates") {
      }
    }

    afterSave();

    if (MainGridRef.current.grid) MainGridRef.current.grid.changeIndicator(1);
  }

  function SelectViewOfPaperForm(event) {
    if (selectedView === "paper") {
      setSelectedView("doc");
      formidRef.current = null;
    } else {
      if (DocumentLayout.Forms.length > 1) {
        handleClickAnchorEl(event);
      } else {
        PaperForm(event);
      }
    }
  }

  // Получение печатной формы документа
  function PaperForm(event) {
    let params = DocumentRequest("GetDocumentForm", true, true);
    let formidlocal;
    if (formidRef.current !== null) formidlocal = formidRef.current;
    else if (event && event.currentTarget) {
      formidlocal = event.currentTarget.getAttribute("formid");
      formidRef.current = formidlocal;
    }
    params.set("ID", formidlocal);
    if (MainGridRef.current.grid.getMultiSelect().length > 0) {
      CheckMultiSelect(MainGridRef.current.grid).then((selection) => {
        params.set("Selection", selection);
        AfterSelection();
      });
    } else {
      AfterSelection();
    }
    function AfterSelection() {
      setAnchorEl(null);
      AxiosRequest(true, params).then((resp) => {
        tokenProcessingTest(resp, { func: openForms });
      });
    }
  }

  function openForms(form) {
    setSelectedView("paper");
    setPaperFormData(form);
  }

  const handleClickAnchorEl = (event) => {
    //
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchorEl = () => {
    setAnchorEl(null);
    setAnchorElButton(null);
  };

  // Функция для обработки кликов в контекстном меню
  async function ContextMenuHandler(data, gridRef) {
    const comand = data.value;
    const grid = gridRef.current ? gridRef.current : gridRef;
    switch (comand) {
      case "ClearDocumentState":
        setDocumentLayout(undefined);
        grid.grid.setFilterData({});
        ClearDocumentStatus("refresh");
        // ApplyButtonClick();
        break;
      case "OnlyViewMode": {
        grid.grid.ChangeViewMode();
        await updStateOfButtons();
        break;
      }
      case "RecordCopy":
        CreateRecordCopy(await grid.grid.source.getFieldText("DocCfgID"), await CheckMultiSelect(grid.grid)).then(
          async (res) => {
            await grid.grid.refreshSource(res.ID);
          },
        );
        break;
      case "SaveRedactor":
        await redactor.postExicstedEditRec();
        if (props.setRedactor) {
          props.setRedactor(false);
        } else {
          setRedactor(false);
        }
        break;
      case "CancelRedactor":
        await redactor.cancelRecord();
        if (props.setRedactor) {
          props.setRedactor(false);
        } else {
          setRedactor(false);
        }
        UpdDetails(selectedTabIndex, DocumentLayoutDetails);
        break;
      case "Settings": {
        // CreateBackgroundForModal();
        openModal(<DocInfoAndSettings grid={grid.grid} />, {
          Title: "Документ",
          style: { height: `${430}px`, width: `${580}px` },
          hiddenButton: true,
          blockMaximize: true,
          onClose: () => {
            setLastFocus();
          },
        });
        break;
      }
      case "Details": {
        setDetailsBool(!detailsBool);
        break;
      }
      case "Samples": {
        handleSamples("list").then((res) => {
          openModal(<Samples request={handleSamples} list={res === undefined ? [] : res} />, {
            Title: "Новый шаблон",
            style: { height: `${430}px`, width: `${580}px` },
            hiddenButton: true,
            blockMaximize: true,
            blockResize: true,
            onClose: () => {
              setLastFocus();
            },
          });
        });
        break;
      }
      case "Numbering": {
        SequenceGetParams({
          Name: DocumentLayout.Sequence,
          GetNumMode: "1",
        }).then((res) => {
          openModal(
            <DialogDocNumbering
              props={{
                Sequence: DocumentLayout.Sequence,
                Name: DocumentLayout.Name,
                res: res,
              }}
            />,
            {
              Title: "Нумерация",
              style: getStyleWindow("sequence"),
              hiddenButton: true,
              onClose: () => {
                setLastFocus();
              },
            },
          );
        });
        break;
      }
      case "CopyDocLink": {
        let link = window.location.href;
        link += `&modal&parsed&docid=${selectedRecordID}&doccfgid=${props.DocCfgID}&card`;
        let lastloginDrx = localStorage.getItem("LastLogin");
        if (lastloginDrx) {
          lastloginDrx = lastloginDrx.split(",");
          if (lastloginDrx.length > 0) {
            link += `configname=${lastloginDrx[0]}`;
          }
        }
        try {
          navigator.clipboard.writeText(link);
        } catch {
          alert(link);
        }
        break;
      }
      case "PrintSelectedRecords": {
        CheckMultiSelect(grid.grid).then((selection) => {
          let params = new Map(),
            data = {},
            sourceFields = grid.grid.getActiveSource().fields;
          params
            .set("prefix", "documents")
            .set("comand", "HandleToolButton")
            .set("ID", "-17")
            .set("DocCfgID", grid.grid.doccfgid)
            .set("WSM", "1");
          // data = getShowedCols(grid.grid.columns.levels);

          for (const [key, value] of Object.entries(getShowedCols(grid.grid.columns.levels, sourceFields))) {
            data[`field${Number(key) + 1}`] = value;
          }
          AxiosRequest(true, params, {
            ...data,
            $content: selection,
          }).then((res) => {
            tokenProcessingTest(res);
          });
        });
        break;
      }
      default:
        if (data.ID) HandleToolButton({ currentTarget: { id: data.ID, doccfgid: data.doccfgid } }, grid);
        if (data.Status) ChangeStatus(data.Status);
        break;
    }
  }

  function getShowedCols(data, sourceFields) {
    return data.map((item) => {
      let field = item.field;
      if (field.hide !== true) {
        if (item.expanded && item.items) {
          return MenuItems(item.items, sourceFields);
        } else {
          const sourceField = getFieldFromData(sourceFields, field.fieldName)[0];
          return {
            Заголовок: field.title,
            Ширина: field.width,
            ИмяПоля: sourceField.displayLabel,
          };
        }
      }
    });
  }

  function getFieldFromData(data, fieldName) {
    return data.filter((val) => {
      if (val.fieldName === fieldName) {
        return val;
      }
    });
  }

  function DocumentRequest(command, details, WSM) {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", command);
    params.set("SectionID", props.id);
    params.set("DocCfgID", props.DocCfgID);
    params.set("MasterID", MainGridRef.current.grid.getActiveSource().getFieldTextSync("ID"));
    params.set("MasFld", MainGridRef.current.grid.getSelectedField()); // выбранная колонка в мастер гриде
    params.set("PlaneView", MainGridRef.current.grid.isCard() ? 1 : 0); //режим карточки
    params.set("DocID", MainGridRef.current.grid.getActiveSource().getFieldTextSync("ID"));
    if (!detailsBool && details) {
      //активны ли детали
      params.set("ActiveDetail", TabsRef.current.nativeElement.getTabContent(selectedTabIndex).parentElement.docCfgID); // doccfgid деталей
      if (SubGridRef.current !== null) {
        params.set("DetailID", SubGridRef.current.grid.getActiveSource().getFieldTextSync("ID"));
      }
      params.set("DetFld", SubGridRef.current.grid.getSelectedField());
    }
    if (WSM) params.set("WSM", "1");
    return params;
  }

  const handlePage = async (data) => {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", "HandlePage");
    params.set("Type", data.type ? data.type : "get");
    params.set("DocID", data.DocID);
    if (data.fieldName) params.set("fieldName", data.fieldName);
    params.set("DocCfgID", data.DocCfgID ? data.DocCfgID : props.DocCfgID);
    params.set("SectionID", data.id ? data.id : props.id);
    // params.set("WSM", "1");
    let content = await AxiosRequest(true, params);
    content = content.ObjType !== undefined ? content : content.content;
    return content === undefined ? "" : content;
    AxiosRequest(true, params).then((res) => {
      tokenProcessingTest(res, { func: HandleStatusChange });
    });
  };

  function GetMenu(commands, layout) {
    // GetMenuStatus().then((statuses) => {
    //   GetMenuState().then((state) => {
    //     return DocumentLayout.Tools.Menu;
    //   });
    // });
    let status,
      obj,
      state,
      finalArr = [];
    status = commands.status ? GetMenuSt("GetStatusList") : [];
    state = commands.state ? GetMenuSt("GetMenuState") : [];

    // if (status.length > 0)
    if (layout.Tools && layout.Tools.Menu)
      // finalArr.push({ Caption: "Статус документа", items: { ...status } });
      // if (state.length > 0) finalArr.push(state);
      finalArr = [...finalArr, ...layout.Tools.Menu];
    obj = {
      arr: finalArr,
      state: state,
      status: { Caption: "Статус документа", items: { ...status } },
    };
    if (!commands.status) {
      delete obj.status;
    }
    return obj;
  }

  // function GetMenuState() {
  //   const params = DocumentRequest("GetMenuState");
  //   // AxiosRequest(true, params).then((resp) => {
  //   //   return resp;
  //   // });
  //   return XMLrequest(params);
  // }
  function GetMenuSt(comand) {
    const params = DocumentRequest(comand);
    // AxiosRequest(true, params).then((resp) => {
    //   return resp;
    // });
    return XMLrequest(params);
  }

  async function ChangeStatus(status) {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", "ChangeStatus");
    params.set("Status", status);
    if (MainGridRef.current.grid.getMultiSelect().length > 0) {
      const selection = await CheckMultiSelect(MainGridRef.current.grid);
      params.set("DocID", selection);
    } else {
      params.set("DocID", MainGridRef.current.grid.getActiveSource().getFieldTextSync("ID"));
    }
    params.set("DocCfgID", props.DocCfgID);
    params.set("SectionID", props.id);
    params.set("WSM", "1");
    if (MainGridRef.current.grid.getActiveSource().isEditMode()) {
      await MainGridRef.current.grid.postExicstedEditRec(); // todo
    }
    tokenProcessingTest(XMLrequest(params), {
      func: HandleStatusChange,
      from: "grid",
    });
    // AxiosRequest(true, params).then((res) => {
    //   tokenProcessingTest(res, { func: HandleStatusChange });
    // });
  }

  async function HandleStatusChange(json) {
    if (json.Result === "1") {
      MainGridRef.current.grid.refreshSource("!");
      if (
        (MainGridRef.current &&
          MainGridRef.current.grid &&
          MainGridRef.current.grid.source.details &&
          MainGridRef.current.grid.source.details.detailsBool === false) ||
        !detailsBool
      ) {
        const selectedIndex = Number(TabsRef.current.nativeElement.getAttribute("selected-index"));
        const CurrentTabContent = TabsRef.current.nativeElement.getTabContent(selectedIndex); //берем текущий контент вкладки деталзации
        if (!CurrentTabContent) return;
        await UpdDetails(selectedIndex, CurrentTabContent.DocumentLayoutDetails);
      }
    }
    await MainGridRef.current.grid.getActiveSource().getFieldText("ID");
    setCanModifyObj({
      delete: !(await MainGridRef.current.grid.canModify(2, undefined, MainGridRef.current.grid)),
      insert: !(await MainGridRef.current.grid.canModify(0, undefined, MainGridRef.current.grid)),
    });
  }

  const showParams = (data) => {
    if (data) {
      if (data.Params === undefined || data.Params.Items === undefined) {
        return false;
      }
      if (data.Params.Items.length === 0) {
        return false;
      }
      if (data.Params.Items.length === 1 && data.Params.Items[0].JSONType !== undefined) {
        return false;
      }
      if (data.HasTree === "1") return false;
    }
    return true;
  };

  const handleDrop = async function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const files = Array.from(e.target.files);
      const filesNames = files.map((file) => file.name);
      const filesData = await Promise.all(files.map((file) => getBase64ByFileReader(file)));
      const finalData = filesData.reduce(
        (acc, rcdata, index) => ({
          ...acc,
          [files[index].name]: {
            size: files[index].size,
            rcdata,
          },
        }),
        {},
      );
      attachFiles(filesNames, e.currentTarget.grid, finalData);
    }
  };

  function attachFiles(filesNames, grid, dataOfFiles) {
    let params = new Map();

    params
      .set("prefix", "documents")
      .set("comand", "AttachFiles")
      .set("DocCfgID", grid.doccfgid === "0" ? props.DocCfgID : grid.doccfgid)
      .set("SectionID", props.id)
      .set("DocID", grid.getActiveSource().getFieldTextSync("ID"))
      .set("WSM", "1");
    if (grid.getActiveSource().Master && grid.getActiveSource().Master.MasterID) {
      params.set("MasterID", grid.getActiveSource().Master.MasterID);
    }
    AxiosRequest(true, params, { Files: filesNames }).then((answer) => {
      tokenProcessingTest(answer, {
        from: "grid",
        func: (data) => {},
        props: {
          files: dataOfFiles,
        },
      });
    });
  }

  /*

  <Result Module="documents" Token="RefreshDocument" Break="1">
    <Params DocCfgID="3008" SectionID="-1" RequestID="32952"/>
  </Result>

  */
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
  };

  const onKeyDown = async (e) => {
    if (CheckModalBackGrounds() > 0) {
      e.stopPropagation();
      return;
    }
    // if (checkShortCuts(e)) {
    //   e.preventDefault();
    //   e.stopPropagation();
    //   return;
    // }
    // console.log(e);
    switch (e.code) {
      case "KeyS": {
        if (
          (e.ctrlKey && !isEmptyObject(saveDocs.current) && !isEmptyObject(saveDocs.current[props.DocCfgID])) ||
          (redactor && e.ctrlKey)
        ) {
          e.preventDefault();
          if (!!redactor) {
            await redactor.postExicstedEditRec();
            if (props.setRedactor) {
              props.setRedactor(false);
            } else {
              setRedactor(false);
            }
          }
          await CommitSaveOrCancelChanges();
        } else {
          e.stopPropagation();
        }
        break;
      }
      case "KeyD":
        if (e.ctrlKey) {
          e.preventDefault();
          const activeElement = document.activeElement;
          activeElement.blur();
          setTimeout(() => {
            applyButton.current.click();
            if (activeElement && activeElement.tagName === "INPUT") {
              activeElement.focus();
              activeElement.select();
            }
          }, 100);
        } else {
          e.stopPropagation();
        }
        break;
      case "Enter": {
        if (e.shiftKey && MainGridRef.current) {
          e.preventDefault();
          ContextMenuHandler({ value: "Settings" }, MainGridRef.current);
        } else {
          e.stopPropagation();
        }
        break;
      }
      case "Tab": {
        if (e.ctrlKey === false && e.shiftKey === false) {
          e.preventDefault();
        }
        break;
      }
      default:
        if (checkShortCuts(e)) {
          e.preventDefault();
        } else {
          e.stopPropagation();
        }
    }
  };

  function setLastFocus() {
    focusedGridRef.current.focus();
  }

  function checkShortCuts(e) {
    if (!isEmptyObject(shortsCutsData)) {
      let keyArr = [];
      if (e.ctrlKey) {
        keyArr.push(17);
      } else if (e.shiftKey) {
      }
      keyArr.push(e.keyCode);
      if (shortsCutsData.hasOwnProperty(keyArr)) {
        e.preventDefault();
        // e.stopPropagation();
        const shortCutElem = shortsCutsData[keyArr];
        if (shortCutElem && shortCutElem.ID) {
          const grid =
            shortCutElem.localdoccfgid === props.DocCfgID
              ? MainGridRef.current
              : me.current.querySelector("gridpanel" + shortCutElem.localdoccfgid);
          HandleToolButton(
            {
              currentTarget: {
                id: shortCutElem.ID,
                doccfgid: shortCutElem.localdoccfgid,
              },
            },
            grid,
          );
          return true;
        } else return false;
      } else return false;
    } else return false;
  }

  return (
    <div style={{ display: "contents" }} tabIndex={"-1"} onKeyDown={onKeyDown} ref={me} id={"Documents" + props.id}>
      {portal}
      <Grid style={{ height: "100%" }} container direction="column" justifyContent="flex-start" alignItems="flex-start">
        {props.SectionToolsJS === true && <SectionToolsJS ID={props.id} />}
        <SplitterLocal
          splitterRef={leftSplitterRef}
          style={{
            width: "100%",
            borderTop: "1px solid rgb(208, 208, 208)",
            flex: "1 1",
          }}
          orientation="vertical"
          onExpand={onExpand}
          onCollapse={onCollapse}
          onResizeEnd={onResizeEnd}
          sizeSecondary={"20%"}
          defaultCollapsed={props.collapsedParams !== undefined ? props.collapsedParams : false}
          primaryIndex={1}
          blockResize={false}
        >
          {!props.HideParams && showParams(DocumentLayout) ? (
            <div id={"item_Param_" + props.id} style={{ height: "100%" }}>
              <div
                style={{
                  height: `calc(100% - 37px)`,
                  // overflow: "auto",
                }}
                id={"ParamsDoc" + props.id}
              >
                {ReturnParams(DocumentLayout)}
              </div>

              <Button
                id="applyButton"
                ref={applyButton}
                style={{
                  marginBottom: "3px",
                  marginTop: "3px",
                  width: "126px",
                  height: "31px",
                  textTransform: "none",
                  marginLeft: "6px",
                }}
                variant="outlined"
                onClick={ApplyButtonClick}
                className={classNames("buttonStimate", {
                  light: getTheme() === "light",
                })}
              >
                Применить
              </Button>
            </div>
          ) : null}
          {DocumentLayout !== undefined && DocumentLayout.HasTree === "1" ? (
            <div id={"item_Param_" + props.id} style={{ height: "100%" }}>
              <div
                style={{
                  height: `100%`,
                  overflow: "auto",
                }}
                id={"ParamsDoc" + props.id}
              >
                {tree}
              </div>
            </div>
          ) : null}
          <div id={"item_Grid_" + props.id} style={{ height: "100%", width: "100%" }}>
            {DocumentLayout === undefined ? <LoadingMask /> : null}
            {props.HideControlButtons ? null : (
              <div style={{ height: "36px", width: "calc(100% - 0px)" }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  sx={{ maxHeight: "36px" }}
                  className={cn("backgroundColorStimate", {
                    light: getTheme() === "light",
                  })}
                >
                  <Grid item sx={{ height: "36px", ml: 1, width: "calc(100% - 170px)" }}>
                    <ButtonsForGridComponent
                      onClick={ControlButtonClick}
                      id={props.id}
                      grid={
                        MainGridRef.current && MainGridRef.current.grid
                          ? {
                              grid: MainGridRef.current.grid,
                              source: MainGridRef.current.grid.source,
                            }
                          : undefined
                      }
                      theme={getTheme()}
                      save={save[props.DocCfgID]}
                      redactor={redactor}
                      canModifyObj={canModifyObj}
                      selectedRecord={selectedRecordID}
                      buttons={controlButtons}
                      buttonsState={controlButtonsState}
                      DisableDetails={DocumentLayout !== undefined && isEmptyObject(DocumentLayout.Details)}
                    />
                    {samples.length > 0 ? (
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && anchorEl.id === "appendButtonForGrid"}
                        onClose={handleCloseAnchorEl}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                      >
                        {Boolean(anchorEl) && anchorEl.id === "appendButtonForGrid"
                          ? MenuItems([...samples, { Caption: "-" }, { Name: "Новый документ" }], sampleMenuClick)
                          : null}
                      </Menu>
                    ) : null}

                    <Menu
                      anchorEl={anchorElButton}
                      open={Boolean(anchorElButton)}
                      onClose={handleCloseAnchorEl}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {DocumentLayout && DocumentLayout.Tools.Buttons && Boolean(anchorElButton)
                        ? MenuItems(
                            DocumentLayout.Tools.Buttons[anchorElButton.getAttribute("index")].items,
                            HandleToolButton,
                            GetMenuSt("GetMenuState"),
                            GetDisable,
                            {
                              doccfgid: props.doccfgid,
                            },
                          )
                        : null}
                    </Menu>
                  </Grid>
                  <Grid item sx={{ height: "100%", ml: 1 }}>
                    {DocumentLayout !== undefined && DocumentLayout.Forms.length > 0 ? (
                      <>
                        <Button
                          variant="outlined"
                          onClick={SelectViewOfPaperForm}
                          id={"FormsButton" + props.id}
                          formid={DocumentLayout.Forms[0].ID}
                          style={{
                            borderRadius: "15px",
                            height: "20px",
                            textTransform: "none",
                          }}
                          className={classNames("buttonStimate", {
                            light: getTheme() === "light",
                          })}
                        >
                          Печатный вид
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && anchorEl.id === "FormsButton" + props.id}
                          onClose={handleCloseAnchorEl}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          {DocumentLayout.Forms.length > 1
                            ? MenuItems(DocumentLayout.Forms, PaperForm, undefined, GetDisable, {
                                doccfgid: props.doccfgid,
                              })
                            : null}
                        </Menu>
                      </>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
            )}
            <Grid
              style={{
                height: `calc(100% - ${props.HideControlButtons ? "0px" : "36px"})`,
                width: "calc(100% - 5px)",
                display: selectedView === "paper" ? "" : "none",
              }}
              id={"PaperFormDocuments" + props.id}
            >
              <div style={{ overflow: "hidden", height: "100%" }}>
                <TabsForReports reportData={PaperFormData} ID={props.id} />
              </div>
            </Grid>

            <SplitterLocal
              style={{
                height: `calc(100% - ${props.HideControlButtons ? "0px" : "36px"})`,
                width: "calc(100% - 5px)",
                display: selectedView === "doc" || selectedView === "card" ? "" : "none",
              }}
              orientation="horizontal"
              onExpand={() => {
                ChangeCollapsedDetails(false);
              }}
              onCollapse={() => {
                ChangeCollapsedDetails(true);
              }}
              onResizeEnd={onResizeEnd}
              sizeSecondary={"40%"}
              collapsed={detailsBool}
              id={"MainSplitterOfDocumentsGrid" + props.id}
              blockResize={props.HideDetails}
              hideSplitter={DocumentLayout !== undefined && isEmptyObject(DocumentLayout.Details)}
            >
              <div id={"DocumentsSplitter" + props.id}>
                <ContextMenu
                  for={"grid"}
                  data={MenuJson}
                  onRightClick={() => {
                    return GetMenu(
                      {
                        state: true,
                        status: DocumentLayout !== undefined && DocumentLayout.HasStatus === "1" ? true : false,
                      },
                      DocumentLayout,
                    );
                  }}
                  onMenuItemClick={ContextMenuHandler}
                  docCfgID={props.DocCfgID}
                  id={props.id}
                  Details={props.HideControlButtons}
                >
                  {/* {!test ? ( */}
                  <LoadingMask bool={!loadMain} />
                  <div
                    id={"gridpanel" + props.DocCfgID}
                    ref={MainGridRef}
                    onFocus={onFocusChange}
                    tabIndex={"0"}
                    style={{
                      height: "100%",
                      width: "100%",
                      display: test ? "none" : "",
                    }}
                    rcsgrid_panel=""
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                  {/* ) : (
                    testSas()
                  )} */}
                </ContextMenu>
              </div>
              <div id={"Details" + props.id}>
                <div key={"tabsDocumentDetailsDiv" + props.id} style={{ height: "100%" }}>
                  <ContextMenu
                    for={"grid"}
                    onRightClick={() => {
                      return GetMenu({ state: true }, DocumentLayoutDetails);
                    }}
                    onMenuItemClick={ContextMenuHandler}
                    id={props.id}
                    data={{
                      Table: [
                        {
                          Caption: "Печать отмеченных записей",
                          value: "PrintSelectedRecords",
                        },
                      ],
                    }}
                  >
                    <LoadingMask bool={!loadDetails} />
                    <Tabs
                      scrollMode="paging"
                      key={"tabsDocumentDetails" + props.id}
                      id={"tabsDocumentDetails" + props.id}
                      animation="none"
                      ref={TabsRef}
                      tabPosition="bottom"
                      style={{
                        position: "absolute",
                        borderBottom: "0px",
                        height: "100%",
                        width: "100%",
                      }}
                      onChange={onTabChange}
                    >
                      {tabs.current}
                    </Tabs>
                  </ContextMenu>
                </div>
              </div>
            </SplitterLocal>
          </div>
        </SplitterLocal>
      </Grid>
    </div>
  );
}
