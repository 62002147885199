import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, { useRef, useState } from "react";
import ContextMenu from "../../../NotWorkArea(Side&Head)/ContextMenu";
import { GridLoader } from "../../../rcsgrid/createGridTools";
import { closeModal } from "../../../Tools/modalManager";

export default function Samples({ request, list }) {
  // const [list,setList]
  const records = useRef(getRecords(list.items));
  const [value, setValue] = useState("Новый шаблон");
  const [checkBoxes, setCheckBoxes] = useState({
    AllUsers: false,
    All: false,
  });
  const SamplesGridRef = React.useRef(null);
  React.useEffect(() => {
    // setTimeout(() => {
    if (!SamplesGridRef.current.grid)
      GridLoader(
        SamplesGridRef.current,
        {},
        {
          defaultFields: getDefaultFieldsSigns(),
          keyField: "ID",
          getRecords: () => records.current,
          recordKeys: ["ID", "Index", "Name", "User", "Section"],
          titleKey: "",
          localGrid: true,
          Columns: setCollumsForGridSigns(),
          indicator: false,
          canModify: () => {
            return false;
          },
        },
      ).then((grid) => {
        console.log(grid);
      });
    // }, 2000);
  }, []);

  function getRecords(data) {
    let arrRecords = [],
      dataFix = data ? data : [];
    dataFix.forEach((value, index) => {
      arrRecords.push({
        ID: value.ID,
        Index: `${index}`,
        Name: value.Name,
        User: value.User,
        Section: value.Section,
      });
    });
    return arrRecords;
  }

  function setCollumsForGridSigns() {
    const Columns = {
      title: [
        { title: "Наименования", width: 183, fieldName: "Name" },
        { title: "Пользователь", width: 197, fieldName: "User" },
        { title: "Рабочее место", width: 180, fieldName: "Section" },
      ],
    };
    return Columns;
  }

  function createGrid(refresh) {}

  function getDefaultFieldsSigns() {
    return [
      {
        Name: "ID",
        Title: "ID",
        Width: "7",
      },
      {
        Name: "Index",
        Title: "Индекс",
        Width: "7",
      },
      {
        Name: "Name",
        Title: "Время подписи",
        Width: "10",
      },
      {
        Name: "User",
        Title: "Пользователь",
        Width: "10",
      },
      {
        Name: "Section",
        Title: "Описание",
        Width: "10",
      },
    ];
  }

  function onValueChange(e) {
    setValue(e.currentTarget.value);
  }

  const onCheckChange = (e) => {
    const key = e.currentTarget.id;
    if (key) {
      setCheckBoxes({
        ...checkBoxes,
        [key]: !checkBoxes[key],
      });
    }
  };

  function ContextMenuHandler(e) {
    const Name = SamplesGridRef.current.grid.source.getFieldTextSync("Name");
    if (Name) {
      switch (e.value) {
        case "delete": {
          const User = SamplesGridRef.current.grid.source.getFieldTextSync("User");
          const Section = SamplesGridRef.current.grid.source.getFieldTextSync("Section");
          request("delete", { Name, User, Section }).then(() => {
            request("list").then((list) => {
              records.current = getRecords(list.items);
              if (SamplesGridRef.current.grid) SamplesGridRef.current.grid.refreshSource("!");
            });
          });
          break;
        }
        case "change": {
          setValue(Name);
          break;
        }
      }
    }
  }

  return (
    <Grid2
      container
      sx={{
        flexDirection: "column",
        alignItems: "stretch",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Grid2 size={{ xs: 6, md: 8 }}>
        <Grid2 container spacing={1} sx={{ width: "100%", flexWrap: "nowrap", alignItems: "center" }}>
          <Grid2 size={{ xs: 8, md: 8 }}>
            <input type="text" style={{ width: "100%", marginTop: "12px" }} value={value} onChange={onValueChange} />
            <FormControlLabel
              onChange={(e) => {
                onCheckChange(e);
              }}
              id="AllUsers"
              control={<Checkbox id="AllUsers" checked={checkBoxes.AllUsers} />}
              sx={{ height: "30px", width: "100%" }}
              label="Для всех пользователей"
            />
            <FormControlLabel
              onChange={(e) => {
                onCheckChange(e);
              }}
              id="All"
              sx={{ height: "30px", width: "100%" }}
              control={<Checkbox id="All" checked={checkBoxes.All} />}
              label="Для всех рабочих мест"
            />
          </Grid2>
          <Grid2
            container
            // size={{ xs: 4, md: 4 }}
            // spacing={1}
            sx={{ alignItems: "stretch" }}
          >
            <Grid2 sx={{ width: "100%" }}>
              <Button
                size="small"
                style={{ textTransform: "none", width: "100%" }}
                variant="outlined"
                // className="button_Modal_Select"
                value={"1"}
                onClick={() => {
                  request("create", {
                    Name: value,
                    AllUsers: checkBoxes.AllUsers ? "1" : "0",
                    All: checkBoxes.All ? "1" : "0",
                  });
                  closeModal();
                }}
              >
                Сохранить
              </Button>
            </Grid2>
            <Grid2 sx={{ width: "100%" }}>
              <Button
                size="small"
                style={{ textTransform: "none", width: "100%" }}
                variant="outlined"
                // className="button_Modal_Select"
                value={"1"}
                onClick={() => {
                  closeModal();
                }}
              >
                Закрыть
              </Button>
            </Grid2>
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        size={{ xs: 6, md: 4 }}
        style={{
          position: "relative",
          height: "74%",
          border: "1px solid #9A9A9A",
        }}
      >
        {/* <div style={{ position: "relative", height: "100%" }}> */}
        <ContextMenu
          onMenuItemClick={ContextMenuHandler}
          data={[
            { Caption: "Удалить шаблон", value: "delete" },
            { Caption: "Заменить шаблон", value: "change" },
          ]}
        >
          <div
            rcspanel=""
            ref={SamplesGridRef}
            tabIndex={"0"}
            style={
              {
                // height: "100%",
                // width: "100%",
              }
            }
          ></div>
        </ContextMenu>
        {/* </div> */}
      </Grid2>
    </Grid2>
  );
}
