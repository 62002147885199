export function createUrlParams(data, push) {
  let href = window.location.href;
  for (const [key, value] of Object.entries(data)) {
    switch (key) {
      case "id": {
        href = updateURLParameter(href, "secid", value);
        break;
      }
      case "view": {
        href = updateURLParameter(href, "view", value);
        break;
      }
      case "doc": {
        href = updateURLParameter(href, "doccfgid", value);
        break;
      }
      case "report": {
        href = updateURLParameter(href, "reportid", value);
        break;
      }
      case "docid": {
        href = updateURLParameter(href, "docid", value);
        break;
      }
    }
  }
  //   console.log(href);
  if (push) window.history.pushState(data, data.title, href);
  else window.history.replaceState({ ...window.history.state, ...data }, window.history.state.title, href);
}

function updateURLParameter(url, param, paramVal) {
  var newAdditionalURL = "";
  var tempArray = url.split("?");
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] != param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  var rows_txt = temp + "" + param + "=" + paramVal;
  return baseURL + "?" + newAdditionalURL + rows_txt;
}

export function getUrlSearh() {
  let search = window.location.search;
  if (window.location.hash.includes("#/")) {
    const hash = window.location.hash.match(/\?.+/);
    if (hash !== null) search = hash[0];
  }
  return search;
}
