// 2023-07-12
"use strict";

import ReactDOM from "react-dom";
import TestEditor from "../../Editor/testEditor";
import { tokenProcessingTest } from "../../TokenProcessing/TokenProcessing";
import { AxiosRequest, GetOptionsForUser, ImgURLSRC } from "../../Url";
import DialogSetObjText from "../Module/Dialogs/DialogSetObjText";
import { getIcons, getIconsAsync, getStyleWindow, isEmptyObject, setColor, throttle } from "../Tools/Tools";
import { getImageHint } from "../Sections/ElementsSections/SectionDocuments/SectionDocuments";
import { EditColumnField, GetColumnDetails, GetColumnHintDocs, GetDocDialogParams } from "../Tools/Requests";
import ColumnButton from "./ColumnButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterGridComponent from "./GridTools/FilterGridComponent";
import queuedGridScroll from "./queuedGridScroll";
import { isCollapsibleRecord, MarginRecordsClassifires } from "./GridTools/collapsibleRecordsTools";
import StatusButtonCardMode from "./GridTools/StatusButtonCardMode";
import CheckBoxMultiCheck from "../Sections/ElementsSections/CheckBoxMultiCheck";
import { openModal } from "../Tools/modalManager";
import CardModeButton from "./CardModeButton";
import DialogCreateObj from "../Module/Dialogs/DialogCreateObj";

// import fontAwesome from "../../../static/fonts/fontawesome/FontAwesome.ttf"

function appendEvent(e, f, el) {
  if (el) {
    el.addEventListener(e, f);
  } else {
    document.addEventListener(e, f);
  }
}

function deleteEvent(e, f, el) {
  if (el) {
    el.removeEventListener(e, f);
  } else {
    document.removeEventListener(e, f);
  }
}

function showApplicationMask(parent, text) {
  if (parent) {
    const maskbox = document.createElement("div");
    maskbox.setAttribute("class", "login-form-mask-box");
    maskbox.setAttribute("id", "mask-box");
    parent.appendChild(maskbox);

    const maskicon = document.createElement("img");
    maskicon.setAttribute("class", "login-form-mask-icon");
    maskbox.appendChild(maskicon);

    const maskboxtext = document.createElement("div");
    maskboxtext.setAttribute("class", "login-form-mask-text");
    maskboxtext.innerText = !text ? "Загрузка данных" : text;
    maskbox.appendChild(maskboxtext);
  }
}
function hideApplicationMask(parent) {
  if (parent) {
    const el = document.getElementById("mask-box");
    if (el) el.remove();
  }
}

function delayCall(f) {
  setTimeout(() => {
    f();
  }, 1);
}

function minint(x, y) {
  if (x < y) return x;
  else return y;
}

function maxint(x, y) {
  if (x > y) return x;
  else return y;
}

function midint(min, max, val) {
  return maxint(min, minint(max, val));
}

function intInRange(int, min, max) {
  return int >= min && int <= max;
}

function showElement(el) {
  el.style.display = "";
}

function hideElement(el) {
  el.style.display = "none";
}

function log(info) {
  //document.getElementById('btnOpen').innerText = info;
}

function moddiv(baseval, divider) {
  if (divider > 0) {
    return (baseval - (baseval % divider)) / divider;
  } else return 0;
}

var // Статус записи
  STATUS_GROUPED = 32,
  STATUS_COLLAPSED = 128,
  // Состояние источника
  dsInactive = 0,
  dsBrowse = 1,
  dsEdit = 2,
  dsInsert = 3,
  // Состояние записи
  usUnmodified = 0,
  usModified = 1,
  usInserted = 2,
  usDeleted = 3;

export function createRecordSource() {
  var me = this,
    points = new Map(),
    state = {},
    bof = true,
    eof = true,
    recordIndex = -1,
    indexFields = "",
    groupFields = "",
    editRec = null,
    gen = 0,
    KeyFieldName = "",
    changes = [],
    gridStateChanged = false,
    savecurrentvalues = {};
  // public properties
  me.recordCount = 0;
  me.activeRecord = 0;
  me.fields = [];
  me.modified = false;
  me.active = false;
  me.defaultFields = false;
  me.InsertFlag = 0;
  me.acquireFields = acquireFields;
  me.Master = {
    isEmpty: false,
  };
  me.onHandleRequest = null;
  me.onRecordCollapsed = null;
  me.CreateCollapsibleRecords = null;
  me.updatesPending = false;
  me.selection = "";
  me.NeedToSave = false;
  me.rights = {
    modify: false,
    insert: false,
    delete: false,
    accsess: false,
  };
  me.actual = false;

  async function handleRequest(request, loadCursor, wsm) {
    if (me.onHandleRequest) {
      var json = await me.onHandleRequest(request, loadCursor, wsm);
      if (json) {
        me.updatesPending = json.UpdatesPending === "1";
        me.actual = json.Actual === "1";
      }
      // if (json.UpdatesPending === "1") {
      me.ChangeSave();
      // }
      return json;
    }
  }

  me.saveState = function () {
    if (gridStateChanged) {
      gridStateChanged = false;
      return state;
    }
  };

  me.getKeyFieldName = function () {
    return KeyFieldName;
  };

  me.setKeyFieldName = function (key) {
    KeyFieldName = key;
  };

  function acquireFields() {
    if (me.active) return;
    var request = {};
    // ,json;
    request.command = "getFields";
    if (indexFields) {
      request.indexFields = indexFields;
      indexFields = "";
    }
    if (groupFields) {
      request.groupFields = groupFields;
      groupFields = "";
    }
    // json =
    return handleRequest(request, true).then((json) => {
      if (json && json.Fields) {
        me.fields = [];
        let sourField,
          destField,
          fields = json.Fields;
        for (var i = 0; i < fields.length; i++) {
          sourField = fields[i];
          if (sourField.Name) {
            destField = [];
            destField.fieldName = sourField.Name;
            if (sourField.Title) destField.displayLabel = sourField.Title;
            else destField.displayLabel = destField.fieldName;
            if (sourField.Width) destField.displayWidth = sourField.Width;
            else destField.displayWidth = 10;
            if (sourField.Type) destField.dataType = sourField.Type;
            else destField.dataType = 1;
            if (sourField.Size) destField.dataSize = sourField.Size;
            else sourField.dataSize = 0;
            if (sourField.Alignment) destField.alignment = sourField.Alignment;
            else destField.alignment = 0;
            if (sourField.Values) {
              //todo
              destField.list = Object.values(sourField.Values);
            } else destField.list = [];
            me.fields.push(destField);
          }
        }
        if (json.RecordCount) me.recordCount = parseInt(json.RecordCount);
        else me.recordCount = 0;
        if (json.Selection) me.selection = json.Selection;
        if (json.KeyFieldName) KeyFieldName = json.KeyFieldName;
        if (json.DisableInsert) me.InsertFlag = Number(json.DisableInsert);
      }
    });
  }

  function checkScrollRecord() {}
  function checkRecordUpdates() {}
  async function recordIndexChanged() {
    if (me.onRecordIndexChanged) {
      await me.getFieldText("ID");
      // await me.acquireRecords(me.activeRecord, 1);
      await me.onRecordIndexChanged(me);
      recordIndex = me.activeRecord;
    }
    sourceStateChanged();
  }
  me.ChangeSave = function (bool) {
    // if (me.updatesPending === bool) return;
    // me.NeedToSave = bool;
    onNeedToSaveChange();
  };
  function onNeedToSaveChange() {
    me.onNeedToSaveChanged && me.onNeedToSaveChanged(me);
  }
  function sourceStateChanged() {}
  function recordStateChanged() {}
  async function createNewRecord(info, subData) {
    return new Promise(async (resolve) => {
      let request = {},
        json,
        fields;
      request.command = "CreateRecord";
      // request.recordIndex = me.activeRecord;
      if (info) request.info = info;
      if (subData) {
        for (let [key, value] of Object.entries(subData)) {
          request[key] = value;
        }
      }
      // json =
      json = await handleRequest(request, true);
      if (json && json.RecordSet) {
        fields = json.RecordSet.Attributes;
        if (fields) {
          let records = json.RecordSet.Records;
          if (records && records.length > 0) {
            editRec = { vals: [], info: {} };
            editRec.insert = true;
            editRec.active = me.activeRecord;
            loadRecordValues(editRec, records[0], fields);
            //todo: EditFields = fields.commaText;
            resolve(true);
          }
        }
      } else {
        resolve();
      }
    });
  }

  me.saveCurrentMarkedValues = async function (field) {
    if (field.marked) {
      let id = await me.getFieldValue(field.fieldName),
        text = await me.getFieldText(field.fieldName);
      savecurrentvalues = {
        ...savecurrentvalues,
        [field.fieldName]: {
          id: id === undefined ? "" : id,
          text: text === undefined ? "" : text,
        },
      };
    } else {
      delete savecurrentvalues[field.fieldName];
    }
  };

  me.updateCurrentMarkedValues = async function () {
    for (const key of Object.keys(savecurrentvalues)) {
      // const fieldName = me.fields[key].fieldName;
      const fieldName = key;
      let id = await me.getFieldValue(fieldName),
        text = await me.getFieldText(fieldName);
      savecurrentvalues = {
        ...savecurrentvalues,
        [key]: {
          id: id === undefined ? "" : id,
          text: text === undefined ? "" : text,
        },
      };
    }
  };

  async function fieldHasData(fieldName) {
    let rec = await getRecordNode(me.activeRecord);
    if (rec) {
      return true;
    }
  }

  function loadRecordValues(dest, sour, fields, index) {
    if (dest && sour && fields && fields.length) {
      if (!dest.info) dest.info = {};
      if (sour.UpdateStatus) {
        dest.info.updateStatus = parseInt(sour.UpdateStatus);
      } else {
        dest.info.updateStatus = 0;
      }
      if (sour.RecordLevel) dest.info.recordLevel = parseInt(sour.RecordLevel);
      else dest.info.recordLevel = 0;
      if (sour.RecordState) dest.info.recordState = parseInt(sour.RecordState);
      else dest.info.recordState = 0;
      if (sour.Data$content) dest.info.Data$content = sour.Data$content;
      else dest.info.Data$content = "";
      if (sour.levelsField) dest.info.levelsField = sour.levelsField;
      if (sour.Title) dest.info.title = sour.Title;
      if (sour.LockStatus) dest.info.LockStatus = Number(sour.LockStatus);
      if (sour.RecordTitle) dest.info.recordTitle = sour.RecordTitle;
      else dest.info.recordTitle = "";
      if (sour.Text) dest.info.recordText = sour.Text;
      else dest.info.recordText = "";
      if (sour.Hidden === "1") dest.info.hidden = true;
      else dest.info.hidden = false;
      dest.info.recordIndex = index;
      CheckRecordInfo(dest, fields);
      let data = sour.Data;
      for (let i = 0, fieldName, index, d, v; i < fields.length; i++) {
        fieldName = fields[i];
        index = me.fields.findIndex(function (f) {
          return f.fieldName == fieldName;
        });
        if (savecurrentvalues[fieldName] && dest.insert === true) {
          if (savecurrentvalues[fieldName].id || savecurrentvalues[fieldName].text) {
            data[i] = savecurrentvalues[fieldName];
          } else {
            data[i] = "";
            dest.vals[index] = { id: "", text: "" };
          }
        }
        if (index !== -1) {
          while (dest.vals.length <= index) dest.vals.push({});
          try {
            d = data[i];
            v = dest.vals[index];
            if (d.text || d.id) {
              v.text = d.text ? d.text : "";
              v.id = d.id;
            } else v.text = d;
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  }

  function CheckRecordInfo(dest, attributes) {
    let dataContent = dest.info.Data$content;
    let fixedDataContent = "0".repeat(me.fields.length);
    if (dataContent !== "" && dataContent && me.fields.length > 0 && attributes) {
      for (let i = 1, index; i < me.fields.length; i++) {
        index = me.getFieldIndex(attributes[i - 1]);
        fixedDataContent = fixedDataContent.replaceAt(index + 1, dataContent[Number(i)]);
      }
      dest.info.Data$content = fixedDataContent;
    }
  }

  function saveRecordValues(sour, dest, fields) {
    if (sour && dest && fields) {
      if (sour.vals) {
        let data = dest.$Data;
        if (data) {
          for (let i = 0, index, fieldName, s, d; i < fields.length; i++) {
            fieldName = fields[i];
            index = me.fields.findIndex(function (f) {
              return f.fieldName == fieldName;
            });
            d = {};
            if (intInRange(index, 0, sour.vals.length - 1)) {
              s = sour.vals[index];
              if (s.text === "" && !s.id) {
                //|| isEmptyObject(s.text)
                d = "";
              } else {
                if (s.text || s.id) {
                  d.text = s.text;
                  d.id = s.id;
                } else d.text = "";
              }
            }
            data.push(d);
          }
        }
      }
    }
  }

  async function loadSingleRecord(first, rec, count, load) {
    // return new Promise(async (resolve) => {
    var request = {},
      json,
      attributes,
      records,
      record,
      data;
    request.command = "getRecords";
    request.first = first;
    request.count = count ? count : 1;
    json = await handleRequest(request, load ? load : true);
    if (json && json.RecordSet) {
      attributes = json.RecordSet.Attributes;
      records = json.RecordSet.Records;
      if (attributes && records && records.length > 0) {
        record = records[0];
        loadRecordValues(rec, record, attributes, first);
        return json;
      }
    }
    return;

    // });
  }

  function getRecordNode(index) {
    const promise = new Promise(async (resolve) => {
      if (editRec !== null) {
        if (editRec.index === index) return resolve(editRec);
        if (editRec.insert && index > editRec.index) index--;
      }
      let rec = points.get(index);
      if (!rec) {
        rec = { vals: [], info: {} };
        await loadSingleRecord(index, rec);
        points.set(index, rec);
      }
      return resolve(rec);
    });
    return promise;
  }

  me.acquireRecords = async function (first, count, load) {
    return new Promise(async (resolve) => {
      let rec = { vals: [], info: {} },
        attributes,
        records = [],
        data;
      let First = maxint(first, 0),
        LastRec = minint(First + count - 1, me.recordCount - 1),
        FR = First,
        countToGet = 0;
      while (FR <= LastRec) {
        if (points.get(FR) !== undefined) {
          ++FR;
        } else {
          break;
        }
      }
      for (let i = FR; i <= LastRec; i++) {
        if (points.get(i) === undefined) {
          ++countToGet;
        }
      }
      if (editRec && editRec.insert === true) {
        --countToGet;
      }
      if (countToGet !== 0) {
        data = await loadSingleRecord(FR, rec, countToGet, load);
        if (data && data.RecordSet) {
          attributes = data.RecordSet.Attributes;
          records = data.RecordSet.Records;
          for (const value of Object.values(records)) {
            let rec = { vals: [], info: {} };
            loadRecordValues(rec, value, attributes, FR);
            points.set(FR, rec);
            FR++;
          }
        }
      }
      return resolve(countToGet);
    });
  };

  me.setGridState = function (obj, changed) {
    state = obj;
    if (changed) {
      gridStateChanged = true;
    }
  };

  me.getGridState = function () {
    return state;
  };

  me.open = async function () {
    return new Promise(async (resolve) => {
      if (me.active) return resolve();
      if (me.fields.length == 0) {
        await acquireFields();
        me.defaultFields = me.fields.length > 0;

        // me.defaultFields = me.fields.length > 0;
      } else await me.refresh();
      me.active = true;
      recordIndex = -1;
      me.first();
      sourceStateChanged();
      resolve();
    });
    // me.active = true;
    // recordIndex = -1;
    // me.first();
    // sourceStateChanged();
  };

  me.close = function () {
    if (!me.active) return;
    points.clear();
    if (me.defaultFields) me.fields = [];
    me.active = false;
    sourceStateChanged();
  };

  me.refresh = async function (key, info) {
    const promise = new Promise(async (resolve) => {
      let request = {},
        json;

      if (key == "!") {
        points.clear();
        if (editRec) {
          editRec = null;
          recordStateChanged();
        }
        request.command = "GetRecordCount";
        if (!me.isEmpty()) {
          request.recordIndex = me.activeRecord;
        }
        json = await handleRequest(request, true);
        if (json) {
          if (json.RecordCount !== undefined) me.recordCount = parseInt(json.RecordCount);
          else me.recordCount = 0;
          if (json.RecordIndex !== undefined) {
            me.activeRecord = parseInt(json.RecordIndex);
          }
        }
        return resolve(json);
      } else {
        checkRecordUpdates();
        request.command = "refresh";
        if (key) request.key = key;
        if (info) request.info = info;
        json = await handleRequest(request, true);
        if (json) {
          recordIndex = -1;
          if (json.RecordCount !== undefined) me.recordCount = parseInt(json.RecordCount);
          else me.recordCount = 0;
          points.clear();
          if (editRec) {
            editRec = null;
            recordStateChanged();
          }
          if (json.DisableInsert !== undefined) {
            me.InsertFlag = Number(json.DisableInsert);
          }
          if (json.RecordIndex !== undefined) {
            me.activeRecord = parseInt(json.RecordIndex);
          }
          if (json.RecordIndex === undefined && json.key) {
            me.activeRecord = parseInt(json.key.replace("!", ""));
          }
        }
        return resolve(json);
      }
    });

    return promise;
  };

  me.getRecordData = async function (recIndex) {
    var rec = await getRecordNode(recIndex !== undefined ? recIndex : me.activeRecord);

    return rec;
  };

  me.getRecordState = async function () {
    var rec = await getRecordNode(me.activeRecord);
    if (rec.info) {
      return rec.info.recordState;
    }
    return 0;
  };

  me.getRecordInfo = function () {
    let rec = {},
      index = Number(me.activeRecord);
    // = await getRecordNode(me.activeRecord);
    if (editRec) {
      if (editRec.index === index) rec = editRec;
      if (editRec.insert && index > editRec.index) index--;
    } else {
      rec = points.get(index);
    }
    try {
      if (rec.info) {
        return rec.info;
      }
      return {};
    } catch {
      return "";
    }
  };

  me.getRecordID = async function (id) {
    var rec = await getRecordNode(id);
    if (rec) return rec.info.recordIndex;
  };

  me.getRecordTitle = async function () {
    var rec = await getRecordNode(me.activeRecord);
    if (rec.info) {
      return rec.info.recordTitle;
    } else return "";
  };

  me.getRecordLevel = async function () {
    var rec = await getRecordNode(me.activeRecord);
    if (rec.info) {
      return rec.info.recordLevel ? rec.info.recordLevel : 0;
    } else return 0;
  };

  me.getFieldText = async function (fieldName, recordIndex) {
    const index = me.fields.findIndex(function (f) {
      return f.fieldName === fieldName;
    });

    if (index == -1) {
      return "";
    }
    const rec = await getRecordNode(recordIndex !== undefined ? recordIndex : me.activeRecord);

    if (!rec || !rec.vals || !rec.vals[index]) return "";
    return rec.vals.length !== 0 ? rec.vals[index].text : "";
  };

  me.getFieldTextSync = function (fieldName, recordIndex) {
    let index = me.fields.findIndex(function (f) {
      return f.fieldName === fieldName;
    });
    let rec = "";
    if (index == -1) {
      return "";
    }
    if (editRec) {
      if (editRec.index === index) rec = editRec;
      if (editRec.insert && index > editRec.index) index--;
    } else {
      rec = points.get(recordIndex !== undefined ? recordIndex : me.activeRecord);
    }
    if (!rec || !rec.vals || !rec.vals[index]) return "";
    return rec.vals.length !== 0 ? rec.vals[index].text : "";
  };

  me.getFieldValueSync = function (fieldName, recordIndex) {
    let index = me.fields.findIndex(function (f) {
      return f.fieldName === fieldName;
    });
    let rec = "";
    if (index == -1) {
      return "";
    }
    if (editRec) {
      if (editRec.index === index) rec = editRec;
      if (editRec.insert && index > editRec.index) index--;
    } else {
      rec = points.get(recordIndex ? recordIndex : me.activeRecord);
    }
    if (!rec || !rec.vals || !rec.vals[index]) return "";
    return rec.vals.length !== 0 ? rec.vals[index].id : "";
  };

  me.findField = function (fieldName) {
    let isExits = false;
    me.fields.filter((fieldInfo) => {
      if (fieldInfo.fieldName === fieldName) isExits = true;
    });
    return isExits;
  };

  me.setFieldText = async function (fieldName, value) {
    var rec = await getRecordNode(me.activeRecord);
    if (rec) {
      var index = me.fields.findIndex(function (f) {
        return f.fieldName === fieldName;
      });
      if (index !== -1) {
        while (rec.vals.length <= index) rec.vals.push({});
        let v = rec.vals[index];
        v.text = value;
      }
      me.setFieldModified(fieldName, true);
      me.modified = true;
    }
  };

  me.setFieldValue = async function (fieldName, value) {
    var rec = await getRecordNode(me.activeRecord);
    if (rec) {
      var index = me.fields.findIndex(function (f) {
        return f.fieldName === fieldName;
      });
      if (index !== -1) {
        while (rec.vals.length <= index) rec.vals.push({});
        let v = rec.vals[index];
        v.id = value;
      }
      me.setFieldModified(fieldName, true);
    }
  };

  me.getFieldValue = async function (fieldName, recordIndex) {
    var index = me.fields.findIndex(function (f) {
      return f.fieldName === fieldName;
    });

    if (index == -1) {
      return "";
    }

    var rec = await getRecordNode(recordIndex || me.activeRecord);

    if (!rec || !rec.vals || !rec.vals[index]) return;
    return rec.vals.length !== 0 && rec.vals[index].id ? rec.vals[index].id : undefined;
  };

  me.getActiveRecordText = async function () {
    var rec = await getRecordNode(me.activeRecord);
    return { text: rec.info.recordText, id: me.activeRecord };
  };

  // me.setActiveRecordText = function (id, text) {
  //   var rec = getRecordNode(id);
  //   rec.info.recordText = text;
  // };

  // me.searchRecord = function(objref) {
  //     for (let i=0;i<this.recordCount;i++){
  //         var rec = getRecordNode(i)
  //        if (rec!==undefined && rec.vals[1].id == objref)
  //         return i;
  //     }
  //     return 0;
  // }

  me.moveBy = function (distance) {
    checkScrollRecord();
    me.activeRecord += distance;
    if (me.activeRecord < 0) me.first();
    else if (me.activeRecord >= me.recordCount) me.last();
    else recordIndexChanged();
  };

  me.first = function () {
    me.activeRecord = 0;
    bof = true;
    eof = me.recordCount == 0;
    recordIndexChanged();
  };

  me.last = function () {
    me.activeRecord = maxint(0, me.recordCount - 1);
    eof = true;
    bof = me.recordCount == 0;
    recordIndexChanged();
  };

  me.setRecordIndex = function (value) {
    me.activeRecord = value;
    this.moveBy(0);
  };

  me.getFieldIndex = function (fieldName) {
    return me.fields.findIndex((el) => el.fieldName === fieldName);
  };

  me.orderRecords = function (indexFieldNames, selection) {
    return new Promise(async (resolve) => {
      var request = {},
        json;
      request.command = "SortRecords";
      if (indexFieldNames) {
        request.fields = indexFieldNames;
        request.recordIndex = me.activeRecord;
      }
      if (selection) request.selection = selection.value;
      json = await handleRequest(request, true);
      if (json) {
        recordIndex = -1;
        points.clear();
        if (editRec) {
          editRec = null;
          recordStateChanged();
        }
        if (json.RecordIndex) me.activeRecord = parseInt(json.RecordIndex);
        if (selection && json.Selection) selection.value = json.Selection;
        await recordIndexChanged();
      }
      return resolve(json.Selection);
    });
  };

  me.SearchRecord = async function (FieldName, text, recordIndex) {
    let request = {},
      json;
    request.command = "SearchRecord";
    if (FieldName) request.FieldName = FieldName;
    if (text) request.Text = text;
    request.RecordIndex = recordIndex !== undefined ? recordIndex : me.activeRecord;
    json = await handleRequest(request, false);
    if (json) {
      if (editRec) {
        editRec = null;
      }
      return parseInt(json.RecordIndex) === -1 ? 0 : parseInt(json.RecordIndex);
    }
  };

  me.groupRecords = function (groupFieldNames) {
    const promise = new Promise(async (resolve) => {
      var request = {},
        json;
      request.command = "GroupRecords";
      request.fields = groupFieldNames;
      request.recordIndex = me.activeRecord;
      json = await handleRequest(request, true);
      if (json) {
        recordIndex = -1;
        points.clear();
        if (editRec) {
          editRec = null;
          recordStateChanged();
        }
        if (json.RecordIndex) me.activeRecord = parseInt(json.RecordIndex);
        if (json.RecordCount) me.recordCount = parseInt(json.RecordCount);
        await recordIndexChanged();
      }
      resolve();
    });
    return promise;
  };

  me.collapseGroup = function (collapse) {
    const promise = new Promise(async (resolve) => {
      var request = {},
        json;
      request.command = "CollapseGroup";
      request.recordIndex = me.activeRecord;
      request.collapsed = collapse ? 1 : 0;
      json = await handleRequest(request, false);
      if (json) {
        recordIndex = -1;
        points.clear();
        if (editRec) {
          editRec = null;
          recordStateChanged();
        }
        if (json.RecordIndex) me.activeRecord = parseInt(json.RecordIndex);
        if (json.RecordCount) me.recordCount = parseInt(json.RecordCount);
        await recordIndexChanged();
      }
      resolve();
    });
    return promise;
  };

  me.getGroupRecord = function () {
    const promise = new Promise(async (resolve) => {
      var request = {},
        json;
      request.command = "GetRecordGroup";
      request.recordIndex = me.activeRecord;
      json = await handleRequest(request, true);
      if (json) {
        if (json.RecordIndex) {
          return resolve(parseInt(json.RecordIndex));
        }
      }
      return resolve(-1);
    });
    return promise;
  };

  me.applyFieldFilter = function (filter) {
    const promise = new Promise(async (resolve) => {
      var request = {},
        json;
      request.command = "ApplyFilter";
      request.recordIndex = me.activeRecord;
      request.fields = filter;
      json = await handleRequest(request, true);
      if (json) {
        recordIndex = -1;
        points.clear();
        if (editRec) {
          editRec = null;
          recordStateChanged();
        }
        if (json.RecordIndex) me.activeRecord = parseInt(json.RecordIndex);
        if (json.RecordCount) me.recordCount = parseInt(json.RecordCount);
        else me.recordCount = 0;
        await recordIndexChanged();
      }
      resolve();
    });
    return promise;
  };

  me.checkActive = function (activate) {
    if (!me.active && activate) {
      if (me.fields.length == 0) {
        acquireFields();
        me.defaultFields = me.fields.length > 0;
      }
      me.active = true;
      recordIndex = -1;
      sourceStateChanged();
    }
    return me.active;
  };

  me.isEmpty = function () {
    return me.recordCount == 0;
  };

  me.insertRecord = async function (down, info, subData) {
    if (await createNewRecord(info, subData)) {
      // me.ChangeSave(true);
      if (down && me.recordCount > 0) me.activeRecord++;
      editRec.index = me.activeRecord;
      me.recordCount++;
      recordIndex = -1;
      await recordIndexChanged();
    }
  };

  me.appendRecord = async function () {
    if (await createNewRecord()) {
      me.ChangeSave(true);
      me.activeRecord = me.recordCount;
      editRec.index = me.activeRecord;
      me.recordCount++;
      recordIndex = -1;
      await recordIndexChanged();
    }
  };

  me.editRecord = async function (recordIndex) {
    if (me.recordCount === 0) {
      await me.appendRecord();
    } else {
      if (!editRec) {
        let rec = await getRecordNode(recordIndex ? recordIndex : me.activeRecord);
        if (rec) {
          editRec = { vals: [], info: {} };
          editRec.insert = false;
          editRec.active = me.activeRecord;
          editRec.index = me.activeRecord;
          loadRecordValues(editRec, rec, me.fields);
        }
      }
    }
  };

  me.CheckFieldText = function (field, ObjRef, text) {
    var request = {},
      json;
    request.command = "CheckFieldText";
    request.RecordIndex = me.activeRecord;
    request.FieldName = field;
    request.ObjRef = ObjRef;
    if (text) request.text = text;
    return handleRequest(request, false).then((json) => {
      return json;
    });
    return json;
  };

  me.GetFieldTextRequest = function (flag, field) {
    var request = {},
      json;
    request.command = "GetFieldText";
    request.RecordIndex = me.activeRecord;
    request.FieldName = field;
    request.ForEdit = flag;
    return handleRequest(request, false).then((json) => {
      return json;
    });
  };

  me.deleteRecord = async function (index) {
    checkRecordUpdates();
    let request = {},
      json;
    request.Command = "DeleteRecord";
    request.RecordIndex = index !== undefined ? index : me.activeRecord;
    if (KeyFieldName !== "") request.RecordKey = me.getFieldText(KeyFieldName, index);

    json = await handleRequest(request, true);
    if (json) {
      points.clear();
      if (json.RecordCount) me.recordCount = parseInt(json.RecordCount);
      if (me.recordCount > 0 && json.RecordIndex) me.activeRecord = parseInt(json.RecordIndex);
      else me.activeRecord = 0;
      me.moveBy(0);
      recordStateChanged();
    }
  };

  me.postRecord = function (index) {
    return new Promise(async (resolve) => {
      const postProcessing = async (json) => {
        points.clear();
        editRec = null;
        if (json.RecordCount) me.recordCount = parseInt(json.RecordCount);
        if (json.RecordIndex) me.activeRecord = parseInt(json.RecordIndex);
        me.modified = false;
        // dataChanged();
        recordStateChanged();

        this.Master.refreshRecord && (await this.Master.refreshRecord());
        changes = [];
        resolve();
      };

      if (editRec) {
        checkRecordUpdates();
        let i,
          request = {},
          json,
          fields,
          fieldName;
        request.Command = "PostRecord";
        request.RecordIndex = index !== undefined ? index : me.activeRecord;
        if (me.getState() === dsInsert) request.NewRecord = "1";
        else if (KeyFieldName !== "") request.RecordKey = await me.getFieldText(KeyFieldName, request.RecordIndex);
        request.RecordSet = {};
        request.RecordSet.Attributes = [];
        fields = request.RecordSet.Attributes;
        if (editRec.insert) {
          for (i = 0; i < editRec.vals.length; i++) {
            fieldName = editRec.vals[i];
            if (fieldHasData(me.fields[i].fieldName)) {
              // todo
              fields.push(me.fields[i].fieldName);
            }
          }
        } else {
          for (i = 0; i < changes.length; i++) {
            fields.push(changes[i]);
          }
        }
        request.RecordSet.Records = { Record: {} };
        let rec = {};
        rec.$Data = [];
        saveRecordValues(editRec, rec, fields);
        request.RecordSet.Records.Record = rec;
        json = await handleRequest(request, false, true);
        if (json) {
          if (json.Token) {
            tokenProcessingTest(json, {
              from: "grid",
              func: async () => {
                await postProcessing(json);
              },
            });
          } else {
            await postProcessing(json);
          }
        } else {
          await me.cancelRecord();
          changes = [];
          resolve();
        }

        // me.CommitToSaveChanges(false);
      }
    });
  };

  me.refreshRecord = async function () {
    let request = {},
      rec,
      fields,
      records,
      json;
    rec = await getRecordNode(me.activeRecord);
    if (rec) {
      request.Command = "RefreshRecord";
      request.First = me.activeRecord;
      request.Count = 1;
      if (KeyFieldName) {
        request.RecordKey = await me.getFieldText(KeyFieldName);
      }
      json = await handleRequest(request);
      if (json && json.RecordSet) {
        fields = json.RecordSet.Attributes;
        if (fields) {
          records = json.RecordSet.Records[0];
          loadRecordValues(rec, records, fields);
          points.set(me.activeRecord, rec);
        }
      }
    }
  };

  me.cancelRecord = function (needToChangeActive) {
    const promise = new Promise(async (resolve) => {
      if (editRec) {
        if (editRec.insert) {
          if (editRec.active !== undefined && needToChangeActive !== false) me.activeRecord = editRec.active;
          me.recordCount--;
          if (needToChangeActive !== false) me.moveBy(0);
        }
        editRec = null;
        recordStateChanged();
      }
      me.modified = false;
      resolve();
    });
    return promise;
  };

  me.isEditMode = function () {
    let state = me.getState();
    return state === dsEdit || state === dsInsert;
  };

  me.isEditModeState = function () {
    let state = me.getState();
    return state;
  };

  me.getState = function () {
    if (me.active) {
      if (editRec) {
        if (editRec.insert) return dsInsert;
        else return dsEdit;
      }
      return dsBrowse;
    }
    return dsInactive;
  };

  me.getFieldModified = function (fieldName) {
    return changes.indexOf(fieldName) !== -1;
  };

  me.setFieldModified = function (fieldName, value) {
    let index = changes.indexOf(fieldName);
    if (index === -1) {
      if (value) changes.push(fieldName);
    } else if (!value) changes.splice(index, 1);
  };

  me.getUpdateStatus = async function (index) {
    return getRecordNode(index).then((rec) => {
      if (rec && rec.info && rec.info.updateStatus) {
        return rec.info.updateStatus;
      }
      return usUnmodified;
    });
  };

  return me;
}

function createScrollbar(view, event) {
  this.view = view;
  view.insertAdjacentHTML("beforeend", '<div class="lazy-scroll">');
  var bar = view.lastChild;
  bar.style.zIndex = 2;

  var lastPageY,
    scrollTop = 0,
    maxScroll = 0,
    timeOut;

  appendEvent(
    "mousedown",
    function (e) {
      lastPageY = e.pageY;

      appendEvent("mousemove", drag);
      appendEvent("mouseup", stop);

      return false;
    },
    bar,
  );

  function drag(e) {
    if (true) {
      scrollTop += e.pageY - lastPageY;
      lastPageY = e.pageY;
      scrollTop = midint(0, maxScroll, scrollTop);
      bar.style.top = scrollTop + "px";

      if (event) {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          queuedGridScroll.scrollGrid(scrollTop / maxScroll, event);
        }, 300);
      }
    }
  }

  function stop() {
    deleteEvent("mousemove", drag);
    deleteEvent("mouseup", stop);
  }

  this.updateBar = function (visibleRows, recordCount, scrollPos) {
    var scrollRatio = visibleRows / recordCount;

    if (scrollRatio < 1) {
      var top,
        barSize,
        totalSize = this.view.getBoundingClientRect().height,
        right = (this.view.clientWidth - bar.clientWidth) * -1,
        height = Math.max(scrollRatio * 100, 5);
      barSize = (totalSize / 100) * height;
      maxScroll = totalSize - barSize;
      if (scrollPos != undefined) {
        scrollTop = (maxScroll / (recordCount - visibleRows)) * scrollPos;
        top = scrollTop + "px";
      } else top = bar.style.top;
      if (!top) {
        top = "0px";
      }
      bar.style.cssText = "height:" + height + "%; top: " + top + ";right:" + right + "px";
      showElement(bar);
    } else hideElement(bar);
  };

  return this;
}

var nfStateChanged = "StateChanged",
  mfInsert = 0,
  mfEdit = 1,
  mfDelete = 2;

export function createGrid(panel) {
  var // константы
    REGION_GROUP = "group",
    REGION_TITLE = "title",
    REGION_DATUM = "datum",
    ROLE_COLUMN = "column",
    ROLE_COLDATA = "col-data",
    ROLE_CHECKBOX = "check-box",
    ROLE_COLLAPSE = "collapse",
    ROLE_GROUP = "group",
    ROLE_FILTER = "filter",
    ROLE_MARKED = "marked",
    ROLE_COLLAPSE_GROUP = "collapsegroup",
    ROLE_FILTER = "filter",
    GRID_BACKGROUND = "var(--indicatorColor)",
    INDICATOR_IMAGE_BROWSE = // плюс
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAzSURBVChTY2AgEvwnpA6kAK8imAKcipAVYFWErgBDETYFKIpINgHD10T7Amd4EQxJnDoBZO8j3Xd97XYAAAAASUVORK5CYII=",
    INDICATOR_IMAGE_EDITING =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAgSURBVChTY2AgEvwHqgNhGEDnE5YgqHOEKCA6JDFiBgCPRhnnsmWU1gAAAABJRU5ErkJggg==",
    INDICATOR_IMAGE_INSERTED = //синий плюс
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAsSURBVChTY2CgMvj/n4EBhHEC8hTAdOGiwXbiwyjuIc8NyEYQNIHKwQo2DgAiSzfJCmFmxgAAAABJRU5ErkJggg==",
    INDICATOR_IMAGE_MODIFIED =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA3SURBVChTY2AgHvz/z8AAw1h10VgBsvEY7sAmCRKDA3TH4fQJTBeKbnT/IktiVYjXFIIBhT1SAGnISbdGg+UeAAAAAElFTkSuQmCC",
    INDICATOR_IMAGE_NEW = // звезда
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAHCAYAAAA1WQxeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAA5SURBVBhXY/wPBAx4ABNMjpGREa4MhY1sAkwC2VBGkAJkHci2gRSCFaBbg2wCihtAEmBdyO4h5AsAEQoi/Ix3kjkAAAAASUVORK5CYII=",
    INDICATOR_IMAGE_UPDATED =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAANCAYAAACUwi84AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA3SURBVChTY2AgBth7nPmPVx1IAV5FMAU4FSErwKoIXQGGImwKUBSRbAKGl4n2Bc7AIhiS+IIZABZaU4nPjT65AAAAAElFTkSuQmCC";
  var // размеры и прочее
    titleSize = 24,
    rowHeight = 24,
    indicatorSize = 42,
    groupSize = 0,
    groups = [],
    showIndicator = true,
    filterSize = 0,
    visibleRows = 0,
    firstRecord = 0,
    overflowColumns = false,
    rightOverflow = false,
    leftRightMover = null,
    columnOffset = 0,
    currentRow = 0,
    currentCol = 0,
    childColumnSize = 24,
    isJustMouseDown = false,
    viewInfo = { horizontal: false, saveSource: null, saveColumns: null },
    assignBMList = [],
    selectedField = undefined,
    editor = null,
    inputEditor = null,
    indicator = null,
    imageFields = [],
    multiSelect = [],
    sortedFileds = "",
    fixedColumn = null,
    constOptions = {
      filter: 1,
      groupBy: 2,
      showIndicator: 4,
    },
    filterData = {};

  var me = this,
    sizeMarker,
    t,
    dragZone,
    handleColumnField;

  me.columns = { title: [] };
  me.OnlyViewMode = false;
  me.BkClrField = undefined;
  me.ColorField = undefined;
  me.StyleField = undefined;
  me.doccfgid = undefined;
  me.gridState = "";
  me.defaultColumns = false;

  me.MultiCLsSelect = null;
  me.ClsSelection = {};

  me.setSource = function (source, clearMultiSelect) {
    if (me.source != source) {
      if (me.defaultColumns) me.columns = {};
    }
    me.source = source;
    me.setSourceLoad = true;
    const promise = new Promise(async (resolve) => {
      if (source.active === false) await source.open();
      if (source) {
        if (me.source && me.defaultColumns) {
          me.columns.fields = [];
          me.columns.levels = [];
          me.columns.title = [];
          var i,
            f,
            c,
            fields = me.source.fields;
          for (i = 0; i < fields.length; i++) {
            c = {};
            f = fields[i];
            c.fieldName = f.fieldName;
            c.title = f.displayLabel;
            c.width = f.displayWidth * 11;
            c.level = 0;
            me.columns.title.push(c);
          }
          prepareLevels();
        }
        if (clearMultiSelect !== false) multiSelect = [];
        currentRow = me.source.activeRecord;
        prepareLevels();
        Zaglushka();
        buildColumns();
        // buildGroups();
        BuildGroupsContent();
        // showApplicationMask(panel);
        await queuedGridScroll.scrollGrid(undefined, buildRecords);

        // hideApplicationMask(panel)

        me.setSourceLoad = false;
      }
      resolve();
    });
    return promise;
  };

  me.canGroup = function (field) {
    if (me.doccfgid) {
      return field.OptionsDisable && field.OptionsDisable.group === true;
    } else {
      return me.canGroupVar !== undefined ? me.canGroupVar : false;
    }
  };

  me.canSort = function (field) {
    if (me.doccfgid) {
      return field.OptionsDisable && field.OptionsDisable.sort === true;
    } else {
      return me.canSortVar !== undefined ? me.canSortVar : false;
    }
  };

  me.canModify = function (flag, column, me) {
    return localCanModify(flag, column, me);
  };

  me.setFilterData = function (data) {
    filterData = data;
  };

  me.changeIndicator = changeIndicator;

  function localCanModify(flag, column, me) {
    // значение возможности редактирования по умолчанию
    let result = false;
    if (me.OnlyViewMode) return false;
    // разрешения на основе флага и прав
    switch (flag) {
      case mfInsert:
        result = me.getActiveSource().rights.insert;
        break;
      case mfEdit:
        result = me.getActiveSource().rights.modify;
        break;
      case mfDelete:
        result = me.getActiveSource().rights.delete;
        break;
    }

    return result;
  }

  me.insertRecord = async function (down, info, subData) {
    let source = me.getActiveSource();
    if (source && source.active && source.getState() != dsInsert && (await me.canModify(mfInsert, undefined, me))) {
      await source.insertRecord(down, info, subData);
      await updateRecordTable();
      await scrollToRecord(source.activeRecord, true);
      await changeIndicator(dsInsert);
      me.CommitToSaveChanges && me.CommitToSaveChanges(me);
      if (viewInfo.horizontal) updateCard();
      // Zaglushka();
    }
  };

  me.appendRecord = async function () {
    let source = me.getActiveSource();
    if (source && source.active && source.getState() != dsInsert && (await me.canModify(mfInsert, undefined, me))) {
      await source.appendRecord();
      await me.postExicstedEditRec();
      await updateRecordTable();

      // await scrollToRecord(source.activeRecord, true);
      // indicator.setAttribute("src", INDICATOR_IMAGE_BROWSE);
    }
  };

  me.postRecord = async function (index) {
    if (sourceActive()) {
      await setColumnText(editor.column, editor.content);
      if (editor.objref) await setColumnValue(editor.column, editor.objref);
      await me.source.postRecord(index);
      let source = this.getActiveSource();
      if (source !== me.source && source.isEditMode()) {
        await source.postRecord();
        // await me.source.acquireRecords(0, me.source.recordCount - 1);
      }
      await updateRecordTable(true);
      scrollToRecord(me.source.activeRecord, true);
    }
  };

  me.cancelRecord = async function (needToChangeActive) {
    async function cancelSource(source) {
      let b = source && source.active && source.isEditMode();
      if (b) await source.cancelRecord(needToChangeActive);
    }

    await cancelSource(me.source);
    if (viewInfo.horizontal) await cancelSource(viewInfo.saveSource);

    await scrollToRecord(me.source.activeRecord, true);
    await updateRecordTable(true);

    if (me.editorMode()) {
      await hideEditor();
      //todo
    }
    me.CommitToSaveChanges && (await me.CommitToSaveChanges(false));
    await changeIndicator(dsBrowse);
    // Zaglushka();
  };

  me.editorMode = function () {
    if (editor) return editor.visible;
  };

  me.switchFilter = null;

  me.getActiveSource = function () {
    if (viewInfo.horizontal) return viewInfo.saveSource;
    return me.source;
  };

  function sourceActive() {
    return me.source != undefined && me.source != null && me.source.active;
  }

  function getPointRegion(x, y) {
    function checkElement(el) {
      var r = el.getBoundingClientRect();
      if (x >= r.left && x <= r.right && y >= r.top && y <= r.bottom) {
        return true;
      } else return false;
    }

    if (checkElement(me.header)) {
      return REGION_TITLE;
    }
    if (checkElement(me.table)) {
      return REGION_DATUM;
    }
    if (checkElement(me.groups)) {
      return REGION_GROUP;
    }
  }

  function sizeColumn(e) {
    var x = e.x - panel.getBoundingClientRect().x;
    if (x > handleColumnField.offset && x < panel.clientWidth) {
      sizeMarker.style.left = x + "px";
      handleColumnField.eventSize = x;
    }
  }

  function ajustColumnWidth(f) {
    //todo
    if (f) {
      let IndexCol = 0,
        maxLengStr = 0,
        row,
        valueCol,
        colWidth;

      for (let i = 0; i < visibleRows; i++) {
        row = getRowElement(i);
        let arr = row.getElementsByClassName("grid-td");
        for (let j = 0; j < arr.length; j++) {
          if (arr[j].field.fieldName == f.fieldName) {
            valueCol = arr[j].firstChild.innerHTML;
            break;
          }
        }
        let textcol = document.createElement("span");
        textcol.className = "grid-cell-inner";
        textcol.innerHTML = valueCol;
        document.body.appendChild(textcol);
        colWidth = textcol.offsetWidth;
        if (colWidth > maxLengStr) {
          maxLengStr = colWidth;
        }
        document.body.removeChild(textcol);
      }
      f.width = maxLengStr + 5;
      updateColumns();
      sizeMarker.style.opacity = 0;
    }
  }

  function getFieldAtPoint(x, y, info) {
    let checkMargin = 0;

    function checkColumn(level, colLeft, colTop, width, height) {
      if (level.items && level.expanded) {
        width.value = 0;
        height = childColumnSize;
        for (let i = 0, l, f, field, left = colLeft, w; i < level.items.length; i++) {
          l = level.items[i];
          f = l.field;
          w = { value: f.width };
          field = checkColumn(l, left, colTop + childColumnSize, w, childColumnSize);
          if (checkMargin == 0) {
            if (field) return field;
            left += w.value;
            width.value += w.value;
          } else checkMargin--;
        }
      }
      if (intInRange(x, colLeft, colLeft + width.value) && intInRange(y, colTop, colTop + height)) {
        return level.field;
      }
      return null;
    }

    var r = getPointRegion(x, y);
    if (info) info.region = r;
    switch (r) {
      case REGION_TITLE: {
        let r = me.header.getBoundingClientRect(),
          leftModal = 0,
          topModal = 0;
        const isModalWindow = me.panel.closest(".smart-window");
        if (isModalWindow && isModalWindow.style) {
          leftModal = Number(isModalWindow.style.left.replace("px", ""));
          topModal = Number(isModalWindow.style.top.replace("px", ""));
        }
        for (
          let levels = me.header.children,
            i = 1,
            left = r.left - leftModal,
            top = r.top - topModal,
            width,
            level,
            field;
          i < levels.length;
          i++
        ) {
          level = levels[i].level;
          width = { value: level.field.width };
          field = checkColumn(levels[i].level, left, top, width, titleSize);
          if (checkMargin == 0) {
            if (field) return field;
            left += width.value;
          } else checkMargin--;
        }
        return me.columns.stubField;
      }
      case REGION_GROUP: {
        var rect = me.groups.getBoundingClientRect(),
          left = rect.left,
          top = rect.top,
          bottom = rect.bottom;
        for (let i = 0, el; i < me.groups.childElementCount; i++) {
          el = me.groups.children[i];
          rect = el.getBoundingClientRect();
          if (intInRange(x, rect.left, rect.right)) {
            if (info) {
              info.left = rect.left;
              info.top = rect.top;
              info.bottom = rect.bottom;
            }
            return el.field;
          }
          left = rect.right;
          top = rect.top;
          bottom = rect.bottom;
        }
        if (info) {
          info.left = left;
          info.top = top;
          info.bottom = bottom;
        }
        return null;
      }
    }
    return null;
  }

  function getFieldColumnRect(field) {
    var rect = me.header.getBoundingClientRect();
    return {
      left: field.offset + rect.left,
      top: rect.top + field.top,
      right: field.offset + field.width,
      bottom: rect.bottom,
    };
  }

  function moveColumn(e) {
    if (!isJustMouseDown) {
      isJustMouseDown = true;
      return;
    }

    log("move: " + e.x + ":" + e.y);

    if (!dragZone) {
      dragZone = document.createElement("div");
      dragZone.classList.add("grid-column");
      dragZone.classList.add("grid-drag-zone");
      dragZone.style.pointerEvents = "none";
      panel.appendChild(dragZone);
      dragZone.moveTop = document.createElement("div");
      dragZone.moveTop.className = "col-move-top";
      dragZone.moveTop.style.display = "none";
      panel.appendChild(dragZone.moveTop);
      dragZone.moveBottom = document.createElement("div");
      dragZone.moveBottom.className = "col-move-bottom";
      dragZone.moveBottom.style.display = "none";
      panel.appendChild(dragZone.moveBottom);
    }
    if (!dragZone.initRect) {
      if (handleColumnField.groupEl) dragZone.initRect = handleColumnField.groupEl.getBoundingClientRect();
      else dragZone.initRect = handleColumnField.el.getBoundingClientRect();
      dragZone.deltaX = e.x - dragZone.initRect.left;
      dragZone.deltaY = e.y - dragZone.initRect.top;
      dragZone.style.display = "";
      dragZone.style.height = dragZone.initRect.height + "px";
      dragZone.style.width = dragZone.initRect.width + "px";
      dragZone.style.left = dragZone.initRect.left + "px";
      dragZone.style.top = dragZone.initRect.top + "px";
      dragZone.style.width = dragZone.initRect.width + "px";
      dragZone.style.height = dragZone.initRect.height + "px";
      dragZone.textContent = handleColumnField.title;
      dragZone.style.display = "";
    }
    var x = e.x - dragZone.deltaX,
      y = e.y - dragZone.deltaY;
    dragZone.style.left = x + "px";
    dragZone.style.top = y + "px";
    var info = {},
      field = getFieldAtPoint(x + 5, y + 5, info);
    if (info.region == REGION_GROUP && me.canGroup(handleColumnField)) {
      dragZone.moveTop.style.left = info.left - 5 + "px";
      dragZone.moveTop.style.top = info.top - dragZone.moveTop.getBoundingClientRect().height + "px";
      dragZone.moveBottom.style.left = info.left - 5 + "px";
      dragZone.moveBottom.style.top = info.bottom + "px";
      showElement(dragZone.moveTop);
      showElement(dragZone.moveBottom);
      dragZone.field = field;
      dragZone.isGroup = true;
      return;
    }
    if (field != null && (field != handleColumnField || field.groupEl) && field.parent == handleColumnField.parent) {
      let rect = getFieldColumnRect(field);
      dragZone.moveTop.style.left = rect.left + "px";
      dragZone.moveTop.style.top = rect.top - dragZone.moveTop.getBoundingClientRect().height + "px";
      dragZone.moveBottom.style.left = rect.left + "px";
      dragZone.moveBottom.style.top = rect.bottom + "px";
      showElement(dragZone.moveTop);
      showElement(dragZone.moveBottom);
      dragZone.field = field;
      dragZone.isGroup = false;
    } else {
      hideElement(dragZone.moveTop);
      hideElement(dragZone.moveBottom);
      dragZone.field = null;
    }
  }

  async function updateColumns() {
    function updateElement(level, left, width) {
      var field = level.field,
        childs = level.items;
      if (childs && level.expanded) {
        width = 0;
        for (let i = 0, l = left, w, lev; i < childs.length; i++) {
          lev = childs[i];
          w = updateElement(lev, l, lev.field.width);
          l += w;
          width += w;
        }
      }
      if (field.el) {
        field.el.style.left = left + "px";
        field.el.style.width = width + "px";
        field.elementWidth = width;
        field.offset = left;
      }
      if (field.hide === true) return 0;
      return width;
    }

    if (overflowColumns) {
      buildColumns();
    } else {
      let levels = me.columns.levels,
        left;
      if (showIndicator) left = indicatorSize;
      else left = 0;
      for (let i = 0, level; i < levels.length; i++) {
        level = levels[i];
        left += updateElement(level, left, level.field.width);
      }
      if (me.columns.fields.length > 1) {
        let field;
        field = me.columns.fields[me.columns.fields.length - 1];
        me.columns.stubField = {
          offset: field.offset + field.width,
          el: field.el,
          parent: me.columns.levels,
        };
      }
    }
    await queuedGridScroll.scrollGrid(undefined, buildRecords);

    updateScrollBar();
    updateFilters();
    Zaglushka();
    if (me.editorMode()) {
      await showEditor();
    }
  }

  function updateScrollBar() {
    if (me.source) {
      me.scrollBar.updateBar(visibleRows, me.source.recordCount, firstRecord);
    } else {
      me.scrollBar.updateBar(0, 0, 0);
    }
  }

  async function getRecordState() {
    if (sourceActive()) {
      return await me.source.getRecordState();
    }
    return 0;
  }

  async function getLevelOffset() {
    if (!viewInfo.horizontal && me.getActiveSource().CreateCollapsibleRecords === null && sourceActive()) {
      return (await me.getActiveSource().getRecordLevel()) * rowHeight;
    }
    return 0;
  }

  async function isGroupRecord(checkView) {
    if (groups.length > 0) {
      if (checkView && viewInfo.horizontal) {
        return false;
      }

      if (((await getRecordState()) & STATUS_GROUPED) != 0) {
        return true;
      }
    }
    return false;
  }

  async function getRecordCollapsed() {
    if (((await getRecordState()) & STATUS_COLLAPSED) != 0) return true;
    else return false;
  }

  async function setRecordCollapsed(collapse) {
    if (sourceActive()) {
      await me.source.collapseGroup(collapse);
      await queuedGridScroll.scrollGrid(undefined, buildRecords);

      updateScrollBar();
    }
  }

  function updateActive() {
    if (sourceActive()) {
      let newRow = me.source.activeRecord - firstRecord;
      if (currentRow != newRow) {
        currentRow = newRow;
        hideEditor();
      }
      updateEditor();
    }
  }

  async function getGroupString() {
    if (sourceActive()) {
      return await me.source.getRecordTitle();
    } else return "";
  }

  async function updateCurrentView() {
    await drawRecords();
    // me.updateCurrentMarkedValues
    updateScrollBar();
  }

  async function updateRecordTable(load) {
    return new Promise(async (resolve) => {
      if (viewInfo.horizontal) me.source.refresh("!");
      await queuedGridScroll.scrollGrid(load, buildRecords);
      updateScrollBar();
      resolve();
    });
    // Zaglushka();
  }

  async function updateAllGrid(queued) {
    return new Promise(async (resolve) => {
      updateGridSizeForCardMode();
      buildColumns();
      queued === false ? await buildRecords() : await queuedGridScroll.scrollGrid(undefined, buildRecords);
      updateScrollBar();
      resolve();
    });
    // Zaglushka();
  }

  function createIndicator(id) {
    let el = document.createElement("img");
    el.className = "strelka";
    el.id = id;
    el.style.width = "9px";
    el.style.height = "12px";
    el.style.position = "absolute";
    el.style.top = "5px";
    el.style.right = "4px";
    if (!showIndicator) el.style.display = "none";
    return el;
  }

  async function changeIndicator(state) {
    if (showIndicator) {
      switch (state) {
        case dsBrowse:
          if (sourceActive() && (await me.source.getUpdateStatus(me.source.activeRecord)) > 0) {
            console.log("updated");
            indicator.setAttribute("src", INDICATOR_IMAGE_UPDATED);
          } else indicator.setAttribute("src", INDICATOR_IMAGE_BROWSE);
          // me.ChangeSave(true);
          break;
        case dsEdit:
          console.log("dsEdit");
          if (viewInfo.horizontal) {
            console.log(panel.querySelector(".titleText"));
          }
          indicator.setAttribute("src", INDICATOR_IMAGE_EDITING);
          break;
        case dsInsert:
          console.log("dsInsert");
          indicator.setAttribute("src", INDICATOR_IMAGE_NEW);
          break;
      }
      indicator.state = state;
    }
  }

  function CheckDrawInfo(fieldName, td, tr, i) {
    if (viewInfo.horizontal && i === 0) return;
    const recordInfo = me.getActiveSource().getRecordInfo();
    if (recordInfo && recordInfo.Data$content) {
      const indexOfField = me.getActiveSource().getFieldIndex(fieldName);
      const Locking = recordInfo.Data$content;
      const lockingStatus = Locking[indexOfField];
      const cellInner = td.getElementsByClassName("grid-cell-inner")[0];
      switch (lockingStatus) {
        case "1": {
          if (td) {
            td.style.backgroundColor = "rgb(162 174 186)";
            td.style.color = "rgb(64, 64, 64)";
            if (cellInner.innerText === "" && !td.getElementsByClassName("grid-cell-img")) {
              cellInner.innerText = "X";
              cellInner.style.textAlign = "center";
            }
          }
          break;
        }
        case "2": {
          if (tr) {
          }
          break;
        }
        default: {
          cellInner.style.textAlign = "";
          // td.style.color = ""
          break;
        }
      }
    }
  }

  async function createRecordColumns(tr, isCurrentRow, margin) {
    var td;
    td = document.createElement("td");
    td.style.width = isNaN(margin) ? 0 : margin + "px";
    td.style.backgroundColor = GRID_BACKGROUND;
    tr.appendChild(td);
    const promise = new Promise(async (resolve) => {
      for (let i = 0, items = me.columns.fields, div, col, width; i < items.length; i++) {
        col = items[i];
        const RecValue = await me.source.getFieldValue(col.fieldName);
        td = document.createElement("td");
        td.className = "grid-td";
        td.col = i + columnOffset;
        td.field = col;
        if (!viewInfo.horizontal) {
          if (
            isCurrentRow &&
            (selectedField === col.fieldName || (currentCol === i + columnOffset && selectedField === undefined))
            // (selectedField === col.fieldName || i == currentCol)
          ) {
            td.classList.add("grid-item-focused");
            // selectedField = col.fieldName;
          }
        }
        //
        width = col.el.clientWidth + 1;
        // width = isClassifires?col.width + 10:col.width
        if (i == 0) width -= margin;
        // if(width < 24) width = 24
        td.style.width = width + "px";
        td.role = ROLE_COLDATA;
        div = document.createElement("div");
        div.className = "grid-cell-inner";
        div.innerText = await me.source.getFieldText(col.fieldName);
        div.innerText = div.innerText === "undefined" ? "" : div.innerText;
        // div.appendChild( me.source.getFieldText(col.fieldName))
        if (RecValue) {
          div.recid = RecValue;
        }
        if (
          me.source.CreateCollapsibleRecords !== null &&
          (col.fieldName === "CODE" || col.collapseRecord || viewInfo.horizontal)
        ) {
          await me.source.CreateCollapsibleRecords(div, me, i, td);
          // const icon = me.source.CreateCollapsibleRecords(div, me, i);
          // if (icon !== null) {
          //   td.appendChild(icon);
          // }
        }
        renderByFiledInfo(col, td, div, RecValue, true);
        td.appendChild(div);
        CheckDrawInfo(viewInfo.horizontal ? td.levelsField.field.fieldName : col.fieldName, td, tr, i);
        tr.appendChild(td);
      }

      tr.isGroup = false;
      resolve();
    });
    //
    return promise;
  }

  function renderByFiledInfo(col, td, div, RecValue, isBuildRecords) {
    let column;
    if (viewInfo.horizontal && td.levelsField && td.levelsField.field) {
      column = td.levelsField.field;
    } else {
      column = col;
    }
    if ((column.fieldInfo && viewInfo.horizontal && col.fieldName !== "Title") || !viewInfo.horizontal) {
      if (column.fieldInfo) {
        if (!viewInfo.horizontal && column.fieldInfo.TextAjust === "1") {
          td.style.textAlign = "center";
        }
        if (column.fieldInfo.EditStyle & 4) {
          let checkboxState;
          if (column.fieldInfo.CheckField) {
            checkboxState = me.source.getFieldValueSync(column.fieldInfo.CheckField);
          } else {
            checkboxState = RecValue ? RecValue : "0";
          }
          if (me.getActiveSource().MultiCLsSelect) {
            if (
              me.getActiveSource().ClsSelection[
                me.getActiveSource().getFieldValueSync(me.getActiveSource().getKeyFieldName())
              ] !== undefined
            ) {
              checkboxState = "1";
            } else checkboxState = "0";
          }

          const oldContainer = td.querySelector(`div[role="check-box"]`);

          if (oldContainer) {
            const checkbox = oldContainer.querySelector("#checkbox-multicheck");

            checkbox.update({
              MultiCheckSet: column.fieldInfo.MultiCheckSet,
              CheckState: checkboxState,
            });
          } else {
            const checkContainer = document.createElement("div");
            checkContainer.role = ROLE_CHECKBOX;
            checkContainer.recordIndex = me.getActiveSource().activeRecord;
            checkContainer.checked = checkboxState;
            checkContainer.style.position = "absolute";
            // const checkBoxDisabled = !me.canModify(mfEdit, column, me);
            let modifyFlag = mfEdit;
            if (me.getActiveSource().recordCount === 0) {
              modifyFlag = mfInsert;
            }
            ReactDOM.render(
              <CheckBoxMultiCheck
                MultiCheckSet={column.fieldInfo.MultiCheckSet}
                customDefaultIcons={column.fieldInfo.customDefaultIcons}
                // disabled={checkBoxDisabled}
                CheckState={checkboxState}
                canModify={() => {
                  return me.canModify(modifyFlag, column, me);
                }}
                onEdit={(data) => {
                  onCheck(data, checkContainer);
                }}
              />,
              checkContainer,
            );

            div.style.marginLeft = "19px";
            td.style.textAlign = "";
            td.appendChild(checkContainer);
          }
        }
        if (column.fieldInfo.Type === "Image" && !viewInfo.horizontal) {
          const DOMimg = td.getElementsByTagName("img");
          if (DOMimg.length > 0) {
            DOMimg[0].remove();
          }
          const img = createImgByIndex(div.innerText);
          img.className = "grid-cell-img";
          // img.style.width = "16px";
          // img.style.height = "16px";
          // img.style.verticalAlign = "middle";
          div.innerText = "";
          div.innerHTML = "";
          td.appendChild(img);
        }
      }
      if (column.OptionsDisable) {
        if (column.OptionsDisable && column.OptionsDisable.link && div.innerText !== "") {
          div.style.cursor = "pointer";
        }
      }
    }
    if (viewInfo.horizontal & (col.fieldName === "Title") && column && column.marked !== undefined) {
      const markedButton = createRoundButton(td.levelsField.field);
      markedButton.style.bottom = "";
      markedButton.style.left = "";
      markedButton.style.width = "4px";
      markedButton.style.height = "4px";
      markedButton.style.margin = "8.5px 0px 0px 1px";
      td.appendChild(markedButton);
    }
  }

  async function onCheck(data, checkContainer) {
    let oldRecordCount = me.getActiveSource().recordCount;
    const checkbox = checkContainer;
    checkbox.checked = data.CheckState;
    let el = checkContainer.parentElement;
    let newCol = el.col,
      newRow = el.parentElement.row;
    if (
      oldRecordCount === 0 &&
      (await canModifyViewInfo(mfInsert, viewInfo.horizontal ? el.levelsField.field : el.field, me))
    ) {
      await me.getActiveSource().appendRecord();
    }
    if (newCol !== currentCol || currentRow !== newRow) await moveColRow(newCol, newRow);
    if (await canModifyViewInfo(mfEdit, viewInfo.horizontal ? el.levelsField.field : el.field, me)) {
      //canModifyViewInfo(mfEdit, el.field, me)

      if (me.getActiveSource().MultiCLsSelect) {
        const value = me.getActiveSource().getFieldValueSync(me.getActiveSource().getKeyFieldName());
        const prefixValue = "";
        if (checkbox.checked) {
          me.getActiveSource().ClsSelection[value] = `${prefixValue}${value}`;
        } else {
          delete me.getActiveSource().ClsSelection[value];
        }
        return;
      }

      if (viewInfo.horizontal && multiSelect.length > 0) {
        const source = me.getActiveSource(),
          saveacrivrecord = source.activeRecord;
        for (const value of Object.values(multiSelect)) {
          source.activeRecord = Number(value);
          await postRecordForCheckBox();
        }
        source.activeRecord = saveacrivrecord;
      } else {
        await postRecordForCheckBox();
      }
      async function postRecordForCheckBox() {
        let fieldName = viewInfo.horizontal ? el.levelsField.field : el.field;
        fieldName =
          fieldName.fieldInfo && fieldName.fieldInfo.CheckField ? fieldName.fieldInfo.CheckField : fieldName.fieldName;
        await me.postSomeData(fieldName, {
          id: checkbox.checked && checkbox.checked !== "0" ? checkbox.checked : "",
          text: "",
        });
        await me.getActiveSource().postRecord();
        // await me.postExicstedEditRec();
      }
      // await updateCurrentView();
      await UpdateOrBuild(oldRecordCount);
    } else {
      // e.preventDefault();
    }

    if (me.source.getState() != dsInsert) await changeIndicator(dsBrowse);
  }

  async function UpdateOrBuild(oldRecordCount, load) {
    if (oldRecordCount !== me.getActiveSource().recordCount) {
      await updateRecordTable(load !== undefined ? load : true);
    } else await updateCurrentView();
  }

  function createGroupRecord(tr, isCurrentRow, margin) {
    const promise = new Promise(async (resolve) => {
      var td,
        width = -1,
        tmp;
      td = document.createElement("td");
      td.style.width = margin + "px";
      td.style.backgroundColor = GRID_BACKGROUND;

      tr.appendChild(td);
      for (let i = 0, items = me.columns.fields, col; i < items.length; i++) {
        col = items[i];
        width += col.width;
      }
      width -= margin;
      td = document.createElement("td");
      td.className = "grid-td";
      // if (tr.parentElement && tr.parentElement.parentElement) {
      if (isCurrentRow) {
        td.classList.add("grid-item-focused");
      } else {
        td.classList.remove("grid-item-focused");
      }
      // }
      td.style.width = width + "px";
      td.role = ROLE_COLDATA;

      tmp = document.createElement("img");
      tmp.src = "data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
      tmp.classList.add("collapse-img");
      tmp.style.marginTop = "4px";
      if (await getRecordCollapsed()) tmp.classList.add("collapse-plus");
      else tmp.classList.add("collapse-minus");
      tmp.style.width = "20px";
      tmp.role = ROLE_COLLAPSE_GROUP;
      td.appendChild(tmp);

      tmp = document.createElement("span");
      tmp.style.width = "100%";
      tmp.textContent = await getGroupString();
      td.appendChild(tmp);

      tr.appendChild(td);
      tr.isGroup = true;
      resolve();
    });
    return promise;
  }

  async function drawRecords() {
    async function drawRow(r) {
      const promise = new Promise(async (resolve) => {
        if (viewInfo.horizontal && r === 0 && fixedColumn !== null) {
          me.source.activeRecord = 0 + r;
        } else {
          me.source.activeRecord = firstRecord + r;
        }

        var el = getRowElement(r),
          isCurrentRow = currentRow === r,
          isGroup,
          margin;
        deleteStyleByField(el);
        await createStyleByField(el, isGroup);
        if (multiSelect.includes(`${me.source.activeRecord}`)) {
          createMultiRow(el);
        } else {
          deleteStyleInRow(el);
        }
        el.recordIndex = me.source.activeRecord;
        me.changeGridStyles && (await me.changeGridStyles(el));
        if (showIndicator) {
          let par = el.getElementsByTagName("tr")[0].firstChild;
          while (par.childElementCount > 0) par.removeChild(par.lastChild);

          function addInd(img) {
            let ind = createIndicator("state");
            par.appendChild(ind);
            ind.setAttribute("src", img);
          }

          switch (await me.source.getUpdateStatus(me.source.activeRecord)) {
            case usInserted:
              addInd(INDICATOR_IMAGE_INSERTED);
              break;
            case usModified:
              addInd(INDICATOR_IMAGE_MODIFIED);
              break;
          }
          if (imageFields.length > 0 && !viewInfo.horizontal) {
            await CreateImageFields(par);
          }
          if (isCurrentRow) {
            placeIndicator(par);
          }
        }
        el = el.getElementsByTagName("tr")[0];
        // deleteStyleByField(el);
        isGroup = ((await getRecordState()) & STATUS_GROUPED) != 0 && groups.length > 0;
        margin = await getLevelOffset();

        if (isGroup || isGroup != el.isGroup || me.source.CreateCollapsibleRecords !== null) {
          while (el.childElementCount > 1) {
            el.removeChild(el.lastChild);
          }

          if (isGroup) {
            await createGroupRecord(el, isCurrentRow, margin);
          } else {
            await createRecordColumns(el, isCurrentRow, margin);
          }
          if (viewInfo.horizontal && isCurrentRow) el.parentElement.parentElement.classList.add("grid-card-selected");
          else el.parentElement.parentElement.classList.remove("grid-card-selected");
          el.isGroup = isGroup;
        } else {
          if (isGroup) {
            let cl = el.children[2];
            cl.textContent = getGroupString();
            el.style.backgroundColor = "";
          } else {
            for (let i = 0, cl, col; i < el.childElementCount; i++) {
              cl = el.children[i];
              if (cl.field) {
                col = cl.col;
                const RecValue = await me.source.getFieldValue(cl.field.fieldName);
                cl.getElementsByClassName("grid-cell-inner")[0].textContent = await me.source.getFieldText(
                  cl.field.fieldName,
                );
                // cl.children[0].textContent = me.source.getFieldText(cl.field.fieldName);
                if (cl.children.length === 2 && cl.firstChild.type === "checkbox") {
                  cl.firstChild.checked = RecValue === "1" ? true : false;
                }
                if (RecValue) {
                  cl.getElementsByClassName("grid-cell-inner")[0].recid = RecValue;
                } else {
                  delete cl.getElementsByClassName("grid-cell-inner")[0].recid;
                }
                renderByFiledInfo(cl.field, cl, cl.getElementsByClassName("grid-cell-inner")[0], RecValue, false);
                CheckDrawInfo(cl.field.fieldName, cl);
                if (col == currentCol) {
                  cl.classList.remove("grid-item-focused");
                  //
                  if (isCurrentRow) {
                    // CreateStrelkaImg(cl)
                    if (!viewInfo.horizontal) cl.classList.add("grid-item-focused");
                    else el.classList.add("grid-card-selected");

                    selectedField = cl.field.fieldName;
                  }
                }
              }
            }
            // me.updateGridSize();
          }
        }
        resolve();
      });
      return promise;
    }

    const saveActiveRecord = me.source.activeRecord;
    await me.source.acquireRecords(firstRecord, visibleRows);
    let id; // = await me.source.getRecordID(saveActiveRecord);
    for (var i = 0; i < visibleRows; i++) {
      await drawRow(i, id);
    }

    me.source.activeRecord = saveActiveRecord;
  }

  async function buildRecords(load) {
    const promise = new Promise(async (resolve) => {
      if (true) {
        var last;
        if (me.data && me.data.lastChild) {
          while ((last = me.data.lastChild)) {
            me.data.removeChild(last);
          }
        }
      }

      if (!me.source) return resolve();

      me.table.style.top = groupSize + filterSize + titleSize + "px";

      var saveActiveRecord = me.source.activeRecord,
        indicatorForCard;

      visibleRows = moddiv(me.data.clientHeight, rowHeight);
      if (visibleRows > me.source.recordCount) {
        visibleRows = me.source.recordCount;
      }
      if (viewInfo.horizontal) {
        const title = panel.querySelector(".TitleForHorizontal");
        if (title) {
          let imageBlock = title.children[0],
            titleText = title.children[1];
          if (!imageBlock) imageBlock = document.createElement("div");
          else imageBlock.innerHTML = "";
          imageBlock.className = "imgCardMode";
          await CreateImageFields(imageBlock);
          if (!titleText) {
            indicatorForCard = createIndicator("indicatorForCard");
            titleText = document.createElement("div");
          } else {
            indicatorForCard = titleText.children[0];
          }
          titleText.innerText = me.getActiveSource().getRecordInfo().title;
          titleText.innerText = titleText.innerText === "undefined" ? "" : titleText.innerText;
          titleText.style.height = "100%";
          titleText.style.display = "grid";
          titleText.style.alignItems = "center";
          titleText.style.marginRight = "16px";
          if (!indicatorForCard) indicatorForCard = createIndicator("indicatorForCard");
          indicatorForCard.src = "";
          titleText.appendChild(indicatorForCard);
          title.appendChild(imageBlock);
          title.appendChild(titleText);
        }
      }

      async function createRecord(r) {
        if (viewInfo.horizontal && r === 0 && fixedColumn !== null) {
          me.source.activeRecord = 0 + r;
        } else {
          me.source.activeRecord = firstRecord + r;
        }
        if (me.needToRender && (await me.needToRender(me))) {
          return;
        }
        const promise = new Promise(async (resolve) => {
          var table = document.createElement("table"),
            tbody = document.createElement("tbody"),
            tr = document.createElement("tr"),
            isCurrentRow = currentRow === r,
            isGroup;
          tr.row = r;
          if (showIndicator) {
            let td = document.createElement("td");
            td.className = "grid-indicator";
            td.style.width = indicatorSize + "px";
            tr.appendChild(td);
          }

          isGroup = ((await getRecordState()) & STATUS_GROUPED) != 0;
          if (isGroup && groups.length > 0) {
            await createGroupRecord(tr, isCurrentRow, await getLevelOffset());
          } else {
            await createRecordColumns(tr, isCurrentRow, await getLevelOffset());
            // tr.style.animation = "grid-td-anim 200ms linear 0s 1 reverse backwards"
            await createStyleByField(table);
          }
          table.classList.add("grid-item");
          table.recordIndex = me.source.activeRecord;
          if (viewInfo.horizontal && isCurrentRow) table.classList.add("grid-card-selected");
          if (me.source.activeRecord % 2 != 0) {
            table.classList.add("grid-item-alt");
          }
          if (multiSelect.includes(`${me.source.activeRecord}`)) {
            createMultiRow(table);
          }
          table.style.width = "0pt";
          table.cellSpacing = "0";
          table.cellPadding = "0";
          tr.style.height = rowHeight + "px";
          tr.style.maxHeight = rowHeight + "px";
          tr.className = "grid-row";
          function addInd(img) {
            let parent = tr.firstChild;
            if (parent) {
              let ind = createIndicator("state");
              if (viewInfo.horizontal) ind = indicatorForCard;
              else parent.appendChild(ind);
              ind.setAttribute("src", img);
            }
          }
          switch (await me.getActiveSource().getUpdateStatus(me.getActiveSource().activeRecord)) {
            case usInserted:
              addInd(INDICATOR_IMAGE_INSERTED);
              break;
            case usModified:
              addInd(INDICATOR_IMAGE_MODIFIED);
              break;
          }

          if (isCurrentRow) placeIndicator(tr);
          if (imageFields.length > 0 && !viewInfo.horizontal) {
            await CreateImageFields(tr.firstChild);
            // tr.firstChild.appendChild(IndicatorImageFields);
          }
          tbody.appendChild(tr);
          table.appendChild(tbody);
          me.changeGridStyles && (await me.changeGridStyles(table));

          me.data.appendChild(table);
          // appendEvent(
          //   "mouseover",
          //   function () {
          //     table.classList.add("grid-item-over");
          //   },
          //   table
          // );
          // appendEvent(
          //   "mouseleave",
          //   function () {
          //     table.classList.remove("grid-item-over");
          //   },
          //   table
          // );
          resolve();
        });
        return promise;
      }

      if (me.source.recordCount < firstRecord) firstRecord = 0;
      if (getLastRecord() >= me.source.recordCount) {
        firstRecord -= getLastRecord() - (me.source.recordCount - 1);
      }
      if (me.source.recordCount === 0) {
        await createRecord(0);
        const emptyRow = getRowElement(0).firstChild.firstChild;
        if (emptyRow.classList.contains("grid-row")) {
          emptyRow.empty = true;
        }
      } else {
        await me.source.acquireRecords(firstRecord, visibleRows, load);
        me.onRecordCountChange && me.onRecordCountChange(me);
        // const id = await me.source.getRecordID(saveActiveRecord);
        for (var i = 0; i < visibleRows; i++) {
          await createRecord(i);
        }
      }

      me.source.activeRecord = saveActiveRecord;
      createResizers();
      resolve();
    });
    return promise;
  }

  function getFixedCol() {
    if (fixedColumn !== null) {
      if (!viewInfo.horizontal) {
        if (me.columns.title && me.columns.title[0].fieldName !== fixedColumn.fieldName && fixedColumn.level === 0) {
          let items = me.columns.title,
            i,
            j,
            item = fixedColumn;
          i = items.indexOf(item);
          if (i != -1) {
            j = 0;
            if (j != -1) {
              items.splice(i, 1);
              items.splice(j, 0, item);
            }
          }
          prepareLevels();
        }
      }
    }
  }

  function getEl(json, fieldName) {
    for (const value of Object.values(json)) {
      if (value.items) {
        getEl(value.items, fieldName);
      }
      if (value.field.fieldName === fieldName) return value;
    }
  }

  async function createStyleByField(table, isGroup) {
    if (table) {
      if (me.BkClrField && !isGroup) {
        //bg color
        table.style.backgroundColor = setColor(Number(await me.source.getFieldText(me.BkClrField)));
      } else {
        table.style.backgroundColor = "";
      }
      if (me.ColorField && !isGroup) {
        //Цвет шрифта
        table.style.color = setColor(Number(await me.source.getFieldText(me.ColorField)));
      } else {
        table.style.color = "";
      }
      if (me.StyleField && !isGroup) {
        // стиль шрифта
        const style = Number(await me.source.getFieldText(me.StyleField));
        table.style.fontWeight = style & 1 ? "bold" : "";
        table.style.fontStyle = style & 2 ? "italic" : "";
        table.style.textDecoration = style & 4 ? "underline" : style & 8 ? "line-through" : "";
      } else {
        table.style.fontWeight = "";
        table.style.fontStyle = "";
        table.style.textDecoration = "";
      }
      const recordData = await me.getActiveSource().getRecordData();
      if (recordData.info.hidden) {
        table.style.textDecoration = "line-through";
      }
    }
  }

  function deleteStyleByField(tr) {
    if (tr) {
      tr.style.backgroundColor = "";
      tr.style.color = "";
      tr.style.fontWeight = "";
      tr.style.fontStyle = "";
      tr.style.textDecoration = "";
    }
  }

  async function CreateImageFields(el) {
    const promise = new Promise(async (resolve) => {
      if (el && el.firstChild && el.firstChild.id === "ImageFields") {
        el.firstChild.remove();
      }
      if (viewInfo.horizontal) console.log("imageFields");
      let arrOfDOMImages = document.createElement("div");
      arrOfDOMImages.classList.add("grid-image-fields");
      // arrOfDOMImages.style.height = 16 + "px";
      // arrOfDOMImages.style.display = "flex";
      // arrOfDOMImages.style.alignItems = "center";
      // arrOfDOMImages.style.overflow = "hidden";
      // arrOfDOMImages.style.whiteSpace = "nowrap";
      // arrOfDOMImages.style.textOverflow = "ellipsis";
      // arrOfDOMImages.style.marginLeft = "3px";
      arrOfDOMImages.id = "ImageFields";
      arrOfDOMImages.onmouseover = function (e) {
        const imgF = e.target;
        if (imgF.imgIndex && imgF.doccfgid && imgF.docid && (imgF.title === "" || imgF.title === undefined)) {
          const img = e.target;
          const titl = getImageHint(imgF.doccfgid, imgF.imgIndex, imgF.docid);
          if (titl !== undefined && !isEmptyObject(titl)) img.title = titl;
        }
      };
      for (const img of imageFields) {
        let imgIndex =
          me.getActiveSource().booksGetImage !== undefined
            ? me.getActiveSource().booksGetImage(await me.getActiveSource().getFieldText(img), img)
            : await me.getActiveSource().getFieldText(img);
        if (imgIndex && imgIndex !== "Редактируется") {
          imgIndex = parseInt(imgIndex);
          if (isNaN(imgIndex)) {
            imgIndex = parseInt(await me.source.getFieldValue(img));
          }
          const imgF = document.createElement("img");
          imgF.style.width = "16px";
          imgF.style.height = "16px";
          const icon = await getIconsAsync(imgIndex);
          if (icon !== undefined && (icon !== null) & (imgIndex !== 0)) {
            imgF.src = ImgURLSRC(icon);
            imgF.imgIndex = imgIndex;
            imgF.docid = await me.getActiveSource().getFieldText("ID");
            imgF.doccfgid = await me.getActiveSource().getFieldText("DocCfgID");
            arrOfDOMImages.appendChild(imgF);
          }
        }
      }
      el.appendChild(arrOfDOMImages);
      // return arrOfDOMImages;
      resolve();
    });
    return promise;
  }

  function placeIndicator(tr) {
    if (tr && showIndicator) {
      let oldPar = indicator.parent,
        newPar = tr.firstChild;
      if (viewInfo.horizontal && tr.className === "grid-indicator") newPar = tr;
      if (newPar) {
        indicator.parent = null;
        if (oldPar && oldPar.childElementCount > 0 && oldPar.firstChild.style.display !== "flex") {
          oldPar.firstChild.style.display = "";
        }
        if (newPar.childElementCount > 0 && newPar.id !== "ImageFields" && newPar.firstChild.id !== "ImageFields") {
          newPar.firstChild.style.display = "none";
        }
        newPar.appendChild(indicator);
        indicator.parent = newPar;

        let updated = sourceActive() && me.source.getUpdateStatus(me.source.activeRecord) > 0;
        if (indicator.updated !== updated) {
          indicator.updated = updated;
          if (updated) {
            indicator.setAttribute("src", INDICATOR_IMAGE_UPDATED);
          } else indicator.setAttribute("src", INDICATOR_IMAGE_BROWSE);
        }
      }
    }
  }

  async function applyFilter(filtered) {
    if (sourceActive()) {
      var filter = {};
      const currentfilters = {};
      // проверка наличия отфильтрованных полей и заполнение объекта отфильтрованными полями
      for (const [key, value] of Object.entries(filterData)) {
        if (value.filtered) {
          currentfilters[key] = value.value;
        }
      }

      if (filtered) {
        for (let i = 0, el, f, t; i < me.filter.childElementCount; i++) {
          el = me.filter.children[i];
          f = el.field;
          if (f && el.value) {
            // удаление фильтрации текущего поля если оно было отфильтровано ранее
            if (currentfilters[f.fieldName]) {
              // кнопка фильтрации внутри поля
              const filterBttn = f.el.querySelector(`#filter-bttn_${f.fieldName}`);

              // очистка состояния кнопки фильтрации внутри поля
              if (filterBttn) filterBttn.clearFilter();

              // очистка данных из объекта фильтрации для состояния таблицы
              delete filterData[f.fieldName];
              // очистка данных из объекта для запроса ApplyFilter
              delete currentfilters[f.fieldName];
            }

            t = {};
            Object.defineProperty(t, el.value, {
              value: null,
              configurable: true,
              writable: true,
              enumerable: true,
            });
            t.$tag = 1;
            Object.defineProperty(filter, f.fieldName, {
              value: t,
              configurable: true,
              writable: true,
              enumerable: true,
            });
          }
        }
        filterData.rowFilter = filter;
      }

      saveState();
      await me.source.applyFieldFilter({ ...filter, ...currentfilters });
      await updateRecordTable();
      scrollToRecord(me.source.activeRecord, true);
      me.onMultiSelectChange && me.onMultiSelectChange(me);
    }
  }

  function buildFilters() {
    if (filterSize == 0) return;
    var el = me.filter,
      top = me.filter.getBoundingClientRect().top;
    while (el.childElementCount > 0) {
      let ch = el.lastChild;
      if (ch.field) {
        ch.field.filterText = ch.value;
      }
      el.removeChild(ch);
    }
    const applyButton = document.createElement("button");
    applyButton.style.position = "relative";
    applyButton.style.top = "1px";
    applyButton.style.left = "2px";
    applyButton.style.width = `calc(${indicatorSize}px - 5px)`;
    applyButton.style.height = "20px";
    applyButton.filtered = filterData.rowFilterFiltered ? true : false;
    applyButton.innerText = filterData.rowFilterFiltered ? "Вкл" : "Выкл";
    applyButton.onclick = (ev) => {
      if (ev.target.filtered) {
        ev.target.innerText = "Выкл";
      } else {
        ev.target.innerText = "Вкл";
      }
      ev.target.filtered = !ev.target.filtered;
      filterData.rowFilterFiltered = ev.target.filtered;
      saveState();
      applyFilter(ev.target.filtered);
    };
    me.filter.appendChild(applyButton);
    for (let i = 0, fields = me.columns.fields, f; i < fields.length; i++) {
      f = fields[i];
      if (filterData.rowFilter && filterData.rowFilter[f.fieldName]) {
        f.filterText = Object.keys(filterData.rowFilter[f.fieldName])[0];
      }
      el = document.createElement("input");
      el.style.position = "absolute";
      el.style.top = "1px";
      el.style.left = f.offset + "px";
      el.style.width = f.elementWidth - 4 + "px";
      el.style.height = filterSize - 6 + "px";
      el.field = f;
      el.classList.add("grid-filter");
      el.role = ROLE_FILTER;
      if (f.filterText) {
        el.value = f.filterText;
      }
      el.onkeydown = function (e) {
        if (e.key == "Enter") {
          if (!applyButton.filtered) {
            applyButton.innerText = "Вкл";
            applyButton.filtered = true;
          }
          filterData.rowFilterFiltered = applyButton.filtered;
          saveState();
          applyFilter(applyButton.filtered);
        }
      };

      el.onblur = () => {
        if (applyButton.filtered) applyFilter(true);
      };

      me.filter.appendChild(el);
    }
    me.switchFilter = function (enabled) {
      if (enabled) {
        applyButton.innerText = "Вкл";
        applyButton.filtered = true;
        filterData.rowFilterFiltered = applyButton.filtered;
        saveState();
        applyFilter(applyButton.filtered);
      } else {
        applyButton.innerText = "Выкл";
        applyButton.filtered = false;
        filterData.rowFilterFiltered = applyButton.filtered;
        saveState();
        applyFilter(applyButton.filtered);
      }
    };
  }

  function updateFilters() {
    if (filterSize == 0) return;
    for (let i = 0, el, f; i < me.filter.childElementCount; i++) {
      el = me.filter.children[i];
      f = el.field;
      if (f) {
        el.style.left = f.offset + "px";
        el.style.width = f.elementWidth - 4 + "px";
        el.setAttribute("tabindex", me.columns.fields.indexOf(f) + 1);
      }
    }
  }

  function getRowElement(r) {
    return me.data.children[r];
  }

  function getColElement(c, r) {
    var rEl = getRowElement(r);
    const colIndex = c - columnOffset;
    if (rEl) {
      rEl = rEl.getElementsByTagName("tr");
      if (rEl) {
        rEl = rEl[0];
        if (showIndicator) {
          return rEl.children[colIndex + 2];
        } else return rEl.children[colIndex + 1];
      }
    }
    return null;
  }

  async function setColumnText(column, text) {
    await me.getActiveSource().setFieldText(column.fieldName, text);
  }

  async function setColumnValue(column, id) {
    await me.getActiveSource().setFieldValue(column.fieldName, id);
  }

  function ClickAway(ev) {
    if (
      ev.target.closest("[checklist]") ||
      ev.target.closest("[calendar]") ||
      ev.target.closest("[details]") ||
      (ev.target.closest("SMART-WINDOW") &&
        ev.target.closest("SMART-WINDOW") !== inputEditor.closest("SMART-WINDOW")) ||
      ev.target.closest("UL") ||
      ev.target.classList.contains("CloseButtonForGrid") ||
      ev.target.classList.contains("ApplyButtonForGrid") ||
      ev.target.tagName === "BODY" ||
      ev.target.closest("[loadmask]") ||
      (viewInfo.horizontal && ev.target.closest("TR") && ev.target.closest("TR").querySelector("[componenteditor]"))
      // ev.target.classList.contains("MuiBackdrop-root") ||
    ) {
      if (
        ev.target.closest("[details]") ||
        (ev.target.closest("SMART-WINDOW") &&
          ev.target.closest("SMART-WINDOW") !== inputEditor.closest("SMART-WINDOW") &&
          inputEditor)
      ) {
        return;
      }
      inputEditor.focus();
      inputEditor.select();
      return;
    }

    if (editor.parentElement && !editor.parentElement.contains(ev.target)) {
      hideEditor();
      deleteEvent("click", ClickAway);
    }
  }

  function SetObjectText(EditData) {
    let params = new Map();
    params.set("prefix", EditData.type);
    params.set("comand", "SetObjectText");
    params.set("Flag", "-1");
    params.set("ObjType", EditData.datatype ? EditData.datatype : "0");
    params.set("ObjRef", EditData.addInfo.Cell.recid ? EditData.addInfo.Cell.recid : EditData.tag ? EditData.tag : "0");
    params.set("Text", EditData.value === "__.__.____" ? "" : EditData.value);
    return AxiosRequest(true, params);
  }

  function GetColumnStyle(ID) {
    let params = new Map();
    params.set("prefix", "documents");
    params.set("comand", "GetColumnStyle");
    params.set("SectionID", me.sectionid);
    params.set("ID", ID);
    params.set("DocID", me.source.getFieldTextSync("ID"));
    params.set("MasterID", me.source.Master.MasterID ? me.source.Master.MasterID : "0");
    params.set("DocCfgID", me.doccfgid);
    params.set("WSM", "1");
    return AxiosRequest(true, params);
  }

  // рендерит редактор в переданный котнейнер
  async function renderEditor(data, editorDiv) {
    appendEvent("click", ClickAway);
    const fieldIndex = me.getActiveSource().getFieldIndex(data.field.fieldName);
    const fieldData = me.getActiveSource().fields[fieldIndex];

    let EditStyle = Number(data.field.fieldInfo.EditStyle),
      DataType = data.field.fieldInfo.DataType,
      Module = data.field.fieldInfo.Module,
      Field = data.field.fieldName,
      List = data.field.fieldInfo.Values?.length > 0 ? "" : fieldData.list?.join("\r\n"),
      ListValues = data.field.fieldInfo.Values,
      Details = data.field.fieldInfo.Details,
      AccDetails = data.field.fieldInfo.AccDetails,
      DetailsByReq = data.field.fieldInfo.DetailsByReq,
      ButtonImage = data.field.fieldInfo.ButtonImage,
      EditMask = data.field.fieldInfo.EditMask,
      OnEdit = data.field.fieldInfo.OnEdit,
      OnList = data.field.fieldInfo.OnList,
      OnListCustom = data.field.fieldInfo.OnListCustom,
      OnCloseUpList = data.field.fieldInfo.OnCloseUpList,
      OnGetEditStyle = data.field.fieldInfo.OnGetEditStyle,
      Path = data.field.fieldInfo.Path,
      FieldName = data.field.fieldInfo.FieldName,
      ColumnID = data.field.fieldInfo.ColumnID,
      Link = data.field.OptionsDisable ? data.field.OptionsDisable.OnEdit : undefined,
      DetailsData,
      onEditButtonClick = data.field.onEditButtonClick,
      onDropDownList = data.field.onDropDownList,
      HardCodeReadOnlyForEditor = data.field.fieldInfo.HardCodeReadOnlyForEditor;

    if (!onDropDownList) {
      onDropDownList = async (data) => {
        //
        let json;
        const params = new Map();
        if (data.onlist) {
          params
            .set("prefix", "documents")
            .set("comand", "GetColumnValues")
            // ID=5059&SectionID=108&DocCfgID=3008&DocID=0&MasterID=0&WSM=1
            .set("ID", ColumnID)
            .set("SectionID", me.sectionid)
            .set("DocCfgID", me.doccfgid)
            .set(
              "DocID",
              me.getActiveSource().getFieldTextSync("ID") ? me.getActiveSource().getFieldTextSync("ID") : "0",
            )
            .set("MasterID", me.getActiveSource().Master.MasterID ? me.getActiveSource().Master.MasterID : "0")
            .set("WSM", "1");
          json = await AxiosRequest(true, params);
        } else {
          params.set("prefix", data.module).set("comand", "GetObjectValues").set("ObjType", data.datatype);
          json = await AxiosRequest(true, params);
        }
        if (json && json.Items) {
          return json.Items.map((item, index) => {
            if (typeof item === "object") {
              return { label: item.text, id: item.id };
            }
            return { label: item, id: index };
          });
        }
      };
    }

    if (OnGetEditStyle) {
      let columnStyle = await GetColumnStyle(ColumnID);
      if (columnStyle.EditStyle) EditStyle = Number(columnStyle.EditStyle);
      if (columnStyle.Token) {
        tokenProcessingTest(columnStyle);
      }
    }

    EditStyle += Number(data.field.fieldInfo.Options) & 4 && !(EditStyle & 64) ? 64 : 0;

    let ParentID = await me.getActiveSource().getFieldText("ID");
    if (Details !== "" && Details !== undefined) {
      EditStyle += !(EditStyle & 64) ? 64 : 0;
      DetailsData = await GetColumnDetails(Details, ParentID, Path, FieldName, Module);
    } else if (AccDetails) {
      EditStyle += !(EditStyle & 64) ? 64 : 0;
      const subData = await GetColumnDetails(undefined, ParentID, Path, FieldName, "accounts");
      if (subData && subData.Items) {
        DetailsData = {
          JSONType: subData.Items.JSONType,
          Items: subData.Items.items,
        };
      }
    } else if (DetailsByReq) {
      DetailsData = await DetailsByReq({ ID: ParentID });
    }

    EditStyle -= EditStyle & 4 ? 4 : 0;
    if (data.cell.style.marginLeft) {
      editorDiv.style.marginLeft = data.cell.style.marginLeft;
    }
    ReactDOM.render(
      // редактор
      // название свойства === название ключа(это необязательно, но нужно для удобства)
      <TestEditor
        // костыль для специфичной работы таблицы Просмотра проводок
        HardCodeReadOnlyForEditor={HardCodeReadOnlyForEditor}
        editor={editorDiv}
        grid={true}
        editStyle={isNaN(EditStyle) ? data.field.fieldInfo.EditStyle : EditStyle}
        editMask={EditMask}
        value={data.value}
        datatype={DataType}
        objref={data.cell.recid}
        type={Module}
        list={List}
        listValues={ListValues}
        onlist={OnList}
        onListCustom={OnListCustom}
        doubleClickOnInput={true}
        settings={{ link: Link, onEdit: OnEdit }}
        buttonImage={ButtonImage}
        docProps={{
          ID: ColumnID,
          DocCfgID: me.doccfgid,
          SectionID: me.sectionid,
          DocID: me.getActiveSource().getFieldTextSync("ID"),
          MasterID: me.getActiveSource().Master.MasterID ? me.getActiveSource().Master.MasterID : "0",
          Reference: "0",
        }}
        details={
          (Details || AccDetails || DetailsByReq) && !isEmptyObject(DetailsData)
            ? {
                Details,
                AccDetails,
                DetailsByReq,
                OnEdit: DetailsData.OnEdit,
                OnEditCustom: DetailsData.OnEditCustom,
                OnList: DetailsData.OnList,
                OnListCustom: DetailsData.OnListCustom,
                OnCloseUpList: DetailsData.OnCloseUpList,
                OnCloseUpListCustom: DetailsData.OnCloseUpListCustom,
                ID: ParentID,
                OnGetEditStyle,
                GetDetails: DetailsData,
                getText: data.getText,
                updateRecord: data.updateRecord,
                Path,
                FieldName,
                Module,
                HardCodeReadOnlyForEditor,
              }
            : undefined
        }
        clickAway={(ev, edit, value) => {
          if (
            (ev.target.closest("SMART-WINDOW") &&
              ev.target.closest("SMART-WINDOW") !== inputEditor.closest("SMART-WINDOW")) ||
            ev.target.closest(".CloseButtonForGrid")
          ) {
            return;
          }
          if (editor.ready) {
            edit(undefined, value);
          }
        }}
        onChange={async (val, ChangeData) => {
          editor.stepValue = val;
          if (data.value !== val) {
            if (!editorDiv.InputChange && ChangeData.valueChanged.current === true) {
              editorDiv.InputChange = true;
              // if (ChangeData.addInfo.Cell.closest("TR").empty !== true)
              me.CommitToSaveChanges && me.CommitToSaveChanges(me);
              me.getActiveSource().modified = true;
            }
            if (me.getActiveSource().getState() != dsInsert) await changeIndicator(dsEdit);
            // me.postSomeData(Field, { text: val })
          }
        }}
        onEditList={
          OnCloseUpList
            ? async (data) => {
                const params = new Map();
                params
                  .set("prefix", "documents")
                  .set("comand", "CloseUpList")
                  .set("ID", ColumnID)
                  .set("SectionID", me.sectionid)
                  .set("DocCfgID", me.doccfgid)
                  .set(
                    "DocID",
                    me.getActiveSource().getFieldTextSync("ID") ? me.getActiveSource().getFieldTextSync("ID") : "0",
                  )
                  .set("MasterID", me.getActiveSource().Master.MasterID ? me.getActiveSource().Master.MasterID : "0")
                  .set("Text", data.value ? data.value : "")
                  .set("ObjRef", data.tag ? data.tag : "0")
                  .set("WSM", "1");
                const json = await AxiosRequest(true, params);
                if (data.setValue) {
                  data.setValue({ label: data.value, id: data.tag });
                }
                if (data.setInputValue) {
                  data.setInputValue(data.value);
                }

                tokenProcessingTest(json, {
                  from: "grid",
                  func: () => {
                    if (me.source.Master.refreshRecord) {
                      me.source.Master.refreshRecord(true);
                    } else {
                      me.refreshSource("!");
                    }
                  },
                });
              }
            : undefined
        }
        onDropDownList={onDropDownList}
        addInfo={{
          FieldName: Field,
          Field: data.field,
          Cell: data.cell,
          Index: data.index,
          ModalButtonCallBack: () => {
            if (me.source.Master.refreshRecord) {
              me.source.Master.refreshRecord(true);
            } else {
              me.refreshSource("!");
            }
          },
          onEditButtonClick: onEditButtonClick,
        }}
        onEdit={onEdit}
        onEditButtonClick={(EditButtonData) => {
          tokenProcessingTest(EditButtonData.json, EditButtonData.subData);
        }}
      />,
      editorDiv,
    );
  }

  async function onEdit(EditData) {
    console.log(EditData);
    let response,
      oldRecordCount = me.getActiveSource().recordCount;
    editor.stepValue = undefined;
    if (EditData.requestId) {
      if (EditData.addInfo.Field.OptionsDisable.link && EditData.addInfo.editButtonClick) {
        await onEdit({ ...EditData, requestId: undefined });
        const params = new Map();
        params.set("prefix", "project");
        params.set("comand", "ResumeRequest");
        params.set("RequestID", EditData.requestId);
        params.set("WSM", "1");
        const json = await AxiosRequest(true, params);
        tokenProcessingTest(json);
        return;
      }
      const params = new Map();
      params.set("prefix", "project");
      params.set("comand", "ResumeRequest");
      params.set("RequestID", EditData.requestId);
      params.set("WSM", "1");
      const json = await AxiosRequest(true, params, {
        Result: "1",
        [EditData.docID ? "DocID" : "ObjRef"]: EditData.tag,
      });
      tokenProcessingTest(json, {
        from: "grid",
        func: () => {
          if (me.source.Master.refreshRecord) {
            me.source.Master.refreshRecord(true);
            me.CommitToSaveChanges && me.CommitToSaveChanges(false);
          } else {
            me.refreshSource("!");
            me.CommitToSaveChanges && me.CommitToSaveChanges(false);
          }
        },
      });
    } else {
      if (
        EditData.addInfo.Cell.closest("TR").empty === true &&
        !(EditData.requestId && EditData.addInfo.Field.fieldInfo.OnEdit)
      ) {
        await me.getActiveSource().appendRecord();
        // changeIndicator(dsInsert);
      }
      if (EditData.type && (EditData.value || EditData.tag) && EditData.proof) {
        let tag = EditData.tag;
        if (EditData.proof) {
          const json = await SetObjectText(EditData);
          if (json.Selection && json.Selection.length) {
            editor.selection = true;

            openModal(
              <DialogSetObjText
                list={json.Selection}
                onEdit={EditData.onEdit}
                data={EditData}
                // record={data.record}
                // CheckState={data.CheckState}
              />,
              {
                Title: "Выберите элемент из списка",
                style: getStyleWindow(),
                hiddenButton: true,
                blockMaximize: true,
              },
            );
            return;
          }
          if (json.CreateNew) {
            editor.selection = true;
            openModal(
              <DialogCreateObj
                onEdit={EditData.onEdit}
                inputNameObj={json.CreateNew.Text}
                spanText={json.CreateNew.Label}
                CLSID={json.CreateNew.CLSID}
                ObjType={EditData.datatype}
                Module={EditData.type}
                groups={json.CreateNew.Groups}
                data={EditData}
              />,
              {
                Title: "Создать новый объект",
                style: getStyleWindow(`${json.CreateNew.CLSID}${json.CreateNew.Groups ? "Group" : ""}`),
                hiddenButton: true,
                blockMaximize: true,
              },
            );
            return;
          }
          tag = json.ObjRef;
        }
        if (tag === "-1") {
          return;
        }
        response = await me.getActiveSource().CheckFieldText(EditData.addInfo.FieldName, tag);
        if (!response.Text) response.Text = EditData.value;
      } else {
        response = {
          EditVal: EditData.value,
          ObjRef: EditData.tag,
          Text: EditData.value === null ? editor.checkvalue : EditData.value,
        };
      }

      if (EditData.setValue) {
        EditData.setValue({
          label: EditData.fixVal ? EditData.fixVal(response.EditVal) : response.EditVal,
          id: response.ObjRef,
        });
      }
      if (EditData.setInputValue) {
        EditData.setInputValue(EditData.fixVal ? EditData.fixVal(response.EditVal) : response.EditVal);
      }

      // if (!me.getActiveSource().isEditMode()) {
      //   await me.getActiveSource().editRecord();
      //   // changeIndicator(dsEdit);
      // }
      inputEditor.modified = true;
      editor.content = response.Text;
      editor.objref = response.ObjRef;
      // editor.visible = false;
      EditData.addInfo.Cell.text = response.Text;
      EditData.addInfo.Cell.editval = response.EditVal;
      EditData.addInfo.Cell.recid = response.ObjRef;

      if (editor.column === null) {
        EditData.addInfo.Cell.innerText = response.Text;
        // await updateRecordTable(true);
        // scrollToRecord(me.source.activeRecord, true);
      }
      // me.postRecord(EditData.addInfo.Index);
      if (viewInfo.horizontal && multiSelect.length > 0) {
        //PostRecord
        const source = me.getActiveSource(),
          saveacrivrecord = source.activeRecord;
        for (const value of Object.values(multiSelect)) {
          source.activeRecord = Number(value);
          await postSomeDataEditor(EditData, response);
        }
        source.activeRecord = saveacrivrecord;
      } else {
        await postSomeDataEditor(EditData, response);
      }

      me.CommitToSaveChanges && me.CommitToSaveChanges(false);

      if ((await me.getActiveSource().getState()) != dsInsert) await changeIndicator(dsBrowse);

      // if (EditData.addInfo.Cell.closest("TR").empty === true) {
      if (viewInfo.horizontal) {
        await me.getActiveSource().getFieldText("ID");
        await updateCard();
      } else {
        await UpdateOrBuild(oldRecordCount, false);
        // await updateRecordTable();
        await scrollToRecord(currentRow + firstRecord, true);
      }
      editor.stepValue = undefined;
      if (oldRecordCount !== me.getActiveSource().recordCount && editor.visible) {
        await showEditor();
      } else if (inputEditor) {
        inputEditor.focus();
      }
    }
  }

  async function postSomeDataEditor(data, res) {
    if (!me.getActiveSource().isEditMode()) {
      await me.getActiveSource().editRecord();
      // changeIndicator(dsEdit);
    }
    await setColumnText({ fieldName: data.addInfo.FieldName }, res.Text);

    if (editor.objref) await setColumnValue({ fieldName: data.addInfo.FieldName }, res.ObjRef);

    const indexForPostRecord = viewInfo.horizontal ? me.getActiveSource().activeRecord : data.addInfo.Index;
    await me.getActiveSource().postRecord(indexForPostRecord);
  }

  async function showEditor(key) {
    if ((await isGroupRecord(false)) === true) return;

    function getModifyFlag() {
      let source = me.getActiveSource();
      if (source.isEmpty()) return mfInsert;
      else return mfEdit;
    }
    // getFieldForEditor();
    let el = getColElement(currentCol, currentRow),
      column;

    if (el) {
      if (viewInfo.horizontal) {
        currentCol = 1;
        el = getColElement(1, currentRow);
        column = getColElement(0, currentRow).levelsField.field;
      } else column = el.field;
      const cellInner = el.getElementsByClassName("grid-cell-inner")[0];
      // const fieldInfo =
      //   cellInner.parentElement.field.fieldInfo === undefined
      //     ? true
      //     : (Number(cellInner.parentElement.field.fieldInfo.Options) & 8) === 0;
      if (cellInner.parentElement.field.fieldInfo === undefined) {
        cellInner.parentElement.field.fieldInfo = {
          DataType: "",
          EditStyle: "",
          Options: "",
          TitleAjust: "",
          TextAjust: "",
          Module: "",
          Values: [],
        };
      }

      if (column && canModifyViewInfo(getModifyFlag(), column, me)) {
        // данные для редактора
        // объект для передачи данных в редактор
        async function getEditValue(cell) {
          if (!cell.editval) {
            const EditValue = await me.getActiveSource().GetFieldTextRequest("1", column.fieldName); //Запрос к серверу, можно ли модифицировать
            cell.editval = EditValue.Text === undefined ? "" : EditValue.Text;
          }
          cell.text = cell.innerText;
          return cell.editval;
        }

        const editData = {
          value: editor && editor.stepValue !== undefined ? editor.stepValue : await getEditValue(cellInner),
          cell: cellInner,
          field: viewInfo.horizontal ? column : cellInner.parentElement.field,
          index: currentRow + firstRecord,
          getText: async () => {
            const EditValue = await me.getActiveSource().GetFieldTextRequest("1", column.fieldName); //Запрос к серверу, можно ли модифицировать
            return EditValue.Text === undefined ? "" : EditValue.Text;
          },
          updateRecord: () => {
            if (me.source.Master.refreshRecord) {
              me.source.Master.refreshRecord(true);
            } else {
              me.getActiveSource().onHandleRequest({
                Command: "RefreshRecord",
                Count: "1",
                RecordKey: me.getActiveSource().getFieldTextSync("ID"),
              });
              me.refreshSource("!");
            }
          },
        };

        // Добавление редактора в ячейку
        // Создание контейнера для рендера
        editor = document.createElement("div");
        // настройка стилей контейнера
        editor.style.height = "21px";
        // рендер реакт компонента внутри созданного контейнера
        // рендерить редактор необходимо каждый раз, чтобы была возможность менять входные данные
        editor.column = column;
        // cellInner.style.display = "none"

        await renderEditor(editData, editor);

        editor.ready = true;
        // получение элемента input
        inputEditor = editor.querySelector("[inputeditor=true]");

        // inputEditor.onkeydown = function () {
        //   if (!me.source.isEditMode()) {
        //     me.source.editRecord();
        //     changeIndicator(dsEdit);
        //   }
        //   inputEditor.modified = inputEditor.content !== inputEditor.value;
        // };

        editor.el = el;
        el.appendChild(editor);
        editor.visible = true;
        let cell = el.getElementsByClassName("grid-cell-inner")[0];
        // el.classList.remove("grid-item-focused");
        el.style.backgroundColor = "white";
        if (cell) {
          const img = cell.parentElement.querySelector(".grid-cell-img");
          if (img) img.style.display = "none";
          inputEditor.content = cell.textContent;
          inputEditor.value = cell.textContent;
          cell.textContent = "";
          cell.style.display = "none";
          // фокусировка после полного рендера компонента
          setTimeout(() => {
            inputEditor.focus();
            inputEditor.select();
            const editorBlock = el.querySelector(`div[componenteditor="true"]`);
            if (editorBlock && key !== "" && key !== undefined) {
              editorBlock.changeValue(key);
            }
          });
        }
        inputEditor.modified = false;
      }
    }
  }

  function canModifyViewInfo(modifyFlag, column, me) {
    let canModify;
    if (viewInfo.horizontal && multiSelect.length > 0) {
      const source = me.getActiveSource(),
        saveacrivrecord = source.activeRecord;
      for (const value of Object.values(multiSelect)) {
        source.activeRecord = Number(value);
        canModify = me.canModify(modifyFlag, column, me);
        if (canModify === false) {
          source.activeRecord = saveacrivrecord;
          break;
        }
      }
      return canModify;
    } else {
      canModify = me.canModify(modifyFlag, column, me);

      return canModify;
    }
  }

  function hideEditor(update) {
    if (editor && editor.visible) {
      editor.stepValue = undefined;
      editor.visible = false;
      if (editor.SearchRecord) {
        editor.onblur({ currentTarget: editor });
        return;
      }
      let el = editor.el;
      if (el) {
        el.style.backgroundColor = "";
        let cell = el.getElementsByClassName("grid-cell-inner")[0];
        cell.style.display = "";
        if (cell) {
          cell.innerText = cell.text;

          delete cell.editval;
          //   if (update) {
          //     setColumnText(editor.column, cell.text);
          //     cell.textContent = me.source.getFieldText(editor.column.fieldName);
          //   } else cell.textContent = cell.text;
        }
      }
      if (el && el.contains(editor)) {
        el.removeChild(editor);
        editor.el = null;
      }
      editor.column = null;

      panel.focus();
      // me.CommitToSaveChanges && me.CommitToSaveChanges(false);
      // changeIndicator(dsBrowse);
      // return updateRecordTable(true);
      // scrollToRecord(me.source.activeRecord, true);
    }
  }
  function updateEditor() {}

  function getActiveRecord() {
    return currentRow + firstRecord;
  }
  function getFirstRecord() {
    return firstRecord;
  }
  function getLastRecord() {
    return firstRecord + visibleRows - 1;
  }

  async function moveColRow(newCol, newRow, e) {
    return new Promise(async (resolve) => {
      selectedField = null;
      async function scrollData(distance) {
        me.source.moveBy(distance);
        let bool;
        //
        // if (moddiv(me.data.clientHeight, rowHeight) !== me.source.recordCount && me.source.activeRecord < getLastRecord())distance = 0
        // else
        if (me.source.activeRecord > getLastRecord()) {
          distance = me.source.activeRecord - getLastRecord();
        } else if (me.source.activeRecord < getFirstRecord()) {
          distance = me.source.activeRecord - getFirstRecord();
        } else distance = 0;
        if (me.source.recordCount === 0) distance = 0;
        if (distance != 0) {
          firstRecord += distance;
          currentRow = me.source.activeRecord - firstRecord;
          // drawing = true
          await updateCurrentView();
          // drawing = false
          bool = true;
          // return false;
        } else {
          currentRow = me.source.activeRecord - firstRecord;
          bool = false;
        }
        multiSelectStyles(e, rowDelta, Array.prototype.indexOf.call(me.data.children, el));
        me.onMultiSelectChange && me.onMultiSelectChange(me);
        return bool;
      }

      let el,
        colDelta = newCol - currentCol,
        rowDelta = newRow - currentRow;
      if (currentRow === -1) {
        currentRow = 0;
      }

      if (colDelta || rowDelta) {
        // hideEditor(true);
        if (
          newRow !== currentRow &&
          me.getActiveSource() &&
          me.source.recordCount > 0 &&
          me.source.isEditModeState() === 3
        ) {
          //для отмены добавления новой записи, при клике на другую запись.
          if (newRow > currentRow) {
            await me.cancelRecord(false);
            rowDelta = newRow - (currentRow + 1);
          } else {
            await me.cancelRecord(false);
            rowDelta = newRow - currentRow;
          }
          // if(newRow>currentRow) rowDelta = newRow - currentRow; else rowDelta=0;

          // return
        }
        el = getColElement(currentCol, currentRow);
        let needToChangeFocused = true;
        if (
          me.source.recordCount !== 0 &&
          rowDelta !== me.source.recordCount &&
          rowDelta !== -Math.abs(me.source.recordCount)
        ) {
          if ((currentRow === 0 && rowDelta === -1) || -Math.abs(visibleRows) === rowDelta) {
            needToChangeFocused = false;
          } else if (currentRow + rowDelta === visibleRows || visibleRows === rowDelta) {
            needToChangeFocused = false;
          }
        }
        if (el && needToChangeFocused) el.classList.remove("grid-item-focused");
        if (me.source.recordCount > 0) {
          if (rowDelta != 0 || getActiveRecord() < getFirstRecord() || getActiveRecord() > getLastRecord()) {
            if (me.getActiveSource().isEditMode()) {
              // if (me.getActiveSource().modified) me.postExicstedEditRec();
              // else await me.getActiveSource().cancelRecord();
            }
            el = getRowElement(currentRow);
            if (el) el.classList.remove("grid-card-selected"); //старая запись
            if (el) el.classList.remove("grid-item-focused"); //старая запись
            clearStylesForGroupRec(el);
            if (!(await scrollData(rowDelta))) {
              el = getRowElement(currentRow);
              me.source.updateCurrentMarkedValues();
              if (el) {
                if (viewInfo.horizontal) el.classList.add("grid-card-selected");
                placeIndicator(el.firstChild.firstChild);
              }
            }
          }
        }
        if (colDelta != 0) {
          if (newCol < 0) {
            if (columnOffset > 0) {
              columnOffset--;
              await updateAllGrid();
            }
            newCol = 0 + columnOffset;
          } else if (newCol > me.columns.fields.length - 1 + columnOffset) {
            if (rightOverflow) {
              columnOffset++;
              await updateAllGrid();
            }
            newCol = me.columns.fields.length - 1 + columnOffset;
          } else if (newCol < columnOffset) {
            if (columnOffset > 0) {
              columnOffset--;
              await updateAllGrid();
            }
          }
          currentCol = newCol;
          if (rowDelta === 0) {
            multiSelectStyles(e, rowDelta, Array.prototype.indexOf.call(me.data.children, el));
            me.onMultiSelectChange && me.onMultiSelectChange(me);
          }
        }
        el = getColElement(currentCol, currentRow);
        if (el) {
          if (el.field) selectedField = el.field.fieldName;
          if (!viewInfo.horizontal) el.classList.add("grid-item-focused");
        }
        el = getRowElement(currentRow);
        if (el && el.firstChild && el.firstChild.firstChild.isGroup === true) {
          el = getColElement(0, currentRow);
          el.classList.add("grid-item-focused");
        }
      }
      resolve();
    });
  }

  function clearStylesForGroupRec(table) {
    if (table && table.firstChild && table.firstChild.firstChild && table.firstChild.firstChild.isGroup === true) {
      const el = getColElement(0, currentRow);
      el.classList.remove("grid-item-focused");
    }
  }

  function multiSelectStyles(e, rowDelta, prevRowIndex) {
    //функция сохранение и отрисовки выделенных записей
    const row = getRowElement(currentRow), //текущий(новый)
      prevRow = getRowElement(prevRowIndex), //Прошлый
      nextRow = getRowElement(currentRow + rowDelta); //След элемент
    //
    if (viewInfo.horizontal) return;
    function selectManyRecords(index) {
      const SignRowDelta = Math.sign(rowDelta) === 1;
      const breakPont = index ? index : me.source.activeRecord;
      for (let i = prevRowIndex; i >= 0; SignRowDelta ? i++ : i--) {
        //на основе boolean, понимаем что надо делать с i, i изначально считается от предыдущего элемента
        if (i === breakPont) break; //если мы дошли до currentRow(активной записи)
        if (!multiSelect.includes(`${i}`)) {
          //если нет в массиве
          multiSelect.push(`${i}`);
          createMultiRow(getRowElement(i)); //получаем элемент и рисуем на нем стили
        }
      }
    }
    let index;
    if (
      e &&
      !isEmptyObject(e) &&
      !multiSelect.includes(`${me.source.activeRecord}`) && //если у нас не сохранено в массиве
      ((e.ctrlKey && e.type === "mousedown") || e.shiftKey) //смотрим на ивенты ctrl + mouse и shift + keyDown
    ) {
      multiSelect.push(`${me.source.activeRecord}`); //Сохраняем в массив текущий
      if (row) {
        createMultiRow(row);
        if (e.key === "PageUp" || e.key === "PageDown") {
          //только для page Up/down
          selectManyRecords();
          me.onMultiSelectChange && me.onMultiSelectChange(me);
        }
        if (prevRow && row && e.shiftKey && e.type === "mousedown") {
          selectManyRecords(currentRow);
        } else if (
          //для предыдущего элемента, мы делаем + 1 шаг если выбираем через shift, выбрали 4 запись, перешли на 3. А 4 осталась не выделенной. Вот для чего это условие
          prevRow &&
          nextRow &&
          !containsMultiRow(prevRow) &&
          !containsMultiRow(nextRow)
          // && e.shiftKey
        ) {
          if (rowDelta === 1 || rowDelta === -1) {
            multiSelect.push(`${me.source.activeRecord - rowDelta}`);
            createMultiRow(prevRow);
          }
        }
      }
    } else {
      //если элемент есть в массиве
      if (row && containsMultiRow(row) && (e.shiftKey || e.ctrlKey)) {
        //проверка на ошибки
        if (e.shiftKey && e.type === "keydown") {
          if (nextRow !== undefined && nextRow !== row) {
            //если мы пошли в обратную сторону, снимаем выделение
            index = multiSelect.indexOf(prevRow);
            deleteStyleInRow(prevRow, index);
          }
        } else {
          if (rowDelta === undefined && row && containsMultiRow(row))
            // если кликаем по той же записи
            return;
          // if (row && !containsMultiRow(row)) {
          //   createMultiRow(row);
          // }
          index = multiSelect.indexOf(me.source.activeRecord);
          deleteStyleInRow(row, index);
        }
      } else {
        // значит клик напустое место, удаляем все стили и очищаем массив с записями
        if (viewInfo.horizontal) return;
        document.querySelectorAll(".grid-multi-selection-row").forEach((rowWithStyle) => {
          deleteStyleInRow(rowWithStyle);
        });
        multiSelect.length = 0;
      }
    }
  }

  const containsMultiRow = (row) => {
    return row ? row.classList.contains("grid-multi-selection-row") : undefined;
  };

  const createMultiRow = (row) => {
    if (viewInfo.horizontal) return;
    if ((row && !containsMultiRow(row)) || row.backgroundColor !== "#464646") {
      row.classList.add("grid-multi-selection-row");
      // row.style.backgroundColor = "#464646";
      // row.style.color = "white";
    }
  };

  const deleteStyleInRow = (row, index) => {
    if (viewInfo.horizontal) return;
    if (row) {
      if (containsMultiRow(row)) {
        row.classList.remove("grid-multi-selection-row");
        row.style.backgroundColor = "";
        row.style.color = "";
        me.changeGridStyles && me.changeGridStyles(row);
      }
    }
    try {
      if (index) {
        multiSelect.splice(index, 1);
      } else if (index === 0) {
        multiSelect.splice(index, 1);
      }
    } finally {
      // me.onMultiSelectChange && me.onMultiSelectChange(me);
    }
  };

  async function scrollToRecord(record, moveCurrent, changeSource) {
    if (sourceActive()) {
      if (moveCurrent) {
        if (me.getActiveSource().activeRecord != record && changeSource !== false) {
          // hideEditor();
          if (me.getActiveSource().isEditMode()) {
            if (me.getActiveSource().modified) await me.getActiveSource().postRecord();
            else await me.getActiveSource().cancelRecord();
          }
          me.getActiveSource().setRecordIndex(record);
        }
        if (viewInfo.horizontal) {
          me.source.setRecordIndex(record);
        }
        let distance;
        if (me.source.activeRecord > getLastRecord()) {
          distance = me.source.activeRecord - getLastRecord();
        } else if (me.source.activeRecord < getFirstRecord()) {
          distance = me.source.activeRecord - getFirstRecord();
        } else distance = 0;
        if (distance !== 0) firstRecord += distance;
        // me.source.moveBy(distance);
        currentRow = me.source.activeRecord - firstRecord;
        await updateCurrentView();
      } else {
        record = midint(0, me.source.recordCount - visibleRows, record);
        if (record != firstRecord) {
          firstRecord = record;
          currentRow = me.source.activeRecord - firstRecord;
          await updateCurrentView();
        }
      }
    }
  }

  function getSelection() {
    let selection = "";
    for (const index of multiSelect) {
      selection += index;
    }
    return selection;
  }
  function setSelection(value) {}
  function notifyGrid(notify) {}

  function saveState() {
    if (me.columns) {
      const groupFields = groups
        .map((val) => {
          return val.fieldName;
        })
        .join();
      let stateObj = {
        IndicatorSize: `${Math.round(indicatorSize)}`,
        ViewMode: `${me.OnlyViewMode ? "1" : "0"}`,
        SelectedColumn: `${currentCol}`,
        ColumnOffset: columnOffset,
        ShowHint: "0",
        sortedFileds: sortedFileds,
        filterData: JSON.stringify(filterData),
        GroupFields: groupFields,
        details: JSON.stringify(me.source.details),
        CardMode: `${viewInfo.horizontal ? "1" : "0"}`,
      };
      if (groupFields === "") delete stateObj.GroupFields;
      for (const value of Object.values(viewInfo.horizontal ? viewInfo.saveColumns : me.columns.title)) {
        if (value) {
          if (value.state) {
            const keyWithLevel = `${value.fieldName}_${value.level}`;
            Object.assign(stateObj, { [keyWithLevel]: value.state }); // todos добавить в ключ level
          } else {
            // CreateStateForRecord(value, key);
          }
        }
      }

      me.getActiveSource().setGridState(stateObj, true);
      //
      /*
      // SelectedColumn
      ShowHint
      TitleSize
      // ViewMode
      ColumnCount
      // ColumnOffset
      DetailRate
      // GroupFields
      */
    }
  }

  me.ChangeViewMode = function () {
    me.OnlyViewMode = !me.OnlyViewMode;
    saveState();
  };

  me.updateStateValues = function () {
    saveState();
  };

  function CreateStateForRecord(field) {
    // грид стейт
    // if (el && el.field) {
    try {
      field.state = {
        0: field.index, //index
        1: field.hide === true ? "0" : "1", //visible
        2: field.width, // width
        3: field.marked !== undefined && field.marked === true ? "1" : "0", // marked
        4: "0", // hidden
        5: field.expanded === false ? "1" : "0", // collapsed
        6: field.sortDirect === "up" ? "1" : field.sortDirect === "down" ? "2" : "0", // sortDirect 1-up, 2-down
        7: "0", // filter
        8: "", // defaultText
        9: "0", // defaultCheck
      };
    } catch (err) {}
    //
    // }
  }

  function UpdStateValue(field, index, value) {
    if (!field.state) {
      CreateStateForRecord(field);
    }
    field.state[index] = value;
    saveState();
  }

  function processLevels(f) {
    function processLevel(level) {
      for (let i = 0, l; i < level.length; i++) {
        l = level[i];
        f.call(me, l);
        if (l.items) {
          processLevel(l.items);
        }
      }
    }

    processLevel(me.columns.levels);
  }

  function getIndexFieldName() {
    var value = "";

    processLevels(function (level) {
      switch (level.field.sortDirect) {
        case "up":
          if (value != "") value += ";";
          value += level.field.fieldName;
          break;
        case "down":
          if (value != "") value += ";";
          value += "-";
          value += level.field.fieldName;
          break;
      }
    });
    //
    sortedFileds = value;
    return value;
  }

  function getGroupFieldNames() {
    var value = "";
    for (let i = 0, field, s; i < groups.length; i++) {
      field = groups[i];
      s = field.fieldName;
      if (field.params) {
        s = s + "." + field.params;
      }
      if (field.sortDirect == "down") {
        s = "-" + s;
      }
      if (value != "") value += ";";
      value += s;
    }
    return value;
  }

  async function checkOrderColumns(fieldName) {
    if (sourceActive()) {
      showApplicationMask(panel);
      try {
        let selection = {};
        selection.value = getSelection();
        await me.source.orderRecords(fieldName, selection);
        if (multiSelect.length > 0) {
          multiSelect = selection.value.split(",");
        }
        await scrollToRecord(me.source.activeRecord, true);
        await updateCurrentView();
      } finally {
        hideApplicationMask(panel);
        saveState();
      }
      notifyGrid(nfStateChanged);
    }
  }

  me.getGroups = () => {
    return groups;
  };

  me.groupByField = (field, clear) => {
    if (clear && groups.indexOf(field) !== -1) {
      groups.splice(groups.indexOf(field), 1);
    } else {
      groups.push(field);
    }
    checkGroupColumnns();
    BuildGroupsContent();
  };

  async function checkGroupColumnns() {
    if (sourceActive()) {
      showApplicationMask(panel);
      try {
        await me.source.groupRecords(getGroupFieldNames());
        await updateRecordTable();
        await scrollToRecord(me.source.activeRecord, true);
      } finally {
        hideApplicationMask(panel);
        saveState();
      }
      notifyGrid(nfStateChanged);
    }
  }

  async function handleMouseUp(e) {
    if (e.button != 0) return;

    isJustMouseDown = false;
    if (sizeMarker && sizeMarker.style.opacity == 1) {
      //resize
      deleteEvent("mousemove", sizeColumn);
      if (handleColumnField && handleColumnField.eventSize) {
        handleColumnField.width = Math.trunc(handleColumnField.eventSize) - handleColumnField.offset;
        handleColumnField.width = handleColumnField.width >= 25 ? handleColumnField.width : 25;
        resizeCardMode();
        if (handleColumnField.indicator) {
          indicatorSize = handleColumnField.width;
          saveState();
        } else UpdStateValue(handleColumnField, 2, handleColumnField.width);
        if (handleColumnField && !handleColumnField.indicator) await updateColumns();
        else if (handleColumnField && handleColumnField.indicator) {
          // await updateAllGrid();
          Zaglushka();
        }
        await updateAllGrid();
      } else {
        sizeMarker.style.opacity = 0;
      }
      handleColumnField = null;
      panel.focus();
      return;
    }

    function resizeCardMode() {
      if (viewInfo.horizontal) {
        const width = me.panel.offsetWidth - indicatorSize;
        for (const field of Object.values(me.columns.title)) {
          if (handleColumnField.fieldName !== field.fieldName) {
            field.width = width - handleColumnField.width;
          }
        }
      }
    }

    deleteEvent("mousemove", moveColumn);
    if (dragZone && dragZone.initRect) {
      dragZone.initRect = null;
      hideElement(dragZone);
      hideElement(dragZone.moveTop);
      hideElement(dragZone.moveBottom);

      if (dragZone.isGroup && me.canGroup(handleColumnField)) {
        let i = groups.indexOf(handleColumnField);
        if (i != -1) groups.splice(i, 1);
        if (dragZone.field) {
          i = groups.indexOf(dragZone.field);
          if (i != -1) groups.splice(i, 0, handleColumnField);
          else groups.push(handleColumnField);
        } else groups.push(handleColumnField);
        handleColumnField = null;
        dragZone.field = null;
        buildGroups();
        panel.focus();
        return;
      }

      if (dragZone.field && dragZone.field.el) {
        let items = handleColumnField.parent,
          i,
          j,
          item = dragZone.field.el.level;
        i = items.indexOf(handleColumnField.el.level);
        if (i != -1) {
          j = items.indexOf(item);
          if (j != -1) {
            items.splice(i, 1);
            items.splice(j, 0, handleColumnField.el.level);
          }
        }

        // todo...
        function addField(level) {
          if (level.items && level.expanded) {
            level.items.forEach(addField);
          } else {
            me.columns.fields.push(level.field);
          }
        }

        me.columns.fields.length = 0;
        me.columns.levels.forEach(addField);
      }
      updateColumns();
      if (handleColumnField.groupEl) {
        let i = groups.indexOf(handleColumnField);
        if (i != -1) {
          groups.splice(i, 1);
          buildGroups();
        }
        handleColumnField.groupEl = null;
      }
      handleColumnField = null;
      dragZone.field = null;
      panel.focus();
      return;
    }

    var el = document.elementFromPoint(e.x, e.y),
      target,
      role;
    target = el;
    while (el) {
      role = el.role;
      if (role) break;
      el = el.parentElement;
    }
    if (role) {
      switch (role) {
        case ROLE_COLUMN: {
          if (me.canSort(el.field)) await me.sortByRecord(el, e.shiftKey);
          panel.focus();
          break;
        }
      }
    }
    if (!me.editorMode() && e.target.className.animVal === undefined && !e.target.className.includes("grid-filter"))
      panel.focus();
  }

  async function EditColumnFieldClick(el, e) {
    return new Promise(async (resolve) => {
      if (me.doccfgid === undefined) return resolve(true);
      if (me.RefWithCtrl === undefined) {
        const optionsForUser = GetOptionsForUser().RefWithCtrl;
        me.RefWithCtrl = optionsForUser === undefined ? false : optionsForUser;
      }
      let column;
      if (viewInfo.horizontal && el.field.fieldName === "Value") {
        column = el.levelsField.field;
      } else {
        column = el.field;
      }
      if ((me.RefWithCtrl === true && !e.ctrlKey) || column === undefined) return resolve(true);
      const refrence = me.getActiveSource().getFieldTextSync(column.fieldName) === "" ? "0" : "1";
      if (
        refrence === "1" &&
        me.doccfgid &&
        column.fieldInfo &&
        column.fieldInfo.OnEdit !== "" &&
        column.OptionsDisable &&
        column.OptionsDisable.link &&
        !me.editorMode()
      ) {
        const linkClick = await EditColumnField({
          DocCfgID: me.doccfgid,
          SectionID: me.sectionid,
          Module: "documents",
          ID: column.fieldInfo.ColumnID,
          DocID: me.getActiveSource().getFieldTextSync("ID"),
          MasterID: me.getActiveSource().Master.MasterID,
          Reference: me.getActiveSource().getFieldTextSync(column.fieldName) === "" ? "0" : "1",
        });
        tokenProcessingTest(linkClick, {
          from: "grid",
          func: () => {
            if (me.getActiveSource().Master.refreshRecord) {
              me.getActiveSource().Master.refreshRecord(true);
              me.CommitToSaveChanges && me.CommitToSaveChanges(false);
              resolve(true);
            } else {
              me.refreshSource("!");
              me.CommitToSaveChanges && me.CommitToSaveChanges(false);
              resolve(true);
            }
          },
          props: {
            Module: el.field.fieldInfo.Module,
            ObjType: el.field.fieldInfo.ObjType,
            ObjRef: el.field.fieldInfo.ObjRef,
          },
        });
      } else if (
        refrence === "1" &&
        column.fieldInfo &&
        column.fieldInfo.EditStyle & 16 &&
        column.OptionsDisable &&
        column.OptionsDisable.link &&
        column.fieldInfo.OnEdit === "" &&
        !me.editorMode()
      ) {
        const subData = {
          props: {
            ObjType: column.fieldInfo.DataType,
            ObjRef: me.getActiveSource().getFieldValueSync(column.fieldName),
            Buttons: 2,
            PlaneView: "1",
          },
        };
        const json = await GetDocDialogParams(
          column.fieldInfo.DataType,
          me.getActiveSource().getFieldValueSync(column.fieldName),
        );
        if (json.ObjType) subData.props.ObjType = json.ObjType;
        if (json.ObjRef) subData.props.ObjRef = json.ObjRef;

        if (!json.Break) json.Break = "";
        if (!json.Token) json.Token = "HandleTable";
        if (json) tokenProcessingTest(json, subData);
        resolve(false);
      }
    });
  }

  async function handleMouseDown(e) {
    if (e.button != 0) return;
    var el = document.elementFromPoint(e.x, e.y),
      role;
    while (el) {
      role = el.role;
      if (role) break;
      el = el.parentElement;
    }
    if (role) {
      switch (role) {
        // start move column
        case ROLE_COLUMN: {
          if (handleColumnField) return;

          handleColumnField = el.field;
          handleColumnField.groupEl = null;
          appendEvent("mousemove", moveColumn);
          break;
        }
        case ROLE_GROUP: {
          if (handleColumnField) return;
          handleColumnField = el.field;
          handleColumnField.groupEl = el;
          appendEvent("mousemove", moveColumn);
          break;
        }
        case ROLE_COLDATA: {
          let newCol = el.col,
            newRow = el.parentElement.row;
          if (newCol === currentCol && newRow === currentRow) {
            // прерывание создания редактора при клике на иконку распахивания записи
            if (e.target.closest("div").id === "ExpandIcon") break;
            if (!me.editorMode() && !e.ctrlKey) {
              await showEditor();
            }
            if (e.ctrlKey) {
              multiSelectStyles(e, 0, currentRow);
              me.onMultiSelectChange && me.onMultiSelectChange(me);
            }
          } else await moveColRow(newCol, newRow, e);
          if (me.source.getState() != dsInsert) await changeIndicator(dsBrowse);
          if (await EditColumnFieldClick(el, e)) return;
          break;
        }
        case ROLE_COLLAPSE: {
          let level = el.parentElement.level;
          if (level) {
            if (!el.parentElement.state) {
              saveState();
            }
            if (el.classList.contains("collapse-minus")) {
              el.classList.remove("collapse-minus");
              el.classList.add("collapse-plus");
              level.expanded = false;
              UpdStateValue(el.parentElement.field, 5, 1);
            } else {
              el.classList.remove("collapse-plus");
              el.classList.add("collapse-minus");
              level.expanded = true;
              UpdStateValue(el.parentElement.field, 5, 0);
            }
            // saveState();

            buildColumns();
            await queuedGridScroll.scrollGrid(undefined, buildRecords).then(() => {
              updateScrollBar();
              panel.focus();
            });
          }
          break;
        }
        case ROLE_MARKED: {
          e.stopPropagation();
          el.style.backgroundColor = el.style.backgroundColor === "white" ? "blue" : "white";
          el.field.marked = !el.field.marked;
          saveCurrentMarkedValue(el.field);
          break;
        }
        case ROLE_COLLAPSE_GROUP: {
          let row = el.parentElement.parentElement.row;
          if (row != undefined) {
            await moveColRow(currentCol, row);
            let collapse = await getRecordCollapsed();
            await setRecordCollapsed(!collapse);
          }
          break;
        }
        case ROLE_FILTER: {
          break;
        }
        case ROLE_CHECKBOX: {
        }
      }
    }
    if (me.source.getState() != dsInsert) await changeIndicator(dsBrowse);
  }

  function saveCurrentMarkedValue(field) {
    if (sourceActive()) {
      me.getActiveSource().saveCurrentMarkedValues(field);
      UpdStateValue(field, 3, field.marked === true ? "1" : "0");
    }
  }

  async function switchEditorMode() {
    // тут был save, enter - true, f2 - false
    if (inputEditor && inputEditor.deleteClickAway) {
      inputEditor.deleteClickAway();
    }
    if (!me.editorMode()) {
      showEditorThrottle();
    } else {
      await hideEditorMode();
    }
  }

  async function hideEditorMode() {
    if (me.editorMode()) {
      if (me.getActiveSource().modified && editor.onEdit) await editor.onEdit(undefined, inputEditor.value);
      if (!editor.selection) hideEditor(inputEditor.modified);
    }
  }

  async function hideEditorOnKeyDown() {
    if (me.editorMode()) {
      if (me.getActiveSource().modified && editor.onEdit) {
        await editor.onEdit(undefined, inputEditor.value);
      }
      hideEditor(inputEditor.modified);
    }
  }

  function moveColRowCardMode(distance) {
    if (viewInfo.horizontal) {
      me.getActiveSource().moveBy(distance);
      updateCard();
    }
  }

  const showEditorThrottle = throttle((e, keyPass) => {
    setTimeout(async () => {
      if (!e && !me.editorMode()) {
        showEditor();
        return;
      }
      if (!me.editorMode() && !keyPass.includes(e.key) && !document.activeElement.className.includes("grid-filter")) {
        e.preventDefault();
        await showEditor(getKeyForEditor(e));
      }
    });
  }, 20);

  async function handleKeyDown(e) {
    const keyPass = ["F5", "F12", "CapsLock", "Shift", "Alt", "Meta", "Tab"];
    // e.stopPropagation();

    if (keypress) return;
    switch (e.key) {
      case "ArrowLeft":
        if (me.editorMode()) break;
        e.preventDefault();
        if (me.source.onRecordCollapsed !== null) {
          const ColElement = getColElement(viewInfo.horizontal ? 0 : currentCol, currentRow);
          if (
            ColElement.children.length >= 2 &&
            ColElement.firstChild.id === "ExpandIcon" &&
            ColElement.firstChild.type == "-"
          ) {
            const dataToRecordCollapsed = viewInfo.horizontal
              ? { currentTarget: ColElement.firstChild }
              : ColElement.firstChild.getAttribute("recordIndex");

            await me.source.onRecordCollapsed(dataToRecordCollapsed);
            // scrollToRecord(ColElement.firstChild.getAttribute("recordIndex"), true);
            break;
          }
        }
        if (await isGroupRecord(true)) {
          if (await getRecordCollapsed()) {
            let record = await me.source.getGroupRecord();
            if (record != -1) scrollToRecord(record, true);
          } else await setRecordCollapsed(true);
        } else {
          if (currentCol == 0 && (await getLevelOffset()) > 0 && columnOffset === 0) {
            let record = await me.source.getGroupRecord();
            if (record != -1) scrollToRecord(record, true);
          } else if (!viewInfo.horizontal) await moveColRow(currentCol - 1, currentRow, e);
          else moveColRowCardMode(-1);
        }
        break;
      case "ArrowRight":
        if (me.editorMode()) break;
        e.preventDefault();
        if (me.source.onRecordCollapsed !== null) {
          const ColElement = getColElement(viewInfo.horizontal ? 0 : currentCol, currentRow);
          if (
            ColElement.children.length >= 2 &&
            ColElement.firstChild.id === "ExpandIcon" &&
            ColElement.firstChild.type == "+"
          ) {
            const dataToRecordCollapsed = viewInfo.horizontal
              ? { currentTarget: ColElement.firstChild }
              : ColElement.firstChild.getAttribute("recordIndex");
            await me.source.onRecordCollapsed(dataToRecordCollapsed);
            break;
          }
        }
        if (await isGroupRecord(true)) {
          if (await getRecordCollapsed()) await setRecordCollapsed(false);
        } else if (!viewInfo.horizontal) await moveColRow(currentCol + 1, currentRow, e);
        else moveColRowCardMode(1);
        break;
      case "ArrowUp":
        e.preventDefault();
        await hideEditorOnKeyDown();
        if (e.ctrlKey) await scrollView(-1);
        else await moveColRow(currentCol, currentRow - 1, e);
        await changeIndicator(dsBrowse);
        break;
      case "ArrowDown":
        e.preventDefault();
        await hideEditorOnKeyDown();
        if (e.ctrlKey) await scrollView(1);
        else {
          if (me.source.recordCount !== 0 && me.source.recordCount - 1 === me.source.activeRecord) {
            if (!viewInfo.horizontal && !e.shiftKey && !e.ctrlKey) await me.insertRecord(true);
          } else {
            await moveColRow(currentCol, currentRow + 1, e);
            await changeIndicator(dsBrowse);
          }
        }
        break;
      case "PageUp":
        e.preventDefault();
        await hideEditorOnKeyDown();
        if (e.ctrlKey) await scrollView(-visibleRows, e);
        else await moveColRow(currentCol, currentRow - visibleRows, e);
        break;
      case "PageDown":
        e.preventDefault();
        await hideEditorOnKeyDown();
        if (e.ctrlKey) await scrollView(visibleRows, e);
        else await moveColRow(currentCol, currentRow + visibleRows, e);
        break;
      case "Home":
        if (me.editorMode()) break;
        if (e.ctrlKey) scrollToRecord(-1, true);
        else await moveColRow(0, currentRow);
        break;
      case "End":
        if (me.editorMode()) break;
        if (e.ctrlKey) scrollToRecord(me.source.recordCount, true);
        else await moveColRow(me.columns.fields.length - 1, currentRow);
        break;
      case "Insert":
        if (await me.canModify(mfInsert, undefined, me)) {
          await me.insertRecord(!e.ctrlKey);
        }
        break;
      case "Escape":
        if (me.editorMode()) await hideEditor(false);
        else await me.cancelRecord();
        break;
      case "F2":
        await switchEditorMode();
        break;
      case "Enter":
        if (
          !document.activeElement.className.includes("grid-filter") &&
          !document.activeElement.className.includes("grid-search-input")
        )
          await switchEditorMode();
        break;
      case "ф":
      case "a":
        if (!me.editorMode() && !document.activeElement.className.includes("grid-filter") && e.ctrlKey) {
          e.preventDefault();
          multiSelect.length = 0;
          for (let i = 0; i < me.source.recordCount; i++) {
            multiSelect.push(i);
            const row = getRowElement(i);
            if (row) {
              createMultiRow(row);
            }
          }
          me.onMultiSelectChange && me.onMultiSelectChange(me);
        }
        break;
      default:
        if (e.ctrlKey) {
          switch (e.code) {
            case "KeyP": {
              e.preventDefault();
              if (inputEditor && inputEditor.deleteClickAway) {
                inputEditor.deleteClickAway();
              }
              await hideEditor(true);
              //   me.postRecord();
            }
            case "KeyF": {
              me.searchByColumn(e);
              break;
            }
            case "KeyQ": {
              if (e.ctrlKey) {
                me.Rotate();
              }
              break;
            }
            // case"KeyS":{
            //   if(e.ctrlKey){
            //     e.preventDefault();
            //
            //   }
            // }

            default:
              //
              break;
          }
        } else {
          showEditorThrottle(e, keyPass);
        }
    }
  }

  function getKeyForEditor(e) {
    switch (e.code) {
      case "Backspace": {
        return "";
      }
      case "Equal": {
        return "";
      }
      case "F1":
      case "F2":
      case "F3":
      case "F4":
      case "F5":
      case "F6":
      case "F7":
      case "F8":
      case "F9":
      case "F10":
      case "F11":
      case "F12":
        return "";
      default: {
        return e.key;
      }
    }
  }

  me.searchByColumn = async function (e, el) {
    if (e !== undefined) {
      e.preventDefault();
    } else {
      await moveColRow(Array.from(el.parentNode.children).indexOf(el) - 1, currentRow);
    }
    const column = el ? el : getColElement(currentCol, currentRow).field.el;
    const input = document.createElement("input");
    input.type = "text";
    input.className = "grid-search-input";
    input.unhideelements = function () {
      for (let i = 0; i < column.children.length; i++) {
        const child = column.children[i];
        child.style.display = child.style.display === "none" ? "" : "none";
      }
    };
    input.unhideelements();
    input.oninput = async function (e) {
      e.stopPropagation();
      const record = await me.source.SearchRecord(column.field.fieldName, e.currentTarget.value);
      scrollToRecord(record, true);
    };
    input.onkeydown = async function (e) {
      if ([13].indexOf(e.keyCode) !== -1) {
        e.stopPropagation();
        const record = await me.source.SearchRecord(
          column.field.fieldName,
          e.currentTarget.value,
          me.source.activeRecord + 1,
        );
        scrollToRecord(record, true);
      }
    };
    let removed = false;
    input.visible = true;
    input.SearchRecord = true;
    input.onblur = async function (e) {
      if (removed) return;
      e.currentTarget.unhideelements();
      removed = true;
      e.currentTarget.remove();
      await me.source.SearchRecord(undefined, undefined, -1);
      editor = null;
    };
    column.appendChild(input);
    input.focus();
    editor = input;
  };

  async function scrollView(distance, e) {
    if (sourceActive()) {
      var oldFirstRecord = firstRecord;
      if (distance > 0) {
        firstRecord += distance;
        let max = me.source.recordCount - visibleRows;
        if (firstRecord > max) firstRecord = max;
      } else if (distance < 0) {
        firstRecord += distance;
        if (firstRecord < 0) firstRecord = 0;
      }
      if (oldFirstRecord != firstRecord) {
        currentRow = me.source.activeRecord - firstRecord; // todo проверить в мозилле при скролле
        await updateCurrentView();
      }
    }
  }

  async function handleMouseWheel(e) {
    if (me.getActiveSource().modified && editor.onEdit) editor.onEdit(undefined, inputEditor.value);
    if (me.editorMode()) await hideEditor(); //todo postRecord with Scroll
    //
    let deltaY = e.type === "touchmove" ? touchStartY - e.touches[0].clientY : e.deltaY;
    if (e.shiftKey) {
      if (e.deltaY < 0) {
        if (columnOffset > 0) {
          columnOffset--;
          await updateAllGrid(false);
        }
      } else if (e.deltaY > 0) {
        if (rightOverflow) {
          columnOffset++;
          await updateAllGrid(false);
        }
      }
    } else {
      if (deltaY < 0) {
        await scrollView(-1);
      } else if (deltaY > 0) {
        await scrollView(1);
      }
    }
  }

  function buildColumns() {
    if (editor) editor.ready = false;
    getFixedCol();
    var last,
      left = 0,
      levels = me.columns.levels,
      maxlev = 1,
      totalWidth = me.header.getBoundingClientRect().width,
      checkMargin = columnOffset;

    while ((last = me.header.lastChild)) {
      me.header.removeChild(last);
    }

    if (!levels) return;

    function getMaxLevel(level) {
      let res = 1,
        max = 0;
      if (level.items && level.expanded) {
        for (let i = 0; i < level.items.length; i++) {
          max = maxint(max, getMaxLevel(level.items[i]));
        }
      }
      return res + max;
    }

    for (let i = 0; i < levels.length; i++) {
      maxlev = maxint(maxlev, getMaxLevel(levels[i]));
    }

    childColumnSize = rowHeight;
    titleSize = maxlev * childColumnSize;

    me.header.style.height = titleSize + "px";

    if (showIndicator) {
      // let anchorElButton = null
      let el = document.createElement("div");
      el.className = "grid-column";
      el.id = "indicatorColumn";
      el.style.position = "absolute";
      el.style.left = left + "px";
      el.style.width = indicatorSize + "px";
      el.style.height = "100%";
      el.style.display = "flex";
      el.style.justifyContent = "flex-start";
      el.style.alignItems = "center";
      el.style.backgroundColor = "#faf9f5";
      el.style.border = "none";
      el.field = { offset: left };
      let CollumnsButton = document.createElement("div");
      el.appendChild(CollumnsButton);
      const dataOfHiddenCols = viewInfo.horizontal ? viewInfo.saveColumns.levels : me.columns.levels;
      if (!isEmptyObject(dataOfHiddenCols)) {
        ReactDOM.render(
          <ColumnButton onClick={onMenuItemClick} data={dataOfHiddenCols} viewInfo={viewInfo} />,
          CollumnsButton,
        );
        createStatusButtonForCardMode();
        me.header.appendChild(el);
      }
      if (me.doccfgid && me.getActiveSource().Master.MasterID) {
        let CardModeButtonDiv = document.createElement("div");
        el.appendChild(CardModeButtonDiv);
        ReactDOM.render(<CardModeButton rotateState={viewInfo.horizontal} onClick={me.Rotate} />, CardModeButtonDiv);
      }
      left += indicatorSize;
    }

    function onMenuItemClick(field) {
      me.hideColumn(field);
    }

    function createFieldElement(level, left, top, width, height) {
      //
      var el,
        tmp,
        field = level.field;
      if (field.hide === true || field.hideInGrid === true) {
        return 0;
      }
      if (level.items && level.expanded) {
        //рисование под-записей
        width = 0;
        for (let i = 0, l = left, w, lev, fld; i < level.items.length; i++) {
          lev = level.items[i];
          fld = lev.field;
          w = fld.width;
          if (l + w > totalWidth) {
            overflowColumns = true;
            rightOverflow = true;
            w -= l + w - totalWidth;
            if (w < rowHeight) break;
            i = level.items.length;
          }
          w = createFieldElement(lev, l, top + childColumnSize, w, height - childColumnSize);
          width += w;
          l += w;
        }
        height = childColumnSize;
      } else {
        if (checkMargin > 0 && !(field.fieldInfo && field.fieldInfo.Options & 64)) {
          checkMargin--;
          return 0;
        }
        me.columns.fields.push(field);
      }
      el = document.createElement("div");
      el.className = "grid-column";
      el.style.position = "absolute";
      el.style.left = left + "px";
      el.style.top = top + "px";
      el.style.width = width + "px";
      el.style.height = height + "px";
      el.style.display = "flex";
      el.style.alignItems = "top";
      el.role = ROLE_COLUMN;
      field.offset = left;
      field.top = top;
      field.height = height;
      field.elementWidth = width;
      el.field = field;
      el.level = level;
      field.el = el;

      const flexContainer = document.createElement("div");
      flexContainer.style.display = "flex";
      flexContainer.style.alignItems = "flex-start";
      flexContainer.style.justifyContent = "space-between";
      flexContainer.style.flexFlow = "column warp";
      flexContainer.style.width = "10px";
      // el.appendChild(flexContainer);

      if (level.items) {
        tmp = document.createElement("div");
        tmp.classList.add("collapse-img");
        tmp.style.position = "fixed";
        if (level.expanded) {
          tmp.classList.add("collapse-minus");
        } else {
          tmp.classList.add("collapse-plus");
        }

        tmp.role = ROLE_COLLAPSE;
        el.appendChild(tmp);
      }

      if (field.marked !== undefined) {
        el.appendChild(createRoundButton(field));
      }

      tmp = document.createElement("div");
      tmp.style.textAlign = "center";
      tmp.style.width = "calc(100%)";
      const parentdiv = document.createElement("div");
      const spanForText = document.createElement("span");
      spanForText.className = "column-span";
      spanForText.style.width = "100%";
      parentdiv.className = "column-header-text";
      createStyleColumn(parentdiv, field);
      if (!field.fieldInfo?.NoTitle) {
        spanForText.innerText = field.title;
      }
      parentdiv.appendChild(spanForText);
      tmp.appendChild(parentdiv);
      if (field.fieldInfo && field.fieldInfo.TitleAjust === "0") {
        parentdiv.style.justifyContent = "flex-start";
        spanForText.style.marginLeft = "3px";
      }
      // tmp.innerHTML =
      //   '<span class="column-header-text">' + field.title + "</span>";
      tmp.className = "grid-column-title";
      if ((level.items && level.items.length > 0) || field.marked !== undefined) {
        tmp.style.marginLeft = "10px";
        tmp.style.width = `calc(100% - ${tmp.style.marginLeft}px - ${el.style.paddingRight}px)`;
        spanForText.style.width = "86%";
      }
      el.appendChild(tmp);

      switch (field.sortDirect) {
        case "up":
          tmp.children[0].classList.add("column-sort-asc");
          break;
        case "down":
          tmp.children[0].classList.add("column-sort-desc");
          break;
      }

      if (
        field &&
        field.OptionsDisable &&
        field.OptionsDisable.filterByField === true //кнопка фильтра
      ) {
        const sortIcon = document.createElement("div");
        sortIcon.style.backgroundColor = "inherit";
        sortIcon.style.border = "none";
        sortIcon.style.position = "absolute";
        sortIcon.style.right = "-1px";
        sortIcon.style.width = "15px";
        sortIcon.style.height = "15px";
        sortIcon.style.cursor = "pointer";
        sortIcon.style.zIndex = "6";
        sortIcon.style.bottom = "3px";
        sortIcon.role = "filter";

        el.appendChild(sortIcon);
        // organizations~HandleTable?LicGUID=9B48C3FA4500D58FDE37289B4EBA91E1&SectionID=145&GroupID=931&ObjType=2310&ID=359
        // documents~HandleDocument?LicGUID=4BD600B446FEC0F05E57528346D3366D&DocCfgID=3000&SectionID=109&ParentID=0
        el.style.paddingRight = "12px";
        tmp.style.width = `calc(100% - ${tmp.style.marginLeft} - ${el.style.paddingRight})`;
        //
        ReactDOM.render(
          <FilterGridComponent
            props={{
              request: me.getActiveSource().onHandleRequest,
              dataForReq: {
                Command: "FilterValues",
                FieldName: field.fieldName,
              },
              ParentID: me.source.Master.MasterID,
              DocCfgID: me.doccfgid,
              applyFilter: async (filter) => {
                await me.source.applyFieldFilter(filter);
                await updateRecordTable();
                saveState();
                await scrollToRecord(me.source.activeRecord, true);
                me.onMultiSelectChange && me.onMultiSelectChange(me);
              },
              filterDiv: me.filter,
              filterData: filterData,
            }}
          />,
          sortIcon,
        );
      }
      //
      // panel.querySelectorAll(".grid-size-marker").map((resizer)=>{
      //   resizer.remove()
      // })

      me.header.appendChild(el);
      return width;
    }

    function createStyleColumn(span, field) {
      if (field.fieldInfo) {
        if (field.fieldInfo.Image) {
          const imgDiv = document.createElement("div");
          imgDiv.position = "absolute";
          const img = createImgByIndex(field.fieldInfo.Image);
          img.style.width = "12px";
          img.className = "grid-column-img";
          imgDiv.style.width = "17px";

          imgDiv.appendChild(img);
          span.appendChild(imgDiv);
          // span.style.background = `url(${img.src}) left center no-repeat`;
          // span.style.backgroundSize = "contain"
        }
        if (field.fieldInfo.TitleTextStyle) {
          const style = Number(field.fieldInfo.TitleTextStyle);
          span.style.fontWeight = style & 1 ? "bold" : "";
          span.style.fontStyle = style & 2 ? "italic" : "";
          span.style.textDecoration = style & 4 ? "underline" : style & 8 ? "line-through" : "";
        }
        if (field.fieldInfo.TextColor) {
          span.style.color = setColor(Number(field.fieldInfo.TextColor));
        }
      }
    }

    me.columns.fields = [];
    overflowColumns = columnOffset != 0;
    rightOverflow = false;

    for (let i = 0, level, field, width; i < levels.length; i++) {
      level = levels[i];
      field = level.field;
      if (field.hide !== true) {
        width = field.width;
        if (left + width > totalWidth) {
          overflowColumns = true;
          rightOverflow = true;
          width -= left + width - totalWidth;
          if (width < rowHeight) break;
          i = levels.length;
        }
        left += createFieldElement(level, left, 0, width, titleSize);
      }
    }

    me.header.style.height = titleSize + "px";
    if (viewInfo.horizontal) {
      const divTitleForHorizontal = document.createElement("div");
      divTitleForHorizontal.style.height = titleSize - 2 + "px";
      divTitleForHorizontal.style.position = "relative";
      divTitleForHorizontal.style.left = indicatorSize + "px";
      divTitleForHorizontal.style.width = `calc(100% - ${indicatorSize + "px"})`;
      divTitleForHorizontal.style.backgroundColor = "rgb(250, 249, 245)";
      divTitleForHorizontal.id = "TitleForHorizontal";
      divTitleForHorizontal.className = "TitleForHorizontal";
      me.header.appendChild(divTitleForHorizontal);
    }
    if (me.columns.fields.length > 1) {
      let field;
      field = me.columns.fields[me.columns.fields.length - 1];
      me.columns.stubField = {
        offset: field.offset + field.width,
        el: field.el,
        parent: me.columns.levels,
      };
    }
    buildFilters();
    if (overflowColumns && !leftRightMover.parentElement && !viewInfo.horizontal) {
      me.header.appendChild(leftRightMover);
    }
  }

  function createImgByIndex(index) {
    const imgDiv = document.createElement("div");
    imgDiv.position = "absolute";
    const img = document.createElement("img");
    const src = getIcons(index);
    img.src = ImgURLSRC(src);
    return img;
  }

  function createRoundButton(field) {
    const button = document.createElement("div");
    button.style.width = "6px";
    button.style.height = "6px";
    button.style.borderRadius = "50%";
    button.style.border = "1px solid";
    button.style.backgroundColor = field.marked === false ? "white" : "blue";
    button.style.position = "absolute";
    button.style.bottom = "3px";
    button.style.left = "2.2px";
    // button.style.inset = "0px 0px 3px 2.2px";
    button.style.cursor = "pointer";
    button.field = field;
    button.role = ROLE_MARKED;
    return button;
  }

  me.hideColumn = async function (field) {
    if (field.hide === undefined) {
      field.hide = true;
    } else {
      field.hide ? (field.hide = false) : (field.hide = true);
    }
    UpdStateValue(field, 1, field.hide ? "0" : "1");
    if (viewInfo.horizontal) updateCard(false);
    await updateAllGrid();
  };

  function createResizers() {
    if (me.DisableResize !== false) {
      panel.querySelectorAll(".grid-size-marker").forEach((r) => {
        r.remove();
      });
      const resizerHeight = visibleRows * rowHeight + titleSize + "px";
      let i = 0;
      for (const column of me.header.children) {
        if (column.className === "pagination") break;
        // console.log(me.header.children[i - 1], column.field.level < me.header.children[i - 1].field.level);
        if (
          (me.header.children[i - 1] && column.field && column.field.level < me.header.children[i - 1].field.level) ||
          (viewInfo.horizontal && column === me.header.children[0]) ||
          (viewInfo.horizontal && column === me.header.children[2])
        ) {
        } else {
          const resizer = document.createElement("div");
          resizer.classList = "grid-size-marker";
          resizer.style.left = parseInt(column.style.width) + parseInt(column.style.left) + "px";
          resizer.style.opacity = 0;
          resizer.style.height = resizerHeight;
          if (column === me.header.children[0]) {
            resizer.style.height = "100%";
            column.field.indicator = true;
          }
          resizer.ondblclick = () => {
            ajustColumnWidth(column.field);
          };
          resizer.onmousedown = function (e) {
            if (handleColumnField === null || handleColumnField === undefined) {
              handleColumnField = column.field;
              e.currentTarget.style.opacity = 1;
              sizeMarker = resizer;
              if (handleColumnField) {
                appendEvent("mousemove", sizeColumn);
              }
            }
          };
          panel.appendChild(resizer);
        }
        i++;
      }
    }
  }

  function buildGroups(fieldName) {
    //todo сделать внешней для контекстного меню
    if (me.groups) {
      groupSize = me.groups.getBoundingClientRect().height;
      let last;
      while ((last = me.groups.lastChild)) {
        me.groups.removeChild(last);
      }
      BuildGroupsContent();
      groupSize = me.groups.getBoundingClientRect().height;
      me.table.style.top = groupSize + filterSize + titleSize + "px";
    }
    checkGroupColumnns();
    // Zaglushka();
  }

  function BuildGroupsContent() {
    if (groups.length > 0) {
      me.groups.innerHTML = "";
      groupSize = maxint(groupSize, rowHeight * groups.length);
      me.groups.style.height = groupSize + "px";
      for (let i = 0, el, field, l = 3, w, t = 3; i < groups.length; i++) {
        field = groups[i];
        w = 100;
        el = document.createElement("div");
        el.style.position = "absolute";
        el.style.left = l + "px";
        el.style.top = t + "px";
        el.style.width = w + "px";
        el.textContent = field.title;
        el.field = field;
        el.classList.add("grid-column");
        el.role = ROLE_GROUP;
        me.groups.appendChild(el);
        t += 7;
        l += w + 3;
      }
      groupSize = me.groups.lastChild.getBoundingClientRect().bottom - me.groups.getBoundingClientRect().top + 3;
      if (groupSize < 43) groupSize = 43;
      me.groups.style.height = groupSize + "px";
    } else {
      me.groups.style.height = rowHeight + "px";
      me.groups.innerHTML = '<p class="grid-groups-text">Для группировки перетащите сюда заголовок колонки</p>';
    }
    groupSize = me.groups.getBoundingClientRect().height;
    me.table.style.top = groupSize + filterSize + titleSize + "px";
  }

  function prepareLevels() {
    me.columns.levels = [];
    me.columns.fields = [];
    groups = [];
    var title = me.columns.title,
      parents = [];

    function getParent(value) {
      var level;
      if (intInRange(value, 0, parents.length - 1)) {
        level = parents[value];
        if (level) {
          if (!level.items) level.items = [];
          return level.items;
        }
      }
      return me.columns.levels;
    }
    // selectedField =
    const statedata = useageDataFromState();
    selectedField = title.fieldName;
    for (let i = 0, level, field, parent, state; i < title.length; i++) {
      field = title[i];
      level = { field: field };
      state = getGridStateFieldValue(field);
      if (field.level) level.level = maxint(0, field.level);
      else level.level = 0;
      if (!field.title) field.title = field.fieldName;
      while (parents.length <= level.level) parents.push({});
      parents[level.level] = level;
      parent = getParent(level.level - 1);
      parent.push(level);
      if (parents[0].field && (parents[0].field.hideInGrid || parents[0].field.hideInCard)) {
        field.hideInCard = parents[0].field.hideInCard;
        field.hideInGrid = parents[0].field.hideInGrid;
      }
      field.index = i;
      field.levelsIndex = parent.level - 1;
      if (field.fieldInfo && field.fieldInfo.Options) {
        if (field.fieldInfo.Options & 1) {
          field.marked = false;
        }
        if (!viewInfo.horizontal) {
          if (field.fieldInfo.Options & 64) {
            field.fixed = true;
            fixedColumn = field;
          }
        }
      }
      if (state) {
        field.state = state;
        field.hide = state[1] === "0";
        field.width = Number(state[2]);
        if (state[6].includes("1") || state[6].includes("2")) {
          //сортировка
          field.sortDirect = state[6] === "1" ? "up" : "down";
        }
        if (state[3] === "1") {
          //маркированный колумн
          field.marked = true;
          // saveCurrentMarkedValue(field);
          if (sourceActive()) me.source.saveCurrentMarkedValues(field);
        }
        level.expanded = state[5] === "1" ? false : true; // expand for field state[5] === "1" ? true : false;
      } else {
        level.expanded = false; // expand for field
      }

      field.expanded = level.expanded;
      field.parent = parent;
      if (statedata) {
        if (statedata.GroupFields && statedata.GroupFields.indexOf(field.fieldName) !== -1) groups.push(field);
      }
    }
  }

  function useageDataFromState() {
    const state = getGridStateFieldValue();
    let returnStateData = {};
    if (state) {
      if (state.IndicatorSize) indicatorSize = Number(state.IndicatorSize);
      if (state.filterData) filterData = JSON.parse(state.filterData);
      else filterData = {};
      if (state.GroupFields)
        returnStateData = {
          ...returnStateData,
          GroupFields: state.GroupFields.split(","),
        };
    }
    return returnStateData;
  }

  function getGridStateFieldValue(field, index) {
    //Получение инфо для поля
    if (sourceActive() && !isEmptyObject(me.source.getGridState())) {
      if (index) {
        return me.source.getGridState()[field.fieldName + `_${field.level}`][index];
      } else if (field) {
        return me.source.getGridState()[field.fieldName + `_${field.level}`];
      } else {
        return me.source.getGridState();
      }
    }
  }

  me.setImageFields = function (imgFields) {
    if (typeof imgFields === "string") {
      imageFields = imgFields.split(",");
    } else if (typeof imgFields === "object") {
      imageFields = imgFields;
    }
    indicatorSize = imageFields.length * 15 + 42 > 123 ? 123 : imageFields.length * 15 + 42;
    // Zaglushka()
  };

  me.setColumns = function (columns) {
    me.columns = columns;
    prepareLevels();
    me.defaultColumns = false;
  };

  me.setIndicatorSize = function (size) {
    indicatorSize = size;
  };

  me.deleteRecord = async function (index) {
    if (viewInfo.horizontal) return;
    if (multiSelect.length > 0) {
      multiSelect.forEach(async (index) => {
        await me.getActiveSource().deleteRecord(index);
      });
      multiSelect.length = 0;
    } else {
      await me.getActiveSource().deleteRecord(index);
    }
    await updateRecordTable(true);
  };

  me.firstScroll = function () {
    scrollToRecord(0, true);
  };

  me.lastScroll = function () {
    scrollToRecord(me.getActiveSource().recordCount - 1, true);
  };

  me.scrollTo = function (index) {
    scrollToRecord(index, true);
  };

  me.getMultiSelect = function () {
    return multiSelect;
  };

  me.sortByRecord = async function (el, multi, clear) {
    deleteEvent("mousemove", moveColumn);
    handleColumnField = null;
    var temp,
      field = el.field;
    if (!el.classList.contains("column-header-text")) {
      temp = el.getElementsByClassName("column-header-text");
      if (temp && temp.length > 0) el = temp[0];
      else el = null;
    }
    if (el && me.source.onRecordCollapsed === null) {
      temp = me.header.getElementsByClassName("column-header-text");
      if (!multi) {
        function clearSortDirect(level) {
          level.field.sortDirect = "";
          if (level.items) {
            level.items.forEach(clearSortDirect);
          }
        }
        me.columns.levels.forEach(clearSortDirect);

        if (temp) {
          for (let i = 0, t; i < temp.length; i++) {
            t = temp[i];
            if (t != el) {
              t.classList.remove("column-sort-asc");
              t.classList.remove("column-sort-desc");
            }
          }
        }
      }
      function ChooseSortDirect(classList, sortDirect) {
        if (!clear) {
          el.classList.add(classList);
          field.sortDirect = sortDirect;
        } else {
          delete field.sortDirect;
        }
      }

      if (el.classList.contains("column-sort-asc")) {
        el.classList.remove("column-sort-asc");
        ChooseSortDirect("column-sort-desc", "down");
      } else {
        el.classList.remove("column-sort-desc");
        ChooseSortDirect("column-sort-asc", "up");
      }
      const sortDirect = field.sortDirect === "up" ? "1" : field.sortDirect === "down" ? "2" : "0";
      UpdStateValue(field, 6, sortDirect);
      await checkOrderColumns(getIndexFieldName());
    }
  };

  me.moveRecord = function (record) {
    if (viewInfo.horizontal) {
      moveColRowCardMode(record);
      return;
    }
    const moverecordNext =
      record === -1 ? me.getActiveSource().activeRecord - 1 : me.getActiveSource().activeRecord + 1;
    if (moverecordNext >= 0 && moverecordNext <= me.getActiveSource().recordCount - 1)
      scrollToRecord(moverecordNext, true);
  };

  me.ChangeSave = function (bool) {
    if (me.source) me.source.ChangeSave(bool);
  };

  me.getSelectedField = function () {
    return selectedField;
  };

  me.ShowIndicatorF = function (bool) {
    showIndicator = bool;
    if (indicator !== null) {
      indicator.remove();
      indicator = null;
    }
    if (me.zaglushka) {
      me.zaglushka.remove();
    }
  };

  async function updateRowCount() {
    let rowCount = 1000;
    if (me.source.recordCount < firstRecord) firstRecord = 0;
    // rowCount = maxint(
    //   await me.source.acquireRecords(firstRecord, visibleRows),
    //   1
    // );//todo
    updateActive();
    if (viewInfo.horizontal && viewInfo.fixedColumn && viewInfo.columnSource) {
      //todo// moveFixedColumn()
    }
  }

  me.postSomeData = async function (fieldName, data, recordIndex) {
    // Для данных снаружи(текстовые блоки и т д)
    const source = me.getActiveSource();
    if (!source.isEditMode()) {
      await source.editRecord(recordIndex);
      // changeIndicator(dsEdit);
    }
    // await source.editRecord();
    await setColumnText({ fieldName: fieldName }, data.text ? data.text : await source.getFieldText(fieldName));
    await setColumnValue({ fieldName: fieldName }, data.id ? data.id : await source.getFieldValue(fieldName));
    // me.CommitToSaveChanges && me.CommitToSaveChanges(me);
  };

  me.postExicstedEditRec = async function (load) {
    // if(editor && editor.EditData){
    //
    //   const setObjTextData =  SetObjectText({...editor.EditData, value:editor.text})
    //   const dataWithCheck=  me.getActiveSource().CheckFieldText(editor.column.fieldName, setObjTextData.ObjRef, setObjTextData.Text);
    //   me.postSomeData(editor.column.fieldName, {text:dataWithCheck.Text, id:dataWithCheck.ObjRef})
    // }
    await changeIndicator(dsBrowse);
    me.CommitToSaveChanges && (await me.CommitToSaveChanges(false));
    if (me.editorMode() && editor.InputChange) {
      inputEditor.focus();
      delete editor.InputChange;
      await hideEditor();
      return;
    } else {
      // updateRecordTable();
    }

    await me.getActiveSource().postRecord();
    if (!viewInfo.horizontal) {
      await updateRecordTable(load);
      await scrollToRecord(me.source.activeRecord, true);
    } else {
      if (viewInfo.horizontal) await updateCard(false);
    }
    await hideEditor();
    //
  };

  me.refreshSource = async function (key, info) {
    if (me.source && me.source.checkActive(key != undefined && key != "")) {
      // showApplicationMask(panel);
      let dataFromRefrsh;
      if (viewInfo.horizontal) {
        viewInfo.saveSource.refresh(key);
        viewInfo.saveSource.acquireRecords(viewInfo.saveSource.activeRecord, 1);
        me.source.refresh("!");
        if ((assignBMList.length = 0)) await me.source.refresh();
      } else {
        dataFromRefrsh = await me.source.refresh(key, info);
        updFieldsFromRefresh(dataFromRefrsh);
      }
      if (sourceActive()) {
        let distance;
        //
        //   `moddiv ${moddiv(me.data.clientHeight, rowHeight)} `,
        //   `ClientHeight ${me.data.clientHeight} `,
        //   `activeRecord ${me.source.activeRecord} `,
        //   `lastRecord ${getLastRecord()} `,
        //   `firstRecord ${getFirstRecord()} `,
        //   `rowHeight ${rowHeight} `,
        //   `recordCount ${me.source.recordCount} `
        // );
        updateRowCount();
        // if (me.source.activeRecord > getLastRecord()) {
        //   if (
        //     moddiv(me.data.clientHeight, rowHeight) < me.source.recordCount &&
        //     me.source.activeRecord - getLastRecord() >
        //       moddiv(me.data.clientHeight, rowHeight)
        //   ) {
        //     if (
        //       me.source.activeRecord + moddiv(me.data.clientHeight, rowHeight) <
        //       me.source.recordCount
        //     ) {
        //       distance = me.source.activeRecord;
        //     } else {
        //       distance =
        //         me.source.activeRecord -
        //         moddiv(me.data.clientHeight, rowHeight);
        //     }
        //     // if (distance < 0) distance = 0;
        //   } else {
        //     distance = 0;
        //   }
        // } else if (me.source.activeRecord < getLastRecord()) {
        //   if((me.source.activeRecord -
        //     moddiv(me.data.clientHeight, rowHeight)) > 0){
        //       distance =
        //       me.source.activeRecord -
        //       moddiv(me.data.clientHeight, rowHeight);

        //     }

        // }

        visibleRows = moddiv(me.data.clientHeight, rowHeight);
        if (visibleRows > me.source.recordCount) {
          visibleRows = me.source.recordCount;
        }

        if (me.source.activeRecord > getLastRecord()) {
          if (getLastRecord() == -1) {
            distance = me.source.activeRecord - moddiv(me.data.clientHeight, rowHeight) + 1;
            if (distance < 0) distance = 0;
          } else {
            distance = 0;
            distance = me.source.activeRecord - getLastRecord();
          }
        } else if (me.source.activeRecord < getFirstRecord()) {
          distance = me.source.activeRecord - getFirstRecord();
        } else distance = 0;

        // else if (maxint(me.source.acquireRecords(firstRecord, visibleRows), 1) < visibleRows)
        //   distance = maxint(me.source.acquireRecords(firstRecord, visibleRows), 1) - minint(visibleRows, me.source.recordCount);
        // else distance = 0;
        if (distance === undefined) {
          distance = 0;
        }
        //
        if (distance != 0) {
          firstRecord += distance;
          updateRowCount();
        }
        updateEditor();
      }
      // delayCall(updateCurrentView);
      // hideApplicationMask(panel)
      multiSelect = [];
      await updateAllGrid();
      // me.CommitToSaveChanges && me.CommitToSaveChanges(false);
      me.onMultiSelectChange && me.onMultiSelectChange(me);
    }
  };

  function updFieldsFromRefresh(data) {
    if (data && data.Columns) {
      for (const [key, value] of Object.entries(data.Columns)) {
        let col = {};
        let field = me.columns.title.filter((val) => {
          return val.fieldName === key;
        });
        field = field[0];
        const index = me.columns.title.indexOf(field);
        if (field) {
          if (value.Hidden === "1" && index) {
            me.columns.title.splice(index, 1);
          } else {
            field.hide = value.Hidden === "1";
            if (field.state && field.state[1]) field.state[1] = value.Hidden === "1" ? "0" : "1";
            if (value.Caption) field.title = value.Caption;
            me.columns.title[index] = field;
          }
        } else {
          col.fieldName = key;
          if (value.Caption) col.title = value.Caption;
          if (value.Width) col.width = parseInt(value.Width * 1.33);
          else col.width = 100;
          if (value.Deep) dest.level = parseInt(value.Deep);
          else col.level = 0;
          if (value.Level) col.level = parseInt(value.Level);
          me.columns.title.push(col);
        }
      }
      prepareLevels();
    }
  }

  me.setOptions = async function (options) {
    for (const [key, value] of Object.entries(constOptions)) {
      if ((value & options) === value) {
        switch (key) {
          case "filter":
            ShowFilter();
            break;
          case "groupBy":
            ShowGroup();
            break;
        }
      }
    }
    await updateRecordTable();
    await updateAllGrid();
  };

  me.updateGridSize = async function () {
    await updateAllGrid();
    if (me.editorMode()) {
      await showEditor();
    }
    // RebuildSizeMaker()
  };

  function getFieldForEditor() {
    console.log(viewInfo);
  }

  function getCorrectionHorizontalOn(arr, data) {
    for (let item of arr) {
      if (data.indexCol > data.trueCurrentCol) return data.result;
      data.indexCol++;

      if (item.expanded) {
        data.result++;
        data.trueCurrentCol++;
      }

      if (item.expanded && item.items && item.items.length !== 0) {
        data.result = getCorrectionHorizontalOn(item.items, data);
      }
    }
    return data.result;
  }

  function getCorrectionHorizontalOff(arr, data) {
    for (let item of arr) {
      if (data.indexCol >= data.trueCurrentCol) return data.result;
      data.indexCol++;

      if (item.expanded) {
        data.result++;
        data.trueCurrentCol++;
      }

      if (item.expanded && item.items && item.items.length !== 0) {
        data.result = getCorrectionHorizontalOff(item.items, data);
      }
    }
    return data.result;
  }

  var canRotate = true;
  me.Rotate = async function (view, newSource) {
    // if (window.location.host !== "localhost:3000") {
    //   alert("Функционал находится в разработке.");
    //   return;
    // }
    if (!canRotate) return;
    // if(viewInfo.horizontal) return
    if (me.editorMode()) {
      await hideEditor();
    }
    let sourceHorizontal = {};
    if (!viewInfo.horizontal) {
      if (!viewInfo.source) {
        sourceHorizontal = await createColumnSource();
      } else {
        sourceHorizontal = viewInfo.source;
      }
      if (!viewInfo.columns) {
        viewInfo.columns = CreateViewColumns();
      }
      viewInfo.saveSource = me.source;
      viewInfo.saveColumns = me.columns;
      viewInfo.source = sourceHorizontal;
      viewInfo.currentRow = currentCol;
      if (me.filter.style.display === "grid") {
        // скрытие
        await me.setOptions(1);
      }
      viewInfo.horizontal = true;
      columnOffset = 0;
      me.onButtonsStateChange && me.onButtonsStateChange(me);
      saveState();
      me.setColumns(viewInfo.columns, false);
      sourceHorizontal.refresh("!").then(async () => {
        indicatorSize = 62;
        await me.setSource(sourceHorizontal, false);

        let collapseCorrection = getCorrectionHorizontalOn(viewInfo.saveColumns.levels, {
          trueCurrentCol: currentCol,
          result: 0,
          indexCol: 0,
        });

        scrollToRecord(viewInfo.currentRow + collapseCorrection, true, false);

        updateScrollBar();
      });
    } else {
      viewInfo.horizontal = false;
      if (newSource !== false) {
        columnOffset = calculateColumnOffset(viewInfo.saveColumns.levels, me.source.activeRecord);

        let collapseCorrection = getCorrectionHorizontalOff(viewInfo.saveColumns.levels, {
          trueCurrentCol: me.source.activeRecord,
          result: 0,
          indexCol: 0,
        });

        currentCol = me.source.activeRecord - collapseCorrection;
        me.setColumns(viewInfo.saveColumns, false);
        me.setSource(viewInfo.saveSource, false);
        Zaglushka();
      }
      me.onButtonsStateChange && me.onButtonsStateChange(me);
      updateScrollBar();
      saveState();
    }
  };

  function calculateColumnOffset(data, col, indexToFind) {
    let field,
      coloffset = 0,
      width,
      level,
      totalWidth = me.panel.offsetWidth - indicatorSize,
      left = 0,
      index = indexToFind ? indexToFind : -1;

    for (const value of Object.values(data)) {
      index++;
      level = value;
      field = level.field;

      if (field.hide !== true && field.hideInCard !== true) {
        // if (value.expanded) {
        //   width = calculateColumnOffset(value, col, index);
        // } else {
        width = field.width;
        if (left + width > totalWidth) {
          if (width > 130) coloffset += 1;
          else coloffset += 1;
          overflowColumns = true;
          rightOverflow = true;
          // width -= left + width - totalWidth;
          // if (width < rowHeight) break;
          // i = levels.length;
        }
        if (col === index) return coloffset;
        left += width;
      }
    }
    if (indexToFind !== undefined) return left;
    else return 0;
  }

  async function createColumnSource() {
    let sourceHorizontal = new createRecordSource();
    sourceHorizontal.CreateCollapsibleRecords = async function (div, me, i, td) {
      const RecordInfo = await me.source.getRecordInfo();
      const RecordLevel = await me.source.getRecordLevel();
      const RecordState = await me.source.getRecordState();

      const ClassfiresIcon = document.createElement("img");
      td.levelsField = RecordInfo.levelsField;
      if (i !== 0) return;
      if (isCollapsibleRecord(RecordState)) {
        ClassfiresIcon.textContent = RecordState === 160 || RecordState === 161 ? "+" : "-";
        ClassfiresIcon.type = RecordState === 160 || RecordState === 161 ? "+" : "-";
        ClassfiresIcon.src = "data:image/gif;base64,R0lGODlhAQABAID/AMDAwAAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";
        ClassfiresIcon.classList.add("collapse-img");
        ClassfiresIcon.classList.add(RecordState === 160 || RecordState === 161 ? "collapse-plus" : "collapse-minus");
        ClassfiresIcon.style.position = "absolute";
        ClassfiresIcon.style.cursor = "pointer";
        ClassfiresIcon.style.marginLeft = 6 + MarginRecordsClassifires(RecordLevel) - 15 + "px";
        ClassfiresIcon.style.marginTop = "5px";
        ClassfiresIcon.id = "ExpandIcon";
        ClassfiresIcon.setAttribute("recordindex", me.source.activeRecord);
        ClassfiresIcon.onclick = function (e) {
          me.source.onRecordCollapsed(e);
        };
      }
      div.style.marginLeft = MarginRecordsClassifires(RecordLevel) + "px";

      // функция монтирования иконок
      if (ClassfiresIcon.textContent !== "") {
        ClassfiresIcon.role = "expandCard";
        td.appendChild(ClassfiresIcon);
      }

      return ClassfiresIcon.textContent === "" ? null : ClassfiresIcon;
    };
    sourceHorizontal.onRecordCollapsed = async function (e) {
      const levelsField = e.currentTarget.parentElement.levelsField;
      levelsField.expanded = !levelsField.expanded;
      updateCard();
    };
    sourceHorizontal.onHandleRequest = HandlePlaneSourceRequest;
    sourceHorizontal.onNeedToSaveChanged = me.getActiveSource().onNeedToSaveChanged;
    // await sourceHorizontal.open();
    return sourceHorizontal;
  }

  function CreateViewColumns() {
    const width = me.panel.offsetWidth - indicatorSize;

    return {
      title: [
        {
          title: "",
          fieldName: "Title",
          width: width * 0.25,
          level: 0,
          collapseRecord: true,
          fieldInfo: { Options: "" }, //запрет открытия редактора
        },
        {
          title: "",
          fieldName: "Value",
          width: width * 0.75,
          level: 0,
          fieldInfo: { Options: "" },
        },
      ],
    };
  }

  async function HandlePlaneSourceRequest(data) {
    async function getRecords(dataFromParent, first, count) {
      const objOfLevels = dataFromParent ? dataFromParent : viewInfo.saveColumns.levels;
      // const valueInfo = grid.getActiveSource().getRecordInfo()
      // for (let index = first; index <= count; index++) {
      //   const element = objOfLevels[index];
      //   console.log(element.field);
      //   if (element.items && element.expanded) {
      //     for (let index = 0; index <= element.items.le; index++) {
      //       const element = objOfLevels[index];
      //       console.log(element.field);
      //     }
      //   }
      // }
      for (const value of Object.values(objOfLevels)) {
        console.log(value.field);

        if (!value.field.hide && value.field.hideInCard !== true) {
          let id,
            text,
            objData,
            info = { levelsField: value, updateStatus: 0 },
            valueInfo;
          id = await me.getActiveSource().getFieldValue(value.field.fieldName);
          // id = result;
          text = me.getActiveSource().getFieldTextSync(value.field.fieldName);
          //32 распахнутая. 160 запахнутая
          if (id) {
            objData = { id: id, text: text };
          } else {
            objData = text;
          }
          if (value.items) {
            info.RecordState = value.expanded ? 32 : 160;
            if (value.expanded) {
            }
          }
          if (value.level > 0) {
            info.RecordLevel = value.level;
          }
          dataRecord.RecordSet.Records.push({
            ...info,
            Data: [value.field.title, objData],
          });
          if (value.items && value.expanded) {
            await getRecords(value.items);
          }
        }
      }
    }

    function getRecordCount(data) {
      let recordCount = 0,
        levels = data ? data : viewInfo.saveColumns.levels;
      for (const val of Object.values(levels)) {
        if (!val.field.hide && val.hideInCard !== true) {
          recordCount += 1;
          if (val.expanded === true && val.items) {
            recordCount += getRecordCount(val.items);
          }
        }
      }
      return recordCount;
    }

    function updPending() {
      return me.getActiveSource().updatesPending === true ? "1" : "0";
    }

    function getFields() {
      let recordCount = getRecordCount();
      return {
        Fields: [
          { Name: "Index", Title: "" },
          { Name: "Title", Title: "" },
          { Name: "Value", Title: "" },
          { Name: "MultiValue", Title: "" },
          { Name: "MultiCheck", Title: "" },
          { Name: "MultiCanEditing", Title: "" },
          { Name: "MultiCanModify", Title: "" },
        ],
        RecordCount: recordCount,
        UpdatesPending: updPending(),
      };
    }
    let dataRecord = {
      UpdatesPending: updPending(),
      RecordSet: {
        Attributes: ["Title", "Value"],
        Records: [],
      },
    };
    switch (data.command) {
      case "getFields":
        return getFields();
      case "getRecords":
        await getRecords();
        return dataRecord;
      case "GetRecordCount":
        let request = { UpdatesPending: updPending() };
        const recordCount = getRecordCount();
        request.RecordCount = recordCount;
        return request;
      default:
        console.log(data.command);
        break;
    }
  }

  async function updateCard(needToReBuild) {
    if (viewInfo.horizontal) {
      await me.source.refresh("!");
      if (needToReBuild !== false) await buildRecords();
      await createStatusButtonForCardMode();
      updateScrollBar();
    }
  }

  async function createStatusButtonForCardMode() {
    const oldbutton = panel.querySelector(".statusButtonForCardGrid");
    if (oldbutton) oldbutton.remove();
    if (viewInfo.horizontal && me.statusData) {
      let statusButtonDiv = document.createElement("div");
      statusButtonDiv.className = "statusButtonForCardGrid";
      statusButtonDiv.style.width = indicatorSize + "px";
      statusButtonDiv.style.position = "relative";
      statusButtonDiv.style.top = "21px";
      statusButtonDiv.style.left = "0px";
      statusButtonDiv.style.zIndex = "1";
      panel.appendChild(statusButtonDiv);
      const icon = await me.getActiveSource().getFieldValue("Status");
      ReactDOM.render(
        <StatusButtonCardMode
          icon={icon}
          handleStatuses={me.statusData.handleStatuses}
          changeStatus={me.statusData.changeStatus}
        />,
        statusButtonDiv,
      );
    }
  }

  me.isCard = function () {
    return viewInfo.horizontal;
  };

  me.updateCard = async function () {
    await updateCard();
  };

  function updateGridSizeForCardMode() {
    if (viewInfo.horizontal && me.columns.fields.length > 0) {
      const width = me.panel.offsetWidth - indicatorSize;
      const totalWidth = me.columns.fields[0].width + me.columns.fields[1].width;
      if (totalWidth !== width) {
        console.log(width - totalWidth);
        const plusWidth = (width - totalWidth) / 2;
        me.columns.fields[0].width += plusWidth;
        me.columns.fields[1].width += plusWidth;
      }
    }
  }

  function Zaglushka() {
    if (showIndicator) {
      if (me.zaglushka) me.zaglushka.remove();
      const zaglushka = document.createElement("div");
      zaglushka.style.position = "absolute";
      zaglushka.className = "zaglushkaGrid";
      zaglushka.style.zIndex = "0";
      zaglushka.style.top = 0 + "px";
      // zaglushka.style.bottom = "0";
      zaglushka.style.height = "100%";
      zaglushka.style.width = indicatorSize + "px";
      zaglushka.style.backgroundColor = "#faf9f5";
      zaglushka.style.borderRightStyle = "solid";
      zaglushka.style.borderRightColor = "#d0d0d0";
      zaglushka.style.borderRightWidth = "1px";
      me.zaglushka = zaglushka;
      me.table.appendChild(me.zaglushka);
      // return zaglushka;
    }
  }

  function ShowFilter() {
    const display = me.filter.style.display;
    me.filter.style.display = display === "grid" ? "none" : "grid";
    filterSize = display === "grid" ? 0 : rowHeight + 2;
    buildFilters();
    me.onButtonsStateChange && me.onButtonsStateChange(me);
  }

  function ShowGroup() {
    const display = me.groups.style.display;
    const pattern = new RegExp(/(.*)px/);
    me.groups.style.display = display === "" ? "none" : "";
    BuildGroupsContent();
    groupSize = display === "" ? 0 : me.groups.getBoundingClientRect().height;
  }
  var keypress = false,
    drawing = false;
  panel.style.userSelect = "none";
  panel.style.overflow = "hidden";
  panel.className = "grid-panel";
  panel.tabIndex = 0;

  let test = throttle(handleKeyDown, 10);
  setTimeout(() => {
    appendEvent("mousedown", handleMouseDown, panel);
    appendEvent("doubleclick", handleMouseDown, panel);
    appendEvent("mouseup", handleMouseUp, panel);
    appendEvent(
      "keydown",
      (e) => {
        if (e.repeat === false) {
          keypress = false;
          drawing = false;
          handleKeyDown(e);
        } else {
          if (!me.editorMode()) e.preventDefault();
          // e.stopPropagation()
          if (!drawing) {
            drawing = true;
            test(e);
            // setTimeout(() => {
            //   handleKeyDown(e)
            // .then(() => {
            drawing = false;
            // });
            // });
          }
        }
      },
      panel,
    );
    // panel.addEventListener("keydown", (function(canMove) {
    //   return function(e) {
    //     keypress = false;
    //     if (!canMove) return false;
    //     canMove = false;
    //     setTimeout(function() { canMove = true; }, 0);
    //     handleKeyDown(e)
    //   };
    // })(true), false);
    appendEvent(
      "keyup",
      () => {
        keypress = true;
      },
      panel,
    );
  }, 100);

  // sizeMarker = document.createElement("div");
  // sizeMarker.className = "grid-size-marker";
  // sizeMarker.style.left = "0px";
  // sizeMarker.style.top = "0px";
  // sizeMarker.style.height = "100%";
  // sizeMarker.style.opacity = 0;
  // sizeMarker.onmousedown = function (e) {
  //   e.stopPropagation()
  //   if (sizeMarker.sizeEl && handleColumnField === null) {
  //     handleColumnField = sizeMarker.sizeEl.field;
  //     sizeMarker.style.opacity = 1;
  //     if (handleColumnField) {
  //       appendEvent("mousemove", sizeColumn);
  //     }
  //   }
  // };
  // sizeMarker.ondblclick = function (e) {
  //   ajustColumnWidth(sizeMarker.sizeEl.field);
  // };
  // panel.appendChild(sizeMarker);

  var lastOverElement,
    prevEl,
    panelLeftOffset = panel.getBoundingClientRect().left;
  setTimeout(() => {
    panel.onmousemove = function (e) {
      let el = e.target;
      if (el !== prevEl && el.className === "grid-cell-inner") {
        let field;
        if (viewInfo.horizontal) {
          field = el.closest("TD").levelsField.field;
        } else {
          field = el.closest("TD").field;
        }

        if (field.fieldInfo && field.fieldInfo.OnHint) {
          if (me.doccfgid && !el.title) {
            const json = GetColumnHintDocs({
              ID: field.fieldInfo.ColumnID,
              DocCfgID: me.doccfgid,
              DocID: me.getActiveSource().getFieldTextSync("ID"),
              MasterID: me.getActiveSource().Master.MasterID,
            });
            el.title = json && json.Text ? json.Text : "";
          }
        } else {
          if (el.scrollWidth > el.offsetWidth) {
            if (el.title !== el.innerText) {
              el.title = el.innerText;
            }
          } else el.title = "";
        }
        prevEl = el;
      }
      if (el !== prevEl && el.className === "column-header-text") {
        if (el.scrollWidth > el.offsetWidth) {
          if (el.title !== el.innerText) {
            el.title = el.innerText;
          }
        } else el.title = "";
        prevEl = el;
      }
      if (lastOverElement != el) {
        const Test = panel.getBoundingClientRect().left;
        lastOverElement = el;
        while (!el.role) {
          el = el.parentElement;
          if (!el) return;
        }
        // sizeMarker.style.left =
        //   el.getBoundingClientRect().right - Test - 5 + "px";
        // sizeMarker.sizeEl = el;
      }
    };
  }, 15);

  panel.classList.add("grid-main-parent");
  me.panel = panel;

  filterSize = 0;

  me.filter = document.createElement("div");
  me.filter.style.display = "none";
  me.filter.className = "grid-filter";
  me.filter.style.position = "relative";
  me.filter.style.top = "0px";
  me.filter.style.width = "100%";
  me.filter.style.height = rowHeight + 2 + "px";
  panel.appendChild(me.filter);

  groupSize = rowHeight + 4;

  if (groupSize > 0) {
    me.groups = document.createElement("div");
    me.groups.className = "grid-groups";
    me.groups.style.display = "none";
    me.groups.style.position = "relative";
    me.groups.style.width = "100%";
    me.groups.style.height = groupSize + "px";
    me.groups.style.overflow = "hidden";
    me.groups.style.whiteSpace = "nowrap";
    panel.appendChild(me.groups);
  }

  me.header = document.createElement("div");
  me.header.className = "grid-header";
  me.header.style.position = "absolute";
  me.header.style.width = "100%";
  panel.appendChild(me.header);

  leftRightMover = document.createElement("ul");
  leftRightMover.className = "pagination";
  leftRightMover.innerHTML = '<li><span role="l">❮</span></li><li><span role="r">❯</span></li>';
  leftRightMover.style.position = "absolute";
  leftRightMover.style.right = "-7px";
  leftRightMover.style.top = "2px";
  leftRightMover.style.width = "40px";
  leftRightMover.style.height = "20px";
  leftRightMover.style.opacity = 1;
  leftRightMover.style.backgroundColor = "white";
  leftRightMover.onclick = async function (el) {
    if (el.target.innerText == "❮") {
      if (columnOffset > 0) {
        columnOffset--;
        if (me.editorMode()) await hideEditor();
        await updateAllGrid();
      }
    } else if (el.target.innerText == "❯") {
      if (rightOverflow) {
        columnOffset++;
        if (me.editorMode()) await hideEditor();
        await updateAllGrid();
      }
    }
  };
  me.header.appendChild(leftRightMover);

  me.table = document.createElement("div");
  me.table.className = "grid-table";
  me.table.style.position = "absolute";
  me.table.style.width = "100%";
  me.table.style.bottom = "1px";

  Zaglushka();

  panel.appendChild(me.table);
  me.data = document.createElement("div");
  me.data.style.float = "left";
  me.data.style.width = "100%";
  me.data.style.height = "100%";
  me.data.style.backgroundColor = GRID_BACKGROUND;
  me.data.style.zIndex = 1;
  me.data.classList.add("grid-data");
  me.data.classList.add("grid-with-row-lines");
  appendEvent(
    "wheel",
    (e) => {
      queuedGridScroll.scrollGrid(e, handleMouseWheel).then(() => {
        queuedGridScroll.stopQueued();
      });
    },
    me.table,
  );
  var touchStartY = 0;
  appendEvent(
    "touchstart",
    (e) => {
      let touchObj = e.changedTouches[0];
      touchStartY = parseInt(touchObj.clientY);
      e.preventDefault();
    },
    me.table,
  );
  appendEvent(
    "touchmove",
    (e) => {
      queuedGridScroll.scrollGrid(e, handleMouseWheel).then(() => {
        queuedGridScroll.stopQueued();
      });
      e.preventDefault();
    },
    me.table,
  );
  if (showIndicator) {
    indicator = createIndicator("strelka");
    indicator.updated = false;
    setTimeout(() => {
      changeIndicator(dsBrowse);
    }, 100);
  }

  panel.onfocus = function (e) {
    me.onfocus(e);
  };
  panel.onblur = function (e) {
    me.onblur(e);
  };

  me.onfocus = function (e) {};

  me.onblur = function (e) {};

  me.table.appendChild(me.data);

  me.scrollBar = new createScrollbar(me.table, async function (ratio) {
    var pos = Math.round((me.source.recordCount - visibleRows) * ratio);

    if (pos != firstRecord) {
      firstRecord = pos;
      let max = me.source.recordCount - visibleRows;
      if (firstRecord > max) firstRecord = max;
      currentRow = me.source.activeRecord - firstRecord;
      // await queuedGridScroll.scrollGrid(undefined, buildRecords);
      await buildRecords();
    }
  });

  return me;
}
