import { Button, Grid } from "@mui/material";
import React from "react";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { isEmptyObject, localization } from "../../Tools/Tools";
import { AxiosRequest } from "../../../Url";

export default function DialogKldr(props) {
  const [mainGrid, setMainGrid] = React.useState();
  const [timer, setTimer] = React.useState();

  //ссылка на компонент для таблицы
  const gridpanelRef = React.useRef(null);

  //временное объявление для работы запроса SetAddress
  //создаем в памяти константу чтобы один раз изменить ее если отсутствует CurrentID у адреса
  const propsData = React.useMemo(() => {
    return { ...props.data };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //запрос посимвольной фильтрации
  function filtration(filter) {
    const params = new Map();
    params.set("prefix", "kladr").set("comand", "setFilter").set("Filter", filter).set("ObjType", props.ObjType);
    return AxiosRequest(true, params);
  }

  React.useLayoutEffect(() => {
    GridLoader(gridpanelRef.current, props).then((res) => {
      setMainGrid(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //запрос для получения параметров для запроса setParamProperty
  function resumeRequest(request) {
    const params = new Map();
    params.set("prefix", "project").set("comand", "ResumeRequest").set("RequestID", props.RequestID).set("WSM", "1");
    return AxiosRequest(true, params, request);
  }
  //создание запроса(request) для resumeRequest
  function getRequestJson() {
    const code = gridpanelRef.current.grid.source.getFieldTextSync("Code");
    const name = gridpanelRef.current.grid.source.getFieldTextSync("Name");
    const short = gridpanelRef.current.grid.source.getFieldTextSync("Socr");
    const GUID = gridpanelRef.current.grid.source.getFieldTextSync("GUID");
    if (propsData.FieldName === "BulkNo" || propsData.FieldName === "BuildNo") {
      propsData.FieldName = "House";
    }
    return {
      Result: "1",
      Code: code,
      Classificator: { Классификатор: code },
      [propsData.FieldName]: {
        [localization()[propsData.FieldName]]: name,
        Сокращение: short,
        Код: code[0] + "" + code[1],
      },
      GUID: { Идентификатор: GUID },
    };
  }

  //функция для установки новго значения для параметра
  async function onSelect() {
    const json = await resumeRequest(getRequestJson());

    propsData.onEdit({
      kladr: true,
      json: json,
      objref: propsData.ObjRef,
    });
  }

  //установка нового значения для полей адреса House

  return (
    <>
      {/* {Основной контейнер} */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        style={{ height: "100%", width: "100%" }}
      >
        {/* {Контейнер для Таблицы} */}
        <Grid
          id="rcgrid_panel"
          ref={gridpanelRef}
          item
          style={{
            position: "relative",
            height: "calc(100% - 40px)",
            width: "100%",
          }}
        ></Grid>
        {/* {Контейнер для Поиска и кнопок} */}
        <Grid item style={{ height: "40px", width: "100%" }}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {/* {Поиск} */}
            <Grid item style={{ margin: "8px", width: "calc(100% - 182px)" }}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <div style={{ width: "62px" }}>
                  <span>Поиск:</span>
                </div>
                <input
                  style={{
                    width: "calc(100% - 62px)",
                    outline: "0",
                  }}
                  onChange={(ev) => {
                    clearTimeout(timer);

                    setTimer(
                      setTimeout(async () => {
                        await filtration(ev.target.value === undefined ? "" : ev.target.value);
                        if (mainGrid) {
                          await mainGrid.grid.refreshSource();
                        }
                      }, 200),
                    );
                  }}
                  title="Filter"
                />
              </Grid>
            </Grid>
            <Grid item style={{ width: "164px" }}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Button
                  size="small"
                  variant="outlined"
                  style={{ textTransform: "none", marginRight: "10px" }}
                  value="1"
                  className="button_Modal_Select"
                  onClick={onSelect}
                >
                  Выбрать
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  value="2"
                  className="button_Modal_Close"
                >
                  Отмена
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
