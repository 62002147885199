import { AxiosRequest } from "../../../Url";
import { PostHandleTable } from "../../Tools/Requests";
import { getRightsData } from "../../Tools/Tools";
import { parseColumns } from "../GridTools/parseColumnTools";

export async function cadresGrid(grid, source, props, data) {
  let json = await PostHandleTable(props.Module, props.ObjType, undefined, props.SectionID, undefined, props.Path);
  source.onHandleRequest = handleCadresRequest(props);
  await source.open();
  if (json) {
    source.rights = getRightsData(json.Rights);
    if (json.ReadOnly === "1") grid.OnlyViewMode = true;
    grid.setColumns(parseColumns(json));
  } else grid.defaultColumns = true;
  if (!props.ObjRef) await source.refresh();
  await grid.setSource(source);
  if (props.ObjRef) await grid.refreshSource(props.ObjRef);
  grid.updateGridSize();
}

export function handleCadresRequest(props) {
  return function onHandleRequest(request, load, wsm) {
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "HandleTable")
      .set("GroupID", props.GroupID)
      .set("Path", props.Path)
      .set("SectionID", props.SectionID);
    if (wsm) params.set("WSM", "1");
    return AxiosRequest(true, params, request);
  };
}
