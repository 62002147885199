import { Button, Grid, IconButton } from "@mui/material";
import React from "react";
import { DateCalendar, LocalizationProvider, heIL } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ru";
import dayjs from "dayjs";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TodayIcon from "@mui/icons-material/Today";
import { formEdit } from "../../Tools/Tools";
import { closeModal } from "../../Tools/modalManager";

export default function DialogCalendar({ props }) {
  const [dateValue, setDateValue] = React.useState(props.value ? props.value : getDateBack(getNowDate()).label);

  const calendar = React.useRef();

  React.useEffect(() => {
    if (calendar.current) {
      const prev = calendar.current.querySelector(".MuiIconButton-edgeEnd");
      if (prev) {
        prev.title = "Предыдущий месяц";
      }
      const next = calendar.current.querySelector(".MuiIconButton-edgeStart");
      if (next) {
        next.title = "Следующий месяц";
      }
    }
  }, [calendar.current]);

  function getNowDate() {
    const test = new Date();
    return `${test.getDate() < 10 ? `0${test.getDate()}` : test.getDate()}.${
      test.getMonth() + 1 < 10 ? `0${test.getMonth() + 1}` : test.getMonth() + 1
    }.${test.getFullYear()}`;
  }

  function getDateInFormat(value) {
    let date = value;

    if (date) {
      if (date.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) return date;
      if (date.match(/[0-9]{2}.[0-9]{2}.[0-9]{4}/)) {
        date = date.split(".");
        date = [date[2], date[1], date[0]];
        return date.join("-");
      }
    }
  }

  function getDateBack(value) {
    const date = getDateInFormat(value);

    if (date) {
      // // вычисление id даты (количество дней с 30.12.1899)
      const oneDay = 1000 * 60 * 60 * 24;
      const startDate = new Date("1899-12-30");
      const currentDate = new Date(date);
      let id = Math.round((currentDate.getTime() - startDate.getTime()) / oneDay);

      if (!id) id = "-1";
      return { label: value === ".." ? "" : value, id: id };
    } else return { label: "", id: "" };
  }

  function getMaxDay(id, year) {
    {
      return {
        ["0"]: "0",
        ["1"]: "31",
        ["2"]: Number(year) % 4 === 0 ? "29" : "28",
        ["3"]: "31",
        ["4"]: "30",
        ["5"]: "31",
        ["6"]: "30",
        ["7"]: "31",
        ["8"]: "31",
        ["9"]: "30",
        ["10"]: "31",
        ["11"]: "30",
        ["12"]: "31",
      }[id];
    }
  }

  function monthSelect(numMonth) {
    const date = dateValue.split(".");
    let day = date[0];
    const month = numMonth < 10 ? `0${numMonth}` : numMonth;
    const year = date[2];
    const maxDay = getMaxDay(numMonth, year);

    if (day > maxDay) day = maxDay;

    setDateValue(`${day}.${month}.${year}`);
  }

  async function onSelect() {
    const date = getDateBack(dateValue);
    if (props.onEdit && props.setValue && props.setInputValue) {
      props.onEdit({
        value: date.label,
        tag: date.id,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
      if (props.newModal) closeModal();

      return;
    }

    if (props.RequestID) {
      await formEdit("1", props.func, { Name: "ObjRef", Value: date.id }, props.RequestID, props.from);
    }
    if (props.newModal) closeModal();
  }

  const stylebttn = {
    height: "20px",
    width: "20px",
    fontSize: "90%",
    marginTop: "6px",
    borderRadius: "3px",
    border: "1px solid #eeeeee",
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        style={{
          height: "calc(100% - 50px)",
          width: "100%",
          border: "1px solid #cccccc",
        }}
      >
        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ height: "100%" }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            style={{
              height: "100%",
              width: "25px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            <Grid>
              <IconButton
                title="Январь"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("1");
                }}
              >
                Ⅰ
              </IconButton>
              <IconButton
                title="Февраль"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("2");
                }}
              >
                Ⅱ
              </IconButton>
              <IconButton
                title="Март"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("3");
                }}
              >
                Ⅲ
              </IconButton>
              <IconButton
                title="Апрель"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("4");
                }}
              >
                Ⅳ
              </IconButton>
              <IconButton
                title="Май"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("5");
                }}
              >
                Ⅴ
              </IconButton>
              <IconButton
                title="Июнь"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("6");
                }}
              >
                Ⅵ
              </IconButton>
              <IconButton
                title="Июль"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("7");
                }}
              >
                Ⅶ
              </IconButton>
              <IconButton
                title="Август"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("8");
                }}
              >
                Ⅷ
              </IconButton>
              <IconButton
                title="Сентябрь"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("9");
                }}
              >
                Ⅸ
              </IconButton>
              <IconButton
                title="Октябрь"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("10");
                }}
              >
                Ⅹ
              </IconButton>
              <IconButton
                title="Ноябрь"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("11");
                }}
              >
                Ⅺ
              </IconButton>
              <IconButton
                title="Декабрь"
                style={stylebttn}
                size="small"
                onClick={() => {
                  monthSelect("12");
                }}
              >
                Ⅻ
              </IconButton>
            </Grid>
          </Grid>
          <Grid ref={calendar}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
              <DateCalendar
                showDaysOutsideCurrentMonth
                views={["year", "month", "day"]}
                value={dayjs(getDateInFormat(dateValue))}
                onChange={(newValue, comand) => {
                  setDateValue(
                    `${newValue.get("date") < 10 ? `0${newValue.get("date")}` : newValue.get("date")}.${
                      newValue.month() + 1 < 10 ? `0${newValue.month() + 1}` : newValue.month() + 1
                    }.${newValue.year()}`,
                  );
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid style={{ height: "100%" }}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              style={{ height: "100%", marginRight: "10px" }}
            >
              <Grid
                style={{
                  height: "33%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  title="В начало года"
                  size="small"
                  onClick={() => {
                    const date = getDateInFormat(dateValue) ? new Date(getDateInFormat(dateValue)) : new Date();
                    setDateValue(`01.01.${date.getFullYear()}`);
                  }}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </IconButton>
              </Grid>
              <Grid
                style={{
                  height: "34%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  title="Текущая дата"
                  onClick={() => {
                    setDateValue(getDateInFormat(getNowDate()));
                  }}
                  size="small"
                >
                  <TodayIcon />
                </IconButton>
              </Grid>
              <Grid
                style={{
                  height: "33%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  title="В конец года"
                  onClick={() => {
                    const date = getDateInFormat(dateValue) ? new Date(getDateInFormat(dateValue)) : new Date();
                    setDateValue(`31.12.${date.getFullYear()}`);
                  }}
                  size="small"
                >
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ height: "40px", width: "100%", marginTop: "10px" }}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginRight: "10px" }}
            value="1"
            className="button_Modal_Select"
            onClick={onSelect}
          >
            Ок
          </Button>
          <Button
            onClick={() => {
              if (props.newModal) closeModal();
            }}
            size="small"
            variant="outlined"
            value={"2"}
            style={{ textTransform: "none", marginLeft: 10, width: 75 }}
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
