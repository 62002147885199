import { Checkbox } from "@mui/material";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import cn from "classnames";

import useTheme from "../../../Hooks/useTheme.jsx";

// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
// import HeightIcon from "@mui/icons-material/Height";
// import CircleIcon from "@mui/icons-material/Circle";
// import SquareIcon from "@mui/icons-material/Square";
// import ClearIcon from "@mui/icons-material/Clear";
export default function CheckBoxMultiCheck(props) {
  const theme = useTheme();

  // Иконки состояний
  const Unchecked = (
    <CheckBoxOutlineBlankIcon checkicon={"true"} className={cn("fontColorStimate", { light: theme === "light" })} />
  );
  const Checked = <CheckBoxIcon checkicon={"true"} className={cn("fontColorStimate", { light: theme === "light" })} />;
  const LeftArrow = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAUUlEQVQokWNgGFZAkIGBoZ4cjYYMDAx3GBgYnpKqMY6BgeEbAwPDf1I0czMwMCyBaiIGH0PWPJ8Ejf8ZGBjOUGLzUar5GRmQHdowQHY8DwEAAEi9LqoZcEUOAAAAAElFTkSuQmCC"
    />
  );
  const UpArrow = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAd0lEQVQokeXPMQrCUAyA4c9ScHARPEgHL6UeSnDqTbxFi6cQHaQOvooEbH1Ip/4QAkl+kjARKxxTzuaEDnWuuE9iH7tfxQrXIN+wHRPXaILYxwWbz+EiyIeU21Bv8fB6581i4IpubC5uzmKOcjnQO2OJ+z8LvvIExvYZVSJjKmIAAAAASUVORK5CYII="
    />
  );
  const Horizontal = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAjElEQVQokd3RMQ4BQRyF8d8Wyw10duMmDqHZytlUShUNCQchQesGFDT/STayI7IdXzLVl5n3Xoa/YIAi44rwnVQ4YpbxTfjqXYxDbDDMXC6xwhmTVGWMPa7x+j1XLWovUWMKWzx7nF1KPuCGOR4fkkssMErJ7c3rLzZf0uY2NU6xu4smfJ2r1fuff4gXahUi5bfUpsIAAAAASUVORK5CYII="
    />
  );
  const Vertical = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAk0lEQVQokd3SSwrCMBRG4a9CXURBW9ck2GWK8wrSdbTiwIG70IFXEGzSjj2T8Cc5N0/SbHDGLjMnKQ544pYqsEqIFzwi39FFf5Z1rHhCEys3kQeUOblAG0WqkKvIbYwv4lueZOrMi/kTucDe75OUOJi57RIjjqi9b7uOPHo/WZYtruhD7iPXc+KHRX97bgddtJO8AC8rHItGG/0wAAAAAElFTkSuQmCC"
    />
  );
  const RightArrow = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAT0lEQVQokWNgGLagnoGBQZBczU8ZGBgeMjAwmJOr+T8DA8MPBgaGfGwKjkEVEIOXMDAwcCNrPkOC5v8MDAzzqWYzRX4mpJns0KYongchAABsAC7n0QjPxwAAAABJRU5ErkJggg=="
    />
  );
  const DownArrow = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAcklEQVQokWNgoAAw4pE7xsDAwMbAwPCTgYHBmlTN/wmpYyLkNHxgVDMDQw0DA8NdKEYGMLEqfIYJQBX9x4IfMDAwCBNyjT4DA8M3NI0/GBgYjAlphIEMNM3pxGqEgYVQjctJ1cjAwMDAzcDAMB9KUx8AALHdGmkOx7M9AAAAAElFTkSuQmCC"
    />
  );
  const Bullet = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QA/wD/AP+gvaeTAAAAWUlEQVQYlX2PUQqAMAxDo+dwnkiK59d5FJ0/KcRuNvBgH28hBfoU8psdwAWgkQrARtIjknOrPIWmyOnimkhOmbPRkuaPmrQd+sM4fHTMFuuNw7WpkzQL+eQFxVQu0d8mj+cAAAAASUVORK5CYII="
    />
  );
  const Required = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAANUlEQVQokWNgGCjAiMTmZGBgUCdCz00GBobv6IIGDAwM/4nABjANTOQ6eVTzkNJMtRRGXwAAhc8NauSW/JMAAAAASUVORK5CYII="
    />
  );
  const LeftArrowBlue = (
    <img
      style={{ transform: "rotate(180deg)" }}
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAq0lEQVQokWNgGJ6AKWZrPUPUFkGscoQ0MzIxpjEzM15gjd1qTrJmKJD7x8BwkClmaz6KwQwMDAzMsduOMTD8tyTOnP9L/zKwpjMsdv/KAhVgI9IFDAwMjNHMDH9+/2VgSIQ6m/EX8Zr/L/3LwJIDdzY+wBy37SnD//9SDAwMP///Zyj/t8R7IkyOhUjrHjExMIT9XuJ9ElmQYGj///d/1t+//w1+L0bVOIQBAKmcMNWgTlTtAAAAAElFTkSuQmCC"
    />
  );
  const RightArrowRed = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAkUlEQVQokd2RsQlCQRBE3xwfcxuwhR+KjZgYmWgiWILVWIiJYHZnCYIlCKJwY+APRETXUCfc3Tezy8J/qsBqD/1XvfQJtjSzlAsMv4YBDANLmwLLx7oAirQ1jCJGSGvVOm/h1HTOvRAIYE+c0pVap6mLv4The/IiNJulY5acpfPzzU0oDA7Y4xZ24Q3h/Z9/VDeRPSpapGpugQAAAABJRU5ErkJggg=="
    />
  );
  const Unknown = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAAAwUlEQVQokeXRPQrCQBCG4XdUgoUI2gqCF/AIXkEFPUGy4A1MGW9gFQjZkDOIhaUeRPAOKcV1rQzxJ2IqC79y4Jn5YOA/kiTJQmvdBahVgVrrFdaurbVDgEYVKOBbmCmlDgBSFXqet73P89phGHbSNG2WwCUikyJ8wI7jpFdjNsUFBTh1XXf3vDivHUVRv1GvHwSOrXZ7nGWZ/67qWwwQx/GgJrIHDNAru/hSG0ApdboYM0LkbGH+CZYmCIKvX/ib3ACBFEg+iHRLAAAAAABJRU5ErkJggg=="
    />
  );
  const Reject = (
    <img
      checkicon={"true"}
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAABmJLR0QA/wD/AP+gvaeTAAABE0lEQVQokc2SO07DQBBA39hNEKk4QSokROhgnSaSlb0BV0BKEKfIGSBA+PUUSJwAKVKaYEhBFYkKOACV7TTYQ0FimWQpqGC6ndn3RjO78C9iGjZqCuKqKcg0bNTKOa8MZn7+GNud/qJAQWJrTjM/H5cFBVwZjF4FvRaknVhzod2vmoKkNugJdIDbSjh6mzNLHVIb9BQ9AK5W16JO+h4cFudm1JYuuRN2CCbAhgt0wjOBl7TME0Jd4bnajDaly8fiPc8BSmqDI4Q6yERgPRma/nwHP8LLM99vCXIC7CVDc+4SFGBig+PYGo2tuSxv25X/NvPsnceK3lTvHvYFtCyOW8GZiO76mbe9Mhi9LHX/7Q/7u/gEmuuBZpiHMdwAAAAASUVORK5CYII="
    />
  );

  // функция получения массива целочисленных состаяний
  function getCheckstate(multicheckSet) {
    // по умолчанию состояния с галочкой и без
    if (!multicheckSet) return [0, 1];
    const result = [];
    let checkState = multicheckSet;
    let value;
    for (let i = 0; i < 14; i++) {
      if (checkState === 0) break;
      value = Math.pow(2, i);
      if (checkState & value) {
        checkState -= value;
        result.push(i);
      }
    }
    return result;
  }

  // функция получения иконки по целочисленному состоянию
  function getIcon(checkstate, customIcons) {
    const icons = {
      0: Unchecked,
      1: Checked,
      2: LeftArrow,
      3: UpArrow,
      4: Horizontal,
      5: Vertical,
      6: RightArrow,
      7: DownArrow,
      8: Bullet,
      9: Required,
      10: LeftArrowBlue,
      11: RightArrowRed,
      12: Unknown,
      13: Reject,
    };

    return (customIcons && customIcons[checkstate]) || icons[checkstate];
  }

  // функция получения следующего состояния из массива состояний
  // аргументы : 1. массив с целочисленными состояниям, 2. текущее целочисленное состояние
  function getNextState(arr, state) {
    const length = arr.length;
    const index = arr.indexOf(Number(state));

    return index < length - 1 ? arr[index + 1] : arr[0];
  }

  // Иконка чекбокса
  const [icon, setIcon] = React.useState(Unchecked);
  // захардкоженое состояние чекбокса блокирующее состояние атрибута checked
  const [checked, setChecked] = React.useState(false);
  // текущее состояние чекбокса
  const [stateCheckBox, setStateCheckBox] = React.useState();
  // массив состояний, в котором хранятся допустимые состояния
  const checkStates = React.useRef();
  const checkboxRef = React.useRef();

  React.useEffect(() => {
    checkboxRef.current.update = (data) => {
      checkStates.current = getCheckstate(data.MultiCheckSet);
      setStateCheckBox(data.CheckState ? Number(data.CheckState) : 0);
    };
  }, []);

  React.useEffect(() => {
    setChecked(false);
    // установка массива состояний
    checkStates.current = getCheckstate(props.MultiCheckSet);
    // получение текущего состояния
    setStateCheckBox(props.CheckState ? Number(props.CheckState) : 0);
  }, [props.CheckState]);

  React.useEffect(() => {
    // при изменении текущего состояния обновляется иконка чекбокса
    if (stateCheckBox !== undefined) {
      setIcon(getIcon(stateCheckBox, props.customDefaultIcons));
    }
  }, [stateCheckBox]);

  return (
    <div id="checkbox-multicheck" ref={checkboxRef} style={{ width: "100%", height: "100%" }}>
      <Checkbox
        // иконка чекбокса
        disabled={props.disabled}
        icon={icon}
        checkedIcon={icon}
        checked={checked}
        onChange={(ev) => {
          ev.stopPropagation();
          // получение следуйщего состояния
          if (props.canModify && props.canModify() === false) {
            return;
          }
          let state = getNextState(checkStates.current, stateCheckBox);
          setStateCheckBox(state);

          const record = ev.target.closest("TR");
          if (!props.onEdit) return;
          if (record) {
            record.dataset.checkstate = state;
            // функция на редактировние обновит состояние чекбокса из ответа внутри функции
            props.onEdit({
              record: record,
              fieldname: record.dataset.fieldname,
              groupid: record.dataset.groupid,
              current: record.dataset.current,
              CheckState: state,
              textchanged: "0",
              setStateCheckBox: setStateCheckBox,
            });
          } else {
            props.onEdit({
              name: props.parentProps ? props.parentProps.name : undefined,
              id: props.parentProps ? props.parentProps.id : undefined,
              CheckState: `${state}`,
            });
          }
        }}
        style={{
          width: "21px",
          height: "21px",
          display: props.display !== undefined ? props.display : "",
        }}
        sx={{
          "& .MuiSvgIcon-root": { fontSize: 18 },
        }}
        type="checkbox"
      />
    </div>
  );
}
