import { Button, Grid, IconButton, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";
import { tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import { AxiosRequest } from "../../../Url";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { closeModal } from "../../Tools/modalManager";
import { formEdit } from "../../Tools/Tools";

export default function DialogSetObjText(props) {
  // массив отсортированных записей
  const [sortRecords, setSortRecords] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // ссылка на контейнер с таблицей
  const tableContainer = React.useRef(null);
  // массив всех записей
  const records = React.useRef([]);
  // выбранный эелемент
  const selected = React.useRef();

  const inputFilter = React.useRef();

  React.useEffect(() => {
    // получение jsx записей для таблицы
    records.current = props.list;
    // первичная отрисовка записей
    setSortRecords(records.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    tableContainer.current.querySelector("[tabIndex]").focus();

    setTimeout(() => {
      selected.current = tableContainer.current.querySelector("LI");
    });
  }, []);

  // функиця на выбор записи из списка
  async function onEdit() {
    if (props.onEdit && selected.current) {
      let data = {};
      if (props.data) {
        data = { ...props.data };
      }
      props.onEdit({
        record: props.record,
        textchanged: "0",
        name: props.name,
        setValue: props.data ? props.data.setValue : undefined,
        setInputValue: props.data ? props.data.setInputValue : undefined,
        ...data,
        proof: false,
        value: selected.current.innerText,
        tag: selected.current.id.split("_")[1],
        code: selected.current.getAttribute("code"),
        requestId: props.RequestID,
        CheckState: props.CheckState,
      });
      closeModal();
      return;
    }

    if (props.RequestID) {
      await formEdit(
        "1",
        props.func,
        { Name: "Index", Value: selected.current.id.split("_")[1] },
        props.RequestID,
        props.from,
      );
    }

    closeModal();
  }

  function onKeyDown(ev) {
    switch (ev.key) {
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  }

  // функиця для получения записей для отрисовки
  function getRecord(item, id) {
    return (
      <MenuItem
        index={id}
        id={`item_${item.id}`}
        code={item.code}
        selected={selectedIndex === id}
        style={{ height: "20px", fontSize: "14px" }}
        onDoubleClick={(ev) => {
          selected.current = ev.target;
          onEdit();
        }}
        onKeyDown={(ev) => {
          if (ev.code === "Enter") {
            selected.current = ev.target;
            onEdit();
          }
        }}
        onClick={(ev) => {
          // на клик по записи она помечается как выбранная
          selected.current = ev.target;
          setSelectedIndex(id);
        }}
        key={`variant_${item.id}_${id}`}
        text={item.text}
      >
        {item.text}
      </MenuItem>
    );
  }

  // функция отсеивания записей по значению из поля ввода
  function filter(reg) {
    const regexp = new RegExp(reg);
    setSortRecords(
      records.current.filter((item) => {
        if (item.text.match(regexp)) return item;
        return false;
      }),
    );
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ height: "100%", width: "100%" }}
    >
      {/* Таблица */}
      <Grid item style={{ width: "100%", height: "calc(100% - 40px)" }}>
        <Grid
          ref={tableContainer}
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #cccccc",
            overflow: "auto",
          }}
          onKeyDown={onKeyDown}
        >
          <Paper
            style={{
              minWidth: "fit-content",
              width: "100%",
              boxShadow: "none",
            }}
          >
            <MenuList
              tabIndex="-1"
              style={{
                height: "100%",
                width: "100%",
                outline: "none",
              }}
            >
              {sortRecords.map((item, id) => {
                return getRecord(item, id);
              })}
            </MenuList>
          </Paper>
        </Grid>
      </Grid>
      {/* Кнопки */}
      <Grid
        item
        style={{
          height: "40px",
          width: "100%",
          display: "flex",
          flexWrap: "nowrap",
        }}
      >
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          {/* {Поиск} */}
          <Grid
            item
            style={{
              margin: "8px",
              width: "calc(100% - 182px)",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <IconButton
              onClick={() => {
                filter(inputFilter.current.value);
              }}
              style={{
                width: "27px",
                height: "27px",
                marginRight: "14px",
                marginLeft: "5px",
                display: "flex",
                flexWrap: "nowrap",
              }}
            >
              <SearchOutlinedIcon />
            </IconButton>
            <input
              ref={inputFilter}
              style={{
                width: "calc(100% - 46px)",
                outline: "0",
                display: "flex",
                flexWrap: "nowrap",
              }}
              // onBlur={(ev) => {
              //   filter(ev.target.value);
              // }}
              onKeyDown={(ev) => {
                if (ev.code === "Enter") {
                  filter(ev.target.value);
                }
              }}
              title="Filter"
            />
          </Grid>
          <Grid item style={{ width: "164px", display: "flex", flexWrap: "nowrap" }}>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              value="1"
              className="button_Modal_Select"
              onClick={(ev) => {
                onEdit();
              }}
            >
              Выбрать
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              value="2"
              className="button_Modal_Close"
              onClick={(ev) => {
                closeModal();
                if (props.onClose) {
                  props.onClose(ev);
                }
              }}
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
