import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { AxiosRequest } from "../../../Url";
import ContextMenu from "../../NotWorkArea(Side&Head)/ContextMenu";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { DocFieldType } from "../../Tools/Consts";
import { closeModal } from "../../Tools/modalManager";
import { EditStyle } from "../../Tools/ProjectX";
import { getObjText, setParamObjectText } from "../../Tools/Requests";
import {
  defaultFieldInfo,
  getDateOfInt,
  getDefaultMenu,
  getIntOfStrDate,
  getStrDateOfDateObj,
} from "../../Tools/Tools";

/**
 * @param {Object} props
 * @param {string} [props.Module]
 * @param {string | number} [props.DataType]
 * @param {string | number} [props.EditStyle]
 * @param {string | number} [props.Options]
 * @param {Map} props.paramsRequest
 * @param {Function} [props.onSelect]
 */

export default function DialogHistory(props) {
  const gridPanelRef = React.useRef();
  const [mainGrid, setMainGrid] = React.useState();
  const records = React.useRef([]);
  const currentDay = React.useRef();
  const valueList = React.useRef([]);

  React.useEffect(() => {
    GetParamHistory().then((res) => {
      const result = [];
      if (res?.Items?.items?.length) {
        res.Items.items.forEach((item, index) => {
          result.push(item);
          result[index].UsedDate
            ? (result[index].UsedDate = {
                text: getDateOfInt(result[index].UsedDate),
                id: result[index].UsedDate,
              })
            : (result[index].UsedDate = "");
        });
      }
      if (res.UsedDate) {
        currentDay.current = {
          text: getDateOfInt(res.UsedDate),
          id: res.UsedDate,
        };
      } else {
        const strDate = getStrDateOfDateObj(new Date());
        currentDay.current = { text: strDate, id: getIntOfStrDate(strDate) };
      }
      if (res.Values) {
        Object.values(res.Values).forEach((item) => {
          if (typeof item === "object") {
            valueList.current.push({ label: item.text, id: item.id });
          } else {
            valueList.current.push({ label: item });
          }
        });
      }
      records.current = result;
      mainGridLoader();
    });
  }, []);

  async function deleteRecord(request, data) {
    const deletedID = gridPanelRef.current.grid.source.getFieldTextSync("ID", request.RecordIndex);
    const deletedIndex = records.current.findIndex((record) => record.ID === deletedID);
    if (deletedIndex !== -1) records.current.splice(deletedIndex, 1);
    return {
      Command: "DeleteRecord",
      RecordIndex: records.current.length === 0 ? 0 : request.RecordIndex - 1,
      RecordCount: records.current.length,
      UpdatesPending: "1",
      RecordKey: {},
    };
  }

  function createRecord(request, data) {
    const index = records.current.length ? gridPanelRef.current.grid.source.activeRecord + 1 : 0;
    const newRecords = [...records.current];
    newRecords.splice(index, 0, { UsedDate: currentDay.current });
    return {
      index: index,
      records: newRecords,
      keys: ["UsedDate"],
      titleKey: "Text",
    };
  }

  function checkFieldText(request) {
    const val = getObjText(props.Module, props.DataType, request.ObjRef, "-1");
    return { Text: val.Text, ObjRef: request.ObjRef, EditVal: val.Text };
  }

  async function mainGridLoader() {
    const res = await GridLoader(gridPanelRef.current, props, {
      localGrid: true,
      refresh: false,
      Columns: getColumns(),
      defaultFields: getDefaultFields(),
      keyField: "ID",
      recordKeys: ["ID", "UsedDate", "ObjRef", "Text", "EditVal"],
      titleKey: "Text",
      getFieldText: getFieldText,
      checkFieldText: checkFieldText,
      onPostRecord: onPostRecord,
      createRecord: createRecord,
      deleteRecord: deleteRecord,
      getRecords: () => records.current,
      Rights: "-1",
    });
    setMainGrid(res);
  }

  function findFreeId(records) {
    const ids = records.map((record) => parseInt(record.ID, 10)).filter((id) => !isNaN(id));
    if (ids.length === 0) {
      return "1";
    }
    for (let i = 1; ; i++) {
      if (!ids.includes(i)) {
        return i.toString();
      }
    }
  }

  async function onPostRecord(request, requestData, data) {
    let attributeIndex = 0;
    if (request.NewRecord === "1") {
      records.current.splice(request.RecordIndex, 0, {
        ID: findFreeId(records.current),
        UsedDate: currentDay.current,
      });
      if (requestData.length === 4) {
        attributeIndex = 3;
      } else attributeIndex = 1;
    }
    const modifiedField = request.RecordSet.Attributes[attributeIndex],
      modifiedData = requestData[attributeIndex];
    const record = records.current[request.RecordIndex];
    if (modifiedField === "UsedDate") {
      let newUsedDate = "";
      if (modifiedData.id)
        newUsedDate = {
          text: getDateOfInt(modifiedData.id),
          id: modifiedData.id,
        };
      record.UsedDate = newUsedDate;
    } else {
      let newText = "",
        newObjRef = "",
        newEditVal = "",
        json;
      if (props.EditStyle == EditStyle.PickList && props.Module === undefined) {
        newText = modifiedData.text;
        newEditVal = modifiedData.text;
        newObjRef = modifiedData.id;
      } else if (props.EditStyle == EditStyle.Check) {
        newObjRef = modifiedData.id;
      } else if (modifiedData) {
        if (!modifiedData.id) {
          json = await setParamObjectText(props.Module, props.DataType, record.ObjRef, modifiedData.text);
          newText = json.Text;
          newObjRef = json.ObjRef;
          newEditVal = json.EditVal || newText;
        } else {
          newObjRef = modifiedData.id;
          newText = getObjText(props.Module, props.DataType, newObjRef, "-1").Text;
          newEditVal = newText;
        }
      }
      record.ObjRef = newObjRef;
      record.Text = newText;
      record.EditVal = newEditVal;
    }
  }

  async function textDropDownList() {
    if (props.Module) {
      const params = new Map();
      params.set("prefix", props.Module).set("comand", "GetObjectValues").set("ObjType", props.DataType);
      let json = await AxiosRequest(true, params);
      json = Object.values(json.Items);
      return json.map((item) => {
        return { label: item.text, id: item.id };
      });
    } else {
      return valueList.current;
    }
  }

  function getColumns() {
    return {
      title: [
        {
          fieldName: "UsedDate",
          title: "Дата",
          width: 100,
          fieldInfo: {
            ...defaultFieldInfo,
            DataType: DocFieldType.IntDate,
            EditStyle: EditStyle.Calendar,
          },
        },
        {
          fieldName: "Text",
          title: "Значение",
          width: 200,
          onDropDownList: textDropDownList,
          fieldInfo: {
            ...defaultFieldInfo,
            DataType: props.DataType,
            EditStyle: props.EditStyle,
            Options: props.Options,
            Module: props.Module,
          },
        },
      ],
    };
  }

  function getDefaultFields() {
    return [
      {
        Name: "ID",
        Title: "ID",
        Width: "7",
      },
      {
        Name: "UsedDate",
        Title: "Дата",
        Width: "10",
      },
      {
        Name: "ObjRef",
        Title: "ObjRef",
        Width: "7",
      },
      {
        Name: "Text",
        Title: "Значение",
        Width: "20",
      },
      {
        Name: "EditVal",
        Title: "Наименование для редактора",
        Width: "30",
      },
    ];
  }

  function getFieldText(request, data) {
    if (records.current.length) {
      let val = request.FieldName === "Text" ? "EditVal" : request.FieldName;
      if (gridPanelRef.current.grid.source.getState() === 3) {
        if (val === "EditVal") return { Text: "" };
        return { Text: currentDay.current.text };
      }
      const recVal = records.current[request.RecordIndex];
      return { Text: recVal[val]?.text || recVal[val] };
    }
    return { Text: "" };
  }

  function GetParamHistory() {
    const params = props.paramsRequest;
    params.set("comand", "GetParamHistory");
    return AxiosRequest(true, params);
  }

  async function SetParamHistory() {
    const params = props.paramsRequest;
    params.set("comand", "SetParamHistory");
    const data = [];
    records.current.forEach((element) => {
      const dataItem = {};
      if (element.UsedDate.id) dataItem.UsedDate = element.UsedDate.id;
      if (element.ObjRef) dataItem.ObjRef = element.ObjRef;
      data.push(dataItem);
    });
    const json = await AxiosRequest(true, params, { Items: data });
    props.onSelect?.(json);
  }

  return (
    <>
      <Box style={{ width: "100%", height: "calc(100% - 40px)" }}>
        <Grid
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid #cccccc",
            overflow: "auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <ContextMenu for="grid" Menu={getDefaultMenu("history")} refs={{ current: mainGrid }}>
            <div id={`rcgrid_panel`} ref={gridPanelRef} style={{ width: "100%", height: "100%" }}></div>
          </ContextMenu>
        </Grid>
      </Box>
      <Box style={{ height: "35px", width: "100%", marginTop: "5px" }}>
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none", marginRight: "10px" }}
              value="1"
              className="button_Modal_Select"
              onClick={(ev) => {
                SetParamHistory();
                closeModal();
              }}
            >
              Выбрать
            </Button>
            <Button
              size="small"
              variant="outlined"
              style={{ textTransform: "none" }}
              value="2"
              className="button_Modal_Close"
              onClick={(ev) => {
                closeModal();
              }}
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
