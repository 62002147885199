import React from "react";
import TestEditor from "../../../../../Editor/testEditor";
import { GridLoader } from "../../../../rcsgrid/createGridTools";
import { defaultFieldInfo } from "../../../../Tools/Tools";
import { AxiosRequest } from "../../../../../Url";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

export default function PaytypesLinksForTabs({ props }) {
  const gridPanelRef = React.useRef();
  const [records, setRecords] = React.useState();
  const [mainGrid, setMainGrid] = React.useState();

  const box = React.useRef();

  React.useEffect(() => {
    box.current.update = update;
    if (props.onRequest) {
      props.onRequest().then((res) => {
        if (res && res.items) setRecords(res.items);
      });
    }
  }, []);

  React.useEffect(() => {
    if (records) {
      if (mainGrid === undefined) {
        mainGridLoader();
      } else {
        mainGridLoader(true);
      }
    }
  }, [records]);

  async function mainGridLoader(refresh) {
    const res = await GridLoader(
      gridPanelRef.current,
      {},
      {
        localGrid: true,
        refresh: refresh,
        source: refresh ? mainGrid.source : undefined,
        Columns: getColumns(),
        defaultFields: getDefaultFields(),
        keyField: "Code",
        getRecords: () => records,
        recordKeys: ["Code", "Image", "Name"],
        titleKey: "Name",
      },
    );
    setMainGrid(res);
  }

  function getColumns() {
    return {
      title: [
        {
          fieldName: "Image",
          title: " ",
          width: 18,
          fieldInfo: { ...defaultFieldInfo, Type: "Image" },
        },
        {
          fieldName: "Name",
          title: "Вид оплаты",
          width: document.body.getBoundingClientRect().width,
          fieldInfo: { ...defaultFieldInfo },
        },
      ],
    };
  }

  function getDefaultFields() {
    return [
      {
        Name: "Code",
        Title: "Code",
        Width: "7",
      },
      {
        Name: "Image",
        title: " ",
        width: "20",
        fieldInfo: { ...defaultFieldInfo, Type: "Image" },
      },

      {
        Name: "Name",
        Title: "Вид оплаты",
        Width: "20",
      },
    ];
  }

  function update(id) {
    props.onRequest(id).then((res) => {
      if (res && res.items) {
        setRecords(res.items);
      } else setRecords([]);
    });
  }

  return (
    <div
      ref={box}
      id={"linksBox"}
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <div style={{ height: "100%", width: "100%" }}>
        <div
          id={`rcgrid_panel`}
          ref={gridPanelRef}
          style={{
            height: "100%",
            width: "100%",
            position: "relative",
          }}
        ></div>
      </div>
    </div>
  );
}
