import { Box, Button, Grid } from "@mui/material";
import React from "react";
import { closeModal } from "../../MainPage/Tools/modalManager";

// Функция для преобразования строки входных данных в массив объектов
const parseInputData = (data) => {
  try {
    // Удаление первой и последней квадратных скобок
    let groups = data;
    const index = groups.indexOf("");
    if (index !== -1) {
      groups.splice(index, 1);
    }
    // Разделение строки на группы по ', '
    return groups.map((group) => {
      const [item1, item2, item3] = group.split(";"); // Разделение группы на элементы
      return { item1, item2, item3 };
    });
  } catch (error) {
    console.error("Error parsing input data:", error);
    return [];
  }
};

const ChooseCert = ({ data, onSelect }) => {
  const [selected, setSelected] = React.useState(0);

  return (
    <Grid container direction="column" justifyContent="center" alignItems="flex-start">
      <Grid item sx={{ width: "100%", overflow: "auto", height: "428px" }}>
        <Box>
          {parseInputData(data).map((data, index) => (
            <Grid
              key={index}
              style={{
                border: `${selected === index ? "3px" : "1px"} solid #ccc`,
                padding: "10px",
                margin: "10px",
                backgroundColor: selected === index ? "#e7e7e7" : "",
              }}
              onClick={() => {
                setSelected(index);
              }}
            >
              <p>
                <strong>Серийный номер</strong> {data.item1}
              </p>
              <p>
                <strong>Тип</strong> {data.item2}
              </p>
              <p>
                <strong>Организация</strong> {data.item3}
              </p>
            </Grid>
          ))}
        </Box>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" style={{ height: "30px" }}>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none" }}
            value="1"
            className="button_Modal_Select"
            onClick={() => {
              const sn = data[selected].split(";")[0];
              onSelect(sn);
              closeModal();
            }}
          >
            {"Выбрать"}
          </Button>
          <Button
            size="small"
            variant="outlined"
            style={{ textTransform: "none", marginLeft: 10 }}
            value="2"
            className="button_Modal_Close"
            onClick={() => {
              closeModal();
            }}
          >
            Отмена
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChooseCert;
