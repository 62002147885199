import React, { useRef, useLayoutEffect } from "react";
import { AxiosRequest } from "../../../Url";
import { tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import { GridLoader } from "../../rcsgrid/createGridTools";

export default function DialogCert(props) {
  const gridpanelRef = useRef(null);
  const [mainGrid, setMainGrid] = React.useState();

  useLayoutEffect(() => {
    GridLoader(gridpanelRef.current, props).then((res) => {
      setMainGrid(res);
    });
  }, []);

  async function InputButton(event, RequestID) {
    let params = new Map(),
      data,
      json,
      ClickedButton = event.currentTarget.value;
    if (ClickedButton === "1") {
      data = {
        Result: ClickedButton,
        RecordIndex: mainGrid.source.activeRecord,
      };
      // if (props.EditorMode) {props.input.value=textValue}
    } else {
      data = { Result: ClickedButton };
    }
    if (RequestID) {
      params.set("prefix", "project");
      params.set("comand", "ResumeRequest");
      params.set("RequestID", RequestID);
      params.set("WSM", "1");
      json = await AxiosRequest(true, params, data);
      tokenProcessingTest(json, {
        func: props.setReturnValue,
        propsId: props.id,
        from: props.from,
      });
    }
    if (props.from === "forms") {
      // props.input.dataset.objref = objref
      // props.onEdit(props.input)
    }
  }

  return (
    <>
      <Box style={{ width: "100%", height: "calc(100%-20)" }}>
        <Grid
          rcsgrid_panel=""
          ref={gridpanelRef}
          data-objref={gridpanelRef}
          style={{
            position: "absolute",
            width: "calc(100% - 20px)",
            height: "calc(100% - 103px)",
          }}
        >
          <Paper elevation={4}></Paper>
        </Grid>
      </Box>
      <Box style={{ width: "100%", display: "flex" }}>
        <Box
          style={{
            marginLeft: "auto",
            position: "absolute",
            bottom: "8px",
            right: "10px",
          }}
        >
          <Button
            size="small"
            variant="outlined"
            value={1}
            onClick={(e) => InputButton(e, props.RequestID)}
            style={{ textTransform: "none", width: 75 }}
            className="button_Modal_Select"
          >
            Выбрать
          </Button>
          <Button
            size="small"
            variant="outlined"
            value={2}
            onClick={(e) => InputButton(e, props.RequestID)}
            style={{ textTransform: "none", marginLeft: 10, width: 75 }}
            className="button_Modal_Close"
          >
            Отмена
          </Button>
        </Box>
      </Box>
    </>
  );
}
