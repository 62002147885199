import React from "react";
import { GridLoader } from "../../rcsgrid/createGridTools";
import { TabItem } from "smart-webcomponents-react/tabs";
import Search from "./Components/Search";
import ContentOrganization from "./Components/ContentOrganization";
import {
  GetDialogParamsForSearch,
  PostHandleTable,
  getBookContextMenu,
  handleTable,
  treeRequest,
} from "../../Tools/Requests";
import TestTree from "../../Windows/ViewData/Tree/testTree";
import { parseColumns } from "../../rcsgrid/GridTools/parseColumnTools";
import { AxiosRequest } from "../../../Url";
import { tokenProcessingTest } from "../../../TokenProcessing/TokenProcessing";
import { formEdit, getRightsData } from "../../Tools/Tools";

async function saveCurrentGrid(props, gridObject, groupID, setSelectedRecordID, mainSources, ObjRef) {
  const json = await PostHandleTable(props.Module, "0", groupID, props.SectionID);

  if (json) {
    gridObject.source.rights = getRightsData(json.Rights);
  }

  const objType = json ? json.ObjType : props.ObjType;

  const columns = parseColumns(json); // функция для выбора нужных полей parseColumnsWithOptions из createGridTools.js
  gridObject.grid.setColumns(columns);

  gridObject.source.onHandleRequest = handleTable({
    Module: props.Module,
    ObjType: objType,
    GroupID: groupID,
    selectedRecordID: ObjRef ? ObjRef : "0",
    SectionID: props.SectionID,
  });
  await gridObject.source.first();
  gridObject.source.onRecordIndexChanged = function (source) {
    setSelectedRecordID(source.getFieldTextSync("ID"));
  };
  await gridObject.grid.setSource(gridObject.source);

  await gridObject.grid.refreshSource(ObjRef ? ObjRef : "0");

  gridObject.grid.updateGridSize();

  setSelectedRecordID(gridObject.source.getFieldTextSync("ID"));
  mainSources.set(groupID, {
    columns: columns,
    ID: gridObject.source.getFieldTextSync("ID"),
    request: handleTable,
    objType: objType,
  });
}

export default function DialogPerson({ props }) {
  //хуки для обновления Grid
  const [mainGrid, setMainGrid] = React.useState(undefined);
  const [selectedRecordID, setSelectedRecordID] = React.useState(props.ObjRef);

  //хук выбора номера(ID) таблицы из дерева
  const [groupID, setGroupID] = React.useState(props.NodeID ? props.NodeID : "0");

  const firstLoad = React.useRef(true);

  //хук для хранения Sources Для Таблицы
  const mainSources = React.useRef(new Map());

  //хук для отображения количества записей
  const [countRecords, setCountRecords] = React.useState("0");

  const [searchResult, setSearchResult] = React.useState();

  //ссылка на компонент с таблицей
  const gridPanel = React.useRef();
  const tabsPanel = React.useRef();

  // сохраняет в памяти компонент с деревом
  const [tree, setTree] = React.useState();
  //Сохраняет в памяти компонент TabItem Search
  const [tab, setTab] = React.useState();

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////Секция запросов/////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  React.useEffect(() => {
    setTree(null);
    setMainGridLoader();
  }, []);
  //запрос для установки нового свойства
  // function setParamProperty(Path, ID, selectRecord) {
  //   const params = new Map();
  //   params
  //     .set("prefix", "programs")
  //     .set("comand", "SetParamProperty")
  //     .set("Path", Path)
  //     .set("ID", ID)
  //     .set("GroupID", "0")
  //     .set("ObjRef", selectRecord)
  //     .set("CheckState", "1")
  //     .set("TextChanged", "0")
  //     .set("WSM", "1");
  //   return XMLrequest(params);
  // }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////Секция Grid(Таблицы)////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  //Первичная отрисовка Таблицы
  React.useEffect(() => {
    // обновление дерева при обновлении выбора таблиц из функций и поиска
    if (tree === null) {
      setTree(
        <TestTree
          props={{
            CLSID: props.CLSID,
            multiCheck: false,
            ObjType: props.ObjType,
            SectionID: props.SectionID,
            Module: props.Module,
            Current: groupID,
            ObjRef: groupID,
            selectID: setGroupID,
            UsedDate: "0",
            Info: "0",
            Internal: "0",
          }}
        />,
      );
      return;
    }
    // setTimeout(() => {

    // });

    if (groupID === "0") {
      setGroupID(treeRequest("persons", props.ObjType, props.SectionID).Current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tree]);

  React.useLayoutEffect(() => {
    if (mainGrid) {
      setTab(getTabItem());
      if (groupID) {
        saveCurrentGrid(
          props,
          mainGrid,
          groupID,
          setSelectedRecordID,
          mainSources.current,
          selectedRecordID ? selectedRecordID : "0",
        ).then(() => {
          firstLoad.current = false;

          //Подсчет количества записей в выбранной таблице при первой отрисовке
          setCountRecords(mainGrid.source.recordCount);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainGrid]);

  //Выбор Source и Columns для таблиц в зависимости от выбранной таблицы в дереве
  React.useLayoutEffect(() => {
    console.log(groupID);

    //Выбор Source
    if (groupID) {
      setMainSource().then(() => {
        //Количество записей в таблице
        if (mainGrid) setCountRecords(mainGrid.source.recordCount);

        //выбор записи для доролнительных таблиц при переключении между таблицами в дереве
        if (mainSources.current.get(groupID)) {
          setSelectedRecordID(mainSources.current.get(groupID).ID);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
      });
    }
  }, [groupID]);

  React.useEffect(() => {
    if (mainGrid && mainSources.current.get(groupID)) {
      mainSources.current.get(groupID).ID = selectedRecordID;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordID]);

  //обновление в зависимости от результатов поиска
  React.useLayoutEffect(() => {
    if (mainGrid && searchResult) {
      // получение id таблицы для записи из поиска
      const id = GetDialogParamsForSearch(props.Module, props.ObjType, searchResult).NodeID;
      //если id таблицы совпадает с id выбранной таблицы то позиционирумся на запись из поиска
      if (id === groupID) {
        findSearchRecordFromTable();
        //если не совпадает, то очищаем дерево, что в последствии инициирует создание нового дерева с нужным GroupID
      } else {
        setTree(null);
        setGroupID(id);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);

  //обновление Таблиц при изменении размера родительского окна

  //функция позиционирования таблицы при выборе значений из поиска
  async function findSearchRecordFromTable() {
    await mainGrid.grid.refreshSource(searchResult).then();
    setSelectedRecordID(searchResult);
    setSearchResult(undefined);
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////секция создания Таблиц//////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  //Сохранение и Выбор Source
  async function setMainSource() {
    if (mainGrid && mainSources.current.get(groupID) === undefined) {
      //Сохранение новых Sources если они не были сохранены ранее
      await saveCurrentGrid(
        props,
        mainGrid,
        groupID,
        setSelectedRecordID,
        mainSources.current,
        firstLoad.current ? props.ObjRef : "0",
      );
      firstLoad.current = false;
    } else if (mainGrid) {
      //Выбор Source в зависимости от выбранной таблицы в дереве
      const mainSource = mainSources.current.get(groupID);
      mainGrid.grid.setColumns(mainSource.columns);
      mainGrid.source.onHandleRequest = mainSource.request({
        Module: props.Module,
        ObjType: mainSource.objType,
        GroupID: groupID,
        selectedRecordID: mainSource.ID ? mainSource.ID : "0",
        SectionID: props.SectionID,
      });
      if (!searchResult) {
        await mainGrid.grid.refreshSource(mainSource.ID);
      }

      await mainGrid.grid.setSource(mainGrid.source);
      mainGrid.grid.updateGridSize();
    }

    if (mainGrid) {
      if (searchResult) {
        await findSearchRecordFromTable();
        return;
      }
    }
  }

  //Функции создания таблиц
  async function setMainGridLoader() {
    if (!mainGrid) {
      setMainGrid(
        await GridLoader(gridPanel.current, props, {
          GroupID: groupID,
          setSelectedRecord: setSelectedRecordID,
          selectedRecordID: selectedRecordID,
        }),
      );
    }
  }

  //Функции для обновления Таблиц
  // function refreshGrid() {
  //   setTimeout(() => {
  //     testUpdateGrid();
  //   }, 200);
  // }

  // function testUpdateGrid() {
  //   if (mainGrid) mainGrid.grid.updateGridSize();
  // }

  function getTabItem() {
    return (
      <TabItem
        key={"tabItem:Search"}
        style={{
          textTransform: "none",
          width: "100%",
          height: "100%",
          padding: "0px",
          display: "inline-block",
        }}
        label={"Поиск"}
      >
        <div id={"item:Search"} style={{ height: "100%", width: "100%" }}>
          <Search GroupId={groupID} Module={props.Module} ObjType={props.ObjType} return={setSearchResult} />
        </div>
      </TabItem>
    );
  }

  //кнопка Выбрать
  async function onSelect() {
    if (props.onEdit && props.setValue && props.setInputValue) {
      await props.onEdit({
        tag: selectedRecordID,
        groupid: groupID,
        name: props.Name,
        record: props.record,
        textchanged: "0",
        setValue: props.setValue,
        setInputValue: props.setInputValue,
        type: props.Module,
        requestId: props.RequestID,
        addInfo: props.addInfo,
      });
      return;
    }

    if (props.RequestID) {
      await formEdit("1", props.func, { Name: "ObjRef", Value: selectedRecordID }, props.RequestID, props.from);
    }
  }

  function BookContextMenuHandler(data) {
    // GET /organizations~BookContextMenuHandler?LicGUID=003E967942C5ED97F5E1A5A51C3AB859&ID=2&ObjRef=165&ObjType=2310&WSM=1
    const params = new Map();
    params
      .set("prefix", props.Module)
      .set("comand", "BookContextMenuHandler")
      .set("ID", data.id)
      .set("ObjRef", data.ObjRef)
      .set("ObjType", data.ObjType)
      .set("WSM", "1");

    if (data.SectionID) params.set("SectionID", data.SectionID);
    return AxiosRequest(true, params);
  }

  async function ContextMenuHandler(data) {
    const comand = data.value;
    let json;
    switch (comand) {
      case "":
        break;

      default:
        if (data.ID !== undefined) {
          json = await BookContextMenuHandler({
            id: data.ID,
            ObjRef: selectedRecordID,
            ObjType: mainSources.current.get(groupID).objType,
          });
          if (json.Token) {
            tokenProcessingTest(json, {
              func: () => mainGrid.grid.refreshSource(selectedRecordID),
            });
          } else mainGrid.grid.refreshSource(selectedRecordID);
        }
        break;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////
  /////////////////////////отрисовка справочника///////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <ContentOrganization
        props={props}
        data={{
          tabs: tab,
          tree: tree,
          gridPanel: gridPanel,
          mainGrid: mainGrid,
          countRecords: countRecords,
          onSelect: async () => {
            await onSelect();
          },
          onCancel: async () => {
            if (props.RequestID) {
              await formEdit("0", props.func, { Name: "ObjRef", Value: selectedRecordID }, props.RequestID, props.from);
            }
          },
          tabsPanel: tabsPanel,
          mainMenuSelector: "organizations",
          mainMenuRequest: getBookContextMenu,
          ContextMenuHandler: ContextMenuHandler,
          objType: mainSources.current.get(groupID) ? mainSources.current.get(groupID).objType : props.ObjType,
          selectedRecordID: selectedRecordID ? selectedRecordID : "0",
        }}
        buttons={{
          search: 0,
          detail: true,
          tree: true,
          grouping: false,
          contragent: false,
        }}
      ></ContentOrganization>
    </>
  );
}
